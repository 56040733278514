import React from 'react';
import { Grid } from '@mui/material';

export default function HtmlText({ value, ...props }) {
  return (
    <Grid container alignItems="baseline" sx={{ p: 1 }} {...props}>
      {value && (
        <Grid
          sx={{
            '& > *': {
              height: 'auto !important',
              width: 'auto !important',
              maxWidth: '100% !important',
              fontFamily: 'Roboto, sans-serif',
              fontSize: '13pt',
              margin: 0,
            },
          }}
          dangerouslySetInnerHTML={{ __html: value }}
        />
      )}
    </Grid>
  );
}
