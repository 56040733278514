import React, { useState, useEffect } from 'react';
import { Typography, TextField, DialogContent, DialogTitle, Grid } from '@mui/material';
import { RoundedDialog, StandardDialogActions } from './index';

export default function TextDialog({ open, initial, editable, onClose, onResult, title, required }) {
  const [text, setText] = useState('');

  useEffect(() => {
    if (!open) return;
    setText(initial || '');
  }, [initial, open]);

  function done() {
    onResult(text);
    onClose();
    setText('');
  }

  return (
    <RoundedDialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>{title || 'Text'}</DialogTitle>
      <DialogContent>
        <Grid container>
          {editable && (
            <TextField
              fullWidth
              variant="outlined"
              multiline
              minRows={5}
              maxRows={50}
              value={text}
              autoFocus
              onChange={(e) => setText(e.target.value)}
            />
          )}
          {!editable && <Typography style={{ whiteSpace: 'break-spaces' }}>{text}</Typography>}
        </Grid>
      </DialogContent>
      <StandardDialogActions onClose={onClose} onDone={done} hideDone={!editable || (required && !text)} />
    </RoundedDialog>
  );
}
