import React from 'react';
import { Grid, Typography, CircularProgress } from '@mui/material';
import { PieChart } from '@mui/x-charts/PieChart';
import { BarChart } from '@mui/x-charts/BarChart';
import { LineChart } from '@mui/x-charts/LineChart';
import { isArray, isEmpty, isFunction } from 'lodash';
import ComboChart from './ComboChart';

export default function Chart({
  data,
  chartType,
  barChartType,
  onBarClick,
  onPieClick,
  onMarkClick,
  loading,
  isPreview,
  numberChartFontSize,
}) {
  if (loading) {
    return (
      <Grid container alignItems="center" justifyContent="center" style={{ height: '100%' }}>
        <CircularProgress />
      </Grid>
    );
  }

  const isComboChart =
    ['ColumnChart', 'bar'].includes(chartType) && isArray(data?.targetLines) && !isEmpty(data.targetLines);

  const legendHidden = Boolean(isPreview);

  if (isComboChart) {
    return <ComboChart data={data} barChartType={barChartType} onBarClick={onBarClick} hideLegend={legendHidden} />;
  }

  if (chartType === 'number') {
    return (
      <Grid container alignItems="center" justifyContent="center" style={{ height: '100%' }}>
        <Typography style={{ fontSize: numberChartFontSize || (isPreview ? '10rem' : '16rem') }}>
          {data?.count || 0}
        </Typography>
      </Grid>
    );
  }

  if (['PieChart', 'pie'].includes(chartType) || !chartType) {
    if (!isArray(data)) return null;

    if (isEmpty(data)) {
      return (
        <Grid container alignItems="center" justifyContent="center" style={{ height: '100%' }}>
          <Typography color={'textSecondary'} variant={isPreview ? 'subtitle1' : 'h6'}>
            No data to display
          </Typography>
        </Grid>
      );
    }

    // v7.3.2 - bug if slotProps are passed legeng labels overlap
    const props = isPreview
      ? {
          slotProps: {
            legend: {
              labelStyle: {
                fontSize: 11,
                maxWidth: 50,
              },
              itemMarkWidth: 8,
              itemMarkHeight: 8,
            },
          },
        }
      : {};

    return (
      <PieChart
        series={[
          {
            data,
            cornerRadius: 4,
            innerRadius: 24,
          },
        ]}
        onItemClick={(_event, _info, pieData) => isFunction(onPieClick) && onPieClick(pieData)}
        tooltip={{ trigger: 'item' }}
        {...props}
      />
    );
  }

  const xAxisLabel = data?.xAxisLabel || '';
  const yAxisLabel = data?.yAxisLabel || '';

  if (['ColumnChart', 'bar'].includes(chartType)) {
    // if render as 'no data'
    if (data?.series === null) {
      return (
        <Grid container alignItems="center" justifyContent="center" style={{ height: '100%' }}>
          <Typography color={'textSecondary'} variant={isPreview ? 'subtitle1' : 'h6'}>
            No data to display
          </Typography>
        </Grid>
      );
    }

    // something's wrong
    if (!isArray(data?.series) || !isArray(data?.xAxis)) return null;

    const series = data.series.map((x, i) => ({
      ...x,
      id: String(i),
      stack: barChartType === 'stacked' ? 'total' : undefined,
    }));

    const props = legendHidden
      ? { slotProps: { legend: { hidden: true } } }
      : {
          slotProps: {
            legend: {
              labelStyle: {
                fontSize: 14,
                maxWidth: 150,
              },
              itemMarkWidth: 12,
              itemMarkHeight: 12,
            },
          },
        };

    return (
      <BarChart
        series={series}
        xAxis={[{ data: data.xAxis, scaleType: 'band', label: xAxisLabel }]}
        yAxis={[{ label: yAxisLabel }]}
        onItemClick={(_event, { dataIndex, seriesId }) => isFunction(onBarClick) && onBarClick(seriesId, dataIndex)}
        grid={{ horizontal: true, vertical: true }}
        tooltip={{ trigger: 'item' }}
        {...props}
      />
    );
  }

  if (['LineChart', 'line'].includes(chartType)) {
    // if render as 'no data'
    if (data?.data === null) {
      return (
        <Grid container alignItems="center" justifyContent="center" style={{ height: '100%' }}>
          <Typography color={'textSecondary'} variant={isPreview ? 'subtitle1' : 'h6'}>
            No data to display
          </Typography>
        </Grid>
      );
    }

    // something's wrong
    if (!isArray(data?.data) || !isArray(data?.xAxis)) return null;

    const props = legendHidden
      ? { slotProps: { legend: { hidden: true } } }
      : {
          slotProps: {
            legend: {
              labelStyle: {
                fontSize: 14,
                maxWidth: 150,
              },
              itemMarkWidth: 12,
              itemMarkHeight: 12,
            },
          },
        };

    return (
      <LineChart
        xAxis={[{ data: data.xAxis, scaleType: 'band', label: xAxisLabel }]}
        yAxis={[{ label: yAxisLabel }]}
        series={[
          {
            data: data.data,
            label: data.label || 'Value',
          },
          ...(isArray(data.targetLines) ? data.targetLines : []),
        ]}
        onMarkClick={(_event, { dataIndex }) => isFunction(onBarClick) && onMarkClick(dataIndex)}
        grid={{ horizontal: true, vertical: true }}
        tooltip={{ trigger: 'item' }}
        {...props}
      />
    );
  }

  return null;
}
