import React from 'react';
import { LooksTwoRounded, EventRounded, EditRounded, TableChartOutlined, SubjectRounded } from '@mui/icons-material';
import { TextFieldsRounded, ScheduleRounded, PanoramaRounded, DescriptionRounded } from '@mui/icons-material';
import { FlashOnRounded, ExpandMoreRounded, CheckBoxOutlined, PersonRounded, FlagRounded } from '@mui/icons-material';
import { PhoneRounded, AlternateEmailRounded, ShowChartRounded, Adjust, GroupAddRounded } from '@mui/icons-material';
import { CallSplitRounded, LineWeightRounded, EngineeringRounded, TodayRounded } from '@mui/icons-material';
import { DrawRounded, AccessibilityRounded } from '@mui/icons-material';
import { grey } from '@mui/material/colors';
import { DataIcon, CalculatorIcon } from '../Icons';

const fieldIconColor = grey[500];

const fieldTypes = {
  text: {
    text: 'Text',
    icon: <TextFieldsRounded style={{ color: fieldIconColor }} />,
  },
  textArea: {
    text: 'Text area',
    icon: <SubjectRounded style={{ color: fieldIconColor }} />,
  },
  number: {
    text: 'Number',
    icon: <LooksTwoRounded style={{ color: fieldIconColor }} />,
  },
  date: {
    text: 'Date',
    icon: <EventRounded style={{ color: fieldIconColor }} />,
  },
  time: {
    text: 'Time',
    icon: <ScheduleRounded style={{ color: fieldIconColor }} />,
  },
  conditional: {
    text: 'Conditional',
    icon: <FlashOnRounded style={{ color: fieldIconColor }} />,
  },
  image: {
    text: 'Image',
    icon: <PanoramaRounded style={{ color: fieldIconColor }} />,
  },
  file: {
    text: 'File',
    icon: <DescriptionRounded style={{ color: fieldIconColor }} />,
  },
  dropbox: {
    text: 'Dropbox',
    icon: <ExpandMoreRounded style={{ color: fieldIconColor }} />,
  },
  tickbox: {
    text: 'Tickbox',
    icon: <CheckBoxOutlined style={{ color: fieldIconColor }} />,
  },
  dataSet: {
    text: 'Data set',
    icon: <DataIcon style={{ color: fieldIconColor }} />,
  },
  user: {
    text: 'User',
    icon: <PersonRounded style={{ color: fieldIconColor }} />,
  },
  signature: {
    text: 'Signature',
    icon: <EditRounded style={{ color: fieldIconColor }} />,
  },
  table: {
    text: 'Table',
    icon: <TableChartOutlined style={{ color: fieldIconColor }} />,
  },
  status: {
    text: 'Status',
    icon: <FlagRounded style={{ color: fieldIconColor }} />,
  },
  phone: {
    text: 'Phone number',
    icon: <PhoneRounded style={{ color: fieldIconColor }} />,
  },
  email: {
    text: 'Email',
    icon: <AlternateEmailRounded style={{ color: fieldIconColor }} />,
  },
  stock: {
    text: 'Stock',
    icon: <ShowChartRounded style={{ color: fieldIconColor }} />,
  },
  calculation: {
    text: 'Calculation',
    icon: <CalculatorIcon style={{ color: fieldIconColor }} />,
  },
  action: {
    text: 'Action',
    icon: <Adjust style={{ color: fieldIconColor }} />,
  },
  people: {
    text: 'People',
    icon: <GroupAddRounded style={{ color: fieldIconColor }} />,
  },
  decision: {
    text: 'Decision',
    icon: <CallSplitRounded style={{ color: fieldIconColor }} />,
  },
  weightedList: {
    text: 'Weighted list',
    icon: <LineWeightRounded style={{ color: fieldIconColor }} />,
  },
  formEntry: {
    text: 'Form entry',
    icon: <DescriptionRounded style={{ color: fieldIconColor }} />,
  },
  actionGroup: {
    text: 'Action group',
    icon: <EngineeringRounded style={{ color: fieldIconColor }} />,
  },
  datetime: {
    text: 'Date Time',
    icon: <TodayRounded style={{ color: fieldIconColor }} />,
  },
  readAndSign: {
    text: 'Read and sign',
    icon: <DrawRounded style={{ color: fieldIconColor }} />,
  },
  humanMap: {
    text: 'Human body map',
    icon: <AccessibilityRounded style={{ color: fieldIconColor }} />,
  },
};

export default fieldTypes;
