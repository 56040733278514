import moment from 'moment';
import StateManager from '../../../Global/StateManager';
import { BASE_URL } from '../../../../constants';
import { useLocation } from 'react-router-dom';

import VpnKeyIcon from '@mui/icons-material/VpnKey';
import PersonIcon from '@mui/icons-material/Person';
import VisibilityIcon from '@mui/icons-material/Visibility';

export const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export const drawerWidth = 230;

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const VFORMAT = 'V1,V2,V3,V4';
export const NFORMAT = '01,02,03,04';
export const AFORMAT = 'a,b,c,d';

export const getStartValue = (option, format) => {
  return format === VFORMAT ? `V${option}` : format === NFORMAT ? option : String.fromCharCode(64 + option);
};

export const getRevisionNumber = (format, issue) => {
  if (format === 'A, B, C, D') {
    if (issue === 1) {
      return 'A';
    } else if (issue === 2) {
      return 'B';
    } else if (issue === 3) {
      return 'C';
    } else if (issue === 4) {
      return 'D';
    } else if (issue === 5) {
      return 'E';
    }
  } else if (format === '01, 02, 03, 04') {
    return `0${issue}`;
  } else if (format === 'V1, V2, V3, V4') {
    return `V${issue}`;
  }
};

export const avatarStyles = {
  width: 28,
  height: 28,
  display: 'inline-flex',
  verticalAlign: 'middle',
  textTransform: 'capitalize',
  color: 'black',
  backgroundColor: 'transparent',
};

export const extStyles = {
  width: 28,
  height: 28,
  fontSize: '0.60rem',
  display: 'inline-flex',
  textTransform: 'capitalize',
  verticalAlign: 'middle',
  color: 'black',
  backgroundColor: 'transparent',
  borderRadius: 0,
};

export const setupStyles = {
  fontWeight: 'bold',
  textTransform: 'none',
};

export const liveStyles = {
  fontWeight: 'bold',
  textTransform: 'capitalize',
};

export const tagColors = ['green', 'blue', 'purple', 'yellow', 'red', 'ice'];

export const tagStyles = (color) => {
  const style = {
    backgroundColor: `#${color}`,
    color: 'white',
  };
  switch (color) {
    case 'green':
      return style;
    case 'blue':
      return style;
    case 'purple':
      return style;
    case 'yellow':
      return {
        ...style,
        color: 'black',
      };
    case 'red':
      return style;
    case 'ice':
      return style;
    default:
      return style;
  }
};

function isTooDark(hexcolor) {
  var r = parseInt(hexcolor.substr(1, 2), 16);
  var g = parseInt(hexcolor.substr(3, 2), 16);
  var b = parseInt(hexcolor.substr(4, 2), 16);
  var yiq = (r * 299 + g * 587 + b * 114) / 1000;
  // Return new color if to dark, else return the original
  return yiq < 40 ? '#2980b9' : hexcolor;
}

export const colorRandomizer = () => {
  var randomColor = Math.floor(Math.random() * 16777215).toString(16);
  if (isTooDark(randomColor)) {
    return `#${randomColor}`;
  } else {
    colorRandomizer();
  }
};

export const dialogStyles = { borderRadius: 10 };

export const openFile = (url) => {
  window.open(url, '_blank');
};

export const downloadFile = (url, name) => {
  //download custom file name from url
  let title = name;
  const urlObj = new URL(url);
  // extract the file extension
  const extension = urlObj.pathname.split('.').pop();
  // add extension to the title if it is not already there
  if (title.split('.').pop() !== extension) {
    title = `${title}.${extension}`;
  }
  let xhr = new XMLHttpRequest();
  xhr.open('GET', url, true);
  xhr.responseType = 'blob';
  xhr.onload = function (e) {
    if (this.status == 200) {
      const blob = this.response;
      const a = document.createElement('a');
      document.body.appendChild(a);
      const blobUrl = window.URL.createObjectURL(blob);
      a.href = blobUrl;
      a.download = title;
      a.click();
      setTimeout(() => {
        window.URL.revokeObjectURL(blobUrl);
        document.body.removeChild(a);
      }, 0);
    }
    if (this.status == 400 || this.status == 500) {
      StateManager.setErrorAlert('Unable to download file');
    }
  };
  xhr.send();
};

export const downloadFileFromBlob = async (url, name) => {
  const blob = await fetch(url)
    .then((r) => r.blob())
    .catch((e) => StateManager.setErrorAlert('Unable to download file'));
  if (blob) {
    const blobUrl = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = blobUrl;
    const extension = url.split('.').pop();
    a.download = `${name}.${extension}`; // add custom extension here
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  }
};

export const goToFile = (doc, history) => {
  if (doc.documentStatus === 'published') {
    if (doc.area.personal) {
      if (doc.isFolder) {
        history.push(`/DocLibrary/myFiles/${doc.folder._id}`);
      } else {
        history.push('/DocLibrary/myFiles/');
      }
    } else {
      if (doc.isFolder) {
        history.push(`/DocLibrary/areas/${doc.area._id}/${doc.folder._id}`);
      } else {
        history.push(`/DocLibrary/areas/${doc.area._id}`);
      }
    }
  } else {
    history.push('/DocLibrary/draft');
  }
};

export const goToFileFromSearch = (doc, history) => {
  if (doc.documentStatus === 'published') {
    if (doc.area[0].personal) {
      if (doc.isFolder) {
        history.push(`/DocLibrary/myFiles/${doc.folder[0]._id}`);
      } else {
        history.push('/DocLibrary/myFiles/');
      }
    } else {
      if (doc.isFolder) {
        history.push(`/DocLibrary/areas/${doc.area[0]._id}/${doc.folder[0]._id}`);
      } else {
        history.push(`/DocLibrary/areas/${doc.area[0]._id}`);
      }
    }
  } else {
    history.push('/DocLibrary/draft');
  }
};

export const upload_handler = (blobInfo, success, failure, progress) => {
  var xhr, formData;
  const baseUpload = '/uploader/upload-single';
  xhr = new XMLHttpRequest();
  xhr.withCredentials = false;
  xhr.open('POST', BASE_URL + baseUpload);
  xhr.setRequestHeader('Authorization', `Bearer ${localStorage.getItem('token')}`);

  xhr.upload.onprogress = function (e) {
    progress((e.loaded / e.total) * 100);
  };

  xhr.onload = function () {
    var json;

    if (xhr.status === 403) {
      failure(`HTTP Error: ${xhr.status}`, { remove: true });
      return;
    }

    if (xhr.status < 200 || xhr.status >= 300) {
      failure(`HTTP Error: ${xhr.status}`);
      return;
    }

    json = JSON.parse(xhr.responseText);

    if (!json || typeof json.file.location != 'string') {
      failure(`Invalid JSON: ${xhr.responseText}`);
      return;
    }

    success(json.file.location);
  };

  xhr.onerror = function () {
    failure(`Image upload failed due to a XHR Transport error. Code: ${xhr.status}`);
  };

  formData = new FormData();
  formData.append('file', blobInfo.blob(), blobInfo.filename());

  xhr.send(formData);
};

export const nextReviewDate = (date, period, stage = 'months') => {
  return moment(date).add(Number(period), stage);
};

export const isReviewOverdue = (date, period, stage) => {
  return moment(nextReviewDate(date)).diff(moment(), 'days') <= 0 ? true : false;
};

export const formatDate = (date, format) => {
  if (!date) return '-';
  return moment(date).format(format);
};

export const formatRevision = (issue, format, manual, manualIssue) => {
  return manual
    ? manualIssue
    : format === VFORMAT
    ? `V${issue}`
    : format === NFORMAT
    ? issue
    : issue > 0
    ? String.fromCharCode(64 + issue)
    : 0;
};

export const formatIssueIteration = (issue, iterations, format, version, manual, manualIssue) => {
  const nextIteration = iterations?.length + 1 || version;
  if (manual) {
    return manualIssue ? [manualIssue, nextIteration].join('.') : nextIteration;
  } else {
    const combineString = [issue, nextIteration].join('.');
    return formatRevision(combineString, format);
  }
};

export const formatMiniRevisionIteration = (issue, format, version, manual, manualIssue) => {
  if (manual) {
    return manualIssue ? [manualIssue, version].join('.') : version;
  } else {
    const combineString = [issue, version].join('.');
    return formatRevision(combineString, format);
  }
};

export const COLORS = {
  ARCHIVE: '#7312a1',
  SETTINGS: '#404040',
  SEARCH: '#2196f3',
  BOOKMARK: '#ffa500',
  CONTROLLED: '#22216e',
  UPLOAD: '#4a8b87',
  PUBLIC: '#428a44',
  LIGHTBLUE: '#edf5fb',
  GREEN: '#4caf50',
  DARKPURPLE: '#081e5c',
  WHITE: '#ffffff',
  MAINBLUE: '#1976d2',
};

export const typeFormatter = (type) => {
  if (type === 'Editing') {
    return 'UpIssue';
  } else {
    return type;
  }
};

export const getObjectLength = (data, property = 'type', allowed = 'REFLINK') => {
  const object = Object.fromEntries(Object.entries(data).filter(([key, value]) => allowed === value[property]));
  return Object.keys(object).length;
};

export const ROLE = {
  Reviewer: 'Reviewer',
  Approver: 'Approver',
};

export const AREAROLES = [ROLE.Reviewer, ROLE.Approver];

function rgbToYIQ({ r, g, b }) {
  return (r * 299 + g * 587 + b * 114) / 1000;
}

function hexToRgb(hex) {
  if (!hex || hex === undefined || hex === '') {
    return undefined;
  }
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : undefined;
}

export function contrast(colorHex, threshold = 128) {
  if (colorHex === undefined) {
    return '#000';
  }
  const rgb = hexToRgb(colorHex);
  if (rgb === undefined) {
    return '#000';
  }
  return rgbToYIQ(rgb) >= threshold ? '#000' : '#fff';
}

export function ConvertUpperCase(value) {
  return value?.charAt(0)?.toUpperCase() + value?.slice(1);
}

export function removeWhitespaces(string) {
  return string.replace(/\s/g, '');
}

export const initialFolderTemplate = {
  _id: 'root',
  name: 'Root',
  children: [],
  type: 'folder',
  path: '',
};

export const REVIEW_STATUS = {
  Review: 'Under Review',
  ReviewOverdue: 'Review Overdue',
  ReviewToday: 'Review Today',
};

export const getReviewStatus = (date) => {
  if (!date) {
    return '';
  }
  const diff = moment(date).diff(moment(), 'days');
  if (diff === 0) {
    return REVIEW_STATUS.ReviewToday;
  } else if (diff < 0) {
    return REVIEW_STATUS.ReviewOverdue;
  } else {
    return `Next Review at ${moment(date).format('DD/MM/YYYY')}`;
  }
};

export const ISSUE_STATUS = {
  Editing: 'In Work',
  Reviewing: 'In Review',
  Approving: 'Waiting Approval',
};

export const DOCUMENT_STATUS = {
  Setup: 'Setup',
  PreApproval: 'Pre-approval',
  PreApprovalSetup: 'Pre-approval setup',
  Draft: 'Draft',
  Normal: 'Normal',
  Approved: 'Approved',
  Controlled: 'Controlled',
  UpIssuing: 'Up-Issuing',
  Template: 'Template',
  Archived: 'Archived',
  ...ISSUE_STATUS,
  ...REVIEW_STATUS,
};

export const ACCESS = {
  view: {
    label: 'Read Only',
    value: 'view',
    icon: <VisibilityIcon />,
  },
  standard: {
    label: 'Write/Edit',
    value: 'standard',
    icon: <PersonIcon />,
  },
  full: {
    label: 'Admin',
    value: 'full',
    icon: <VpnKeyIcon />,
  },
};

export const ACCESS_OPTIONS = [ACCESS.view, ACCESS.standard, ACCESS.full];

export const RUACCESS = {
  view: {
    label: 'Read Only',
    value: 'view',
    icon: <VisibilityIcon />,
  },
  admin: {
    label: 'Admin',
    value: 'admin',
    icon: <VpnKeyIcon />,
  },
};

export const RUACCESS_OPTIONS = [RUACCESS.view, RUACCESS.admin];
