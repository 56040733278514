import React from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { BASE_URL } from '../../../constants';

export default function MSViewer({ title, src, height = '75vh', marginTop = 15 }) {
  return (
    <Container maxWidth="xl" disableGutters>
      <Box
        component="iframe"
        src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(src)}`}
        title={title}
        sx={{ width: '100%', border: '1px solid #ccc', height, marginTop: `${marginTop}px` }}
      />
    </Container>
  );
}
