import React, { useState, useEffect } from 'react';
import { DialogContent } from '@mui/material';
import { RoundedDialog, StandardDialogActions } from '../Components';
import DataGridBuilder from './DataGridBuilder';
import StateManager from '../StateManager';
import { checkFieldColumns } from '../Functions';
import { cloneDeep } from 'lodash';

export default function FieldGridBuilderDialog({
  open,
  onClose,
  initial,
  onResult,
  dialogWidth = 'md',
  excludedTypes = ['conditional', 'table', 'stock', 'action'],
}) {
  const [result, setResult] = useState(null);

  useEffect(() => {
    if (!open) return;
    setResult(initial || { columns: [], rows: [] });
  }, [open, initial]);

  function done() {
    const { error } = checkFieldColumns(result?.columns);
    if (error) {
      StateManager.setErrorAlert(error);
      return;
    }
    onResult(result);
    onClose();
  }

  return (
    <RoundedDialog open={open} maxWidth={dialogWidth} fullWidth onClose={onClose}>
      <DialogContent>
        <DataGridBuilder
          initialColumns={result?.columns}
          initialRows={result?.rows}
          onTableChange={(res) => {
            const updated = cloneDeep({ ...result, ...res });
            setResult(updated);
          }}
          excludedTypes={excludedTypes}
        />
      </DialogContent>
      <StandardDialogActions
        onClose={onClose}
        onDone={done}
        hideDone={!Array.isArray(result?.columns) || result.columns.length === 0}
      />
    </RoundedDialog>
  );
}
