import { useLocation } from 'react-router-dom';
import logo from '../Images/logos/main-logo.png';
import darklogo from '../Images/logos/main-logo-dark.png';
import { lightGreen, orange, blue, teal, deepPurple, red } from '@mui/material/colors';

const QLogo = '/letter-logo.png';

const nodeEnv = process.env.NODE_ENV;
const reactEnv = process.env.REACT_APP_ENV;

const rollBarToken = 'a926d616c25e444ca987778b7ef3f823';

const stripeTestKey =
  'pk_test_51HhivOF6kWXksMSYopKKDIJRKETHsYBvPrtaNbCZZDKoWTBr35swbPeOD8IgIXk1SzZZJD619UfDcUdokpkviyo5004bHOpZAc';
const stripeProdKey =
  'pk_live_51HhivOF6kWXksMSYiIF5eNIBrgBLdsBJVT4guIMxzHxEIYSCJCuXxD5TrlUqZsLaCCfi83utaFMQlvPMfHEfN8mz00VHAYhEpj';

const apiDev = `http://${location.hostname}:5000`;
const apiStage = 'https://api.q-hub.co.uk';
const apiProd = '/api';

const isDev = nodeEnv === 'development';
const isStaging = nodeEnv === 'production' && reactEnv === 'staging';
const isProduction = nodeEnv === 'production' && reactEnv === 'production';

export const rollBarEnv =
  nodeEnv === 'production' ? (reactEnv === 'staging' ? 'staging' : 'production') : 'development';

const webPushDevKey = 'BFl2aqNozEBBFcEmEwEtwr8rZ7VlwQ-1YlP2bfHRwWApgzsnmZ9myVeZQ3NCY42RIV9-GWTX9XBS646XWa5WvSc';
const webPushStagingKey = 'BCCcULUgCH1v-sXuijWN2FWDG7bVPrFr6o6ObzyOn0ZWkHIKMs80fqE2xPh5lILrwik9BgDMXF6Vr04xlqWqk6I';
const webPushProdKey = 'BOXv9kBNaXFS85Xdvf8t904oItUrwV5JBcf85QTPPamJB7NSNZbl2-GiQTtzvdZry9WEXfpJzIHOzXxv_0DD9Go';

export const ENV = nodeEnv === 'production' ? (reactEnv === 'staging' ? 'STAGING' : 'PRODUCTION') : 'DEVELOPMENT';

export const WEB_PUSH_URL =
  nodeEnv === 'production' ? (reactEnv === 'staging' ? webPushStagingKey : webPushProdKey) : webPushDevKey;

export const BASE_URL =
  process.env.REACT_APP_BASE_URL || (nodeEnv === 'production' ? (reactEnv === 'staging' ? apiStage : apiProd) : apiDev);
export const calendlyUrl = 'https://calendly.com/qhub/qhub-60-minute-demo-meeting-clone';
export const paidRoles = ['admin', 'standard'];

var socket_url = process.env.REACT_APP_BASE_WS_URL || `https://${window.location.host}`;

if (!process.env.REACT_APP_BASE_WS_URL) {
  if (process.env.NODE_ENV === 'production' && process.env.REACT_APP_ENV === 'staging') socket_url = `${BASE_URL}`;
  if (process.env.NODE_ENV === 'development') socket_url = `http://${location.hostname}:5000`;
}

export const WS_BASE_URL = socket_url;

export const stripeApiKey = isProduction ? stripeProdKey : stripeTestKey;

export function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const CLIENTID = isProduction
  ? 'be10a6df4b5e41998e776d15ce828b64'
  : isStaging
  ? '4e961fedf2464229bf32aa1363643b56'
  : 'cc24ae4b574d4627a51b57b4557f8f88';

const QDOCSvg = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="2em" viewBox="0 0 150 150" height="2em" {...props}>
    <path
      d="M74.576 118.526c-14.781 0-27.29-4.988-37.516-14.968-10.23-9.989-15.344-22.614-15.344-37.875 0-15.27 5.114-27.868 15.344-37.797C47.287 17.948 59.814 12.98 74.638 12.98c14.832 0 27.313 4.968 37.438 14.906 10.125 9.93 15.187 22.527 15.187 37.797 0 8.605-1.742 16.507-5.218 23.703-3.47 7.187-8.235 13.183-14.297 17.984l20.984 27H98.357l-12.484-17.015c-3.918.78-7.684 1.171-11.297 1.171ZM54.67 44.105c-4.938 5.375-7.407 12.57-7.407 21.578 0 9 2.47 16.195 7.407 21.578 4.945 5.375 11.554 8.062 19.828 8.062 8.27 0 14.875-2.687 19.812-8.062 4.946-5.383 7.422-12.578 7.422-21.578 0-9.008-2.476-16.203-7.422-21.578-4.937-5.383-11.543-8.079-19.812-8.079-8.274 0-14.883 2.696-19.828 8.079Zm0 0"
      fill="currentColor"
    />
  </svg>
);

const HUB_COLORS = {
  process: orange[600],
  form: lightGreen[600],
  doc: '#2196f3',
  audit: blue[500],
  asset: blue[500],
  register: teal[600],
  work: deepPurple[600],
  kpi: red[700],
};

export { QLogo, QDOCSvg, isDev, isStaging, isProduction, logo, darklogo, rollBarToken, HUB_COLORS };
