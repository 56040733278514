import React, { useState, useEffect } from 'react';
import { Grid, Typography, TextField, IconButton, CircularProgress, Tooltip } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { grey } from '@mui/material/colors';
import { ClearRounded } from '@mui/icons-material';
import { FormIcon } from '../Icons';
import axios from 'axios';

export default function FormAutocomplete({ formId, formName, saveForm, disabled }) {
  const [availableForms, setAvailableForms] = useState([]);
  const [formsOpen, setFormsOpen] = useState(false);
  const loadForms = formsOpen && availableForms.length === 0;

  useEffect(() => {
    if (!loadForms) return;
    axios
      .get('/forms/general/getFormsForSearch')
      .then((res) => {
        setAvailableForms(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [loadForms]);

  return (
    <>
      {!formId && (
        <Autocomplete
          options={availableForms}
          style={{ marginLeft: '1rem', flexGrow: 1 }}
          getOptionLabel={(option) => option.title}
          openOnFocus
          renderOption={(props, option) => (
            <li {...props}>
              <Grid container alignItems="center">
                <FormIcon />
                <Typography style={{ marginLeft: '1rem' }} noWrap>
                  {option.title}
                </Typography>
              </Grid>
            </li>
          )}
          renderInput={(params) => (
            <TextField
              variant="outlined"
              {...params}
              label="Select a form"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loadForms ? <CircularProgress color="primary" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
          onChange={(e, value) => {
            saveForm(value);
          }}
          open={formsOpen}
          onOpen={() => setFormsOpen(true)}
          onClose={() => setFormsOpen(false)}
          loading={loadForms}
        />
      )}
      {formId && (
        <>
          <Typography variant="h6" noWrap style={{ marginLeft: '1rem', flexGrow: 1 }}>
            {formName}
          </Typography>
          {!disabled && (
            <Tooltip placement="top" title="Select another form">
              <IconButton onClick={() => saveForm({})} style={{ marginLeft: 32 }}>
                <ClearRounded style={{ color: grey[500] }} />
              </IconButton>
            </Tooltip>
          )}
        </>
      )}
    </>
  );
}
