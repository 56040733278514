import { useEffect, useState, useRef } from 'react';

const useElementSize = (elementId) => {
  const [height, setHeight] = useState(null);
  const [width, setWidth] = useState(null);
  const element = useRef(null);
  const resizeObserver = useRef(null);

  function observeElementResize(elem) {
    resizeObserver.current = new ResizeObserver((entries) => {
      for (let entry of entries) {
        if (entry.target.id !== elementId) return;
        setWidth(entry.contentRect.width);
        setHeight(entry.contentRect.height);
      }
    });
    resizeObserver.current.observe(elem);
  }

  function findElement(elementId, depth = 0) {
    if (depth >= 5) return;
    element.current = document.getElementById(elementId);
    if (element.current) {
      observeElementResize(element.current);
    } else {
      // if element not found in DOM, wait a moment and try again
      setTimeout(() => findElement(elementId, depth + 1), 400);
    }
  }

  useEffect(() => {
    if (!elementId) return;
    findElement(elementId);
  }, [elementId]); // eslint-disable-line

  return { width, height };
};

export default useElementSize;
