import React, { useState, useEffect, useMemo } from 'react';
import { DialogTitle, TextField, DialogContent, List, Avatar, Grid } from '@mui/material';
import { ListItemAvatar, ListItemText, Typography, CircularProgress, ListItemButton } from '@mui/material';
import { SearchRounded } from '@mui/icons-material';
import { RoundedDialog, StandardDialogActions } from '../../Global/Components';
import { isArray, isEmpty } from 'lodash';
import { green } from '@mui/material/colors';
import UserManager from '../../Global/UserManager';

export default function SelectUserDialog({
  open,
  onClose,
  onlyPaid = false,
  adminsOnly = false,
  excluded,
  included,
  title,
}) {
  const [pattern, setPattern] = useState('');
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);

  const filtered = useMemo(() => {
    const result = users.filter(
      (user) =>
        (!onlyPaid || ['admin', 'standard'].includes(user.access)) &&
        (excluded || user._id !== excluded) &&
        (!isArray(included) || isEmpty(included) || included.includes(user._id)) &&
        (!adminsOnly || user.access === 'admin') &&
        (!pattern || String(user.fullName).toLowerCase().includes(pattern)),
    );

    return result;
  }, [users, excluded, included, pattern, adminsOnly, onlyPaid]);

  const loadUsers = open && users.length === 0;

  useEffect(() => {
    if (!loadUsers) return;
    setLoading(true);
    UserManager.getUserList(true)
      .then((res) => {
        setUsers(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  }, [loadUsers]);

  return (
    <RoundedDialog open={open} maxWidth="xs" fullWidth onClose={() => onClose(null)} className="scroll-bar">
      <DialogTitle>{title || 'Select user'}</DialogTitle>
      <DialogContent>
        <Grid container sx={{ mb: 1 }}>
          <TextField
            placeholder="Search for user"
            style={{ flexGrow: 1 }}
            InputProps={{
              startAdornment: <SearchRounded sx={{ mr: 0.5 }} />,
            }}
            onChange={(event) => setPattern(event.target.value.toLowerCase())}
            variant="standard"
          />
        </Grid>
        {loading ? (
          <Grid container justifyContent={'center'} alignItems={'center'} style={{ minHeight: '30vh' }}>
            <CircularProgress />
          </Grid>
        ) : (
          <List style={{ minHeight: '30vh', overflow: 'auto', width: '100%' }}>
            {filtered.map((user) => (
              <ListItemButton key={user._id} onClick={() => onClose(user)} sx={{ borderRadius: 0.6 }}>
                <ListItemAvatar>
                  <Avatar style={{ backgroundColor: user.avatarColor }} src={user.avatar}>
                    {user.avatarLetters}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Typography>
                      {user.fullName}{' '}
                      {user.access === 'portal' ? (
                        <span style={{ fontWeight: 600, color: green[500] }}>portal</span>
                      ) : (
                        ''
                      )}
                    </Typography>
                  }
                />
              </ListItemButton>
            ))}
          </List>
        )}
      </DialogContent>
      <StandardDialogActions onClose={() => onClose(null)} />
    </RoundedDialog>
  );
}
