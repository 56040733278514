import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Popover, List, ListItemIcon, Badge, ListItemText, Hidden, Typography, Avatar } from '@mui/material';
import { Grid, Divider, ListItemButton } from '@mui/material';
import { LogoutRounded, PersonOutlineRounded, QuestionMarkRounded, SettingsRounded } from '@mui/icons-material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import axios from 'axios';
import SecurityIcon from '@mui/icons-material/Security';
import ChatIcon from '@mui/icons-material/Chat';
import { blue } from '@mui/material/colors';

function clear() {
  localStorage.clear();
  sessionStorage.clear();
  window.location.href = '/login';
}

function logout() {
  let subscriptionId = localStorage.getItem('pushSubscription'); // important!!!
  if (subscriptionId) {
    axios
      .post('/notifications/cancelPushSubscription', { subscriptionId })
      .then(() => clear())
      .catch(() => clear());
  } else {
    clear();
  }
}

export default function Profile(props) {
  const { user } = useSelector(({ profile }) => profile);
  const isAdmin = user && user.access === 'admin';
  const isSuperAdmin = user && user.access === 'admin' && user.isSuperAdmin;

  return (
    <Popover
      open={props.open}
      anchorEl={props.anchorEl}
      onClose={props.onClose}
      style={{
        marginTop: '40px',
      }}
    >
      <Grid container direction={'column'} sx={{ p: 1 }}>
        <Grid container alignItems={'center'} wrap="nowrap" sx={{ p: 1 }}>
          <Grid item sx={{ pr: 2 }}>
            <Avatar src={user && user.picture} sx={{ background: user?.avatarColor, height: 120, width: 120 }}>
              {user?.avatarLetters}
            </Avatar>
          </Grid>
          <Grid item sx={{ pr: 4 }}>
            <Grid container>
              <Typography noWrap variant="h6">
                {user?.fullName}
              </Typography>
            </Grid>
            <Grid container>
              {isAdmin && (
                <Typography variant="body2" sx={{ color: blue[500] }}>
                  admin
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Divider />
        <List>
          <Hidden smUp>
            <Link to="/notifications">
              <ListItemButton>
                <ListItemIcon>
                  <Badge badgeContent={props.badgeContent} color="error" invisible={props.badgeContent === 0}>
                    <NotificationsIcon />
                  </Badge>
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{ sx: { color: (theme) => theme.palette.text.primary } }}>
                  Notifications
                </ListItemText>
              </ListItemButton>
            </Link>
            <Divider />
            <ListItemButton onClick={() => props.openChats(true)}>
              <ListItemIcon>
                <Badge badgeContent={props.unreadChats} color="error" invisible={props.unreadChats === 0}>
                  <ChatIcon />
                </Badge>
              </ListItemIcon>
              <ListItemText>Chats</ListItemText>
            </ListItemButton>
            <Divider />
          </Hidden>
          <Hidden smDown>
            {isAdmin && (
              <>
                <Link to="/admin">
                  <ListItemButton>
                    <ListItemIcon>
                      <SettingsRounded />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography color={'textPrimary'}>Admin Panel</Typography>
                    </ListItemText>
                  </ListItemButton>
                </Link>
                <Divider />
              </>
            )}
            {isSuperAdmin && (
              <>
                <Link to="/superadmin">
                  <ListItemButton>
                    <ListItemIcon>
                      <SecurityIcon />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography color={'textPrimary'}>Super Admin</Typography>
                    </ListItemText>
                  </ListItemButton>
                </Link>
                <Divider />
              </>
            )}
          </Hidden>
          <Link to={'/profile'}>
            <ListItemButton>
              <ListItemIcon>
                <PersonOutlineRounded />
              </ListItemIcon>
              <ListItemText>
                <Typography color={'textPrimary'}>Profile</Typography>
              </ListItemText>
            </ListItemButton>
          </Link>
          <Divider />
          <ListItemButton onClick={props.openSupport}>
            <ListItemIcon>
              <QuestionMarkRounded />
            </ListItemIcon>
            <ListItemText>Support</ListItemText>
          </ListItemButton>
          <Divider />
          <ListItemButton onClick={logout}>
            <Grid container alignItems={'center'} justifyContent={'flex-end'} wrap="nowrap">
              <Typography sx={{ mr: 1 }}>Logout</Typography>
              <LogoutRounded />
            </Grid>
          </ListItemButton>
        </List>
      </Grid>
    </Popover>
  );
}
