import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { isEqual, isArray, isEmpty } from 'lodash';
import {
  Button,
  CircularProgress,
  Divider,
  Fade,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import EditIcon from '@mui/icons-material/Edit';
import LanguageIcon from '@mui/icons-material/Language';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import SaveIcon from '@mui/icons-material/Save';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt';
import LockIcon from '@mui/icons-material/Lock';
import { grey } from '@mui/material/colors';

import ViewDialog from './ViewDialog';

import StateManager from '../../StateManager';
import { COLORS } from '../../../Hubs/dochub/constants';
import { paidRoles, useQuery } from '../../../../constants';
import { TooltipTypography } from '../../Components';

export default function DataGridViews({ initial, id, defaultId, onResult, size = 'small', privateOnly, initialViews }) {
  const { user } = useSelector(({ profile }) => profile);
  const viewId = useQuery().get('viewId');
  const configId = id ?? null;
  const [list, setList] = useState([]);
  const [loadingList, setLoadingList] = useState(false);
  const [selectedView, setSelectedView] = useState(null);
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [editSubmitting, setEditSubmitting] = useState(false);
  const [menuAnchorIndex, setMenuAnchorIndex] = useState(null);
  const [subMenuAnchor, setSubMenuAnchor] = useState(null);
  const [editedName, setEditedName] = useState();
  const [viewDialog, setViewDialog] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [updatingMode, setUpdatingMode] = useState();

  const onToggleDefault = (viewId, isDefault) => {
    setUpdating(viewId);
    axios
      .post('/general/datagrid/toggleDefaultView', { viewId, isDefault })
      .then((res) => {
        const views = [...list];
        const index = views.findIndex((view) => view._id === viewId);
        views[index] = res.data;
        // remove user from other views
        if (isDefault) {
          views.forEach((view) => {
            if (view._id !== viewId) {
              view.defaultUsers = view.defaultUsers.filter((x) => x !== user?._id);
            }
          });
        }
        setList([...views]);
        setUpdating(null);
      })
      .catch((err) => {
        setUpdating(null);
        StateManager.setAxiosErrorAlert(err);
      });
  };

  const onDeleteView = (viewId) => {
    axios
      .delete('/general/datagrid/removeView', { params: { viewId } })
      .then((res) => {
        setList(list.filter((view) => view._id !== viewId));
        setSelectedView(null);
        setSubMenuAnchor(null);
        setMenuAnchor(null);
        onResult({
          filterModel: { items: [] },
          sortModel: [],
          pageSize: initial?.paginationModel?.pageSize,
          columnVisibilityModel: {},
          pinnedColumns: {},
          columnsModel: [],
          configId: id,
        });
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
      });
    reset();
  };

  const confirmDelete = (viewId) => {
    StateManager.setConfirm('You are about to delete this view', () => onDeleteView(viewId));
  };

  const onEditSubmit = () => {
    if (editedName && editedName?.trim() !== '') {
      setEditSubmitting(true);
      axios
        .post('/general/datagrid/saveDataGridView', {
          viewId: list?.[menuAnchorIndex]?._id,
          data: { title: editedName },
        })
        .then((res) => {
          reset();
          setEditSubmitting(false);
          const views = [...list];
          const index = views.findIndex((view) => view._id === res.data._id);
          views[index] = res.data;
          setList([...views]);
        })
        .catch((err) => {
          setEditSubmitting(false);
          StateManager.setAxiosErrorAlert(err);
        });
    }
  };

  const onAccessChange = (viewId, access) => {
    setUpdating(viewId);
    axios
      .post('/general/datagrid/saveDataGridView', { viewId, data: { access } })
      .then((res) => {
        const views = [...list];
        const index = views.findIndex((view) => view._id === viewId);
        views[index] = res.data;
        setList([...views]);
        setUpdating(null);
      })
      .catch((err) => {
        setUpdating(null);
        StateManager.setAxiosErrorAlert(err);
      });
  };

  const onViewModelSubmit = (res) => {
    const data = {
      columnVisibilityModel: initial.columnVisibilityModel,
      pinnedColumns: initial.pinnedColumns,
      columnsModel: initial.columnsModel,
      filterModel: initial.filterModel,
      sortModel: initial.sortModel,
      pageSize: initial.paginationModel?.pageSize,
    };
    if (selectedView && updatingMode === 'new') {
      const body = {
        title: res.title,
        access: res.access,
        configId,
        data,
      };
      setUpdating('new');
      axios
        .post('/general/datagrid/createDataGridView', body)
        .then((response) => {
          setList([...list, response?.data]);
          setSelectedView(response?.data);
          setUpdating(null);
          onResult(response?.data);
          setViewDialog(false);
          setUpdatingMode(null);
        })
        .catch((err) => {
          setUpdating(null);
          setUpdatingMode(null);
          StateManager.setAxiosErrorAlert(err);
        });
      return;
    }
    if (selectedView) {
      const body = {
        viewId: selectedView?._id,
        data: {
          title: res.title,
          access: res.access,
          ...data,
        },
      };
      setUpdating(selectedView?._id);
      axios
        .post('/general/datagrid/saveDataGridView', body)
        .then((response) => {
          const views = [...list];
          const index = views.findIndex((view) => view._id === response.data._id);
          views[index] = response.data;
          setList([...views]);
          setSelectedView(response.data);
          setUpdating(null);
          setViewDialog(false);
          onResult(response.data);
        })
        .catch((err) => {
          setUpdating(null);
          StateManager.setAxiosErrorAlert(err);
        });
    } else {
      const body = {
        title: res.title,
        access: res.access,
        configId,
        data,
      };
      setUpdating('new');
      axios
        .post('/general/datagrid/createDataGridView', body)
        .then((response) => {
          setList([...list, response?.data]);
          setSelectedView(response?.data);
          setUpdating(null);
          onResult(response?.data);
          setViewDialog(false);
        })
        .catch((err) => {
          setUpdating(null);
          StateManager.setAxiosErrorAlert(err);
        });
    }
  };

  const reset = () => {
    setMenuAnchorIndex(null);
    setSubMenuAnchor(null);
    setIsEdit(false);
    setEditedName();
  };

  useEffect(() => {
    if (!isArray(initialViews)) return;

    setList(initialViews);

    const currentView = defaultId ? initialViews.find((x) => x._id === defaultId) : null;

    setSelectedView(currentView);
  }, [initialViews]); // eslint-disable-line

  useEffect(() => {
    if (!defaultId && !viewId) return;
    let view = list?.find((x) => x._id === viewId);
    if (defaultId) {
      view = list?.find((x) => x._id === defaultId);
    }
    setSelectedView(view);
  }, [defaultId, viewId, list]);

  if (!configId) return null;

  return (
    <>
      {(initial?.filterModel?.items?.length > 0 || initial?.sortModel?.length > 0 || initial?.pageSize) &&
        paidRoles?.includes(user?.access) &&
        !selectedView &&
        isEmpty(list) && (
          <Button onClick={() => setViewDialog(true)} startIcon={<SaveIcon />} variant="outlined" size={size}>
            Save View
          </Button>
        )}
      {!isEmpty(list) && (
        <Button
          onClick={(e) => setMenuAnchor(e.currentTarget)}
          endIcon={<ArrowDropDownIcon />}
          sx={{
            /*color: (theme) =>
              theme.palette.mode === 'dark'
                ? '#bdbdbd'
                : !isEqual(initial?.filterModel?.items, selectedView?.filterModel?.items)
                ? 'textPrimary'
                : 'textSecondary',*/
            textTransform: 'none',
            //fontWeight: !isEqual(initial?.filterModel?.items, selectedView?.filterModel?.items) ? 500 : 400,
            maxWidth: '150px',
            height: '30px',
            marginLeft: '10px',
            //borderWidth: !isEqual(initial?.filterModel?.items, selectedView?.filterModel?.items) ? '2px' : '1px',
          }}
          variant="outlined"
          size={size}
        >
          {loadingList ? (
            <CircularProgress size={24} />
          ) : (
            <TooltipTypography>{selectedView?.title ?? 'All rows'}</TooltipTypography>
          )}
        </Button>
      )}
      <Menu
        anchorEl={menuAnchor}
        open={Boolean(menuAnchor)}
        onClose={() => {
          setSubMenuAnchor(null);
          setMenuAnchor(null);
          setIsEdit(false);
          setEditedName();
        }}
        TransitionComponent={Fade}
        slotProps={{ paper: { sx: { minWidth: '250px', mt: 0.2 } } }}
      >
        {(!isEqual(initial?.filterModel?.items, selectedView?.filterModel?.items) ||
          !isEqual(initial?.sortModel, selectedView?.sortModel) ||
          !isEqual(initial?.pageSize, selectedView?.pageSize)) &&
          paidRoles?.includes(user?.access) && (
            <MenuItem
              onClick={(e) => {
                e.stopPropagation();
                setViewDialog(true);
                setUpdatingMode('new');
                setMenuAnchor(null);
              }}
            >
              <ListItemText primary={selectedView ? 'Save as New View' : 'Save as a View'} />
            </MenuItem>
          )}
        {selectedView &&
          !isEqual(initial?.filterModel?.items, selectedView?.filterModel?.items) &&
          paidRoles?.includes(user?.access) && (
            <MenuItem
              onClick={(e) => {
                e.stopPropagation();
                setMenuAnchor(null);
                setUpdatingMode('update');
                onViewModelSubmit(selectedView);
              }}
            >
              <ListItemText primary="Update Current View" />
            </MenuItem>
          )}
        {paidRoles?.includes(user?.access) && <Divider />}
        <MenuItem
          onClick={(e) => {
            e.stopPropagation();
            setSelectedView(null);
            onResult({
              filterModel: { items: [] },
              sortModel: [],
              pageSize: initial?.paginationModel?.pageSize,
              columnVisibilityModel: {},
              pinnedColumns: {},
              columnsModel: [],
              configId: id,
            });
          }}
        >
          <ListItemText
            primary={<Typography color={selectedView ? 'textSecondary' : 'textPrimary'}>Display all</Typography>}
            disableTypography
          />
        </MenuItem>
        {list?.map((item, index) => {
          return (
            <MenuItem
              key={item._id}
              onClick={(e) => {
                e.stopPropagation();
                if (!isEdit) {
                  setSelectedView(item);
                  onResult(item);
                }
              }}
            >
              {isEdit && index === menuAnchorIndex ? (
                <>
                  <TextField
                    variant="outlined"
                    value={editedName ?? item?.title}
                    onChange={(e) => setEditedName(e?.target?.value)}
                    sx={{
                      width: '150px',
                      input: {
                        padding: '10px',
                      },
                    }}
                  />
                  {editSubmitting ? (
                    <CircularProgress size={20} />
                  ) : (
                    <Grid>
                      <IconButton
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation();
                          onEditSubmit();
                        }}
                      >
                        <CheckCircleOutlineIcon />
                      </IconButton>
                      <IconButton
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation();
                          reset();
                        }}
                      >
                        <DoNotDisturbAltIcon />
                      </IconButton>
                    </Grid>
                  )}
                </>
              ) : (
                <>
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography
                        color={selectedView?._id === item?._id ? 'textPrimary' : 'textSecondary'}
                        style={{ fontWeight: selectedView?._id === item?._id ? 500 : 400 }}
                      >
                        {item.title}
                      </Typography>
                    }
                  />
                  <ListItemIcon
                    onClick={(e) => {
                      e.stopPropagation();
                      const isDefault = item?.defaultUsers?.includes(user?._id);
                      onToggleDefault(item?._id, !isDefault);
                    }}
                    sx={{
                      color: item?.defaultUsers?.includes(user?._id) ? COLORS.BOOKMARK : grey[500],
                      '&:hover': {
                        color: COLORS.BOOKMARK,
                      },
                    }}
                  >
                    {item?.defaultUsers?.includes(user?._id) ? <StarIcon /> : <StarBorderIcon />}
                  </ListItemIcon>
                  <ListItemIcon
                    onClick={(e) => {
                      e.stopPropagation();
                      setEditedName();
                      setIsEdit(false);
                      setMenuAnchorIndex(index);
                      setSubMenuAnchor({
                        mouseX: e.clientX - 350,
                        mouseY: e.clientY,
                      });
                    }}
                    sx={{
                      '&:hover': {
                        color: (theme) => (theme.palette.mode === 'dark' ? '#bdbdbd' : 'textSecondary'),
                      },
                    }}
                  >
                    <MoreHorizIcon />
                  </ListItemIcon>
                </>
              )}
            </MenuItem>
          );
        })}
      </Menu>
      <Menu
        anchorEl={subMenuAnchor}
        open={Boolean(subMenuAnchor)}
        onClose={() => {
          setSubMenuAnchor(null);
        }}
        anchorReference="anchorPosition"
        anchorPosition={subMenuAnchor !== null ? { top: subMenuAnchor.mouseY, left: subMenuAnchor.mouseX } : undefined}
        TransitionComponent={Fade}
        slotProps={{ paper: { style: { minWidth: '200px' } } }}
      >
        <MenuItem
          onClick={(e) => {
            e.stopPropagation();
            const item = list?.[menuAnchorIndex];
            const isDefault = item?.defaultUsers?.includes(user?._id);
            onToggleDefault(item?._id, !isDefault);
          }}
        >
          <ListItemText
            disableTypography
            primary={
              <Typography color={'textSecondary'} style={{ fontWeight: 400 }}>
                Set As Default
              </Typography>
            }
          />
          <ListItemIcon
            sx={{ color: list?.[menuAnchorIndex]?.defaultUsers?.includes(user?._id) ? COLORS.BOOKMARK : grey[500] }}
          >
            {list?.[menuAnchorIndex]?.defaultUsers?.includes(user?._id) ? <StarIcon /> : <StarBorderIcon />}
          </ListItemIcon>
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            e.stopPropagation();
            setIsEdit(true);
            setSubMenuAnchor(null);
          }}
        >
          <ListItemText
            disableTypography
            primary={
              <Typography color={'textSecondary'} style={{ fontWeight: 400 }}>
                Edit View
              </Typography>
            }
          />
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
        </MenuItem>
        <Divider sx={{ my: 0.5 }} />
        {!privateOnly && (
          <MenuItem
            onClick={(e) => {
              e.stopPropagation();
              onAccessChange(
                list?.[menuAnchorIndex]?._id,
                list?.[menuAnchorIndex]?.access === 'public' ? 'private' : 'public',
              );
            }}
          >
            <ListItemText
              disableTypography
              primary={
                <Typography color={'textSecondary'} style={{ fontWeight: 400 }}>
                  {`Set As ${list?.[menuAnchorIndex]?.access === 'public' ? 'Private' : 'Global'}`}
                </Typography>
              }
            />
            <ListItemIcon>
              {updating === list?.[menuAnchorIndex]?._id ? (
                <CircularProgress size={20} />
              ) : list?.[menuAnchorIndex]?.access === 'public' ? (
                <LockIcon />
              ) : (
                <LanguageIcon />
              )}
            </ListItemIcon>
          </MenuItem>
        )}
        <MenuItem
          onClick={(e) => {
            e.stopPropagation();
            confirmDelete(list?.[menuAnchorIndex]?._id);
          }}
        >
          <ListItemText
            disableTypography
            primary={
              <Typography color={'textSecondary'} style={{ fontWeight: 400 }}>
                Delete View
              </Typography>
            }
          />
          <ListItemIcon>
            <DeleteOutlineIcon />
          </ListItemIcon>
        </MenuItem>
      </Menu>
      <ViewDialog
        open={viewDialog}
        onClose={() => setViewDialog(false)}
        onResult={onViewModelSubmit}
        initial={selectedView}
        saving={updating === 'new' || updating === selectedView?._id}
        privateOnly={privateOnly}
      />
    </>
  );
}
