import React, { useState, useEffect } from 'react';
import { green, red } from '@mui/material/colors';
import { DialogContent, DialogTitle, Grid, Avatar, TextField, MenuItem, IconButton } from '@mui/material';
import { Typography, DialogActions, Button } from '@mui/material';
import { RoundedDialog, UserAutocomplete, User } from './index';
import { Done, DeleteOutline, CloseRounded, DoneRounded } from '@mui/icons-material';
import { useTheme } from '@mui/material';
import StateManager from '../StateManager';
import { v4 } from 'uuid';

const notifyOptions = [
  { id: 'notification', text: 'Notification' },
  //{ id: 'email', text: 'Email' },
  { id: 'both', text: 'Notification & Email' },
];

export default function NotifyDialog({ open, onClose, onResult, triggers, initial, onlyView }) {
  const [notifyList, setNotifyList] = useState([]);
  const [newEntry, setNewEntry] = useState({ triggerId: '', notifyOptionId: 'notification' });
  const theme = useTheme();

  useEffect(() => {
    if (!open || !initial || !Array.isArray(initial)) return;
    setNotifyList(initial);
    setNewEntry({ triggerId: '', notifyOptionId: 'notification' });
  }, [open]); // eslint-disable-line

  function addEntry() {
    if (!newEntry.user) {
      StateManager.setErrorAlert('Provide user');
      return;
    }
    if (!newEntry.triggerId) {
      StateManager.setErrorAlert('Provide trigger');
      return;
    }

    setNotifyList([
      ...notifyList,
      { id: v4(), triggerId: newEntry.triggerId, userId: newEntry.user._id, notifyOptionId: newEntry.notifyOptionId },
    ]);
    setNewEntry({ triggerId: '', notifyOptionId: 'notification' });
  }

  function done() {
    onResult(notifyList);
    onClose();
  }

  return (
    <RoundedDialog open={open} onClose={onClose} maxWidth="sm" className="scroll-bar">
      <DialogTitle>{onlyView ? 'Users to notify' : 'Select users to notify'}</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid container item>
            {notifyList.map((item) => (
              <Grid
                key={item.id}
                container
                item
                alignItems="center"
                wrap="nowrap"
                style={{ margin: theme.spacing(1, 0) }}
              >
                <User id={item.userId} dense onlyAvatar={!onlyView} />
                <Typography noWrap style={{ margin: '0 0.5em', textTransform: 'lowercase' }}>
                  {`will be sent ${notifyOptions.find((t) => t.id === item.notifyOptionId)?.text} when ${
                    triggers.find((t) => t.id === item.triggerId)?.text
                  }`}
                </Typography>
                {!onlyView && (
                  <IconButton
                    style={{ marginLeft: 'auto' }}
                    onClick={() => setNotifyList(notifyList.filter((x) => x.id !== item.id))}
                  >
                    <DeleteOutline style={{ color: red[500] }} />
                  </IconButton>
                )}
              </Grid>
            ))}
          </Grid>
          {!onlyView && (
            <Grid container item alignItems="center" style={{ margin: theme.spacing(2, 0) }}>
              <Grid container item alignItems="center" wrap="nowrap" style={{ margin: theme.spacing(1, 0) }}>
                {newEntry?.user ? (
                  <Avatar src={newEntry?.user.avatar} style={{ background: newEntry?.user.avatarColor }}>
                    {newEntry?.user.avatarLetters}
                  </Avatar>
                ) : (
                  <Avatar />
                )}
                <UserAutocomplete
                  onResult={(user) => setNewEntry({ ...newEntry, user })}
                  style={{ margin: '0 1rem', width: 250 }}
                  selected={newEntry.user}
                />
                <Typography variant="h6">will be sent</Typography>
              </Grid>
              <Grid container item alignItems="center" wrap="nowrap" style={{ margin: theme.spacing(1, 0) }}>
                <TextField
                  select
                  label="Select"
                  value={newEntry.notifyOptionId}
                  onChange={(event) => setNewEntry({ ...newEntry, notifyOptionId: event.target.value })}
                  style={{ marginRight: '1rem', width: 200 }}
                  variant="standard"
                >
                  {notifyOptions.map((trigger) => (
                    <MenuItem key={trigger.id} value={trigger.id}>
                      {trigger.text}
                    </MenuItem>
                  ))}
                </TextField>
                <Typography variant="h6">when</Typography>
                <TextField
                  select
                  label="Select"
                  value={newEntry.triggerId}
                  onChange={(event) => setNewEntry({ ...newEntry, triggerId: event.target.value })}
                  style={{ margin: '0 1rem', width: 150 }}
                  variant="standard"
                >
                  {triggers.map((trigger) => (
                    <MenuItem key={trigger.id} value={trigger.id}>
                      {trigger.text}
                    </MenuItem>
                  ))}
                </TextField>
                <Button
                  variant="outlined"
                  onClick={addEntry}
                  style={{ textTransform: 'none', borderRadius: 8, marginLeft: 'auto' }}
                  startIcon={<Done style={{ color: green[500] }} />}
                >
                  Add
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} style={{ color: red[500] }} startIcon={<CloseRounded />}>
          close
        </Button>
        {!onlyView && (
          <Button
            onClick={done}
            style={{ borderRadius: 8 }}
            variant="contained"
            color="primary"
            startIcon={<DoneRounded />}
          >
            done
          </Button>
        )}
      </DialogActions>
    </RoundedDialog>
  );
}
