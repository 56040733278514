import React from 'react';
import { Table, TableHead, TableRow, TableCell, TableBody, DialogContent, DialogTitle } from '@mui/material';
import { RoundedDialog, StandardDialogActions } from '../Components';

export default function SelectedRowsDialog({ columns, rows, open, onClose }) {
  if (!Array.isArray(columns) || !Array.isArray(rows)) return null;
  return (
    <RoundedDialog open={open} onClose={onClose} maxWidth="lg">
      <DialogTitle>Selected</DialogTitle>
      <DialogContent>
        <Table style={{ width: 'fit-content' }}>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id}>{column.title}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.id} hover>
                {columns.map((column) => (
                  <TableCell key={`${column.id}#${row.id}`}>{row[column.id]}</TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </DialogContent>
      <StandardDialogActions onClose={onClose} />
    </RoundedDialog>
  );
}
