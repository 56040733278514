import { useEffect, useState, useMemo } from 'react';
import { isArray, isEmpty } from 'lodash';
import { RoundedDialog, StandardDialogActions } from './index';
import { DialogTitle, DialogContent, Grid, Typography, CircularProgress } from '@mui/material';
import { FormatDate } from '../Functions';
import StateManager from '../StateManager';
import { useHistory } from 'react-router-dom';
import { User, UserGroup } from './index';
import axios from 'axios';
import moment from 'moment';
import UserInput from '../DataGrid/Filters/UserInput';
import { DataGridPro } from '@mui/x-data-grid-pro';

export default function FormEntriesDialog({ open, onClose, formId, entriesIds, loadingInfo, title = 'Entries' }) {
  const [loading, setLoading] = useState(false);
  const [entries, setEntries] = useState([]);
  const [refFields, setRefFields] = useState([]);
  const history = useHistory();

  useEffect(() => {
    if (!formId || !isArray(entriesIds) || isEmpty(entriesIds)) {
      setEntries([]);
      return;
    }

    setLoading(true);

    const body = { formId, entries: entriesIds };
    axios
      .post('/forms/entries/getEntriesWithRefFields', body)
      .then((res) => {
        if (isArray(res.data?.entries) && !isEmpty(res.data?.entries)) {
          setEntries(res.data.entries);
        } else {
          setEntries([]);
        }
        if (isArray(res.data?.referenceFields) && !isEmpty(res.data?.referenceFields)) {
          setRefFields(res.data.referenceFields);
        } else {
          setRefFields([]);
        }
        setLoading(false);
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
        setLoading(false);
      });
  }, [formId, entriesIds]);

  const columns = useMemo(() => {
    const usersOnlyOperators = [
      {
        label: 'Includes',
        value: 'includes',
        InputComponent: UserInput,
        InputComponentProps: { type: 'user' },
        getValueAsString: (value) => (isArray(value) ? value.join(', ') : ''),
      },
    ];

    const result = [
      {
        width: 50,
        headerName: '#',
        field: 'number',
        type: 'number',
      },
      {
        width: 300,
        headerName: 'Started',
        field: 'createdAt',
        type: 'date',
        valueGetter: ({ row }) => moment(row.createdAt).toDate(),
        renderCell: ({ row }) => FormatDate(row.createdAt),
      },
      {
        width: 250,
        headerName: 'Started by',
        field: 'startedBy',
        sortable: false,
        renderCell: ({ row }) => <User avatarSize={32} id={row.startedBy} />,
        filterOperators: usersOnlyOperators,
      },
      {
        width: 250,
        headerName: 'Assigned users',
        field: 'assignedUsers',
        sortable: false,
        renderCell: ({ row }) => <UserGroup avatarSize={32} ids={row.assignedUsers} />,
        valueGetter: ({ row }) => '',
        filterOperators: usersOnlyOperators,
      },
      ...(isArray(refFields) ? refFields : []).map((field) => ({
        headerName: field.title,
        field: `referenceValues.${field.id}`,
        width: 250,
        valueGetter: ({ row }) => row.referenceValues?.[field.id],
      })),
    ];

    return result;
  }, [refFields]);

  return (
    <RoundedDialog open={open} onClose={onClose} maxWidth="xl" fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {loading || loadingInfo ? (
          <Grid container justifyContent="center" alignItems="center" style={{ height: '75vh' }}>
            <CircularProgress color="primary" />
          </Grid>
        ) : isEmpty(entries) ? (
          <Grid container style={{ height: '75vh' }} justifyContent="center" alignItems="center">
            <Typography color="textSecondary" variant="h5">
              No entries
            </Typography>
          </Grid>
        ) : (
          <Grid container style={{ height: '75vh' }}>
            <DataGridPro
              getRowId={(row) => row._id}
              columns={columns}
              rows={entries}
              disableRowSelectionOnClick
              onRowClick={({ row }) => history.push(`/forms/entry/${row._id}`)}
            />
          </Grid>
        )}
      </DialogContent>
      <StandardDialogActions onClose={onClose} />
    </RoundedDialog>
  );
}
