import React, { useRef } from 'react';
import { useRouteMatch } from 'react-router-dom';

import { AtomicBlockUtils, EditorState } from 'draft-js';

import Grid from '@mui/material/Grid';
import TableContainer from '@mui/material/TableContainer';

import FieldDataGrid from '../../../../../Global/Fields/FieldDataGrid';

export function EditorTable({ block, className, theme = {}, ...otherProps }) {
  const {
    blockProps,
    customStyleMap,
    customStyleFn,
    decorator,
    forceSelection,
    offsetKey,
    selection,
    tree,
    contentState,
    blockStyleFn,
    preventScroll,
    ...elementProps
  } = otherProps;
  const table = useRef(null);
  // const dispatch = useDispatch();
  const editorPage = useRouteMatch(['/editor/:id', '/template/:id', '/editor/:id/upissue/:issue']);
  const key = block.getEntityAt(0);
  const data = contentState.getEntity(key).getData();
  const rows = data?.rows;
  const columns = data?.columns;
  const hover = editorPage && editorPage.isExact;

  const memoizedData = React.useMemo(() => {
    return {
      rows: rows,
      columns: columns,
    };
  }, [rows, columns]);

  return (
    <Grid container {...elementProps} sx={{ cursor: hover ? 'pointer' : 'default' }} ref={table}>
      <TableContainer style={{ margin: '5px 70px' }}>
        <FieldDataGrid
          columns={memoizedData.columns}
          initial={memoizedData.rows}
          editable={false}
          gridHeight={'100%'}
          maxHeight="100%"
          autoRowHeight={true}
          textWrapping={true}
        />
      </TableContainer>
    </Grid>
  );
}

export const findTableEntities = (contentBlock, callback, contentState) => {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    return entityKey !== null && contentState.getEntity(entityKey).getType() === 'table';
  }, callback);
};

export const onTableAdd = (editorState, extraData = {}) => {
  const contentState = editorState.getCurrentContent();
  const contentStateWithEntity = contentState.createEntity('table', 'MUTABLE', extraData);
  const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
  const newEditorState = AtomicBlockUtils.insertAtomicBlock(editorState, entityKey, ' ');
  return EditorState.forceSelection(newEditorState, newEditorState.getCurrentContent().getSelectionAfter());
};

export const excludedTypes = [
  'conditional',
  'table',
  'stock',
  'action',
  'dataSet',
  'calculation',
  'image',
  'file',
  'status',
  'dropbox',
  'tickbox',
  'people',
  'user',
  'weightedList',
];

const createTablePlugin = ({ entityType = 'table', component = EditorTable, decorator }) => {
  let EdTable = component;
  if (typeof decorator === 'function') {
    EdTable = decorator(EdTable);
  }

  return {
    blockRendererFn: (block, { getEditorState }) => {
      if (block.getType() === 'atomic') {
        const contentState = getEditorState().getCurrentContent();
        const entity = block.getEntityAt(0);
        if (!entity) return null;
        const type = contentState.getEntity(entity).getType();
        if (type === entityType) {
          return {
            component: (props) => <EditorTable {...props} />,
            editable: false,
          };
        }
      }
      return null;
    },
    addTable: onTableAdd,
  };
};

export default createTablePlugin;
