import React, { useState, useEffect, useRef } from 'react';
import { Avatar, Grid, DialogContent, DialogTitle, DialogActions, Button, ListItemButton } from '@mui/material';
import { CloseRounded } from '@mui/icons-material';
import AvatarGroup from '@mui/material/AvatarGroup';
import Skeleton from '@mui/material/Skeleton';
import UserManager from '../UserManager';
import { isHex24 } from '../Functions';
import User from './User';
import RoundedDialog from './RoundedDialog';
import { red } from '@mui/material/colors';
import { isFunction } from 'lodash';

export default function UserGroup({
  ids,
  max = 4,
  dialogTitle = 'Users',
  onClick,
  onlyAvatar,
  avatarSize = 40,
  buttonStyle,
  clickable = true,
  ...props
}) {
  const [users, setUsers] = useState([]);
  const [open, setOpen] = useState(false);
  const subscription = useRef(null);

  const fontSize = Math.round(avatarSize / 2);
  const padding = Math.round(avatarSize / 8);

  const userIds = Array.isArray(ids) ? ids : isHex24(ids) ? [ids] : null;

  const loaded = users.length === userIds?.length;

  useEffect(() => {
    return () => {
      if (!subscription.current) return;
      subscription.current.unsubscribe();
    };
  }, []);

  function resolveUsers() {
    let res = [];
    for (let i = 0; i < userIds.length; ++i) {
      res.push(UserManager.resolveUser(userIds[i]));
    }
    return res;
  }

  useEffect(() => {
    if (!userIds || userIds.length === 1) return;

    let resolved = resolveUsers();
    if (resolved[0]?._id) {
      setUsers(resolved);
    } else {
      subscription.current = UserManager.subscribeToUser(userIds[0], () => {
        subscription.current.unsubscribe();
        setUsers(resolveUsers());
      });
    }
  }, [userIds]); // eslint-disable-line

  const skeleton = (
    <AvatarGroup>
      {[1, 2, 3].map((i) => (
        <Skeleton key={i} variant="circular" width={avatarSize} height={avatarSize} />
      ))}
    </AvatarGroup>
  );

  function openDialog() {
    if (!loaded) return;
    setOpen(true);
  }

  if (props.disable) return;

  if (userIds?.length === 1) {
    return (
      <User
        id={userIds[0]}
        onClick={onClick}
        buttonStyle={buttonStyle}
        onlyAvatar={onlyAvatar}
        avatarSize={avatarSize}
        clickable={clickable}
        {...props}
      />
    );
  }

  return (
    <Grid {...props} onClick={(e) => e.stopPropagation()}>
      <ListItemButton
        disabled={!clickable}
        style={{ borderRadius: 5, padding: `${padding}px ${padding * 2}px`, zIndex: 1, ...buttonStyle }}
        onClick={isFunction(onClick) ? onClick : openDialog}
      >
        {!loaded && skeleton}
        {loaded && (
          <AvatarGroup
            max={max}
            sx={{
              '.MuiAvatarGroup-avatar': {
                height: avatarSize,
                width: avatarSize,
                fontSize: fontSize,
              },
            }}
          >
            {users.map((user, index) => {
              const inactive = Boolean(user.suspendedAt || user.deletedAt);
              return (
                <Avatar
                  key={index}
                  src={inactive ? undefined : user.avatar}
                  style={{ background: inactive ? undefined : user.avatarColor }}
                >
                  {user.avatarLetters}
                </Avatar>
              );
            })}
          </AvatarGroup>
        )}
      </ListItemButton>
      {clickable && <GroupDialog open={open} onClose={() => setOpen(false)} ids={userIds} title={dialogTitle} />}
    </Grid>
  );
}

function GroupDialog({ open, onClose, ids, title }) {
  if (!ids) return null;

  return (
    <RoundedDialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent>
        <Grid container>
          {ids.map((id) => (
            <User key={id} id={id} fullWidth />
          ))}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} style={{ color: red[500] }} startIcon={<CloseRounded />}>
          close
        </Button>
      </DialogActions>
    </RoundedDialog>
  );
}
