import React, { useState, useEffect } from 'react';
import { grey, red } from '@mui/material/colors';
import { Button } from '@mui/material';
import { ArrowDropDownRounded, VisibilityRounded } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { isArray, isEmpty } from 'lodash';
import SelectedOptionsDialog from './SelectedOptionsDialog';
import SelectedRowsDialog from './SelectedRowsDialog';
import { DataSetMenu } from './DataSetField';

export default function DataSetCell({ params, onResult, value, editable, disableClick, textAlign }) {
  const [selectedRows, setSelectedRows] = useState([]);
  const selectedRowsIds = selectedRows.map((x) => x.id);

  const [menuAnchor, setMenuAnchor] = useState(null);

  const [dialogOpen, setDialogOpen] = useState(false);

  useEffect(() => {
    if (!isArray(value?.rows)) return;

    setSelectedRows(value.rows);
  }, [value]);

  // no columns defined - unable to display
  if (!isArray(params?.columns) || isEmpty(params?.columns)) {
    return editable ? <Typography color="textSecondary">No data set</Typography> : null;
  }

  const { columns, setId, selectType } = params;

  // === NOT EDITABLE (DISPLAY VALUE) ===
  if (!editable) {
    // nothing selected
    if (!isArray(value?.rows) || isEmpty(value?.rows)) return null;

    const rows = value.rows;

    const options = columns.length === 1 ? rows.map((row) => row[columns[0].id]) : [];

    const buttonText =
      columns.length === 1 ? options.join(', ') : `${rows.length} row${rows.length === 1 ? '' : 's'} selected`;

    return (
      <>
        <Button
          style={{
            textTransform: 'none',
            minWidth: 150,
            maxWidth: '100%',
            justifyContent: textAlign || 'flex-start',
          }}
          onClick={disableClick ? undefined : () => setDialogOpen(true)}
          startIcon={<VisibilityRounded sx={{ color: (theme) => theme.palette.text.secondary }} />}
          fullWidth
        >
          <Typography noWrap sx={{ fontWeight: 500, color: (theme) => theme.palette.text.secondary }}>
            {buttonText}
          </Typography>
        </Button>

        {columns.length === 1 ? (
          <SelectedOptionsDialog open={dialogOpen} onClose={() => setDialogOpen(false)} options={options} />
        ) : (
          <SelectedRowsDialog open={dialogOpen} onClose={() => setDialogOpen(false)} rows={rows} columns={columns} />
        )}
      </>
    );
  }

  // === EDITABLE ===

  function handleRows(row) {
    if (!row) return;

    const rowId = row.id;

    let updatedSelectedRows = [];

    if (selectedRowsIds.includes(rowId)) {
      updatedSelectedRows = selectedRows.filter((x) => x.id !== rowId);
    } else {
      if (selectType === 'single') {
        updatedSelectedRows = [row];
      } else {
        updatedSelectedRows = [...selectedRows, row];
      }
    }

    const columnIds = columns.map((x) => x.id);

    const result = updatedSelectedRows.map((row) => ({
      id: row.id,
      ...columnIds.reduce((prev, columnId) => ({ ...prev, [columnId]: row[columnId] }), {}),
    }));

    setSelectedRows(result);

    onResult({ rows: result });

    // close the menu if single select
    if (selectType === 'single') {
      setMenuAnchor(null);
    }
  }

  const buttonText = isEmpty(selectedRows)
    ? columns.length === 1
      ? `Pick option${selectType === 'single' ? '' : 's'}`
      : `Pick row${selectType === 'single' ? '' : 's'}`
    : columns.length === 1
    ? selectedRows.map((row) => row[columns[0].id]).join(', ')
    : `${selectedRows.length} row${selectedRows.length === 1 ? '' : 's'} selected`;

  return (
    <>
      <Button
        style={{
          textTransform: 'none',
          minWidth: 150,
          maxWidth: '100%',
          justifyContent: textAlign || 'flex-start',
        }}
        onClick={(e) => setMenuAnchor(e.currentTarget)}
        startIcon={<ArrowDropDownRounded sx={{ color: (theme) => theme.palette.text.secondary }} />}
      >
        <Typography noWrap sx={{ fontWeight: 500, color: (theme) => theme.palette.text.secondary }}>
          {buttonText}
        </Typography>
      </Button>

      <DataSetMenu
        anchorEl={menuAnchor}
        onClose={() => setMenuAnchor(null)}
        columns={columns}
        setId={setId}
        onRowClick={(rows, rowId) => handleRows(rows, rowId)}
        selectedRowsIds={selectedRowsIds}
      />
    </>
  );
}
