import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Typography, Link, Grid, MenuItem, Menu, ListItemIcon, ListItemText, CardActionArea } from '@mui/material';
import { ImageListItem, ImageListItemBar, IconButton, Paper } from '@mui/material';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { MoreHoriz, GetAppRounded, DeleteOutlineRounded } from '@mui/icons-material';
import LinkIcon from '@mui/icons-material/Link';
import { DocIcon, FormIcon, AuditIcon } from '../Icons';
import { WorkOutline } from '@mui/icons-material';
import { openPDFDialog } from '../PDFViewer/dialog';
import { grey, red } from '@mui/material/colors';
import { formatBytes } from '../Functions';
import ImageDialog from '../Components/ImageDialog';

import { previewFile } from '../../Hubs/dochub/redux/actions/file';
import { highlightDocument } from '../../Hubs/dochub/redux/actions';
import { useHistory } from 'react-router-dom';
import { isString } from 'lodash';

export default function FileViewer({ files, title = 'Attached', onDelete, renderButton }) {
  if (!files?.length) return null;
  return (
    <Grid container alignContent={'center'} alignItems={'center'} spacing={2}>
      {title && (
        <Grid container item>
          <Typography style={{ fontWeight: 500 }}>{title}</Typography>
        </Grid>
      )}
      {files
        .filter((file) => !Boolean(file.link))
        .map((file, i) => (
          <Grid item key={i}>
            <Grid container item>
              <Tile file={file} onDelete={onDelete} renderButton />
            </Grid>
            <Grid container item>
              {renderButton && renderButton(file)}
            </Grid>
          </Grid>
        ))}
      {files
        .filter((file) => Boolean(file.link))
        .map((file, i) => (
          <Grid item key={i}>
            <Grid container item>
              <LinkTile file={file} onDelete={onDelete} renderButton />
            </Grid>
            <Grid container item>
              {renderButton && renderButton(file)}
            </Grid>
          </Grid>
        ))}
    </Grid>
  );
}

export function Tile({ file, onDelete }) {
  const dispatch = useDispatch();
  const width = 200;
  const height = 140;
  const [open, setOpen] = useState(false);
  const [menu, setMenu] = useState(null);
  const [previewFailed, setPreviewFailed] = useState(false);

  const openFile = (selected) => {
    dispatch(previewFile(selected));
  };

  function getPreviewUrl(fileLocation) {
    if (!isString(fileLocation)) return null;
    const baseImageExtension = 'jpg';
    // the old logic
    if (fileLocation.startsWith('https://s3.eu-west-2.amazonaws.com/iqloud.app/')) {
      let modifiedKey = fileLocation.replace('https://s3.eu-west-2.amazonaws.com/iqloud.app/', '');
      if (modifiedKey.includes('hubs/')) {
        modifiedKey = modifiedKey.replace('hubs/', '');
      }
      if (modifiedKey.includes('stage/')) {
        modifiedKey = modifiedKey.replace('stage/', '');
      }
      if (modifiedKey.includes('dev/')) {
        modifiedKey = modifiedKey.replace('dev/', '');
      }
      let fileParts = modifiedKey.split('.');
      fileParts[fileParts.length - 1] = `${baseImageExtension}`;
      modifiedKey = fileParts.join('.');
      return { main: `https://qhub-previews.s3.eu-west-2.amazonaws.com/${modifiedKey}` };
    } else {
      const parts = fileLocation.split('/');
      if (parts.length !== 5) return null;
      const companyIdMatch = parts[2].match(/[0-9a-f]{24}/);
      if (!companyIdMatch || !companyIdMatch[0]) return null;
      const mainPath = `${companyIdMatch}/${parts[3]}/${parts[4]?.split('.')[0]}`;
      const alternativePath = `${companyIdMatch[0]}/${parts[3]}`;
      return {
        main: `https://q-hub-previews.s3.eu-west-2.amazonaws.com/${mainPath}.${baseImageExtension}`,
        alternative: `https://q-hub-previews.s3.eu-west-2.amazonaws.com/${alternativePath}.${baseImageExtension}`,
      };
    }
  }

  if (!file) return null;

  const previewUrl = getPreviewUrl(file.location);

  return (
    <ImageListItem component={Paper} variant="outlined" style={{ height, width, overflow: 'hidden' }}>
      <Grid container>
        <Grid
          container
          component={CardActionArea}
          onClick={() => {
            // const newBaseUrl = 'https://content.q-hub.co.uk/';
            // let cdn = { ...file };
            // // extract amazon prefix url from s3 file location
            // if (file.location.startsWith('https://s3.eu-west-2.amazonaws.com/iqloud.app/')) {
            //   let modifiedKey = file.location.replace('https://s3.eu-west-2.amazonaws.com/iqloud.app/', newBaseUrl);
            //   cdn.location = modifiedKey;
            // }
            // if (file.key) {
            //   cdn.location = `${newBaseUrl}${file.key}`;
            // } else {
            //   cdn.location = cdn.location.replace(/https:\/\/.*\.s3\.eu-west-2\.amazonaws\.com\//, newBaseUrl);
            // }
            if (file.type === 'application/pdf') {
              openPDFDialog(file);
            } else if (String(file.mimetype).startsWith('image')) {
              setOpen(true);
            } else {
              openFile({ category: 'normal', file, title: file.originalname });
            }
          }}
        >
          <Grid container justifyContent="center" alignItems="center">
            {!previewFailed && previewUrl ? (
              <img
                src={previewUrl?.main}
                alt={file.originalname}
                style={{
                  maxHeight: height,
                  maxWidth: width,
                  overflow: 'hidden',
                }}
                onError={(e) => {
                  if (e.currentTarget.src === previewUrl?.alternative || !previewUrl?.alternative) {
                    setPreviewFailed(true);
                    return;
                  }
                  e.currentTarget.src = previewUrl.alternative;
                }}
              />
            ) : (
              <>
                {String(file.mimetype).startsWith('image') ? (
                  <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    style={{
                      width,
                      height,
                      backgroundImage: `url(${file.location})`,
                      borderRadius: 8,
                      backgroundSize: 'cover',
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'center center',
                      cursor: 'pointer',
                    }}
                  />
                ) : (
                  <Grid
                    container
                    justifyContent="center"
                    alignItems={'flex-start'}
                    style={{ width, height, textAlign: 'center', paddingTop: height / 8 }}
                  >
                    <Typography
                      style={{
                        fontWeight: 500,
                        wordBreak: 'break-word',
                        maxHeight: height / 2,
                        maxWidth: width * 0.8,
                        overflow: 'hidden',
                      }}
                    >
                      {file.originalname}
                    </Typography>
                  </Grid>
                )}
              </>
            )}
          </Grid>
        </Grid>
        <ImageDialog open={open} onClose={() => setOpen(false)} src={file.location} />
      </Grid>
      <ImageListItemBar
        style={{ borderBottomLeftRadius: 8, borderBottomRightRadius: 8, bottom: -2 }}
        title={file.originalname}
        subtitle={formatBytes(file.size)}
        actionIcon={
          onDelete ? (
            <IconButton onClick={(event) => setMenu(event.currentTarget)}>
              <MoreHoriz style={{ color: 'white' }} />
            </IconButton>
          ) : (
            <IconButton onClick={() => window.open(file.location, '_blank')}>
              <GetAppRounded style={{ color: 'white' }} />
            </IconButton>
          )
        }
      />
      <Menu anchorEl={menu} keepMounted open={Boolean(menu)} onClose={() => setMenu(null)}>
        <MenuItem
          onClick={() => {
            onDelete(file.id);
            setMenu(null);
          }}
        >
          <ListItemIcon>
            <DeleteOutlineRounded style={{ color: red[500] }} />
          </ListItemIcon>
          <ListItemText primary={'Delete'} />
        </MenuItem>
        <MenuItem
          onClick={() => {
            window.open(file.location, '_blank');
            setMenu(null);
          }}
        >
          <ListItemIcon>
            <GetAppRounded style={{ color: grey[500] }} />
          </ListItemIcon>
          <ListItemText primary={'Download'} />
        </MenuItem>
      </Menu>
    </ImageListItem>
  );
}

function LinkTile({ file, onDelete }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const width = 200;
  const height = 140;
  const [menu, setMenu] = useState(null);
  return (
    <ImageListItem component={Paper} variant="outlined" style={{ height, width }}>
      <Grid container style={{ width, height, padding: '1rem 0.5rem' }}>
        <Link
          color="inherit"
          href={file.hub === 'doc' ? undefined : file.link}
          target={file.hub === 'doc' ? '_self' : '_blank'}
          onClick={() => {
            if (file.hub === 'doc') {
              dispatch(highlightDocument({ ...file, _id: file.id }));
              history.push(file.link);
            }
            return;
          }}
        >
          <Grid container item alignItems="center" justifyContent="center">
            {(() => {
              if (file.hub === 'doc') return <DocIcon />;
              if (file.hub === 'auditTemplate') return <WorkOutline style={{ color: '#00bfa5' }} />;
              if (file.hub === 'audit') return <AuditIcon />;
              if (file.hub === 'form') return <FormIcon />;
              return <LinkIcon />;
            })()}
            <Typography style={{ marginLeft: '0.5em' }}>{file.originalname}</Typography>
          </Grid>
        </Link>
        <Grid container item alignItems="flex-end" justifyContent="flex-end">
          {onDelete && <MoreHoriz style={{ cursor: 'pointer' }} onClick={(event) => setMenu(event.currentTarget)} />}
        </Grid>
      </Grid>

      <Menu anchorEl={menu} keepMounted open={Boolean(menu)} onClose={() => setMenu(null)}>
        <MenuItem
          onClick={() => {
            onDelete(file.id);
            setMenu(null);
          }}
        >
          <ListItemIcon>
            <DeleteOutlineRounded style={{ color: red[500] }} />
          </ListItemIcon>
          <ListItemText primary={'Delete'} />
        </MenuItem>
      </Menu>
    </ImageListItem>
  );
}
