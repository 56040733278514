import React, { useEffect, useState } from 'react';
import { isArray, isObject, isEmpty, isEqual } from 'lodash';
import { RoundedDialog, StandardDialogActions } from './index';
import { DialogTitle, DialogContent, Grid, Typography, TextField, MenuItem } from '@mui/material';
import { CircularProgress } from '@mui/material';
import { ArrowForwardRounded } from '@mui/icons-material';
import { grey, blue } from '@mui/material/colors';
import fieldTypes from '../Fields/FieldTypes';
import axios from 'axios';
import StateManager from '../StateManager';

export default function FieldMapDialog({
  open,
  onClose,
  onResult,
  domain,
  codomainFormId,
  codomainProcessId,
  initialMap,
}) {
  const [map, setMap] = useState({});
  const [codomain, setCodomain] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!codomainFormId || !open) return;
    setLoading(true);
    axios
      .get('/forms/general/getFormFields', { params: { formId: codomainFormId } })
      .then((res) => {
        setCodomain(res.data);
        setLoading(false);
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
        setLoading(false);
      });
  }, [codomainFormId, open]);

  useEffect(() => {
    if (!codomainProcessId) return;
    setLoading(true);
    axios
      .get('/process/getProcessFields', { params: { processId: codomainProcessId } })
      .then((res) => {
        setCodomain(res.data);
        setLoading(false);
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
        setLoading(false);
      });
  }, [codomainProcessId]);

  useEffect(() => {
    if (!initialMap || !isObject(initialMap)) {
      setMap({});
      return;
    }
    if (isEqual(initialMap, map)) return;

    setMap(initialMap);
  }, [initialMap]); // eslint-disable-line

  const fieldTypeFilter = (field) => field.fieldType && field.title;

  const filteredDomain = isArray(domain) ? domain.filter(fieldTypeFilter) : [];
  const filteredCodomain = isArray(codomain) ? codomain.filter(fieldTypeFilter) : [];

  const fields = filteredDomain.map((field) => ({
    id: field.id,
    title: field.title,
    icon: fieldTypes[field.fieldType]?.icon,
    codomain: filteredCodomain
      .filter(
        (x) =>
          x.fieldType === field.fieldType &&
          (x.fieldType !== 'dataSet' || x.dataSetParams.setId === field.dataSetParams.setId),
      )
      .map((x) => ({ id: x.id, title: x.title, icon: fieldTypes[x.fieldType]?.icon })),
  }));

  function done() {
    onResult(map);
    onClose();
  }

  return (
    <RoundedDialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Set field mapping</DialogTitle>
      <DialogContent>
        {isEmpty(fields) ? (
          <Grid container style={{ height: 200 }} alignItems="center" justifyContent="center">
            {loading ? (
              <CircularProgress color="primary" />
            ) : (
              <Typography variant="h6" color="textSecondary">
                No items defined 😥
              </Typography>
            )}
          </Grid>
        ) : (
          <Grid container spacing={2}>
            {fields.map((field) => (
              <Grid container item key={field.id} wrap="nowrap" alignItems="center" style={{ minHeight: 64 }}>
                <Grid container item xs={5} alignItems="center" wrap="nowrap">
                  {field.icon}
                  <Typography style={{ marginLeft: 12 }}>{field.title}</Typography>
                </Grid>
                <Grid container item xs={2}>
                  <ArrowForwardRounded style={{ color: map[field.id] ? blue[900] : grey[500] }} />
                </Grid>
                <Grid container item xs={5}>
                  {!isEmpty(field.codomain) ? (
                    <TextField
                      value={map[field.id] || ''}
                      onChange={(e) => setMap({ ...map, [field.id]: e.target.value })}
                      select
                      fullWidth
                      label="Select field"
                      variant="standard"
                    >
                      {field.codomain.map((codField) => (
                        <MenuItem key={codField.id} value={codField.id}>
                          {codField.title}
                        </MenuItem>
                      ))}
                      <MenuItem value="">NO OPTION</MenuItem>
                    </TextField>
                  ) : (
                    <Typography color="textSecondary">No items available</Typography>
                  )}
                </Grid>
              </Grid>
            ))}
          </Grid>
        )}
      </DialogContent>
      <StandardDialogActions onClose={onClose} onDone={done} />
    </RoundedDialog>
  );
}
