import { useEffect, useState } from 'react';
import { RoundedDialog, StandardDialogActions, UserChip } from '../../../Global/Components';
import { DialogContent, Grid, Typography, Chip, TextField, Autocomplete, Button } from '@mui/material';
import { DialogTitle } from '@mui/material';
import { CheckCircleRounded, CheckCircleOutlineRounded, ArrowDropDownRounded } from '@mui/icons-material';
import { FilterAltOffRounded } from '@mui/icons-material';
import { green, grey } from '@mui/material/colors';
import StateManager from '../../../Global/StateManager';
import { isEmpty, isFunction, isArray, isEqual } from 'lodash';
import axios from 'axios';

export default function AssetFilterDialog({ open, onClose, initial, onResult }) {
  const [loadingAssetsInfo, setLoadingAssetsInfo] = useState(false);

  const [assets, setAssets] = useState([]);
  const [selectedAssets, setSelectedAssets] = useState([]);

  const [locations, setLocations] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);

  const [profiles, setProfiles] = useState([]);
  const [selectedProfiles, setSelectedProfiles] = useState([]);

  const [selectedAssetOwners, setSelectedAssetOwners] = useState([]);

  const loadAssetInfo = open && (isEmpty(assets) || isEmpty(locations) || isEmpty(profiles));

  const result = getResult();

  useEffect(() => {
    if (isEqual(result, initial)) return;

    console.log('here');

    if (isArray(initial?.selectedAssets)) {
      setSelectedAssets(assets.filter((x) => initial.selectedAssets.includes(x._id)));
    } else {
      setSelectedAssets([]);
    }

    if (isArray(initial?.selectedLocations)) {
      setSelectedLocations(locations.filter((x) => initial.selectedLocations.includes(x._id)));
    } else {
      setSelectedLocations([]);
    }

    if (isArray(initial?.selectedProfiles)) {
      setSelectedProfiles(profiles.filter((x) => initial.selectedProfiles.includes(x._id)));
    } else {
      setSelectedProfiles([]);
    }

    if (isArray(initial?.selectedAssetOwners)) {
      setSelectedAssetOwners(initial.selectedAssetOwners);
    } else {
      setSelectedAssetOwners([]);
    }
  }, [initial, assets, locations, profiles]); // eslint-disable-line

  useEffect(() => {
    if (!loadAssetInfo) return;
    setLoadingAssetsInfo(true);

    axios
      .get('/asset/kpi/getAssetsInfo')
      .then(({ data }) => {
        setAssets(data.assets);
        setLocations(data.locations);
        setProfiles(data.profiles);
        setLoadingAssetsInfo(false);
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
        setLoadingAssetsInfo(false);
      });
  }, [loadAssetInfo]);

  function pickAssetOwners() {
    StateManager.selectMultipleUsers(saveAssetOwners, { initiallySelected: selectedAssetOwners });
  }

  function saveAssetOwners(res) {
    if (!res.users) return;

    setSelectedAssetOwners(res.users);
  }

  function getResult() {
    if (
      isEmpty(selectedAssets) &&
      isEmpty(selectedLocations) &&
      isEmpty(selectedProfiles) &&
      isEmpty(selectedAssetOwners)
    ) {
      return null;
    }

    return {
      selectedAssets: selectedAssets.map((x) => x._id),
      selectedLocations: selectedLocations.map((x) => x._id),
      selectedProfiles: selectedProfiles.map((x) => x._id),
      selectedAssetOwners,
    };
  }

  function done() {
    isFunction(onResult) && onResult(result);
    onClose();
  }

  function clear() {
    isFunction(onResult) && onResult(null);
    onClose();
  }

  return (
    <RoundedDialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Filter by asset data</DialogTitle>
      <DialogContent>
        <Grid container item sx={{ mb: 2 }} direction={'column'}>
          <Typography sx={{ mb: 1, fontWeight: 500 }}>Filter by assets:</Typography>
          <Autocomplete
            multiple
            loading={loadingAssetsInfo}
            fullWidth
            disableCloseOnSelect
            value={selectedAssets}
            onChange={(event, newValue) => {
              setSelectedAssets([...newValue]);
            }}
            getOptionLabel={(option) => (option ? option.title : '')}
            options={assets}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => <Chip color="primary" label={option.title} {...getTagProps({ index })} />)
            }
            renderInput={(params) => <TextField {...params} label="Select assets" variant="outlined" />}
            isOptionEqualToValue={(option, value) => option?._id === value?._id}
            renderOption={(props, option, { selected }) => (
              <li {...props} key={option._id}>
                <Grid container item alignItems="center" wrap="nowrap">
                  <Typography style={{ marginLeft: '1rem' }}>{option.title}</Typography>
                  {selected ? (
                    <CheckCircleRounded fontSize="small" style={{ color: green[500], marginLeft: 'auto' }} />
                  ) : (
                    <CheckCircleOutlineRounded fontSize="small" style={{ color: grey[300], marginLeft: 'auto' }} />
                  )}
                </Grid>
              </li>
            )}
          />
        </Grid>

        <Grid container item sx={{ mb: 2 }} direction={'column'}>
          <Typography sx={{ mb: 1, fontWeight: 500 }}>Filter by asset locations:</Typography>
          <Autocomplete
            multiple
            loading={loadingAssetsInfo}
            fullWidth
            disableCloseOnSelect
            value={selectedLocations}
            onChange={(event, newValue) => {
              setSelectedLocations([...newValue]);
            }}
            getOptionLabel={(option) => (option ? option.title : '')}
            options={locations}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => <Chip color="primary" label={option.title} {...getTagProps({ index })} />)
            }
            renderInput={(params) => <TextField {...params} label="Select asset locations" variant="outlined" />}
            isOptionEqualToValue={(option, value) => option?._id === value?._id}
            renderOption={(props, option, { selected }) => (
              <li {...props} key={option._id}>
                <Grid container item alignItems="center" wrap="nowrap">
                  <Typography style={{ marginLeft: '1rem' }}>{option.title}</Typography>
                  {selected ? (
                    <CheckCircleRounded fontSize="small" style={{ color: green[500], marginLeft: 'auto' }} />
                  ) : (
                    <CheckCircleOutlineRounded fontSize="small" style={{ color: grey[300], marginLeft: 'auto' }} />
                  )}
                </Grid>
              </li>
            )}
          />
        </Grid>

        <Grid container item sx={{ mb: 2 }} direction={'column'}>
          <Typography sx={{ mb: 1, fontWeight: 500 }}>Filter by asset profile:</Typography>
          <Autocomplete
            multiple
            loading={loadingAssetsInfo}
            fullWidth
            disableCloseOnSelect
            value={selectedProfiles}
            onChange={(event, newValue) => {
              setSelectedProfiles([...newValue]);
            }}
            getOptionLabel={(option) => (option ? option.title : '')}
            options={profiles}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => <Chip color="primary" label={option.title} {...getTagProps({ index })} />)
            }
            renderInput={(params) => <TextField {...params} label="Select asset profiles" variant="outlined" />}
            isOptionEqualToValue={(option, value) => option?._id === value?._id}
            renderOption={(props, option, { selected }) => (
              <li {...props} key={option._id}>
                <Grid container item alignItems="center" wrap="nowrap">
                  <Typography style={{ marginLeft: '1rem' }}>{option.title}</Typography>
                  {selected ? (
                    <CheckCircleRounded fontSize="small" style={{ color: green[500], marginLeft: 'auto' }} />
                  ) : (
                    <CheckCircleOutlineRounded fontSize="small" style={{ color: grey[300], marginLeft: 'auto' }} />
                  )}
                </Grid>
              </li>
            )}
          />
        </Grid>

        <Grid container direction={'column'} sx={{ mb: 2 }}>
          <Typography sx={{ fontWeight: 500 }} gutterBottom>
            Filter by asset owners:
          </Typography>
          <Button variant="outlined" onClick={pickAssetOwners} startIcon={<ArrowDropDownRounded />} sx={{ width: 230 }}>
            Pick owners
          </Button>
        </Grid>

        <Grid container alignItems="center">
          {selectedAssetOwners.map((userId, i) => (
            <UserChip
              key={i}
              id={userId}
              onDelete={() => {
                const res = selectedAssetOwners.filter((x) => x !== userId);
                setSelectedAssetOwners(res);
              }}
            />
          ))}
        </Grid>
      </DialogContent>

      <StandardDialogActions
        additionalActions={
          initial && (
            <Button startIcon={<FilterAltOffRounded />} onClick={clear} sx={{ color: grey[500], mr: 'auto' }}>
              clear filters
            </Button>
          )
        }
        onClose={onClose}
        onDone={done}
        hideDone={!result}
      />
    </RoundedDialog>
  );
}
