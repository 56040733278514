import React, { useState, useEffect, useRef } from 'react';
import { DeleteOutlineRounded } from '@mui/icons-material';
import { Grid, Typography, IconButton, CardActionArea } from '@mui/material';
import { green, red } from '@mui/material/colors';
import { FormIcon, ProcessIcon, RoutineIcon, AssetIcon } from '../../../Global/Icons';
import { Skeleton } from '@mui/material';
import axios from 'axios';
import useElementSize from '../../../Global/hooks/useElementSize';
import ProcessKPIDialog from './ProcessKPIDialog';
import GenericKpiDialog from './GenericKpiDialog';
import RoutineAdherenceKPIDialog from './RoutineAdherenceKPIDialog';
import RoutineFieldKpiDialog from './RoutineFieldKpiDialog';
import FieldKpiDialog from './FieldKpiDialog';
import AssetKpiDialog from './AssetKpiDialog';
import { v4 } from 'uuid';
import Chart from '../../../Global/Charts/Chart';

export default function KpiPreviewDashboard({ kpi, download, onDelete, clickable }) {
  const [data, setData] = useState(null);
  const [dialog, setDialog] = useState(false);
  const [id] = useState(v4());
  const { height } = useElementSize(id);
  const fontSize = Math.max(Math.round(height / 15), 10);

  useEffect(() => {
    if (!kpi) return;
    if (download || !kpi.data) {
      axios
        .get('/kpi/general/getKpi', { params: { type: kpi.type, kpiId: kpi._id } })
        .then((res) => {
          setData(res.data);
        })
        .catch((err) => console.error(err));
    } else {
      setData(kpi);
    }
  }, [kpi, download]);

  function handleUpdate(update) {
    if (update?._id !== kpi?._id) return;
    data.data = update.data;
  }

  if (!kpi) {
    return null;
  }

  const content = (
    <Grid container direction="column" style={{ height: '100%' }}>
      <Grid container alignContent="flex-start">
        <Grid container item xs={2}>
          {data ? (
            <>
              {data.type === 'process' && <ProcessIcon />}
              {data.type === 'form' && <FormIcon />}
              {data.type === 'asset' && <AssetIcon />}
              {data.type === 'routineField' && <RoutineIcon />}
              {data.type === 'routineAdherence' && <RoutineIcon />}
            </>
          ) : (
            <Skeleton variant="circular" width={40} height={40} />
          )}
        </Grid>
        <Grid container item xs={8} justifyContent="center">
          <Typography style={{ fontSize }}>{data ? data.title : <Skeleton variant="text" width={150} />}</Typography>
        </Grid>
        <Grid container item xs={2} alignItems="center" justifyContent="flex-end">
          {onDelete && (
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                onDelete();
              }}
            >
              <DeleteOutlineRounded style={{ color: red[500] }} />
            </IconButton>
          )}
        </Grid>
      </Grid>

      <Grid
        container
        item
        justifyContent="center"
        alignItems="center"
        style={{ overflow: 'hidden', position: 'relative', flex: 1 }}
      >
        {data ? (
          <Grid container style={{ height: '100%', position: 'absolute', right: 0, top: 0 }}>
            <Chart
              data={data.data}
              chartType={data.chartParams?.chartType || data.chartType}
              barChartType={data.chartParams?.barChartType || data.barChartType}
              isPreview
              numberChartFontSize={height ? Math.round(height / 2) : '10rem'}
            />
          </Grid>
        ) : (
          <Skeleton variant="circular" height={100} width={100} />
        )}
      </Grid>
    </Grid>
  );
  const withButton = clickable && data;

  return (
    <Grid id={id} container style={{ height: '100%', padding: withButton ? 0 : 16 }}>
      {withButton ? (
        <>
          <CardActionArea
            onClick={() => setDialog(true)}
            style={{ width: '100%', height: '100%', position: 'relative', padding: 16, borderRadius: 5 }}
          >
            {content}
          </CardActionArea>
          {data.dataSource ? (
            <GenericKpiDialog open={dialog} onClose={() => setDialog(false)} kpi={data} onChange={handleUpdate} />
          ) : (
            <>
              {data.type === 'process' && (
                <ProcessKPIDialog open={dialog} onClose={() => setDialog(false)} kpi={data} onChange={handleUpdate} />
              )}
              {data.type === 'field' && (
                <FieldKpiDialog open={dialog} onClose={() => setDialog(false)} kpi={data} onChange={handleUpdate} />
              )}
              {data.type === 'routineAdherence' && (
                <RoutineAdherenceKPIDialog
                  open={dialog}
                  onClose={() => setDialog(false)}
                  kpi={data}
                  onChange={handleUpdate}
                />
              )}
              {data.type === 'asset' && (
                <AssetKpiDialog open={dialog} kpi={data} onClose={() => setDialog(false)} onChange={handleUpdate} />
              )}
              {data.type === 'routineField' && (
                <RoutineFieldKpiDialog
                  open={dialog}
                  kpi={data}
                  onClose={() => setDialog(false)}
                  onChange={handleUpdate}
                />
              )}
            </>
          )}
        </>
      ) : (
        content
      )}
    </Grid>
  );
}
