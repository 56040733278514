import React, { useState } from 'react';
import { DialogContent, Grid, DialogTitle, TextField } from '@mui/material';
import { RoundedDialog, StandardDialogActions } from '../../../Global/Components';
import { Alert } from '@mui/material';
import axios from 'axios';

export default function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  function sendResetLink() {
    setLoading(true);
    setError('');
    axios
      .post('/auth/sendResetLinkByEmail', { email })
      .then((res) => {
        setSuccess(res.data.message);
        setLoading(false);
      })
      .catch((err) => {
        setError(err?.response?.data?.message || 'Sometning went wrong');
        setLoading(false);
      });
  }

  return (
    <RoundedDialog open maxWidth="xs" fullWidth onClose={() => {}}>
      <DialogTitle>{success ? 'Success' : 'Please enter you email'}</DialogTitle>
      <DialogContent>
        {!success && (
          <Grid container>
            <Grid container item sx={{ mt: 2 }}>
              <TextField
                type="email"
                id="email"
                variant="outlined"
                fullWidth
                label="Your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>
          </Grid>
        )}
        {error && (
          <Alert severity="error" style={{ width: '100%', marginTop: 16, borderRadius: 8 }}>
            {error}
          </Alert>
        )}
        {success && (
          <Alert severity="success" style={{ width: '100%', marginTop: 8, borderRadius: 8, marginBottom: 16 }}>
            {success}
          </Alert>
        )}
      </DialogContent>
      {!success && (
        <StandardDialogActions doneButtonText="SEND" saving={loading} onDone={sendResetLink} disableDone={!email} />
      )}
    </RoundedDialog>
  );
}
