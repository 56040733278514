/**
 * * Actions for Profile Page
 */
import axios from '../../../../redux/apiCaller';
import { successAction, attemptAction, errorAction } from '../../../../redux/constants';

import {
  GETPROFILE_ATTEMPT,
  GETPROFILE_SUCCESS,
  GETPROFILE_FAIL,
  UPDATEPROFILE_ATTEMPT,
  UPDATEPROFILE_SUCCESS,
  UPDATEPROFILE_FAIL,
  SETPROFILE,
  UPLOADPICTURE_ATTEMPT,
  UPLOADPICTURE_FAIL,
  UPLOADPICTURE_SUCCESS,
  CHANGEPASSWORD_ATTEMPT,
  CHANGEPASSWORD_SUCCESS,
  CHANGEPASSWORD_FAIL,
  FETCHDEFAULTUSER_ATTEMPT,
  FETCHDEFAULTUSER_SUCCESS,
  FETCHDEFAULTUSER_FAIL,
} from '../constants';

export const getUser = () => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(GETPROFILE_ATTEMPT));
      const body = await axios.get(`user/profile`);
      dispatch(successAction(GETPROFILE_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(GETPROFILE_FAIL, err));
    }
  };
  return thunk;
};

export const fetchDefaultAdmin = () => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(FETCHDEFAULTUSER_ATTEMPT));
      const body = await axios.get(`user/defaultAdmin`);
      dispatch(successAction(FETCHDEFAULTUSER_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(FETCHDEFAULTUSER_FAIL, err));
    }
  };
  return thunk;
};

export const updateUser = (data) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(UPDATEPROFILE_ATTEMPT));
      const body = await axios.post(`user/profile`, data);
      dispatch(successAction(UPDATEPROFILE_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(UPDATEPROFILE_FAIL, err));
    }
  };
  return thunk;
};

export const updateTour = (data) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(UPDATEPROFILE_ATTEMPT));
      const body = await axios.post(`user/tour`, data);
      dispatch(successAction(UPDATEPROFILE_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(UPDATEPROFILE_FAIL, err));
    }
  };
  return thunk;
};

export const updatePassword = (data) => {
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(CHANGEPASSWORD_ATTEMPT));
      const body = await axios.post(`user/updatePassword`, data);
      dispatch(successAction(CHANGEPASSWORD_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(CHANGEPASSWORD_FAIL, err));
    }
  };
  return thunk;
};

export const setUser = (data) => {
  const thunk = async function thunk(dispatch) {
    dispatch(successAction(SETPROFILE, data));
  };
  return thunk;
};

export const uploadPicture = (file) => {
  const data = new FormData();
  data.append('file', file);
  const thunk = async function thunk(dispatch) {
    try {
      dispatch(attemptAction(UPLOADPICTURE_ATTEMPT));
      const body = await axios.post(`/uploader/upload-single`, data, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      dispatch(successAction(UPLOADPICTURE_SUCCESS, body.data));
    } catch (err) {
      dispatch(errorAction(UPLOADPICTURE_FAIL, err));
    }
  };
  return thunk;
};
