import React, { useState, useEffect, useRef } from 'react';
import { Button, Menu, MenuItem, DialogTitle, DialogContent, DialogActions, Divider } from '@mui/material';
import { useMediaQuery, Grid, IconButton, Typography, InputBase, useTheme } from '@mui/material';
import { ListItemIcon, ListItemText, Tooltip, Chip } from '@mui/material';
import { RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { DeleteOutlineRounded, Clear, Close, RestoreFromTrashRounded } from '@mui/icons-material';
import { Flag, FlagOutlined, ArrowBack, PlayArrow, Pause, FileCopy } from '@mui/icons-material';
import { Settings, CreateRounded, Done, History, UnarchiveRounded } from '@mui/icons-material';
import { MoreHoriz, ArchiveOutlined, NotificationsActive, DeleteForeverRounded } from '@mui/icons-material';
import { Timer, Timelapse, Add, Visibility, VisibilityOff, Person, PeopleAlt } from '@mui/icons-material';
import { DueDate, HubTaskViewer } from '../workflowComponents';
import { StartAndEndDates } from '../workflowComponents';
import { CrossButton, HtmlContent, GeneralButton, User, NcrInfo } from '../../../Global/Components';
import { RoundedDialog, UserGroup } from '../../../Global/Components';
import { ProjectIcon, TaskIcon, ReoccurringIcon } from '../../../Global/Icons';
import { SubTasks, PriorityButton, StatusButton, LinkedTasks } from '../Components';
import { red, grey, green, blue, amber } from '@mui/material/colors';
import { FormatDate } from '../../../Global/Functions';
import { useSelector } from 'react-redux';
import TimerManager from '../../../Global/TimerManager';
import TaskManager from '../../../Global/TaskManager';
import { Link } from 'react-router-dom';
import FileViewer from '../../../Global/FileViewer';
import Uploader from '../../../Global/Uploader';
import axios from 'axios';
import moment from 'moment';
import StateManager from '../../../Global/StateManager';
import CommentsSection from '../../../Global/CommentsSection';
import Field from '../../../Global/Fields/Field';
import EditTaskFieldsDialog from '../Components/EditTaskFieldsDialog';
import RejectTaskDialog from './RejectTaskDialog';
import ReoccuringDialog from './ReoccuringDialog';
import FormAction from './FormAction';
import AuditReference from './AuditReference';
import SendToProject from './SendToProject';
import RecordedTime from './RecordedTime';
import StyledBadge from './StyledBadge';
import FailTaskDialog from './FailTaskDialog';
import WatchingDialog from './WatchingDialog';
import ProcessStep from './ProcessStep';
import HistoryDialog from './HistoryDialog';
import AssetMeasurements from './AssetMeasurements';
import AssetRecall from './AssetRecall';
import RoutineTemplateInfo from './RoutineTemplateInfo';
import AcceptanceDialog from './AcceptanceDialog';
import RowInfo from './RowInfo';
import FieldReference from '../../../Global/Fields/FieldReference';
import ExecutedActionReference from '../../../Global/Fields/ExecutedActionReference';
import ReviewTaskDialog from '../../../Global/Components/ReviewTaskDialog';
import TicketDialog from '../../../Support/components/TicketDialog';
import TicketReference from '../TaskDialog/TicketReference';
import { isFunction } from 'lodash';

export default function TaskDialog({
  open,
  task,
  onClose,
  selectedSubTaskId,
  fromProject,
  onMovingToBacklog,
  onResult,
  deleted,
  archived,
  onDelete,
}) {
  const userId = localStorage.getItem('_id');
  const theme = useTheme();
  const largeDevices = useMediaQuery(theme.breakpoints.up('sm'));
  const { user } = useSelector(({ profile }) => profile);
  const isPortalUser = user?.access === 'portal';

  const [isStartEnd, setIsStartEnd] = useState(false);

  // menus
  const [taskMenu, setTaskMenu] = useState(null);

  // componenent switches
  const [changeDueDate, setChangeDueDate] = useState(false);

  // task info being edited
  const [taskId, setTaskId] = useState();
  const [owner, setOwner] = useState();
  const [title, setTitle] = useState();
  const titleTimer = useRef();
  const [status, setStatus] = useState();
  const [dueAt, setDueAt] = useState();
  const [priority, setPriority] = useState();
  const [description, setDescripton] = useState();
  const files = useRef([]);
  const filesTimer = useRef();
  const [uploaded, setUploaded] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [comments, setComments] = useState([]);
  const [subTasks, setSubTasks] = useState([]);
  const [linkedTasks, setLinkedTasks] = useState([]);
  const linkedTasksTimer = useRef();
  const [repeatCycle, setRepeatCycle] = useState(0);
  const [flagged, setFlagged] = useState(false);
  const [watching, setWatching] = useState(false);
  const [fields, setFields] = useState([]);
  const [assignedUsers, setAssignedUsers] = useState([]);

  const [watchingMenu, setWatchingMenu] = useState();
  const [watchingDialog, setWatchingDialog] = useState(false);

  const [saving, setSaving] = useState('');

  const [ticketId, setTicketId] = useState('');
  const [linkedTicket, setLinkedTicket] = useState();
  const [linkedTicketOpen, setLinkedTicketOpen] = useState(false);

  // for project tasks
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  // dialogs
  const [reoccuringDialog, setReoccuringDialog] = useState(false);
  const [openMoveDialog, setOpenMoveDialog] = useState(false);
  const [failDialog, setFailDialog] = useState(false);
  const [fieldDialogOpen, setFieldDialogOpen] = useState(false);
  const [historyDialog, setHistoryDialog] = useState(false);
  const [acceptanceDialog, setAcceptanceDialog] = useState(false);

  // task acceptance
  const [rejecting, setRejecting] = useState(false);
  const [acceptance, setAcceptance] = useState(null);

  // timer
  const [timerActive, setTimerActive] = useState(false);
  const [recorderDialog, setRecordedDialog] = useState(false);
  const [timerMenu, setTimerMenu] = useState();

  const [duration, setDuration] = useState('');
  const [timer, setTimer] = useState('');
  const interval = useRef();
  const timerSubscription = useRef();

  function getTaskAcceptance() {
    if (!task) return null;
    // new way
    if (task.skipAcceptance) return true;
    if (task.userAcceptance) {
      let res = task.userAcceptance.find((x) => x.userId === userId);
      if (!res || !res.attempts || !res.attempts[0] || res.attempts[0].state === 'pending') return null;
      return res.attempts[0].state === 'accepted';
    }
    // old way
    if (task.acceptance?.status === 'true') return true;
    if (task.acceptance?.status === 'rejected') return false;
    return null;
  }

  useEffect(() => {
    if (timer && timer.state === 'active') {
      let start = moment(timer.startedAt);
      updateDuration(start, timer.elapsed);
      setTimerActive(true);
      if (interval.current) clearInterval(interval.current);
      interval.current = setInterval(updateDuration, 1000, start, timer.elapsed);
    } else {
      if (interval.current) {
        clearInterval(interval.current);
      }
      setDuration('');
      setTimerActive(false);
    }
  }, [timer, timer?.state]); // eslint-disable-line react-hooks/exhaustive-deps

  // to rerender when updated from WS or new task selected
  useEffect(() => {
    if (!task) return;

    setSaving('');
    setTaskId(task._id);
    setOwner(task.created_by);
    setTitle(task.title);
    setStatus(task.progress);
    setAcceptance(getTaskAcceptance());
    setPriority(task.priority);
    setDueAt(task.dueAt ? moment(task.dueAt) : null);
    setRepeatCycle(task.repeatCycle);
    setComments(task.notes || []);
    setDescripton(task.description || task.instructions);
    setUploaded(task.uploaded);
    setAttachments(task.attachments);
    setSubTasks(task.subTasks || []);
    setLinkedTasks(task.linkedTasks || []);
    setStartDate(task.startDate);
    setEndDate(task.endDate);
    setFlagged(task.flagged || false);
    setIsStartEnd(Boolean(task.startDate));
    setChangeDueDate(false);
    setWatching(task.watching && task.watching.indexOf(userId) > -1);
    setFields(task.fields || []);
    setAssignedUsers(task.assignedUsers || []);
    setTicketId(task.ticketId || '');
  }, [task]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!open || !task) {
      if (interval.current) clearInterval(interval.current);
      if (timerSubscription.current) timerSubscription.current.unsubscribe();
      setTimer(null);
      setFields([]); // to update the fields
      return;
    }
    let taskTimer = TimerManager.getTimer(task._id);
    setTimer(taskTimer);
    setTimerActive(Boolean(taskTimer) && taskTimer.state === 'active');
    timerSubscription.current = TimerManager.subscribeToTaskTimer(task._id, setTimer);
  }, [open]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (ticketId) {
      try {
        getTicket(ticketId);
      } catch (e) {
        console.error(e);
      }
    }
  }, [linkedTicketOpen, ticketId]);

  if (task?.type === 'review' && task.reviewEntryId) {
    return <ReviewTaskDialog showTarget open={open} onClose={onClose} reviewEntryId={task.reviewEntryId} />;
  }

  const isReoccuring = task?.repeatCycle != null && task?.repeatCycle > 0;
  const isFormAction = task?.type === 'form_action' || task?.type === 'form_action_hub';
  const isHubTask = Boolean(task?.hubTaskInfo?.id);
  const disabled = Boolean(task?.archivedAt) || Boolean(task?.recycledAt) || deleted || archived;
  const inAssignedUsers = Boolean(task?.assignedUsers?.includes(userId));
  const assignee =
    (task?.assigned_to === userId && (!task?.assignedUsers || task?.assignedUsers?.length === 0)) || inAssignedUsers;
  const creator = task?.created_by === userId;
  const editable = (assignee && acceptance) || creator;
  const isAdmin = user?.access === 'admin';

  //console.log(`acceptance: ${acceptance}; assignee: ${assignee}; disabled: ${disabled}; creator: ${creator};`);

  function deleteComment(id) {
    let update = {
      field: 'comments',
      action: 'delete',
      value: id,
    };
    save(update);
    setComments([...comments.filter((x) => x.id !== id)]);
  }

  function saveComment(comment) {
    const update = {
      field: 'comments',
      action: 'add',
      value: comment,
    };
    save(update);
    comments.push(comment);
    setComments([...comments]);
  }

  function saveReoccurringSettings(settings) {
    if (settings) {
      setDueAt(settings.dueAt);
      setRepeatCycle(settings.repeatCycle);

      let update = {
        field: 'reoccuringSettings',
        value: {
          dueAt: settings.dueAt,
          repeatCycle: settings.repeatCycle,
          repeatDays: settings.repeatDays,
        },
      };
      save(update);
    }
    setReoccuringDialog(false);
  }

  function confirmTaskDelete() {
    StateManager.setConfirm('You are about to delete this task', () => {
      axios
        .post('/tasks/management/deleteTask', { taskId: task._id })
        .then(() => {
          isFunction(onDelete) && onDelete(task._id);
          StateManager.setSuccessAlert('Task has been deleted');
          TaskManager.reportTaskDeleteEvent([task._id]);
          onClose();
        })
        .catch((err) => {
          console.error(err);
          StateManager.setErrorAlert('Failed to delete the task');
        });
    });
  }

  function archiveTask() {
    axios
      .post('/tasks/management/archiveTask', { taskId: task._id })
      .then((res) => {
        StateManager.setSuccessAlert('Task has been archived');
        TaskManager.reportTaskDeleteEvent([task._id]);
        onClose();
      })
      .catch((err) => {
        console.error(err);
        StateManager.setErrorAlert('Failed to archive the task');
      });
  }

  const repeatingPeriods = {
    1: 'Daily',
    7: 'Weekly',
    14: 'Fortnightly',
    30: 'Monthly',
    91: 'Every 3 Months',
    183: 'Every 6 Months',
    365: 'Yearly',
  };

  function getStringForPeriod(number) {
    let str = repeatingPeriods[number];
    return str ? str : 'Select Period';
  }

  function acceptTask() {
    axios
      .post('/tasks/saveTaskAcceptance', {
        taskId: task._id,
        accept: true,
      })
      .catch(() => StateManager.setErrorAlert('Failed to accept task'));
    setAcceptance(true);
  }

  function rejectTask(reason) {
    if (!reason) return;
    axios
      .post('/tasks/saveTaskAcceptance', {
        taskId: task._id,
        accept: false,
        reason,
      })
      .catch(() => StateManager.setErrorAlert('Failed to reject task'));
    setAcceptance(false);
    onClose();

    setRejecting(false);
  }

  function scrollToBottom() {
    setTimeout(() => {
      var element = document.getElementById('dialog-content');
      if (element) {
        element.scrollTop = element.scrollHeight;
      }
    }, 100);
  }

  function save(update) {
    if (disabled) return;
    setSaving('Saving...');
    axios
      .post('/tasks/saveTask', { taskId: task._id, update })
      .then((res) => {
        setSaving('Saved');
        //TaskManager.reportTaskUpdateEvent({ ...task, ...res.data });
        if (typeof onResult === 'function') {
          onResult(res.data);
        }
      })
      .catch((err) => {
        setSaving('Failed to save the task');
        StateManager.setErrorAlert(err?.response?.data?.message || 'Something went wrong');
      });
  }

  function sendTitle(value) {
    let update = {
      field: 'title',
      value,
    };
    save(update);
  }

  function saveTitle(value) {
    setTitle(value);

    if (titleTimer.current) clearTimeout(titleTimer.current);

    titleTimer.current = setTimeout(sendTitle, 1500, value);
  }

  function saveStatus(value) {
    let update = {
      field: 'progress',
      value,
    };
    save(update);
    setStatus(value);
  }

  function changeOwner() {
    StateManager.selectUser((user) => {
      if (user._id === task.created_by) return;
      let update = {
        field: 'created_by',
        value: user._id,
      };
      save(update);
      setOwner(user._id);
    });
  }

  function duplicate() {
    StateManager.selectMultipleUsers(duplicateTask, { title: 'Select assignees' });
  }

  function duplicateTask(res) {
    if (!res.users) return;
    axios
      .post('/tasks/duplicateTask', { users: res.users, taskId: task._id })
      .then(() => {
        StateManager.setSuccessAlert('Task has been duplicated');
      })
      .catch(() => {
        StateManager.setErrorAlert('Failed to duplicate a task');
      });
  }

  function savePriority(value) {
    let update = {
      field: 'priority',
      value,
    };
    save(update);
    setPriority(value);
  }

  function saveDescription(value) {
    let update = {
      field: 'description',
      value,
    };
    save(update);
    setDescripton(value);
  }

  function saveFiles(value) {
    let update = {
      field: 'uploaded',
      action: 'change',
      value,
    };
    save(update);
  }

  function saveField(fieldId, value) {
    let update = {
      field: 'field',
      value: {
        fieldId,
        value,
      },
    };
    save(update);
  }

  function saveFields(items) {
    setFields(items);
    let update = {
      field: 'fields',
      value: items,
    };
    save(update);
  }

  function onUploadedChange(files) {
    files.current = files;
    setUploaded(files);
    if (filesTimer.current) clearTimeout(filesTimer.current);

    filesTimer.current = setTimeout(() => saveFiles(files.current), 1200);
  }

  function deleteFile(id) {
    setUploaded(uploaded.filter((x) => x.id !== id));
    files.current = files.current.filter((x) => x.id !== id);
    let update = {
      field: 'uploaded',
      action: 'delete',
      value: id,
    };
    save(update);
  }

  function deleteAttachment(id) {
    setAttachments([...attachments.filter((x) => x.id !== id)]);
    let update = {
      field: 'attachments',
      action: 'delete',
      value: id,
    };
    save(update);
  }

  function saveLinked(tasks) {
    let update = {
      field: 'linkedTasks',
      value: tasks,
    };
    save(update);
  }

  function saveLinkedTasks(tasks) {
    setLinkedTasks([...tasks]);
    if (linkedTasksTimer.current) clearTimeout(linkedTasksTimer.current);
    linkedTasksTimer.current = setTimeout(saveLinked, 2000, tasks);
  }

  function resetDueDate() {
    setChangeDueDate(false);
    if (task.startDate && task.endDate) {
      setStartDate(moment(task.startDate));
      setEndDate(moment(task.endDate));
    }
    setDueAt(task.dueAt ? moment(task.dueAt) : null);
  }

  function saveDueDate() {
    setChangeDueDate(false);
    let update = {
      field: 'dueAt',
      startDate: startDate,
      endDate: endDate,
      value: endDate && startDate ? endDate : dueAt,
    };
    save(update);
  }

  function remind() {
    axios
      .post('/tasks/remind', { taskId: task._id })
      .then(() => StateManager.setSuccessAlert('Reminder has been sent'))
      .catch(() => StateManager.setErrorAlert('Failed to send a reminder'));
  }

  function switchFlagged() {
    let value = !Boolean(flagged);
    let update = {
      field: 'flagged',
      value,
    };
    setFlagged(value);
    save(update);
  }

  function closeMoveTask(res) {
    setOpenMoveDialog(false);
    if (res) {
      onClose();
      isFunction(onDelete) && onDelete(task._id);
    }
  }

  function confirmMoveToWF() {
    StateManager.setConfirm('You are about to move the task to workflow', moveTaskToWF);
  }

  function moveTaskToWF() {
    axios
      .post('/tasks/moveToWF', { taskId: task._id })
      .then(() => {
        StateManager.setSuccessAlert('Task has been moved to workflow');
        isFunction(onDelete) && onDelete(task._id);
        onClose();
      })
      .catch(() => StateManager.setErrorAlert('Failed to move the task'));
  }

  function confirmMoveToBacklog() {
    StateManager.setConfirm('You are about to move the task to backlog', moveTaskToBacklog);
  }

  function moveTaskToBacklog() {
    axios
      .post('/tasks/moveToBacklog', { taskId: task._id })
      .then((res) => {
        StateManager.setSuccessAlert('Task has been moved to backlog');
        res.data?.id && isFunction(onMovingToBacklog) && onMovingToBacklog(res.data);
        isFunction(onDelete) && onDelete(task._id);
        onClose();
      })
      .catch(() => StateManager.setErrorAlert('Failed to move the task'));
  }

  function confirmCancel() {
    StateManager.setConfirm('You are about to mark this task as canceled', markAsCanceled);
  }

  function markAsCanceled() {
    axios
      .post('/tasks/markAsCanceled', { taskId: task._id })
      .then(() => {
        StateManager.setSuccessAlert('Task has been marked as canceled');
        onClose();
      })
      .catch(() => StateManager.setErrorAlert('Failed to mark the task as canceled'));
  }

  function watchUnwatchTask() {
    let value = !watching;
    setWatching(value);
    axios
      .post('/tasks/watchUnwatchTask', { taskId: task._id })
      .then(() => StateManager.setSuccessAlert(value ? 'Added to watching list' : 'Removed from watching list'))
      .catch(() => {
        StateManager.setErrorAlert(
          value ? 'Failed to add to watchin list' : 'Failed to remove from from watching list',
        );
        setWatching(!value);
      });
  }

  function inviteToWatch() {
    StateManager.selectUser((user) => {
      if (user._id === userId) return;
      axios
        .post('/tasks/inviteToWatch', { taskId: task._id, userId: user._id })
        .then(() => StateManager.setSuccessAlert('Invitation has been sent'))
        .catch(() => StateManager.setErrorAlert('Failed to send the invitation'));
    });
  }

  function updateDuration(start, initialElapsed) {
    let diff = moment().diff(start);
    setDuration(moment.utc(diff + initialElapsed * 1000).format('HH:mm:ss'));
  }

  function startStopTaskTimer() {
    if (timerActive) {
      TimerManager.stopTaskTimer(task._id);
    } else {
      TimerManager.startTaskTimer(task._id, true);
    }
  }

  function confirmDeleteArchived() {
    StateManager.setConfirm('You are about to delete this task', deleteArchived);
  }

  function deleteArchived() {
    axios
      .post('/tasks/management/deleteArchivedTask', { taskId: task._id })
      .then(() => {
        StateManager.setSuccessAlert('Task has been deleted');
        if (typeof onDelete === 'function') onDelete(task._id);
        onClose();
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
      });
  }

  function confirmDeleteForever() {
    StateManager.setConfirm('You are about to delete this task permanently', deleteForever);
  }

  function deleteForever() {
    axios
      .post('/tasks/management/deleteTaskPermanently', { taskId: task._id })
      .then(() => {
        StateManager.setSuccessAlert('Task has been permanently deleted');
        if (typeof onDelete === 'function') onDelete(task._id);
        onClose();
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
      });
  }

  function unarchive() {
    axios
      .post('/tasks/management/unarchiveTask', { taskId: task._id })
      .then(() => {
        StateManager.setSuccessAlert('Task has been unarchived');
        if (typeof onDelete === 'function') onDelete(task._id);
        onClose();
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
      });
  }

  function restore() {
    axios
      .post('/tasks/management/restoreTask', { taskId: task._id })
      .then(() => {
        StateManager.setSuccessAlert('Task has been restored');
        if (typeof onDelete === 'function') onDelete(task._id);
        onClose();
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
      });
  }
  async function getTicket(ticketId) {
    try {
      await axios.get(`/support/getTicket?ticketID=${ticketId}`).then((res) => {
        setLinkedTicket(res.data[0]);
      });
    } catch (err) {
      StateManager.setAxiosErrorAlert(err);
    }
  }

  function onTicketSave(data) {
    setLinkedTicket({ ...linkedTicket, ...data });
    StateManager.setSuccessAlert('Tiket has been saved');
  }

  if (!task) return null;
  return (
    <RoundedDialog
      maxWidth="md"
      fullWidth
      open={open}
      onClose={() => onClose(null)}
      className="scroll-bar"
      fullScreen={!largeDevices}
      keepMounted
      elevation={26}
    >
      <DialogTitle style={{ padding: theme.spacing(1) }}>
        <Grid container alignItems="center">
          <Grid item xs={4} container>
            <User id={owner} onlyAvatar={!largeDevices} dense />
          </Grid>
          <Grid container item xs={4} justifyContent="center" alignItems="center">
            <TaskIcon />
            {largeDevices && (
              <Typography style={{ fontSize: 20, color: grey[500], marginLeft: '0.7em' }}>{task.number}</Typography>
            )}
            {largeDevices && (
              <Typography style={{ fontSize: 20, color: grey[500], marginLeft: '0.7em' }}>{duration}</Typography>
            )}
          </Grid>
          <Grid item xs={4} container justifyContent="flex-end">
            {!disabled && (
              <>
                <Tooltip title={`Task timer${timerActive ? ' (active)' : ''}`}>
                  <IconButton
                    style={{ width: '1.5em', height: '1.5em' }}
                    onClick={(event) => setTimerMenu(event.currentTarget)}
                  >
                    <StyledBadge overlap="circular" variant="dot" invisible={!timerActive}>
                      <Timer style={{ color: grey[500] }} />
                    </StyledBadge>
                  </IconButton>
                </Tooltip>
                <Menu anchorEl={timerMenu} open={Boolean(timerMenu)} onClose={() => setTimerMenu(null)}>
                  <MenuItem
                    onClick={() => {
                      startStopTaskTimer();
                      setTimerMenu(null);
                    }}
                  >
                    <ListItemIcon>
                      {timerActive ? (
                        <Pause style={{ color: grey[500] }} />
                      ) : (
                        <PlayArrow style={{ color: grey[500] }} />
                      )}
                    </ListItemIcon>
                    <ListItemText primary={timerActive ? 'Stop timer' : 'Start timer'} />
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setRecordedDialog(true);
                      setTimerMenu(null);
                    }}
                  >
                    <ListItemIcon>
                      <Timelapse style={{ color: grey[500] }} />
                    </ListItemIcon>
                    <ListItemText primary="Time records" />
                  </MenuItem>
                </Menu>
                {!isPortalUser && (
                  <IconButton
                    style={{ width: '1.5em', height: '1.5em' }}
                    onClick={(e) => setWatchingMenu(e.currentTarget)}
                  >
                    {watching ? (
                      <VisibilityOff style={{ color: grey[500] }} />
                    ) : (
                      <Visibility style={{ color: grey[500] }} />
                    )}
                  </IconButton>
                )}
                <Menu anchorEl={watchingMenu} open={Boolean(watchingMenu)} onClose={() => setWatchingMenu(null)}>
                  <MenuItem
                    onClick={() => {
                      watchUnwatchTask();
                      setWatchingMenu(null);
                    }}
                  >
                    <ListItemIcon>
                      {watching ? (
                        <VisibilityOff style={{ color: grey[500] }} />
                      ) : (
                        <Visibility style={{ color: grey[500] }} />
                      )}
                    </ListItemIcon>
                    <ListItemText primary={watching ? 'Remove from watching' : 'Add to watching'} />
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      inviteToWatch();
                      setWatchingMenu(null);
                    }}
                  >
                    <ListItemIcon>
                      <Person style={{ color: grey[500] }} />
                    </ListItemIcon>
                    <ListItemText primary="Invite to watch" />
                  </MenuItem>
                  {task.watching && task.watching[0] && (
                    <MenuItem
                      onClick={() => {
                        setWatchingDialog(true);
                        setWatchingMenu(null);
                      }}
                    >
                      <ListItemIcon>
                        <PeopleAlt style={{ color: grey[500] }} />
                      </ListItemIcon>
                      <ListItemText primary="People watching" />
                    </MenuItem>
                  )}
                </Menu>
                {largeDevices && (
                  <Tooltip title="Remind the assignee" placement="top">
                    <IconButton style={{ width: '1.5em', height: '1.5em' }} onClick={remind}>
                      <NotificationsActive style={{ color: grey[500] }} />
                    </IconButton>
                  </Tooltip>
                )}
                {owner === userId && largeDevices && !isPortalUser && (
                  <Tooltip title={`${flagged ? 'Unflag' : 'Flag'} the task`}>
                    <IconButton style={{ width: '1.5em', height: '1.5em' }} onClick={switchFlagged}>
                      {flagged ? <Flag style={{ color: red[500] }} /> : <FlagOutlined style={{ color: grey[500] }} />}
                    </IconButton>
                  </Tooltip>
                )}
              </>
            )}
            <Tooltip title="Task history">
              <IconButton style={{ width: '1.5em', height: '1.5em' }} onClick={() => setHistoryDialog(true)}>
                <History style={{ color: grey[500] }} />
              </IconButton>
            </Tooltip>
            {!disabled && (
              <>
                <IconButton
                  onClick={(event) => setTaskMenu(event.currentTarget)}
                  style={{ width: '1.5em', height: '1.5em' }}
                >
                  <MoreHoriz />
                </IconButton>
                <Menu anchorEl={taskMenu} open={Boolean(taskMenu)} onClose={() => setTaskMenu(null)}>
                  {userId === owner && (
                    <MenuItem
                      onClick={() => {
                        confirmTaskDelete();
                        setTaskMenu(null);
                      }}
                    >
                      <ListItemIcon>
                        <DeleteOutlineRounded style={{ color: red[500] }} />
                      </ListItemIcon>
                      <ListItemText primary="Delete task" />
                    </MenuItem>
                  )}
                  {userId === owner && task.completedDate && (
                    <MenuItem
                      onClick={() => {
                        archiveTask();
                        setTaskMenu(null);
                      }}
                    >
                      <ListItemIcon>
                        <ArchiveOutlined />
                      </ListItemIcon>
                      <ListItemText primary="Archive task" />
                    </MenuItem>
                  )}
                  {!assignee && !largeDevices && (
                    <MenuItem
                      onClick={() => {
                        remind();
                        setTaskMenu(null);
                      }}
                    >
                      <ListItemIcon>
                        <NotificationsActive style={{ color: '#757575' }} />
                      </ListItemIcon>
                      <ListItemText primary="Remind" />
                    </MenuItem>
                  )}
                  {owner === userId && !largeDevices && (
                    <MenuItem
                      onClick={() => {
                        switchFlagged();
                        setTaskMenu(null);
                      }}
                    >
                      <ListItemIcon>
                        {flagged ? <Flag style={{ color: red[500] }} /> : <FlagOutlined style={{ color: grey[500] }} />}
                      </ListItemIcon>
                      <ListItemText primary={`${flagged ? 'Unflag' : 'Flag'} the task`} />
                    </MenuItem>
                  )}
                  {owner === userId && !isPortalUser && (
                    <MenuItem
                      onClick={() => {
                        setOpenMoveDialog(true);
                        setTaskMenu(null);
                      }}
                    >
                      <ListItemIcon>
                        <ProjectIcon />
                      </ListItemIcon>
                      <ListItemText primary="Move to project" />
                    </MenuItem>
                  )}
                  {owner === userId && task.projectId && (
                    <MenuItem
                      onClick={() => {
                        confirmMoveToWF();
                        setTaskMenu(null);
                      }}
                    >
                      <ListItemIcon>
                        <TaskIcon />
                      </ListItemIcon>
                      <ListItemText primary="Move to workflow" />
                    </MenuItem>
                  )}
                  {owner === userId && task.projectId && (
                    <MenuItem
                      onClick={() => {
                        confirmMoveToBacklog();
                        setTaskMenu(null);
                      }}
                    >
                      <ListItemIcon>
                        <ArrowBack style={{ color: grey[600] }} />
                      </ListItemIcon>
                      <ListItemText primary="Move to backlog" />
                    </MenuItem>
                  )}
                  {(owner === userId || isAdmin) && !isPortalUser && (
                    <MenuItem
                      onClick={() => {
                        changeOwner();
                        setTaskMenu(null);
                      }}
                    >
                      <ListItemIcon>
                        <Person style={{ color: grey[500] }} />
                      </ListItemIcon>
                      <ListItemText primary="Change owner" />
                    </MenuItem>
                  )}
                  {!isPortalUser && (
                    <MenuItem
                      onClick={() => {
                        duplicate();
                        setTaskMenu(null);
                      }}
                    >
                      <ListItemIcon>
                        <FileCopy style={{ color: grey[500] }} />
                      </ListItemIcon>
                      <ListItemText primary="Duplicate" />
                    </MenuItem>
                  )}
                </Menu>
              </>
            )}
            {largeDevices && <CrossButton onClick={() => onClose(null)} style={{ marginLeft: '0.5rem' }} />}
          </Grid>
        </Grid>
      </DialogTitle>

      <DialogContent id="dialog-content" style={{ minHeight: '50vh' }}>
        <Grid container direction="column">
          {disabled && (
            <Grid container>
              <Typography variant="h4" color="textSecondary">
                This task is {task?.archivedAt || archived ? 'archived' : 'deleted'}
              </Typography>
            </Grid>
          )}

          {!isHubTask && (
            <Grid
              container
              item
              direction={largeDevices ? 'row' : 'column'}
              justifyContent={largeDevices ? 'space-between' : ''}
              alignItems="flex-start"
              style={{ padding: theme.spacing(2, 0) }}
              spacing={2}
              wrap="nowrap"
            >
              <Grid item style={{ flexGrow: 1, paddingRight: '1rem' }}>
                {owner !== userId || disabled ? (
                  <Typography variant="h5">{title}</Typography>
                ) : (
                  <InputBase
                    value={title}
                    disabled={owner !== userId || disabled}
                    style={{ margin: theme.spacing(1), width: '100%' }}
                    inputProps={{ style: { fontSize: 26 } }}
                    onChange={(event) => saveTitle(event.target.value)}
                  />
                )}
              </Grid>

              <Grid item style={{ display: 'flex', width: 'fit-content', alignItems: 'center', marginLeft: 'auto' }}>
                <Typography style={{ marginRight: '1em', fontWeight: 700 }}> Status</Typography>
                <StatusButton
                  status={status}
                  setStatus={saveStatus}
                  isDisabled={disabled || !editable}
                  onCancel={() => confirmCancel(true)}
                  onFail={() => setFailDialog(true)}
                  isOverdue={task.isOverdue}
                  projectStatuses={task.projectStatuses}
                />
              </Grid>
            </Grid>
          )}

          {task.actionReferenceInfo && (
            <FieldReference actionReferenceInfo={task.actionReferenceInfo} actionReferences={task.actionReferences} />
          )}
          {ticketId && (
            <Grid container item>
              <TicketReference
                disabled={user.isSuperAdmin}
                ticketId={ticketId}
                ticket={linkedTicket}
                setTicket={setLinkedTicket}
                setTicketOpen={setLinkedTicketOpen}
              />
            </Grid>
          )}

          {task.executedActionId && <ExecutedActionReference executedActionId={task.executedActionId} />}

          <HubTaskViewer task={task} />

          {flagged && !disabled && (
            <Grid item container alignItems="center">
              <Flag style={{ color: red[500] }} />
              <Typography style={{ color: red[500], marginLeft: theme.spacing(1) }}>This task is flagged</Typography>
            </Grid>
          )}

          {task.tags && task.tags[0]?._id && !disabled && (
            <Grid item container alignItems="center">
              <Typography style={{ marginRight: '1em', fontWeight: 700 }}>Tags</Typography>
              {task.tags.map((tag) => (
                <Chip
                  key={tag._id}
                  style={{
                    background: tag.color,
                    color: theme.palette.getContrastText(tag.color),
                    margin: theme.spacing(1),
                  }}
                  label={tag.text}
                />
              ))}
            </Grid>
          )}

          {task.ncrId && <NcrInfo onClick={onClose} ncrId={task.ncrId} sx={{ py: 1 }} />}

          {!task.ncrId && task.auditId && (
            <AuditReference
              auditId={task.auditId}
              requirementId={task.auditRequirementId}
              onTaskClose={() => onClose(null)}
            />
          )}

          {changeDueDate && task.projectId && !disabled && (
            <Grid container item alignItems="center">
              <Typography style={{ fontWeight: 600, marginRight: '1em' }}>Due date type</Typography>
              <RadioGroup
                row
                value={String(isStartEnd)}
                onChange={(event) => {
                  setIsStartEnd(event.target.value === 'true');
                  if (event.target.value === 'true') {
                    setStartDate(moment().add(1, 'day').startOf('day'));
                    setEndDate(moment().add(4, 'days').endOf('day'));
                  } else {
                    setStartDate(null);
                    setEndDate(null);
                  }
                }}
              >
                <FormControlLabel value="false" control={<Radio />} label="Only due date" />
                <FormControlLabel value="true" control={<Radio />} label="Start and end dates" />
              </RadioGroup>
            </Grid>
          )}

          {!disabled && (
            <Grid
              item
              container
              justifyContent="space-between"
              alignItems="center"
              style={{ padding: theme.spacing(2, 0), margin: largeDevices ? 0 : '10px 0px' }}
            >
              <Grid item lg={9} md={9} xs={12}>
                {startDate && endDate ? (
                  <StartAndEndDates
                    editable={changeDueDate}
                    start={startDate}
                    end={endDate}
                    setStart={setStartDate}
                    setEnd={setEndDate}
                  />
                ) : (
                  <DueDate editable={changeDueDate} value={dueAt} setValue={setDueAt} />
                )}
              </Grid>
              {changeDueDate && (
                <Grid item lg={3} md={3} xs={12} container justifyContent={largeDevices ? 'flex-end' : 'flex-start'}>
                  <Tooltip title="Cancel" placement="top">
                    <IconButton onClick={resetDueDate}>
                      <Clear />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Save" placement="top">
                    <IconButton onClick={saveDueDate}>
                      <Done />
                    </IconButton>
                  </Tooltip>
                </Grid>
              )}
              {!changeDueDate && isReoccuring && (
                <Grid item lg={3} md={3} xs={12} container justifyContent={largeDevices ? 'flex-end' : 'flex-start'}>
                  <ReoccurringIcon style={{ margin: 'auto 0.5em' }} />
                  <Typography style={{ fontSize: 16, margin: 'auto 1em auto 0px' }}>
                    {getStringForPeriod(repeatCycle)}
                  </Typography>
                  <Typography style={{ fontSize: 16, margin: 'auto 1em auto 0px' }}>{`#${task.iteration}`}</Typography>
                </Grid>
              )}
              {!changeDueDate && userId === owner && !disabled && (
                <Grid item container lg={3} md={3} xs={12} justifyContent={largeDevices ? 'flex-end' : 'flex-start'}>
                  {!isReoccuring && (
                    <Button
                      style={{ textTransform: 'none' }}
                      startIcon={<CreateRounded style={{ color: amber[500] }} />}
                      onClick={() => setChangeDueDate(true)}
                    >
                      Change
                    </Button>
                  )}
                  {isReoccuring && (
                    <Button
                      style={{
                        textTransform: 'none',
                        width: '100%',
                        whiteSpace: 'nowrap',
                      }}
                      startIcon={<Settings style={{ color: 'grey' }} />}
                      onClick={() => setReoccuringDialog(true)}
                    >
                      Reoccurring Settings
                    </Button>
                  )}
                </Grid>
              )}
            </Grid>
          )}

          <Grid container item alignItems="center">
            <Typography style={{ fontWeight: 700, marginRight: '1em' }}>Priority:</Typography>
            <PriorityButton priority={priority} setPriority={savePriority} disabled={disabled || owner !== userId} />
          </Grid>

          {task.projectId && !fromProject && (
            <Grid container item style={{ padding: theme.spacing(2, 0) }}>
              <Link to={`/tasks/projects/project/${task.projectId}`} onClick={onClose}>
                <Grid container item alignItems="center">
                  <ProjectIcon style={{ marginRight: '1rem' }} />
                  <Typography variant="h6" color={'primary'}>{`Go to project "${task.projectName}"`}</Typography>
                </Grid>
              </Link>
            </Grid>
          )}

          {task.failedAt && (
            <Grid container item style={{ padding: theme.spacing(1, 0) }}>
              <Typography>{`Marked as failed for a reason: ${task.failReason}`}</Typography>
            </Grid>
          )}

          <RoutineTemplateInfo templateId={task.routineTemplateId} />
          <RowInfo rowId={task.rowId} />

          <ProcessStep ongoingStepId={task.ongoingStepId} />

          <Grid container item style={{ padding: theme.spacing(2, 0) }} alignItems="center">
            <Typography sx={{ fontWeight: 600 }}>Assigned to:</Typography>
            <UserGroup
              ids={assignedUsers?.length > 0 ? assignedUsers : [task.assigned_to]}
              onClick={isPortalUser || disabled ? undefined : () => setAcceptanceDialog(true)}
              style={{ marginLeft: '1rem' }}
              dialogTitle="Assigned users"
            />
            {!disabled && (
              <AcceptanceDialog
                open={acceptanceDialog}
                onClose={() => setAcceptanceDialog(false)}
                task={{ ...task, assignedUsers }}
                onTaskChange={(update) => {
                  task.userAcceptance = update.userAcceptance;
                  setAssignedUsers(update.assignedUsers || []);
                }}
              />
            )}
          </Grid>

          {task?.userAcceptance?.length > 0 && acceptance !== true && !disabled && (
            <>
              {task.userAcceptance.findIndex((x) => x.attempts[0]?.state === 'pending') > -1 ? (
                <Grid container item style={{ margin: theme.spacing(1, 0) }}>
                  <Typography color="textSecondary" variant="h6">
                    Pending acceptance.
                    <span
                      onClick={() => setAcceptanceDialog(true)}
                      style={{ color: blue[800], fontWeight: 500, marginLeft: '0.5em', cursor: 'pointer' }}
                    >
                      Details
                    </span>
                  </Typography>
                </Grid>
              ) : (
                <>
                  {task.userAcceptance.findIndex((x) => x.attempts[0]?.state === 'accepted') === -1 && (
                    <Grid container item style={{ margin: theme.spacing(1, 0) }}>
                      <Typography color="textSecondary" variant="h6">
                        Task has been <span style={{ color: red[500] }}>rejected</span>.
                        <span
                          onClick={() => setAcceptanceDialog(true)}
                          style={{ color: blue[800], fontWeight: 500, marginLeft: '0.5em', cursor: 'pointer' }}
                        >
                          Details
                        </span>
                      </Typography>
                    </Grid>
                  )}
                </>
              )}
            </>
          )}

          {isFormAction && <FormAction formInfo={task.hubTaskInfo.formInfo} taskId={task._id} />}

          {task.assetId && !task.managementTaskId && <AssetRecall assetId={task.assetId} />}

          <AssetMeasurements assetId={task.assetId} managementTaskId={task.managementTaskId} taskId={task._id} />

          {(!disabled || description) && (
            <>
              <Grid container item style={{ margin: theme.spacing(2, 0) }}>
                <HtmlContent
                  content={description}
                  editable={!disabled && editable}
                  onSave={saveDescription}
                  title="Task description"
                  editIfEmpty={false}
                  placeholder="No description provided for this task"
                />
              </Grid>

              <Divider light />
            </>
          )}

          {(!disabled || attachments?.length + uploaded.length > 0) && (
            <>
              <Grid container item style={{ margin: theme.spacing(2, 0), padding: theme.spacing(0, 1) }}>
                <Grid container item>
                  <Typography variant="h6" gutterBottom>
                    Attached:
                  </Typography>
                </Grid>

                {attachments?.length > 0 && (
                  <Grid container item>
                    <FileViewer
                      files={attachments}
                      onDelete={userId === owner && !disabled ? deleteAttachment : null}
                      title=""
                    />
                  </Grid>
                )}

                {uploaded?.length > 0 && (
                  <Grid container item>
                    <FileViewer
                      files={uploaded}
                      title="Uploaded"
                      onDelete={!disabled && editable ? deleteFile : null}
                    />
                  </Grid>
                )}

                {!disabled && !isHubTask && editable && (
                  <Uploader
                    buttonText="Upload files"
                    uploaded={uploaded}
                    clearOnDone
                    onChange={onUploadedChange}
                    onCancel={deleteFile}
                  />
                )}
              </Grid>
              <Divider light />
            </>
          )}

          {(!disabled || fields?.length > 0) && (
            <>
              <Grid container item style={{ margin: theme.spacing(2, 0), padding: theme.spacing(1) }}>
                <Grid container item>
                  <Typography variant="h6">Task fields:</Typography>
                </Grid>
                <Grid container item sx={{ pt: 1 }}>
                  {fields.map((item) => (
                    <Field
                      key={item.id}
                      editable={!disabled && editable && !task.completedDate}
                      item={item}
                      onSave={saveField}
                      assignedUsers={assignedUsers?.length > 0 ? assignedUsers : [task.assigned_to]}
                      dueDate={dueAt}
                      assignedBy={owner}
                      activityId={taskId}
                      activity={'Task'}
                      parentTaskId={taskId}
                    />
                  ))}
                </Grid>
                {!disabled && editable && !task.completedDate && (
                  <Grid container item style={{ padding: theme.spacing(1, 0) }}>
                    <GeneralButton
                      onClick={() => setFieldDialogOpen(true)}
                      startIcon={<Add style={{ color: grey[500] }} />}
                    >
                      Add fields
                    </GeneralButton>
                  </Grid>
                )}
                <EditTaskFieldsDialog
                  initial={fields}
                  open={fieldDialogOpen}
                  onClose={() => setFieldDialogOpen(false)}
                  onResult={saveFields}
                  forPortal={isPortalUser}
                />
              </Grid>

              <Divider light />
            </>
          )}

          {(!disabled || subTasks?.length > 0) && (
            <>
              <Grid container item style={{ margin: theme.spacing(2, 0), padding: theme.spacing(1) }}>
                <SubTasks
                  mainTaskId={task._id}
                  mainTaskCreator={owner}
                  subTasks={subTasks}
                  setSubTasks={setSubTasks}
                  disabled={disabled || !editable}
                  selectedSubTaskId={selectedSubTaskId}
                />
              </Grid>

              <Divider light />
            </>
          )}

          {!disabled && (
            <Grid container item style={{ margin: theme.spacing(3, 0) }}>
              <LinkedTasks
                linkedTasks={linkedTasks}
                setLinkedTasks={saveLinkedTasks}
                taskId={task._id}
                disabled={disabled || !editable}
              />
            </Grid>
          )}
          <CommentsSection
            disabled={disabled}
            comments={comments}
            deleteComment={deleteComment}
            addComment={saveComment}
            onAddingCommentChange={(val) => {
              if (!val) return;
              scrollToBottom();
            }}
          />

          <ReoccuringDialog task={task} open={reoccuringDialog} onClose={saveReoccurringSettings} />
          <SendToProject
            currProjectId={task.projectId}
            taskId={task._id}
            open={openMoveDialog}
            onClose={closeMoveTask}
          />

          {failDialog && (
            <FailTaskDialog
              open={failDialog}
              onClose={() => setFailDialog(false)}
              taskId={task._id}
              onResult={onClose}
            />
          )}
          <RecordedTime open={recorderDialog} onClose={() => setRecordedDialog(false)} taskId={task._id} />
          <WatchingDialog open={watchingDialog} onClose={() => setWatchingDialog(false)} users={task.watching || []} />
          <HistoryDialog open={historyDialog} onClose={() => setHistoryDialog(false)} taskId={task._id} />
        </Grid>
      </DialogContent>

      <DialogActions style={{ borderTop: '1px solid #d0d0d0aa' }}>
        {(task?.archivedAt || archived) && (
          <>
            <Button startIcon={<UnarchiveRounded />} style={{ color: grey[600] }} onClick={unarchive}>
              Unarchive task
            </Button>
            <Button startIcon={<DeleteOutlineRounded />} style={{ color: red[600] }} onClick={confirmDeleteArchived}>
              Delete task
            </Button>
          </>
        )}
        {(task?.recycledAt || deleted) && (
          <>
            <Button startIcon={<RestoreFromTrashRounded />} style={{ color: grey[600] }} onClick={restore}>
              Restore task
            </Button>
            <Button startIcon={<DeleteForeverRounded />} style={{ color: red[600] }} onClick={confirmDeleteForever}>
              Delete permanently
            </Button>
          </>
        )}
        {!disabled && (
          <Typography style={{ fontSize: 14, color: grey[600] }}>{`Created ${FormatDate(task.createdAt)}`}</Typography>
        )}
        <div style={{ marginRight: 'auto' }} />
        {!disabled && (
          <Typography style={{ fontSize: 14, color: grey[600], marginRight: '1rem', marginLeft: 'auto' }}>
            {saving}
          </Typography>
        )}
        {(acceptance != null || creator) && (
          <Button startIcon={<Clear />} style={{ color: red[500] }} onClick={onClose}>
            Close
          </Button>
        )}
        {acceptance == null && assignee && !disabled && !creator && (
          <>
            <Button
              style={{ background: green[500], color: 'white', borderRadius: 8 }}
              startIcon={<Done />}
              onClick={acceptTask}
            >
              Accept task
            </Button>
            <Button
              style={{
                marginLeft: '1rem',
                background: red[500],
                color: 'white',
                borderRadius: 8,
              }}
              startIcon={<Close />}
              onClick={() => setRejecting(true)}
            >
              Reject task
            </Button>
            <RejectTaskDialog open={rejecting} onClose={rejectTask} />
          </>
        )}
      </DialogActions>
      {linkedTicket && user.isSuperAdmin && (
        <TicketDialog
          open={linkedTicketOpen}
          ticket={linkedTicket}
          fromAdmin={user.isSuperAdmin}
          onClose={() => setLinkedTicketOpen(false)}
          onSave={onTicketSave}
        />
      )}
    </RoundedDialog>
  );
}
