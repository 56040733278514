import React, { useState, useRef, useEffect } from 'react';
import { DialogContent, DialogTitle, Grid, CardActionArea, Paper, Typography, Button } from '@mui/material';
import { TableContainer, Table, TableBody, TableHead, TableRow, TableCell } from '@mui/material';
import { TextField, CircularProgress, IconButton } from '@mui/material';
import { ArrowForwardIosRounded, ArrowBackIosRounded, VisibilityRounded, LanguageRounded } from '@mui/icons-material';
import {
  CheckCircleRounded,
  CheckCircleOutlineRounded,
  DashboardRounded,
  CloseRounded,
  ViewQuilt,
} from '@mui/icons-material';
import { RoundedDialog, StandardDialogActions } from '../../../../Global/Components';
import { TaskIcon, FormIcon, ProcessIcon, DocIcon, KPIHubIcon, WorkspaceIcon } from '../../../../Global/Icons';
import { AssetIcon, AuditIcon, RegistersIcon, RoutineIcon, ProjectIcon } from '../../../../Global/Icons';
import Autocomplete from '@mui/material/Autocomplete';
import { grey, green, teal } from '@mui/material/colors';
import StateManager from '../../../../Global/StateManager';
import { FormatDateShort } from '../../../../Global/Functions';
import SwipeableViews from 'react-swipeable-views';
import axios from 'axios';
import { v4 } from 'uuid';
import FolderPicker from '../../../dochub/components/MoveFolderDialog/tree';
import { useSelector } from 'react-redux';

function TabPanel({ children, value, index, ...props }) {
  return (
    <Grid
      container
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...props}
    >
      <Grid container>{children}</Grid>
    </Grid>
  );
}

const workhubOptions = (forPortal) =>
  forPortal
    ? [
        {
          title: 'Go to a task',
          icon: <TaskIcon />,
          type: 'task',
          url: '/tasks/search/searchTasks',
          text: 'Select task',
          lazy: true,
        },
      ]
    : [
        { title: 'Go to work hub', icon: <TaskIcon />, link: '/tasks', type: 'task' },
        { title: 'Go to my tasks', icon: <TaskIcon />, link: '/tasks/my', type: 'task' },
        { title: 'Go to routines', icon: <RoutineIcon />, link: '/tasks/routines', type: 'routine' },
        { title: 'Go to projects', icon: <ProjectIcon />, link: '/tasks/projects/home', type: 'project' },
        {
          title: 'Go to a routine',
          icon: <RoutineIcon />,
          type: 'routine',
          url: '/tasks/routines/getRoutines',
          text: 'Select routine',
        },
        {
          title: 'Go to a project',
          icon: <ProjectIcon />,
          type: 'project',
          url: '/project/getUserProjects',
          text: 'Select project',
        },
        {
          title: 'Go to a task',
          icon: <TaskIcon />,
          type: 'task',
          url: '/tasks/search/searchTasks',
          text: 'Select task',
          lazy: true,
        },
      ];

const formhubOptions = (forPortal) =>
  forPortal
    ? [
        {
          title: 'Go to a form',
          icon: <FormIcon />,
          type: 'form',
          url: '/forms/search/getSearchForms',
          text: 'Select form',
        },
        {
          title: 'Go to an entry',
          icon: <FormIcon />,
          type: 'formEntry',
          url: '/forms/search/getSearchForms',
          text: 'Select form',
        },
      ]
    : [
        { title: 'Go to form hub', icon: <FormIcon />, link: '/forms/home', type: 'form' },
        { title: 'Working forms', icon: <FormIcon />, link: '/forms/working', type: 'form' },
        {
          title: 'Go to a form',
          icon: <FormIcon />,
          type: 'form',
          url: '/forms/search/getSearchForms',
          text: 'Select form',
        },
        {
          title: 'Go to an entry',
          icon: <FormIcon />,
          type: 'formEntry',
          url: '/forms/search/getSearchForms',
          text: 'Select form',
        },
      ];

const processhubOptions = (forPortal) =>
  forPortal
    ? [
        {
          title: 'Go to a process',
          icon: <ProcessIcon />,
          type: 'process',
          url: '/process/search/getSearchProcesses',
          text: 'Select process',
        },
        {
          title: 'Go to an entry',
          icon: <ProcessIcon />,
          type: 'processEntry',
          url: '/process/search/getSearchProcesses',
          text: 'Select process',
        },
      ]
    : [
        { title: 'Go to process hub', icon: <ProcessIcon />, link: '/processes/home', type: 'process' },
        { title: 'Working processes', icon: <ProcessIcon />, link: '/processes/working', type: 'process' },
        {
          title: 'Go to a process',
          icon: <ProcessIcon />,
          type: 'process',
          url: '/process/search/getSearchProcesses',
          text: 'Select process',
        },
        {
          title: 'Go to an entry',
          icon: <ProcessIcon />,
          type: 'processEntry',
          url: '/process/search/getSearchProcesses',
          text: 'Select process',
        },
      ];

const assethubOptions = [
  { title: 'Go to asset hub', icon: <AssetIcon />, link: '/asset/home', type: 'asset' },
  {
    title: 'Go to an asset',
    icon: <AssetIcon />,
    type: 'asset',
    url: '/asset/search/getSearchAssets',
    text: 'Select asset',
  },
  {
    title: 'Go to a profile',
    icon: <AssetIcon />,
    type: 'assetProfile',
    url: '/asset/search/getSearchProfiles',
    text: 'Select asset profile',
  },
];

const audithubOptions = [
  { title: 'Go to audit hub', icon: <AuditIcon />, link: '/audits', type: 'audit' },
  {
    title: 'Go to an audit',
    icon: <AuditIcon />,
    type: 'audit',
    url: '/audits/search/getSearchAudits',
    text: 'Select audit',
  },
];

const registerhubOptions = (forPortal) =>
  forPortal
    ? [
        {
          title: 'Go to a register',
          icon: <RegistersIcon />,
          type: 'register',
          url: '/registers/general/getUserRegisters',
          text: 'Select register',
        },
      ]
    : [
        { title: 'Go to register hub', icon: <RegistersIcon />, link: '/registers/home', type: 'register' },
        {
          title: 'Go to a register',
          icon: <RegistersIcon />,
          type: 'register',
          url: '/registers/general/getUserRegisters',
          text: 'Select register',
        },
      ];

const kpihubOptions = [
  { title: 'Go to KPI hub', icon: <KPIHubIcon />, link: '/kpiHub/myKpi', type: 'kpi' },
  {
    title: 'Go to KPI dashboard',
    icon: <DashboardRounded style={{ color: teal[500] }} />,
    type: 'kpiDashboard',
    url: '/kpi/getDashboards',
    text: 'Select dashboard',
  },
];

const workspaceOptions = [
  {
    title: 'Go to a dashboard',
    icon: <DashboardRounded />,
    type: 'dashboard',
    url: '/workhub/workspace/getUserWorkspaces',
    text: 'Select dashboard',
  },
];

const viewOptions = [
  {
    title: 'Go to a view',
    icon: <ViewQuilt sx={{ color: grey[500] }} />,
    type: 'view',
    url: '/general/datagrid/getDataViewsList',
    text: 'Select view',
  },
];

const dochubOptions = (forPortal) =>
  forPortal
    ? [
        {
          title: 'Go to a document',
          icon: <DocIcon />,
          type: 'doc',
          url: 'getCompanyDocuments',
          text: 'Select document',
          bulk: true,
        },
        {
          title: 'Go to an area/folder',
          icon: <DocIcon />,
          type: 'area',
          url: 'getAreas',
          text: 'Select area',
        },
        {
          title: 'Go to areas',
          icon: <DocIcon />,
          type: 'areas',
          link: '/DocLibrary/areas',
          text: 'Select areas',
        },
      ]
    : [
        { title: 'Go to doc hub', icon: <DocIcon />, link: '/DocLibrary/home', type: 'doc' },
        {
          title: 'Go to an area',
          icon: <DocIcon />,
          type: 'area',
          url: 'getAreas',
          text: 'Select area',
        },
        {
          title: 'Go to a document',
          icon: <DocIcon />,
          type: 'doc',
          url: 'getCompanyDocuments',
          text: 'Select document',
        },
      ];

const hubs = (forPortal) =>
  forPortal
    ? [
        { value: 'work', icon: <TaskIcon />, title: 'Work.hub', options: workhubOptions(forPortal) },
        { value: 'form', icon: <FormIcon />, title: 'Form.hub', options: formhubOptions(forPortal) },
        { value: 'process', icon: <ProcessIcon />, title: 'Process.hub', options: processhubOptions(forPortal) },
        { value: 'doc', icon: <DocIcon />, title: 'Doc.hub', options: dochubOptions(forPortal) },
        { value: 'web', icon: <LanguageRounded style={{ color: grey[500] }} />, title: 'Web page', options: [] },
        { value: 'register', icon: <RegistersIcon />, title: 'Register.hub', options: registerhubOptions(forPortal) },
        { value: 'workspace', icon: <WorkspaceIcon />, title: 'Workspaces', options: workspaceOptions },
      ]
    : [
        { value: 'work', icon: <TaskIcon />, title: 'Work.hub', options: workhubOptions(forPortal) },
        { value: 'form', icon: <FormIcon />, title: 'Form.hub', options: formhubOptions(forPortal) },
        { value: 'process', icon: <ProcessIcon />, title: 'Process.hub', options: processhubOptions(forPortal) },
        { value: 'doc', icon: <DocIcon />, title: 'Doc.hub', options: dochubOptions },
        { value: 'kpi', icon: <KPIHubIcon />, title: 'KPI.hub', options: kpihubOptions },
        { value: 'asset', icon: <AssetIcon />, title: 'Asset.hub', options: assethubOptions },
        { value: 'audit', icon: <AuditIcon />, title: 'Audit.hub', options: audithubOptions },
        { value: 'register', icon: <RegistersIcon />, title: 'Register.hub', options: registerhubOptions(forPortal) },
        { value: 'web', icon: <LanguageRounded style={{ color: grey[500] }} />, title: 'Web page', options: [] },
        { value: 'view', icon: <ViewQuilt sx={{ color: grey[500] }} />, title: 'View', options: viewOptions },
      ];

export default function AddLinkDialog({ folderId, forDashboard, open, onClose, onResult, forPortal, allowBulk }) {
  const [saving, setSaving] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [currOptions, setCurrOptions] = useState([]);
  const [entries, setEntries] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedEntry, setSelectedEntry] = useState(null);
  const [result, setResult] = useState(null);
  const [bulkResult, setBulkResult] = useState([]);
  const [tab, setTab] = useState(0);
  const [url, setUrl] = useState('');
  const [urlTitle, setUrlTitle] = useState('');

  useEffect(() => {
    setSaving(false);
    setSelectedOption(null);
    setResult(null);
    setTab(0);
    setEntries([]);
    setBulkResult([]);
    setUrl('');
    setUrlTitle('');
  }, [open]);

  function handleOption(option) {
    if (option.link) {
      const body = { folderId, type: option.type, link: option.link, title: option.title };
      if (forDashboard) {
        onResult(body);
        onClose();
        return;
      }
      setSaving(true);
      axios
        .post('/workhub/workspace/createLinkItem', body)
        .then((res) => {
          setSaving(false);
          onResult(res.data);
          StateManager.setSuccessAlert('Item has been added');
          onClose();
        })
        .catch((err) => {
          StateManager.setAxiosErrorAlert(err);
          setSaving(false);
        });
    } else {
      setTab(2);
      setSelectedOption(option);
    }
  }

  function handleItemResult(item) {
    if (selectedOption.type === 'formEntry' && tab !== 3) {
      setTab(3);
      setLoading(true);
      axios
        .get('/forms/search/getSearchFormsEntries', { params: { formId: item._id } })
        .then((res) => {
          setEntries(res.data);
          setLoading(false);
        })
        .catch((err) => {
          StateManager.setErrorAlert('Failed to load form entries');
          setLoading(false);
        });
      return;
    }
    if (selectedOption.type === 'processEntry' && tab !== 3) {
      setTab(3);
      setLoading(true);
      axios
        .get('/process/search/getSearchProcessEntries', { params: { processId: item._id } })
        .then((res) => {
          setEntries(res.data);
          setLoading(false);
        })
        .catch((err) => {
          StateManager.setErrorAlert('Failed to load process entries');
          setLoading(false);
        });
      return;
    }

    const res = {
      folderId,
      type: selectedOption.type,
      id: item._id,
      title: item.title,
    };

    if (selectedOption.type === 'view') {
      res.gridClass = item.gridClass;
      res.access = item.access;
      res.userId = item.userId;
    }

    if (allowBulk && selectedOption.bulk) {
      if (bulkResult.find((x) => x.id === item.id)) return;
      setBulkResult([...bulkResult, res]);
    } else {
      setResult(res);
    }
  }

  function saveResult() {
    if (!result && bulkResult.length === 0) return;
    if (result) {
      if (result.type === 'web' && urlTitle) {
        result.title = urlTitle;
      }
      if (forDashboard) {
        onResult(result);
        onClose();
        return;
      }
      setSaving(true);
      axios
        .post(result.link ? '/workhub/workspace/createLinkItem' : '/workhub/workspace/createItem', result)
        .then((res) => {
          setSaving(false);
          onResult(res.data);
          StateManager.setSuccessAlert('Item has been added');
          onClose();
        })
        .catch((err) => {
          StateManager.setAxiosErrorAlert(err);
          setSaving(false);
        });
    }

    if (bulkResult.length > 0) {
      setSaving(true);
      axios
        .post('/workhub/workspace/createItemsBulk', { items: bulkResult })
        .then((res) => {
          setSaving(false);
          onResult(res.data);
          StateManager.setSuccessAlert('Item has been added');
          onClose();
        })
        .catch((err) => {
          StateManager.setAxiosErrorAlert(err);
          setSaving(false);
        });
    }
  }

  function handleFolderResult(item) {
    let res = {
      folderId,
      type: selectedOption.type,
      id: item.area?._id,
      title: item.area?.name,
    };
    if (item.folder) {
      res.type = 'docFolder';
      res.subId = item?.folder?._id;
      res.title = item?.folder?.name;
    }
    setResult(res);
  }

  function isValidHttpUrl(string) {
    if (!string || string.length < 4) return null;
    let parsed;

    try {
      parsed = new URL(string);
    } catch (_) {
      return null;
    }

    return parsed.hostname ? parsed : null;
  }

  function selectEntry(entry) {
    const res = {
      folderId,
      type: selectedOption.type,
      id: entry._id,
      title: `${entry.title} V${entry.version} - Entry #${entry.number}`,
      link: selectedOption.type === 'processEntry' ? `/processes/ongoing/${entry._id}` : `/forms/entry/${entry._id}`,
    };
    setSelectedEntry(entry._id);
    setResult(res);
  }

  return (
    <RoundedDialog open={open} maxWidth="sm" fullWidth onClose={onClose} className="scroll-bar">
      <DialogTitle>Add link</DialogTitle>

      <DialogContent>
        <Grid container>
          <SwipeableViews
            index={tab}
            onChangeIndex={setTab}
            style={{ width: '100%' }}
            slideStyle={{ height: '100%' }}
            containerStyle={{ height: '100%' }}
            disabled
          >
            <TabPanel value={tab} index={0}>
              <Grid container>
                {hubs(forPortal).map((hub) => (
                  <Grid container key={hub.value} lg={6} md={6} xs={12} item style={{ padding: 8 }}>
                    <CardActionArea
                      sx={{
                        borderRadius: 1,
                        width: '100%',
                        padding: 0,
                      }}
                      onClick={() => {
                        setCurrOptions(hub.options);
                        setTab(hub.value === 'web' ? 4 : hub.value === 'view' ? 2 : 1);
                        if (hub.value === 'view') {
                          setSelectedOption(hub.options[0]);
                        }
                      }}
                    >
                      <Paper
                        variant="outlined"
                        sx={{
                          borderRadius: 1,
                          py: 1,
                          px: 2,
                          border: `2px solid ${grey[300]}`,
                          width: '100%',
                        }}
                      >
                        <Grid container alignItems="center">
                          {hub.icon}
                          <Typography style={{ margin: '0px 16px' }}>{hub.title}</Typography>
                          <ArrowForwardIosRounded color="primary" style={{ marginLeft: 'auto' }} />
                        </Grid>
                      </Paper>
                    </CardActionArea>
                  </Grid>
                ))}
              </Grid>
            </TabPanel>

            <TabPanel value={tab} index={1}>
              <Grid container>
                <Grid container item>
                  <Button startIcon={<ArrowBackIosRounded />} color="primary" onClick={() => setTab(0)}>
                    back
                  </Button>
                </Grid>
                {currOptions.map((option, i) => (
                  <Grid container key={i} lg={6} md={6} xs={12} item style={{ padding: 8 }}>
                    <CardActionArea
                      sx={{
                        borderRadius: 1,
                        width: '100%',
                        padding: 0,
                      }}
                      onClick={() => handleOption(option)}
                    >
                      <Paper
                        variant="outlined"
                        sx={{
                          borderRadius: 1,
                          py: 1,
                          px: 2,
                          border: `2px solid ${grey[300]}`,
                          width: '100%',
                        }}
                      >
                        <Grid container alignItems="center" wrap="nowrap">
                          {option.icon}
                          <Typography noWrap style={{ margin: '0px 8px' }}>
                            {option.title}
                          </Typography>
                          <ArrowForwardIosRounded color="primary" style={{ marginLeft: 'auto' }} />
                        </Grid>
                      </Paper>
                    </CardActionArea>
                  </Grid>
                ))}
              </Grid>
            </TabPanel>

            <TabPanel value={tab} index={2}>
              <Grid container>
                <Grid container item>
                  <Button
                    startIcon={<ArrowBackIosRounded />}
                    color="primary"
                    onClick={() => {
                      if (selectedOption?.type === 'view') {
                        setTab(0);
                      } else {
                        setTab(1);
                      }
                      setSelectedOption(null);
                      setResult(null);
                      setBulkResult([]);
                    }}
                  >
                    back
                  </Button>
                </Grid>
                {selectedOption && (
                  <Grid container item alignContent="flex-start" style={{ paddingTop: 16, minHeight: '50vh' }}>
                    {selectedOption?.type !== 'area' && (
                      <SearchItem
                        url={selectedOption.url}
                        title={selectedOption.text}
                        onResult={handleItemResult}
                        lazy={selectedOption.lazy}
                        icon={selectedOption.icon}
                      />
                    )}
                    {selectedOption?.type === 'area' && (
                      <FolderPicker handleConfirm={handleFolderResult} title="" excludePersonal />
                    )}
                    {selectedOption.bulk && bulkResult.length > 0 && (
                      <Grid container>
                        <Typography variant="h6">Selected:</Typography>
                        {bulkResult.map((item, i) => (
                          <Grid container item key={i} alignItems="center">
                            {selectedOption.icon}
                            <Typography style={{ marginLeft: 16 }}>{item.title}</Typography>
                            <IconButton
                              style={{ marginLeft: 'auto' }}
                              onClick={() => setBulkResult(bulkResult.filter((x) => x.id !== item.id))}
                            >
                              <CloseRounded style={{ color: grey[500] }} />
                            </IconButton>
                          </Grid>
                        ))}
                      </Grid>
                    )}
                  </Grid>
                )}
              </Grid>
            </TabPanel>

            <TabPanel value={tab} index={3}>
              <Grid container>
                <Grid container item>
                  <Button
                    startIcon={<ArrowBackIosRounded />}
                    color="primary"
                    onClick={() => {
                      setTab(2);
                      setEntries([]);
                    }}
                  >
                    back
                  </Button>
                </Grid>
                {loading ? (
                  <Grid container style={{ height: 200 }}>
                    <CircularProgress color="primary" style={{ margin: 'auto' }} />
                  </Grid>
                ) : (
                  <TableContainer>
                    <Table stickyHeader>
                      <TableHead>
                        <TableRow>
                          <TableCell>#</TableCell>
                          <TableCell>Version</TableCell>
                          <TableCell>Submitted</TableCell>
                          <TableCell></TableCell>
                          <TableCell />
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {entries.map((entry) => (
                          <TableRow hover key={entry._id} onClick={() => selectEntry(entry)}>
                            <TableCell>{entry.number}</TableCell>
                            <TableCell>V{entry.version}</TableCell>
                            <TableCell>{entry.completedAt ? FormatDateShort(entry.completedAt) : '-'}</TableCell>
                            <TableCell style={{ padding: 5 }}>
                              <Button
                                onClick={(e) => {
                                  window.open(
                                    selectedOption.type === 'processEntry'
                                      ? `/processes/ongoing/${entry._id}`
                                      : `/forms/entry/${entry._id}`,
                                    '_blank',
                                  );
                                }}
                                startIcon={<VisibilityRounded />}
                                style={{ borderRadius: 8, textTransform: 'none', color: grey[600] }}
                              >
                                Preview
                              </Button>
                            </TableCell>
                            <TableCell>
                              {selectedEntry === entry._id ? (
                                <CheckCircleRounded style={{ color: green[500] }} />
                              ) : (
                                <CheckCircleOutlineRounded style={{ color: grey[300] }} />
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              </Grid>
            </TabPanel>

            <TabPanel value={tab} index={4}>
              <Grid container>
                <Grid container item>
                  <Button
                    startIcon={<ArrowBackIosRounded />}
                    color="primary"
                    onClick={() => {
                      setTab(0);
                      setSelectedOption(null);
                      setResult(null);
                    }}
                  >
                    back
                  </Button>
                </Grid>

                <Grid container item>
                  <TextField
                    variant="outlined"
                    fullWidth
                    value={url}
                    label="Type a URL"
                    style={{ marginTop: 16 }}
                    onChange={(e) => {
                      setUrl(e.target.value);
                      const parsed = isValidHttpUrl(e.target.value);
                      if (parsed) {
                        const res = {
                          folderId,
                          type: 'web',
                          id: v4(),
                          title: parsed.host,
                          link: e.target.value,
                        };
                        setResult(res);
                      }
                    }}
                  />
                  <TextField
                    variant="outlined"
                    fullWidth
                    value={urlTitle}
                    label="Title (optional)"
                    style={{ marginTop: 16 }}
                    onChange={(e) => {
                      setUrlTitle(e.target.value);
                    }}
                  />
                </Grid>
              </Grid>
            </TabPanel>
          </SwipeableViews>
        </Grid>
      </DialogContent>
      <StandardDialogActions
        saving={saving}
        onClose={onClose}
        onDone={saveResult}
        hideDone={!result && bulkResult.length === 0}
      />
    </RoundedDialog>
  );
}

function SearchItem({ url, title, lazy, icon, onResult }) {
  const { user } = useSelector(({ profile }) => profile);
  const userId = user?._id;
  const [value, setValue] = useState(null);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [text, setText] = useState('');
  const timer = useRef();

  function getDocs() {
    setLoading(true);
    axios
      .post('/dochub/documents/getCompanyDocuments')
      .then((res) => {
        const docs = res.data.Documents.docs.map((x) => ({ _id: x._id, title: x.title }));
        setOptions(docs);
        setLoading(false);
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
        setLoading(false);
      });
  }

  function getAreas() {
    setLoading(true);
    axios
      .post('/dochub/areas/getAreas')
      .then((res) => {
        const areas = res.data.areas.map((x) => ({ _id: x._id, title: x.name }));
        setOptions(areas);
        setLoading(false);
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
        setLoading(false);
      });
  }

  const formatViewGridClass = (gridClass) => {
    if (gridClass === 'controlledDocHub') {
      return '- Controlled Documents';
    }
    if (gridClass === 'bookmark') {
      return '- Bookmarked Documents';
    }
    if (gridClass === 'responsibleDocHub') {
      return '- Responsible Documents';
    }
    if (gridClass.startsWith('docArea-')) {
      return '- Area Documents';
    }
    if (gridClass.startsWith('docAreaNavigation-')) {
      return '- Folder Documents';
    }
    if (gridClass === 'my-tasks-working') {
      return '- My Working Tasks';
    }
    if (gridClass === 'assigned-tasks-working') {
      return '- Assigned Working Tasks';
    }
    if (gridClass.includes('process')) {
      if (gridClass === 'processes-working-entries') {
        return '- Working Process Entries';
      }
      if (gridClass === 'processes-completed-entries') {
        return '- Completed Process Entries';
      }
      if (gridClass === 'processes-held-entries') {
        return '- Held Process Entries';
      }
      if (gridClass === 'processes-cancelled-entries') {
        return '- Cancelled Process Entries';
      }
      if (
        gridClass.startsWith('completed-process-entries-') ||
        gridClass.startsWith('working-process-entries-') ||
        gridClass.startsWith('held-process-entries-') ||
        gridClass.startsWith('cancelled-process-entries-')
      ) {
        return '- Process Entries';
      }
    }
    if (gridClass.includes('form')) {
      if (gridClass === 'forms-working-entries') {
        return '- Working Form Entries';
      }
      if (gridClass === 'forms-completed-entries') {
        return '- Completed Form Entries';
      }
      if (gridClass.startsWith('completed-form-entries-') || gridClass.startsWith('working-form-entries-')) {
        return '- Form Entries';
      }
    }
    if (gridClass === 'assetTasks') {
      return '- Asset Tasks';
    }
    if (gridClass === 'assets') {
      return '- Assets';
    }
    return '';
  };

  useEffect(() => {
    setOptions([]);
    setValue(null);
    setText('');
    if (timer.current != null) {
      clearTimeout(timer.current);
    }
    if (url === 'getCompanyDocuments') {
      getDocs();
      return;
    }
    if (url === 'getAreas') {
      getAreas();
      return;
    }
    if (!lazy) {
      setLoading(true);
      axios
        .get(url)
        .then((res) => {
          if (url === viewOptions[0].url) {
            const formatted = res.data?.map((x) => ({
              ...x,
              title: `${x.title} ${formatViewGridClass(x.gridClass)}`,
            }));
            setOptions(formatted);
          } else {
            setOptions(res.data);
          }
          setLoading(false);
        })
        .catch((err) => {
          StateManager.setAxiosErrorAlert(err);
          setLoading(false);
        });
    }
  }, [url, lazy]);

  function search(text) {
    if (!text) {
      setLoading(false);
      return;
    }
    setLoading(true);
    axios
      .get(url, { params: { text } })
      .then((res) => {
        setOptions(res.data);
        setLoading(false);
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
        setLoading(false);
      });
  }

  function triggerTimer(text) {
    setText(text);
    if (!lazy) return;
    if (timer.current != null) {
      clearTimeout(timer.current);
    }
    timer.current = setTimeout(() => {
      search(text);
    }, 800);
  }

  return (
    <Grid container justifyContent="center" style={{ padding: 16 }}>
      <Autocomplete
        fullWidth
        options={options?.filter((x) => {
          if (url === viewOptions[0].url) {
            return x.access === 'public' || x.userId === userId;
          }
          return x;
        })}
        inputValue={text}
        value={value}
        onInputChange={(e) => {
          if (!e) return;
          triggerTimer(e.target.value);
        }}
        onChange={(e, x) => {
          setValue(x);
          setText(x?.title || '');
          onResult(x);
        }}
        getOptionLabel={(option) => option.title}
        noOptionsText={text ? (loading ? 'Loading...' : 'Nothing has been found') : 'Type to search'}
        renderOption={(props, option) => (
          <li {...props}>
            <Grid container alignItems="center" wrap="nowrap">
              {icon}
              <Typography style={{ marginLeft: '1rem' }} noWrap>
                {option.title}
              </Typography>
            </Grid>
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label={title}
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <React.Fragment>
                  {icon}
                  {params.InputProps.startAdornment}
                </React.Fragment>
              ),
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color="primary" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </Grid>
  );
}
