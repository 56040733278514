import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import MoreVert from '@mui/icons-material/MoreVert';
import CircularProgress from '@mui/material/CircularProgress';

import { TooltipTypography } from '../../Global/Components';
import FileIconAvatar from '../../Hubs/dochub/components/FileIconAvatar';
import { isString } from 'lodash';
import useDocumentName from '../../Hubs/dochub/hooks/useDocumentName';

import axios from 'axios';
import { CardActionArea } from '@mui/material';

const PREFIX = 'FileGridItem';

const classes = {
  root: `${PREFIX}-root`,
  image: `${PREFIX}-image`,
  container: `${PREFIX}-container`,
  title: `${PREFIX}-title`,
  subtitle: `${PREFIX}-subtitle`,
  top: `${PREFIX}-top`,
};

const StyledPaper = styled(Paper)(({ theme }) => ({
  [`&.${classes.root}`]: ({ selected }) => ({
    border: selected ? `1px solid ${theme.palette.primary.main}` : `1px solid #dfe1e5`,
    borderRadius: 3,
    boxShadow: 'none',
    cursor: 'pointer',
    opacity: 1,
    marginBottom: 20,
    marginRight: 20,
    display: 'inline-block',
    maxWidth: 210,
    '&:hover': {
      boxShadow: '0px 0px 10px rgba(0,0,0,0.16)',
    },
  }),

  [`& .${classes.image}`]: {
    height: 180,
    width: 208,
    objectFit: 'contain',
    borderTopLeftRadius: 3,
    borderTopRightRadius: 3,
  },

  [`& .${classes.container}`]: {
    position: 'relative',
    borderTop: '1px solid #e2e2e2',
    padding: '8px',
  },

  [`& .${classes.title}`]: {
    fontSize: '14px',
    fontWeight: 500,
    letterSpacing: '0.15px',
    lineHeight: '18px',
    marginLeft: '10px',
    verticalAlign: 'middle',
  },

  [`& .${classes.subtitle}`]: {},

  [`& .${classes.top}`]: {
    padding: '8px',
    borderBottom: '1px solid #e2e2e2',
  },
}));

const FileGridItem = ({
  selected,
  onSelect,
  image,
  fileKey,
  title,
  sequence,
  conventionSet,
  conventionSetParams,
  status,
  extension,
  handleMore,
}) => {
  const baseImageExtension = 'jpg';

  const [imageLoaded, setImageLoaded] = useState(false);
  const name = useDocumentName({
    sequence,
    title,
    initial: conventionSetParams,
    setId: conventionSet,
  });

  function getPreviewUrl(fileLocation) {
    if (!isString(fileLocation)) return null;
    // the lod logic
    if (fileLocation.startsWith('https://s3.eu-west-2.amazonaws.com/iqloud.app/')) {
      let modifiedKey = fileLocation.replace('https://s3.eu-west-2.amazonaws.com/iqloud.app/', '');
      if (modifiedKey.includes('hubs/')) {
        modifiedKey = modifiedKey.replace('hubs/', '');
      }
      if (modifiedKey.includes('stage/')) {
        modifiedKey = modifiedKey.replace('stage/', '');
      }
      if (modifiedKey.includes('dev/')) {
        modifiedKey = modifiedKey.replace('dev/', '');
      }
      let fileParts = modifiedKey.split('.');
      fileParts[fileParts.length - 1] = `${baseImageExtension}`;
      modifiedKey = fileParts.join('.');
      return `https://qhub-previews.s3.eu-west-2.amazonaws.com/${modifiedKey}`;
    } else {
      const parts = fileLocation.split('/');
      if (parts.length !== 5) return null;
      const companyIdMatch = parts[2].match(/[0-9a-f]{24}/);
      if (!companyIdMatch || !companyIdMatch[0]) return null;
      let name = `${companyIdMatch}/${parts[3]}/${parts[4]?.split('.')[0]}`;
      return `https://q-hub-previews.s3.eu-west-2.amazonaws.com/${name}.${baseImageExtension}`;
    }
  }

  function checkImage() {
    if (image) {
      return <img src={image} className={classes.image} alt={`grid-file-image-${new Date().toTimeString()}`} />;
    } else if (fileKey) {
      const url = getPreviewUrl(fileKey);
      if (!url)
        return <FileIconAvatar document={{ extension }} fontSize={60} extraStyles={{ width: 208, height: 185 }} />;
      let image = document.createElement('img');
      image.src = url;
      image.onload = () => {
        setImageLoaded(true);
      };
      image.onerror = () => {
        setImageLoaded(false);
      };
      if (imageLoaded) {
        return <img src={url} className={classes.image} alt={`grid-file-image-${new Date().toTimeString()}`} />;
      }
      return <FileIconAvatar document={{ extension }} fontSize={60} extraStyles={{ width: 208, height: 185 }} />;
    } else {
      return <FileIconAvatar document={{ extension }} fontSize={60} extraStyles={{ width: 208, height: 185 }} />;
    }
  }

  return (
    <StyledPaper variant="outlined" className={classes.root}>
      <Grid container direction="column" component={CardActionArea} onClick={onSelect}>
        <Grid container direction="row" alignItems={'center'} className={classes.top} wrap={'nowrap'}>
          <FileIconAvatar document={{ extension }} fontSize={20} extraStyles={{ width: 24, height: 24 }} />
          <TooltipTypography variant="h6" color="textPrimary" className={classes.title}>
            {name}
          </TooltipTypography>
        </Grid>
        {checkImage()}
      </Grid>

      {(status || handleMore) && (
        <Grid container className={classes.container} direction="column">
          <Grid item xs={12} container direction="row" alignItems="center" justifyContent="space-between">
            {status}
            <IconButton size="small" onClick={handleMore}>
              <MoreVert fontSize="small" />
            </IconButton>
          </Grid>
        </Grid>
      )}
    </StyledPaper>
  );
};

export default FileGridItem;
