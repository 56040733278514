import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Grid, Menu, MenuItem, ListItemText, CircularProgress, List, ListItem, ListItemIcon } from '@mui/material';
import { Typography, TextField, Checkbox, Button, DialogContent, DialogTitle, Avatar } from '@mui/material';
import { Table, TableHead, TableRow, TableCell, TableBody, useTheme } from '@mui/material';
import { IconButton, Tooltip } from '@mui/material';
import {
  EditRounded,
  SubjectRounded,
  FiberManualRecord,
  VisibilityRounded,
  AddRounded,
  CheckCircleOutlineRounded,
} from '@mui/icons-material';
import { CheckBoxOutlineBlankRounded, CheckBoxRounded, PersonRounded, ArrowDropDownRounded } from '@mui/icons-material';
import { DescriptionRounded, BackupRounded, AttachMoneyRounded, InfoRounded } from '@mui/icons-material';
import { CheckCircleRounded, FiberManualRecordRounded, NotInterested, CurrencyPoundRounded } from '@mui/icons-material';
import { NotificationsActiveRounded, PercentRounded, EuroRounded } from '@mui/icons-material';
import { grey, red, green, amber } from '@mui/material/colors';
import { TimePicker, DatePicker, RoundedDialog, StandardDialogActions, ItemBox, UserGroup } from '../Components';
import { TextDialog, DateTimePicker, SearchMenu, TooltipTypography } from '../Components';
import StateManager from '../StateManager';
import Uploader from '../Uploader';
import FileViewer from '../FileViewer';
import { FormatOnlyTime, FormatOnlyDate, validateEmail, validateUkNumber, isUuid, isHex24 } from '../Functions';
import { FormatDate, FormatDateFull } from '../Functions';
import { StatusButton } from '../../Hubs/tasks/Components';
import Autocomplete from '@mui/material/Autocomplete';
import StockDialog from './StockDialog';
import StockOptionsDialog from './StockOptionsDialog';
import { TaskIcon, FormIcon, ProcessIcon } from '../Icons';
import { useHistory } from 'react-router-dom';
import { isEqual, isNumber, isArray, isEmpty, cloneDeep, difference, isString } from 'lodash';
import moment from 'moment';
import axios from 'axios';
import ActionGroupButton from './ActionGroupButton';
import ReadAndSignDialog from './ReadAndSignDialog';
import TabbedSignature from './TabbedSignature';
import DataSetCell from './DataSetCell';
import SelectedOptionsDialog from './SelectedOptionsDialog';

const ISO_DATE_FORMAT = 'YYYY-MM-DD';

const GridCell = React.memo(
  ({
    column,
    initial,
    onChange,
    editable,
    users,
    building,
    withTimeout,
    optionsEditable,
    row,
    onDataChange,
    textAlign,
    activityInfo,
    allCreatedActions,
    onCreatedAction,
    onDeletedAction,
    disableClick,
    forRegister,
    textWrapping,
  }) => {
    const theme = useTheme();
    const timer = useRef(null);
    const [columnType, setColumnType] = useState(null);
    const [text, setText] = useState('');
    const [error, setError] = useState(false);
    const [number, setNumber] = useState(null);
    const [date, setDate] = useState(null);
    const [dropboxValue, setDropboxValue] = useState('');
    const [tickboxValues, setTickboxValues] = useState([]);
    const [userIds, setUserIds] = useState([]);
    const [menuAnchor, setMenuAnchor] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [files, setFiles] = useState([]);
    const [signature, setSignature] = useState({});
    const [dataSetValues, setDataSetValues] = useState([]);
    const [dataSetRows, setDataSetRows] = useState([]);
    const [statusValue, setStatusValue] = useState('');
    const [stockDialog, setStockDialog] = useState(false);
    const [stockOptions, setStockOptions] = useState(false);
    const [stockOptionsDialog, setStockOptionsDialog] = useState(false);
    const [calculationType, setCalculationType] = useState(null);
    const [rnsDialog, setRnsDialog] = useState(null);
    const invitationId = localStorage.getItem('invitationId');
    const currUserId = localStorage.getItem('_id');
    const columnWidth = column?.width || 250;
    const SAVING_TIMEOUT = column?.fieldType === 'number' ? 800 : 1600;
    const prev = useRef(null);

    function triggerChange(data, sendInstantly) {
      if (typeof onChange !== 'function') return;
      if (withTimeout && !sendInstantly) {
        if (timer.current) {
          clearTimeout(timer.current);
        }
        timer.current = setTimeout(() => {
          if (isEqual(data, prev.current)) return;
          if (column.fieldType === 'phone' && !validateUkNumber(data.value)) return;
          if (column.fieldType === 'email' && !validateEmail(data.value)) return;
          prev.current = cloneDeep(data);
          onChange(data);
        }, SAVING_TIMEOUT);
      } else {
        if (isEqual(data, prev.current)) return;
        prev.current = cloneDeep(data);
        onChange(data);
      }
    }

    const noValue = null;

    function toNum(value) {
      return isNaN(+value) ? 0 : +value;
    }

    function getObjectType(obj) {
      if (obj == null) return null;
      if (!isNaN(obj) && !isNaN(parseFloat(obj))) return 'number';
      const date = moment(obj, true);
      if (date.isValid()) {
        return 'date';
      }

      return 'time';
    }

    useEffect(() => {
      setColumnType(column.fieldType);

      const value = initial?.value;
      prev.current = cloneDeep(initial);
      if (!column.fieldType) return;
      if (['textArea', 'text', 'email', 'phone'].includes(column.fieldType)) {
        setText(isString(value) || isNumber(value) ? String(value) : '');
      }
      if (column.fieldType === 'number') {
        setNumber(isNumber(value) ? value : '');
      }
      if (column.fieldType === 'calculation') {
        const type = getObjectType(value);
        setCalculationType(type);
        if (type === 'date') {
          setDate(moment(value).format(ISO_DATE_FORMAT));
        } else if (type === 'time') {
          setText(value);
        } else {
          setNumber(value == null ? null : Number(value));
        }
      }
      if (column.fieldType === 'stock') {
        setNumber(value == null ? toNum(column.stockOptions?.initial || 0) : toNum(value));
        const options = column.stockOptions?.perRow ? initial?.options || column.stockOptions : column.stockOptions;
        setStockOptions(options);
      }
      if (column.fieldType === 'time') {
        setDate(
          value && moment(value, true).isValid()
            ? moment(value).set({ year: 2000, month: 0, date: 1, seconds: 0, milliseconds: 0 }).toISOString()
            : null,
        );
      }
      if (column.fieldType === 'date') {
        setDate(value && moment(value, true).isValid() ? moment(value).format(ISO_DATE_FORMAT) : null);
      }
      if (column.fieldType === 'datetime') {
        setDate(value && moment(value, true).isValid() ? moment(value) : null);
      }
      if (column.fieldType === 'dropbox' || column.fieldType === 'weightedList') {
        setDropboxValue(isUuid(value) ? value : '');
      }
      if (column.fieldType === 'tickbox') {
        setTickboxValues(Array.isArray(value) && value.every(isUuid) ? value : []);
      }
      if (column.fieldType === 'user') {
        setUserIds(Array.isArray(value) ? value : isHex24(value) ? [value] : []);
      }
      if (column.fieldType === 'signature') {
        setSignature(value?.id ? value : {});
      }
      if (column.fieldType === 'file' || column.fieldType === 'image') {
        setFiles(Array.isArray(value) ? value : []);
      }
      if (column.fieldType === 'dataSet') {
        if (isArray(initial?.rows)) {
          setDataSetRows(initial.rows);
          setDataSetValues(initial.rows.map((x) => x.id));
        }
        if (isArray(initial?.value?.rows)) {
          setDataSetRows(initial.value.rows);
          setDataSetValues(initial.value.rows.map((x) => x.id));
        }
      }
      if (column.fieldType === 'status') {
        setStatusValue(isUuid(value) ? value : '');
      }
    }, [column?.fieldType, column?.stockOptions, initial]);

    function selectUser() {
      StateManager.selectMultipleUsers(
        (res) => {
          if (res.users) {
            setUserIds(res.users);
          }
          triggerChange({ value: res.users }, true);
        },
        { initiallySelected: userIds },
      );
    }

    function onDataSetResult({ rows }) {
      const value = rows.map((x) => x.id);
      setDataSetValues(value);
      setDataSetRows(rows);
      triggerChange({ value, rows });
    }

    const formatTime = useCallback(() => FormatOnlyTime(date), [date]);
    const formatDate = useCallback(() => FormatOnlyDate(date), [date]);
    const formatDateTime = useCallback(() => FormatDateFull(date), [date]);

    if (!columnType) return null;

    const containerProps = { container: true, justifyContent: textAlign };

    if (columnType === 'text') {
      if (editable) {
        return (
          <Grid {...containerProps}>
            <TextField
              variant="standard"
              fullWidth
              value={text || ''}
              id={`textfield-${column.id}-${row?.id}`}
              onKeyDown={(e) => e.stopPropagation()} // critical
              onChange={(e) => {
                setText(e.target.value);
                triggerChange({ value: e.target.value });
              }}
              inputProps={{ maxLength: 1024, style: { textAlign } }}
            />
          </Grid>
        );
      }

      return <Grid {...containerProps}>{text != null ? <TooltipTypography>{text}</TooltipTypography> : noValue}</Grid>;
    }
    if (columnType === 'email') {
      if (editable) {
        return (
          <Grid {...containerProps}>
            <TextField
              variant="standard"
              placeholder="Email"
              onKeyDown={(e) => e.stopPropagation()} // critical
              fullWidth
              value={text || ''}
              type="email"
              id={`textfield-${column.id}-${row?.id}`}
              error={error && !!text}
              helperText={error && !!text ? 'Enter a correct email' : ''}
              onChange={(e) => {
                const email = String(e.target.value).toLowerCase();
                const valid = validateEmail(email);
                setError(!valid);
                setText(email);
                triggerChange({ value: email });
              }}
              inputProps={{ maxLength: 128, style: { textAlign } }}
            />
          </Grid>
        );
      }

      return <Grid {...containerProps}>{text != null ? <TooltipTypography>{text}</TooltipTypography> : noValue}</Grid>;
    }
    if (columnType === 'phone') {
      if (editable) {
        return (
          <Grid {...containerProps}>
            <TextField
              placeholder="Phone number"
              onKeyDown={(e) => e.stopPropagation()} // critical
              variant="standard"
              fullWidth
              value={text || ''}
              type="tel"
              id={`textfield-${column.id}-${row?.id}`}
              error={error && !!text}
              helperText={error && !!text ? 'Enter a correct phone number' : ''}
              onChange={(e) => {
                const value = String(e.target.value).replace(/[^\d+]/g, '');
                const valid = validateUkNumber(value);
                setError(!valid);
                setText(value);
                triggerChange({ value });
              }}
              inputProps={{ maxLength: 128, style: { textAlign } }}
            />
          </Grid>
        );
      }

      return <Grid {...containerProps}>{text != null ? <TooltipTypography>{text}</TooltipTypography> : noValue}</Grid>;
    }

    if (columnType === 'number') {
      if (editable) {
        return (
          <Grid {...containerProps}>
            <TextField
              fullWidth
              type="number"
              onKeyDown={(e) => e.stopPropagation()} // critical
              variant="standard"
              value={number == null ? '' : number}
              onChange={(e) => {
                const value = e.target.value === '' ? '' : Number(e.target.value);
                setNumber(value);
                triggerChange({ value });
              }}
              id={`textfield-${column.id}-${row?.id}`}
              onWheel={(e) => e.target.blur()}
              inputProps={{ maxLength: 64, style: { textAlign } }}
              InputProps={{
                startAdornment: column.numberOptions ? (
                  <>
                    {column.numberOptions.format === 'dollar' && <AttachMoneyRounded fontSize="small" />}
                    {column.numberOptions.format === 'pound' && <CurrencyPoundRounded fontSize="small" />}
                    {column.numberOptions.format === 'euro' && <EuroRounded fontSize="small" />}
                    {column.numberOptions.format === 'percent' && <PercentRounded fontSize="small" />}
                  </>
                ) : null,
              }}
            />
          </Grid>
        );
      }

      return (
        <Grid {...containerProps}>
          {number != null ? (
            <>
              {number != null && (
                <>
                  {column.numberOptions?.format === 'dollar' && <AttachMoneyRounded fontSize="small" />}
                  {column.numberOptions?.format === 'pound' && <CurrencyPoundRounded fontSize="small" />}
                  {column.numberOptions?.format === 'euro' && <EuroRounded fontSize="small" />}
                </>
              )}

              <Typography>{number}</Typography>
              {number != null && column.numberOptions?.format === 'percent' && <PercentRounded fontSize="small" />}
            </>
          ) : (
            noValue
          )}
        </Grid>
      );
    }

    if (columnType === 'time') {
      if (editable) {
        return (
          <Grid {...containerProps}>
            <TimePicker
              value={date}
              label={date ? '' : undefined}
              disablePadding
              id={`textfield-${column.id}-${row?.id}`}
              fullWidth
              onChange={(time) => {
                const value = moment(time)
                  .set({ year: 2000, month: 0, date: 1, seconds: 0, milliseconds: 0 })
                  .toISOString();
                setDate(value);
                triggerChange({ value }, true);
              }}
              inputProps={{ style: { textAlign } }}
            />
          </Grid>
        );
      }

      return (
        <Grid {...containerProps}>
          {date ? <Typography style={{ fontWeight: 500 }}>{formatTime(date)}</Typography> : noValue}
        </Grid>
      );
    }

    if (columnType === 'date') {
      if (editable) {
        return (
          <DatePicker
            value={date}
            label={date ? '' : undefined}
            disablePadding
            id={`textfield-${column.id}-${row?.id}`}
            fullWidth
            onChange={(date) => {
              const value = moment(date).format(ISO_DATE_FORMAT);
              setDate(value);
              triggerChange({ value }, true);
            }}
            inputProps={{ style: { textAlign } }}
          />
        );
      }

      return (
        <Grid {...containerProps}>
          {date ? <Typography style={{ fontWeight: 500 }}>{formatDate(date)}</Typography> : noValue}
        </Grid>
      );
    }

    if (columnType === 'datetime') {
      if (editable) {
        return (
          <Grid {...containerProps}>
            <DateTimePicker
              value={date}
              label={date ? '' : undefined}
              id={`textfield-${column.id}-${row?.id}`}
              disablePadding
              fullWidth
              onChange={(date) => {
                setDate(date);
                triggerChange({ value: date });
              }}
              inputProps={{ style: { textAlign } }}
            />
          </Grid>
        );
      }

      return (
        <Grid {...containerProps}>
          {date ? <Typography style={{ fontWeight: 500 }}>{formatDateTime(date)}</Typography> : noValue}
        </Grid>
      );
    }

    if (columnType === 'dropbox' || columnType === 'weightedList') {
      const selectedOption = isArray(column.options) ? column.options.find((x) => x.id === dropboxValue) : null;
      const textColor = selectedOption?.color ? theme.palette.getContrastText(selectedOption.color) : undefined;
      if (Array.isArray(column.options) && column.options.length > 0) {
        if (editable) {
          return (
            <Grid {...containerProps}>
              <Button
                style={{
                  textTransform: 'none',
                  justifyContent: textAlign || 'flex-start',
                  color: textColor,
                  background: selectedOption?.color,
                }}
                onClick={disableClick ? undefined : (e) => setMenuAnchor(e.currentTarget)}
                startIcon={<ArrowDropDownRounded style={{ color: textColor }} />}
                fullWidth
              >
                <Typography noWrap sx={{ fontWeight: 500, color: (theme) => theme.palette.text.secondary }}>
                  {dropboxValue ? selectedOption?.text : 'Pick option'}
                </Typography>
              </Button>

              <SearchMenu
                anchorEl={menuAnchor}
                open={Boolean(menuAnchor)}
                onClose={() => setMenuAnchor(null)}
                items={column.options}
                minWidth={columnWidth}
                map={(x) => x}
                onResult={(option) => {
                  if (option === null) {
                    setDropboxValue(null);
                    triggerChange({ value: null }, true);
                    return;
                  }
                  const params = {
                    value: option.id,
                    weight: columnType === 'weightedList' ? option.weight : undefined,
                  };
                  setDropboxValue(option.id);
                  triggerChange(params, true);
                }}
                showClear={dropboxValue}
                withColor
              />
            </Grid>
          );
        }

        return (
          <Grid {...containerProps}>
            {dropboxValue ? (
              <Button
                disabled
                style={{
                  textTransform: 'none',
                  justifyContent: textAlign || 'flex-start',
                  background: selectedOption?.color,
                }}
                fullWidth
              >
                <Typography
                  noWrap
                  sx={{
                    maxWidth: '100%',
                    fontWeight: 500,
                    color: selectedOption?.color
                      ? theme.palette.getContrastText(selectedOption?.color)
                      : (theme) => theme.palette.text.secondary,
                  }}
                >
                  {selectedOption?.text}
                </Typography>
              </Button>
            ) : (
              noValue
            )}
          </Grid>
        );
      } else {
        return <Typography color="textSecondary">Set options</Typography>;
      }
    }

    if (columnType === 'tickbox') {
      if (isArray(column.options) && column.options.length > 0) {
        if (editable) {
          return (
            <Grid {...containerProps}>
              <Button
                style={{ textTransform: 'none', justifyContent: textAlign || 'flex-start' }}
                onClick={disableClick ? undefined : (e) => setMenuAnchor(e.currentTarget)}
                startIcon={<ArrowDropDownRounded style={{ color: grey[500] }} />}
                fullWidth
              >
                <Typography noWrap sx={{ fontWeight: 500, color: (theme) => theme.palette.text.secondary }}>
                  {tickboxValues.length > 0
                    ? column.options
                        .filter((x) => tickboxValues.includes(x.id))
                        .map((x) => x.text)
                        .join(', ')
                    : `Pick option${column.selectType === 'multiple' ? 's' : ''}`}
                </Typography>
              </Button>

              <Menu
                PaperProps={{ style: { minWidth: columnWidth } }}
                anchorEl={menuAnchor}
                open={Boolean(menuAnchor)}
                onClose={() => setMenuAnchor(null)}
                style={{ minWidth: columnWidth }}
              >
                {column.options.map((value) => (
                  <MenuItem
                    key={value.id}
                    onClick={() => {
                      if (tickboxValues.includes(value.id)) {
                        if (column.selectType === 'multiple') {
                          const newValue = tickboxValues.filter((x) => x !== value.id).sort();
                          setTickboxValues(newValue);
                          triggerChange({ value: newValue });
                        } else {
                          setTickboxValues([]);
                          triggerChange({ value: [] });
                        }
                      } else {
                        if (column.selectType === 'multiple') {
                          const newValue = [...tickboxValues, value.id].sort();
                          setTickboxValues(newValue);
                          triggerChange({ value: newValue });
                        } else {
                          setTickboxValues([value.id]);
                          triggerChange({ value: [value.id] });
                        }
                      }
                    }}
                  >
                    <Checkbox
                      icon={<CheckBoxOutlineBlankRounded fontSize="small" />}
                      checkedIcon={<CheckBoxRounded fontSize="small" />}
                      color="primary"
                      checked={tickboxValues.includes(value.id)}
                    />
                    <ListItemText primary={value.text} />
                  </MenuItem>
                ))}
                {!isEmpty(tickboxValues) && (
                  <MenuItem
                    onClick={() => {
                      setTickboxValues([]);
                      triggerChange({ value: [] }, true);
                      setMenuAnchor(null);
                    }}
                  >
                    <ListItemIcon>
                      <NotInterested color="error" />
                    </ListItemIcon>
                    Clear
                  </MenuItem>
                )}
              </Menu>
            </Grid>
          );
        }

        if (tickboxValues.length === 0) return noValue;

        const options = column.options.filter((x) => tickboxValues.includes(x.id)).map((x) => x.text);

        if (options.length === 1)
          return (
            <Grid {...containerProps}>
              <Typography noWrap style={{ maxWidth: columnWidth, fontWeight: 500 }}>
                {options[0]}
              </Typography>
            </Grid>
          );

        return (
          <>
            <Button
              style={{ textTransform: 'none', justifyContent: textAlign || 'flex-start' }}
              onClick={() => setDialogOpen(true)}
              startIcon={<VisibilityRounded style={{ color: grey[500] }} />}
              fullWidth
            >
              <Typography noWrap style={{ maxWidth: '100%' }}>
                {options.join(', ')}
              </Typography>
            </Button>

            <SelectedOptionsDialog open={dialogOpen} onClose={() => setDialogOpen(false)} options={options} />
          </>
        );
      } else {
        return <Typography color="textSecondary">Set options</Typography>;
      }
    }

    if (columnType === 'user') {
      if (invitationId && !currUserId) {
        return (
          <ExternalUserCell
            selected={userIds}
            editable={editable}
            users={users}
            onResult={(value) => {
              setUserIds(value);
              triggerChange({ value }, true);
            }}
          />
        );
      }
      if (userIds?.length > 0) {
        return (
          <Grid {...containerProps}>
            <UserGroup max={8} ids={userIds} fullWidth onClick={editable ? selectUser : undefined} avatarSize={30} />
          </Grid>
        );
      } else {
        return editable ? (
          <Button
            style={{ textTransform: 'none', justifyContent: textAlign || 'flex-start' }}
            onClick={selectUser}
            startIcon={<PersonRounded />}
            sx={{ color: (theme) => theme.palette.text.secondary }}
            fullWidth
          >
            Pick users
          </Button>
        ) : (
          noValue
        );
      }
    }

    if (columnType === 'textArea') {
      if (!editable && !text) return noValue;
      return (
        <>
          <Button
            style={{ textTransform: 'none', minWidth: 150, justifyContent: textAlign || 'flex-start' }}
            onClick={() => setDialogOpen(true)}
            startIcon={<SubjectRounded sx={{ color: (theme) => theme.palette.text.secondary }} />}
            fullWidth
          >
            <Typography
              noWrap={!textWrapping}
              sx={{ maxWidth: '100%', color: (theme) => theme.palette.text.secondary }}
            >
              {text || 'Add text'}
            </Typography>
          </Button>

          <TextDialog
            open={dialogOpen}
            initial={text}
            onClose={() => setDialogOpen(false)}
            editable={editable}
            onResult={(text) => {
              setText(text);
              triggerChange({ value: text }, true);
            }}
          />
        </>
      );
    }

    if (columnType === 'file' || columnType === 'image') {
      if (!editable && files.length === 0) return noValue;
      const imageWidth = 66;
      const limit = Math.floor((columnWidth - 50) / imageWidth);
      const images = files.filter((x) => x.type?.startsWith('image/')).slice(0, limit); // first n pictures
      const remaining = files.length - images.length;
      return (
        <>
          <Button
            style={{ textTransform: 'none', justifyContent: textAlign || 'flex-start' }}
            onClick={disableClick ? undefined : () => setDialogOpen(true)}
            startIcon={images.length > 0 ? undefined : <DescriptionRounded style={{ color: grey[500] }} />}
            fullWidth
          >
            <Grid container alignItems="center" justifyContent={textAlign}>
              {images.length > 0 ? (
                <>
                  {images.map((image) => (
                    <img
                      key={image.id}
                      style={{
                        maxWidth: imageWidth,
                        maxHeight: 30,
                        userSelect: 'none',
                        pointerEvents: 'none',
                        marginRight: 8,
                      }}
                      src={image.location}
                      alt={image.name}
                    />
                  ))}
                  {remaining > 0 && (
                    <Typography color="textSecondary" variant="h5">
                      +{remaining}
                    </Typography>
                  )}
                </>
              ) : (
                <Typography noWrap style={{ maxWidth: '100%' }}>
                  {files.length > 0 ? `${files.length} file${files.length > 1 ? 's' : ''}` : 'Add files'}
                </Typography>
              )}
            </Grid>
          </Button>

          <FileDialog
            open={dialogOpen}
            initial={files}
            editable={editable}
            onClose={() => setDialogOpen(false)}
            onResult={(files) => {
              setFiles(files);
              triggerChange({ value: files }, true);
            }}
            onlyImages={columnType === 'image'}
          />
        </>
      );
    }

    if (columnType === 'signature') {
      const hasSignature = Boolean(signature?.base64Data || signature?.location);
      if (!editable && !hasSignature) return noValue;
      return (
        <>
          <Button
            style={{ textTransform: 'none', justifyContent: textAlign || 'flex-start' }}
            onClick={disableClick ? undefined : () => setDialogOpen(true)}
            startIcon={
              editable ? (
                <EditRounded style={{ color: grey[500] }} />
              ) : (
                <VisibilityRounded style={{ color: grey[500] }} />
              )
            }
          >
            {!hasSignature && <Typography>Add signature</Typography>}
            {hasSignature && (
              <img
                style={{
                  width: 60,
                  height: 30,
                  userSelect: 'none',
                  pointerEvents: 'none',
                  filter:
                    theme.palette.mode === 'dark' && (!signature.type || signature.type === 'signature')
                      ? 'invert(100%)'
                      : '',
                }}
                src={signature?.base64Data || signature?.location}
                alt="signature"
              />
            )}
          </Button>

          <SignatureDialog
            open={dialogOpen}
            initial={signature}
            editable={editable}
            onClose={() => setDialogOpen(false)}
            onResult={(signature) => {
              setSignature(signature);
              triggerChange({ value: signature }, true);
            }}
            onlyImages={columnType === 'image'}
          />
        </>
      );
    }

    if (columnType === 'status') {
      if (building && !forRegister) {
        return (
          <Grid {...containerProps}>
            <Typography gutterBottom color="textSecondary" style={{ fontWeight: 500 }}>
              To be set
            </Typography>
          </Grid>
        );
      }

      const selected = statusValue ? column.options?.find((x) => x.id === statusValue) : null;
      const textColor = selected?.color ? theme.palette.getContrastText(selected.color) : undefined;

      if (editable && !column.readOnly) {
        if (Array.isArray(column.options) && column.options.length > 0) {
          return (
            <>
              <Button
                style={{
                  textTransform: 'none',
                  background: selected ? selected.color : undefined,
                }}
                onClick={disableClick ? undefined : (e) => setMenuAnchor(e.currentTarget)}
                endIcon={
                  <ArrowDropDownRounded sx={{ color: selected ? textColor : (theme) => theme.palette.primary.main }} />
                }
                fullWidth
                variant={selected ? 'text' : 'outlined'}
              >
                <Typography
                  noWrap
                  sx={{ fontWeight: 500, color: selected ? textColor : (theme) => theme.palette.primary.main }}
                >
                  {selected ? selected.text : 'Select status'}
                </Typography>
              </Button>

              <Menu
                PaperProps={{ style: { minWidth: columnWidth } }}
                anchorEl={menuAnchor}
                open={Boolean(menuAnchor)}
                onClose={() => setMenuAnchor(null)}
              >
                {Array.isArray(column.options) &&
                  column.options.map((value) => (
                    <MenuItem
                      key={value.id}
                      onClick={() => {
                        setStatusValue(value.id);
                        triggerChange({ value: value.id }, true);
                        setMenuAnchor(null);
                      }}
                    >
                      <ListItemIcon>
                        <FiberManualRecord style={{ color: value.color }} />
                      </ListItemIcon>
                      {value.text}
                    </MenuItem>
                  ))}
                {statusValue && (
                  <MenuItem
                    onClick={() => {
                      setStatusValue(null);
                      triggerChange({ value: null }, true);
                      setMenuAnchor(null);
                    }}
                  >
                    <ListItemIcon>
                      <NotInterested color="error" />
                    </ListItemIcon>
                    Clear
                  </MenuItem>
                )}
              </Menu>
            </>
          );
        }
        return <Typography color="textSecondary">Define statuses</Typography>;
      } else {
        if (!selected) return null;
        return (
          <Button style={{ textTransform: 'none', background: selected.color }} disabled fullWidth>
            <Typography noWrap style={{ fontWeight: 500, color: textColor }}>
              {selected ? selected.text : 'No status'}
            </Typography>
          </Button>
        );
      }
    }

    if (columnType === 'stock') {
      function getStockTooltip() {
        if (stockOptions?.min == null) return '';
        return (
          <Typography>
            Min: {stockOptions.min}, Max: {stockOptions.max}
          </Typography>
        );
      }

      const background =
        number != null && stockOptions && (stockOptions.min > number || stockOptions.max < number)
          ? red[500]
          : undefined;
      return (
        <Grid container alignItems="center" justifyContent="center" wrap="nowrap">
          <Button
            style={{ textTransform: 'none', background, minWidth: 150 }}
            onClick={disableClick ? undefined : () => setStockDialog(true)}
            startIcon={editable ? <ArrowDropDownRounded /> : undefined}
            disabled={!editable}
            fullWidth
          >
            <Typography style={{ fontWeight: 500 }} color="textPrimary">
              {number}
            </Typography>
          </Button>

          {column.stockOptions?.perRow && (
            <>
              {optionsEditable && editable ? (
                <>
                  <IconButton
                    style={{ width: 36, height: 36, marginLeft: 4 }}
                    onClick={() => setStockOptionsDialog(true)}
                  >
                    <EditRounded fontSize="small" style={{ color: grey[500] }} />
                  </IconButton>

                  <StockOptionsDialog
                    onlyMinMax
                    open={stockOptionsDialog}
                    onClose={() => setStockOptionsDialog(false)}
                    initialOptions={stockOptions}
                    onResult={(options) => {
                      setStockOptions(options);
                      triggerChange({ value: number, options, optionsUpdate: true });
                    }}
                  />
                </>
              ) : (
                <Tooltip title={getStockTooltip()} placement="top">
                  <InfoRounded style={{ color: grey[400], marginLeft: 4 }} />
                </Tooltip>
              )}
            </>
          )}

          {editable && (
            <StockDialog
              open={stockDialog}
              initial={number}
              onResult={(res) => {
                if (res.value === 0) return;
                const value = number + res.value * (res.action === 'add' ? 1 : -1);
                setNumber(value);
                triggerChange({ value, action: res.action, delta: res.value, options: stockOptions });
              }}
              onClose={() => setStockDialog(false)}
            />
          )}
        </Grid>
      );
    }

    if (columnType === 'dataSet') {
      return (
        <DataSetCell
          params={column.dataSetParams}
          onResult={onDataSetResult}
          disableClick={disableClick}
          value={{ rows: dataSetRows }}
          editable={editable}
          textAlign={textAlign}
        />
      );
    }

    if (columnType === 'calculation') {
      if (['date'].includes(calculationType)) {
        return (
          <Grid {...containerProps}>
            <Typography style={{ fontWeight: 500 }}>{formatDate(date)}</Typography>
          </Grid>
        );
      } else if (['time'].includes(calculationType)) {
        return (
          <Grid {...containerProps}>
            <Typography style={{ fontWeight: 500 }}>{text}</Typography>
          </Grid>
        );
      } else {
        if (!column?.expressionParams) return <Typography style={{ marginLeft: '0.5rem' }}>-</Typography>;

        function round(num, decimal) {
          return +Number(num).toFixed(decimal);
        }
        const decimal = column.expressionParams.format === 'number' ? column.expressionParams.decimals || 12 : 2;
        const hasValue = number != null;
        return (
          <Grid {...containerProps} alignItems="center" wrap="nowrap">
            {column.expressionParams.format === 'dollar' && hasValue && (
              <AttachMoneyRounded style={{ color: grey[600] }} />
            )}
            {column.expressionParams.format === 'pound' && hasValue && (
              <CurrencyPoundRounded style={{ color: grey[600] }} />
            )}
            {column.expressionParams.format === 'euro' && hasValue && <EuroRounded style={{ color: grey[600] }} />}
            <Typography nowrap style={{ marginLeft: '0.5rem' }}>
              {hasValue ? round(number, decimal) : '-'}
            </Typography>
            {column.expressionParams.format === 'percent' && hasValue && (
              <PercentRounded fontSize="small" style={{ color: grey[600] }} />
            )}
          </Grid>
        );
      }
    }

    if (columnType === 'action') {
      return (
        <CellActionButton
          initial={initial?.value}
          params={column.actionParams}
          onChange={(value) => triggerChange({ value }, true)}
          editable={editable}
          rowId={row?.id}
          onDataChange={onDataChange}
          columnId={column.id}
          building={building}
        />
      );
    }

    if (columnType === 'actionGroup') {
      return (
        <ActionGroupButton
          activityInfo={activityInfo}
          rowId={row?.id}
          columnId={column.id}
          allCreatedActions={allCreatedActions}
          editable={editable}
          building={building}
          preSetActions={column.preSetActions}
          onResult={onCreatedAction}
          onDeletedAction={onDeletedAction}
          disableClick={disableClick}
        />
      );
    }

    if (columnType === 'readAndSign') {
      const useSelectedDocuments = column?.fieldData?.useSpaces || column?.fieldData?.useSubFolders;

      const filesToRead = useSelectedDocuments
        ? initial?.value?.selectedDocuments || []
        : column?.readAndSignOptions?.type === 'docs' && isArray(column.readAndSignOptions.files)
        ? column?.readAndSignOptions.files
        : column?.readAndSignOptions?.type === 'folder' && isArray(column?.fieldData?.files)
        ? column?.fieldData.files
        : [];

      const readDocuments = isArray(initial?.value?.readDocuments) ? initial?.value?.readDocuments : [];

      const notRead = difference(
        filesToRead.map((x) => x.id),
        readDocuments,
      );

      const totalToRead = filesToRead.length;

      const read = totalToRead - notRead.length;

      const allRead = isEmpty(notRead);

      return (
        <Grid container alignItems="center">
          <Button
            onClick={() => setRnsDialog(true)}
            endIcon={totalToRead > 0 && !building && <CheckCircleOutlineRounded />}
            sx={{
              width: '100%',
              height: '100%',
              borderRadius: 0,
              fontSize: '1.1rem',
              color: totalToRead > 0 && allRead ? green[500] : grey[500],
            }}
            disabled={building}
          >
            {building && read === 0 ? 'To be read' : totalToRead > 0 ? `${read}/${totalToRead} read` : 'Pick files'}
          </Button>

          <ReadAndSignDialog
            initial={initial?.value}
            column={column}
            open={rnsDialog}
            onClose={() => setRnsDialog(false)}
            onResult={(value) => triggerChange({ value }, true)}
            editable={editable}
          />
        </Grid>
      );
    }

    return <Typography color="textSecondary">Type not supported yet</Typography>;
  },
);

export function FileDialog({ open, initial, editable, onClose, onResult, onlyImages, maxFiles }) {
  const [files, setFiles] = useState([]);
  const [dialogOpen, setDialogOpen] = useState([]);

  useEffect(() => {
    if (!open) return;
    const uploaded = Array.isArray(initial) ? initial : [];
    setFiles([...uploaded]);
    setDialogOpen(Boolean(uploaded.length === 0 && editable));
  }, [open]); // eslint-disable-line

  function done() {
    onResult(files);
    onClose();
  }

  function close() {
    if (!isEqual(initial, files)) {
      onResult(files);
    }
    onClose();
  }

  return (
    <RoundedDialog open={open} onClose={close} maxWidth="sm" fullWidth>
      <DialogTitle>Files</DialogTitle>
      <DialogContent>
        <FileViewer
          files={files}
          title=""
          onDelete={editable ? (id) => setFiles(files.filter((x) => x.id !== id)) : undefined}
        />
        {editable && files.length === 0 && (
          <Typography variant="h5" gutterBottom color="textSecondary">
            Click the bellow button to upload files
          </Typography>
        )}
        {editable && (
          <Grid container style={{ minHeight: '20vh' }} alignContent="flex-start">
            <Uploader
              clearOnDone
              onlyImages={onlyImages}
              uploaded={files}
              buttonText="Upload files"
              onChange={setFiles}
              dialogOpen={dialogOpen}
              onDialogClose={() => setDialogOpen(false)}
              maxFiles={maxFiles}
            />
            {(!isNumber(maxFiles) || maxFiles > files.length) && (
              <Grid container>
                <Button
                  variant="contained"
                  style={{ textTransform: 'none' }}
                  endIcon={<BackupRounded />}
                  onClick={() => setDialogOpen(true)}
                >
                  Upload
                </Button>
              </Grid>
            )}
          </Grid>
        )}
      </DialogContent>
      <StandardDialogActions onClose={close} onDone={done} hideDone={!editable} />
    </RoundedDialog>
  );
}

export function SignatureDialog({ open, initial, editable, onClose, onResult }) {
  const [signature, setSignature] = useState({});

  useEffect(() => {
    setSignature(initial || {});
  }, [initial]);

  function done() {
    onResult(signature);
    onClose();
  }

  return (
    <RoundedDialog open={open} onClose={onClose} maxWidth={initial.type === 'text' ? 'md' : 'sm'} fullWidth>
      <DialogTitle>Signature</DialogTitle>
      <DialogContent>
        {open && (
          <Grid container justifyContent="center">
            <TabbedSignature initial={signature} onResult={setSignature} editable={editable} />
          </Grid>
        )}
      </DialogContent>
      <StandardDialogActions onClose={onClose} onDone={done} hideDone={!editable} />
    </RoundedDialog>
  );
}

function ExternalUserCell({ onResult, selected, editable, style, users }) {
  const [value, setValue] = useState(null);

  useEffect(() => {
    if (!selected || users.length === 0) return;
    const id = Array.isArray(selected) ? selected[0] : selected;
    setValue(users.find((x) => x._id === id) || null);
  }, [selected, users]);

  if (users.length === 0) return null;

  return (
    <Grid container alignItems="center">
      {editable ? (
        <Autocomplete
          options={users}
          value={value}
          style={{ width: 220, ...style }}
          getOptionLabel={(option) => option?.fullName || ''}
          openOnFocus
          renderOption={(props, user) => (
            <li {...props}>
              <Grid container alignItems="center">
                <Avatar
                  src={user.avatar}
                  style={{ background: user.avatarColor, marginRight: 5, width: 25, height: 25 }}
                >
                  {user.avatarLetters}
                </Avatar>
                <Typography style={{ fontSize: '0.75rem' }}>{user.fullName}</Typography>
              </Grid>
            </li>
          )}
          renderInput={(params) => (
            <TextField onKeyDown={(e) => e.stopPropagation()} variant="standard" {...params} label="Select a user" />
          )}
          onChange={(e, x) => {
            setValue(x);
            onResult(x?._id);
          }}
        />
      ) : (
        <>
          <Avatar src={value?.avatar} style={{ background: value?.avatarColor, marginRight: 8 }}>
            {value?.avatarLetters}
          </Avatar>
          <Typography>{value?.fullName}</Typography>
        </>
      )}
    </Grid>
  );
}

function CellActionButton({ params, initial, onChange, editable, rowId, columnId, onDataChange, building }) {
  const [starting, setStarting] = useState(false);
  const [itemsDialog, setItemsDialog] = useState(false);
  const [notApplicable, setNotApplicale] = useState(false);
  const [naDialog, setNaDialog] = useState(false);
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (!params) return;
    setNotApplicale(initial?.notApplicable);

    if (params.action === 'task' && isArray(initial?.resolvedTasks)) {
      setItems(initial.resolvedTasks);
    }
    if (params.action === 'form' && isArray(initial?.resolvedForms)) {
      setItems(initial.resolvedForms);
    }
    if (params.action === 'process' && isArray(initial?.resolvedProcesses)) {
      setItems(initial.resolvedProcesses);
    }
  }, [initial, params]);

  function checkOptional() {
    if (params.actionOptional) {
      setNaDialog(true);
      return;
    } else {
      startAction();
    }
  }

  function saveItems(items) {
    const field = params.action === 'task' ? 'tasks' : params.action === 'form' ? 'forms' : 'processes';
    onChange({ [field]: items });
    if (params.action === 'task') {
      setLoading(true);
      axios
        .post('/tasks/getTasksByIds', { ids: items })
        .then((res) => {
          setLoading(false);
          setItems(res.data);
          onDataChange({ resolvedTasks: res.data });
        })
        .catch((err) => {
          StateManager.setAxiosErrorAlert(err);
          setLoading(false);
        });
    }

    if (params.action === 'form') {
      setLoading(true);
      axios
        .post('/forms/entries/getEntriesByIds', { ids: items })
        .then((res) => {
          setLoading(false);
          setItems(res.data);
          onDataChange({ resolvedForms: res.data });
        })
        .catch((err) => {
          setLoading(false);
          StateManager.setAxiosErrorAlert(err);
        });
    }

    if (params.action === 'processes') {
      setLoading(true);
      axios
        .post('/process/getOngoingProcessesByIds', { ids: items })
        .then((res) => {
          setLoading(false);
          setItems(res.data);
          onDataChange({ resolvedProcesses: res.data });
        })
        .catch((err) => {
          setLoading(false);
          StateManager.setAxiosErrorAlert(err);
        });
    }
  }

  function startAction() {
    setNotApplicale(false);
    if (params.action === 'task') createTask();
    if (params.action === 'form') startEntry();
    if (params.action === 'process') startProcess();
  }

  function makeNotApplicable() {
    setNotApplicale(true);
    onChange({ ...initial, notApplicable: true });
  }

  function createTask() {
    if (starting) return;
    setStarting(true);
    axios
      .post('/tasks/createTask', { ...params.task, rowId })
      .then((res) => {
        setStarting(false);
        StateManager.setSuccessAlert('Task has been created');
        StateManager.selectTask(res.data._id);
        items.push(res.data._id);
        setItems([...items]);
        saveItems(items);
      })
      .catch((err) => {
        setStarting(false);
        StateManager.setAxiosErrorAlert(err);
      });
  }

  function startEntry() {
    if (starting) return;
    setStarting(true);
    axios
      .post('/forms/entries/sendEntry', { formId: params.form._id, rowId })
      .then((res) => {
        StateManager.setSuccessAlert('Entry has been started');
        if (res.data.length === 1) {
          items.push(res.data[0]._id);
          setItems([...items]);
          saveItems(items);
          setTimeout(() => history.push(`/forms/entry/${res.data[0]._id}`), 1000);
        }
      })
      .catch(() => {
        StateManager.setErrorAlert('Failed to start an entry');
        setStarting(false);
      });
  }

  function startProcess() {
    if (starting) return;
    setStarting(true);
    axios
      .post('/process/startProcess', { processId: params.process._id, rowId })
      .then((res) => {
        StateManager.setSuccessAlert('Process has been started');
        items.push(res.data.ongoingProcess._id);
        setItems([...items]);
        saveItems(items);
        setTimeout(() => history.push(`/processes/ongoing/${res.data.ongoingProcess._id}?started=true`), 1000);
      })
      .catch(() => {
        StateManager.setErrorAlert('Failed to start the process');
        setStarting(false);
      });
  }

  const actionColor = items.every((x) => x.completedAt || x.completedDate) ? green[500] : amber[500];

  if (building) {
    return <Typography color={'textSecondary'}>Actions</Typography>;
  }

  if (items.length > 0) {
    return (
      <>
        <Button
          startIcon={
            params.action === 'task' ? <TaskIcon /> : params.action === 'form' ? <FormIcon /> : <ProcessIcon />
          }
          onClick={() => setItemsDialog(true)}
          style={{ width: '100%' }}
          endIcon={
            loading ? (
              <CircularProgress color="primary" size={16} />
            ) : (
              <FiberManualRecordRounded style={{ color: actionColor }} />
            )
          }
        >
          {items.length}{' '}
          {params.action === 'task' ? `task${items.length === 1 ? '' : 's'}` : items.length === 1 ? 'entry' : 'entries'}
        </Button>
        <ActionDialog
          open={itemsDialog}
          onClose={() => setItemsDialog(false)}
          params={params}
          initial={items}
          onChange={saveItems}
          editable={editable}
          rowId={rowId}
          columnId={columnId}
          onItemsChange={(items) => {
            setItems(items);
            const field =
              params.action === 'task'
                ? 'resolvedTasks'
                : params.action === 'form'
                ? 'resolvedForms'
                : 'resolvedProcesses';
            onDataChange({ [field]: items });
          }}
        />
      </>
    );
  } else {
    let button = null;

    if (editable && params) {
      if (notApplicable) {
        button = (
          <Button fullWidth onClick={() => setNaDialog(true)}>
            N/A
          </Button>
        );
      } else if (params.action === 'task') {
        button = (
          <Button
            style={{ width: '100%' }}
            startIcon={starting ? <CircularProgress color="primary" size={24} /> : <TaskIcon />}
            onClick={checkOptional}
          >
            create a task
          </Button>
        );
      } else if (params.action === 'form') {
        button = (
          <Button
            style={{ width: '100%' }}
            startIcon={starting ? <CircularProgress color="primary" size={24} /> : <FormIcon />}
            onClick={checkOptional}
          >
            start an entry
          </Button>
        );
      } else if (params.action === 'process') {
        button = (
          <Button
            style={{ width: '100%' }}
            startIcon={starting ? <CircularProgress color="primary" size={24} /> : <ProcessIcon />}
            onClick={checkOptional}
          >
            start a process
          </Button>
        );
      }

      return (
        <>
          {button}
          <NaDialog
            params={params}
            open={naDialog}
            onClose={() => setNaDialog(false)}
            onResult={(start) => (start ? startAction() : makeNotApplicable())}
          />
        </>
      );
    } else {
      return null;
    }
  }
}

function ActionDialog({ open, onClose, params, initial, onChange, editable, columnId, rowId, onItemsChange }) {
  const [items, setItems] = useState([]);
  const [sendingReminder, setSendingReminder] = useState(null);
  const processedItems = items.map((item) => ({ ...item, overdue: !!item.dueAt && moment(item.dueAt) < moment() }));
  const [starting, setStarting] = useState(false);
  const history = useHistory();
  const type = params?.action;

  useEffect(() => {
    setItems(Array.isArray(initial) ? initial : []);
  }, [initial, params, open]);

  function saveTask(task) {
    if (!task?._id) return;
    const index = items.findIndex((x) => x._id === task._id);
    if (index === -1) return;
    items[index] = task;
    setItems([...items]);
    onItemsChange(items);
  }

  function onItemClick(item) {
    if (params?.action === 'task') {
      StateManager.selectTask(item._id, (t) => saveTask(t));
    }
    if (params?.action === 'form') {
      history.push(`/forms/entry/${item._id}`);
    }
    if (params?.action === 'process') {
      history.push(`/processes/ongoing/${item._id}`);
    }
  }

  function startEntry() {
    setStarting(true);
    axios
      .post('/forms/entries/sendEntry', { formId: params.form._id, rowId })
      .then((res) => {
        StateManager.setSuccessAlert('Entry has been started');
        if (res.data.length === 1) {
          onChange([...items.map((x) => x._id), res.data[0]._id]);
          setTimeout(() => history.push(`/forms/entry/${res.data[0]._id}`), 1000);
        }
      })
      .catch(() => {
        StateManager.setErrorAlert('Failed to start an entry');
        setStarting(false);
      });
  }

  function createTask() {
    if (starting) return;
    setStarting(true);
    axios
      .post('/tasks/createTask', { ...params.task, rowId })
      .then((res) => {
        setStarting(false);
        StateManager.setSuccessAlert('Task has been created');
        StateManager.selectTask(res.data._id);
        onChange([...items.map((x) => x._id), res.data._id]);
      })
      .catch((err) => {
        setStarting(false);
        StateManager.setAxiosErrorAlert(err);
      });
  }

  function startProcess() {
    setStarting(true);
    axios
      .post('/process/startProcess', { processId: params.process._id, rowId })
      .then((res) => {
        StateManager.setSuccessAlert('Process has been started');
        onChange([...items.map((x) => x._id), res.data.ongoingProcess._id]);
        setTimeout(() => history.push(`/processes/ongoing/${res.data.ongoingProcess._id}?started=true`), 1000);
      })
      .catch(() => {
        StateManager.setErrorAlert('Failed to start the process');
        setStarting(false);
      });
  }

  function addItem() {
    if (params?.action === 'task') {
      createTask();
    }
    if (params?.action === 'form') {
      startEntry();
    }
    if (params?.action === 'process') {
      startProcess();
    }
  }

  function sendReminder(entry) {
    if (sendingReminder) return;
    setSendingReminder(entry?._id);
    axios
      .post('/forms/entries/sendFormReminder', { entryId: entry?._id, registerColumnId: columnId })
      .then((res) => {
        setSendingReminder(false);
        StateManager.setSuccessAlert('Reminder has been sent');
      })
      .catch((err) => {
        setSendingReminder(false);
        StateManager.setAxiosErrorAlert(err);
      });
  }

  return (
    <RoundedDialog open={open} onClose={onClose} maxWidth={type === 'task' ? 'md' : 'sm'} fullWidth>
      <DialogTitle>Actions</DialogTitle>
      <DialogContent>
        <Grid container>
          {processedItems.length === 0 && (
            <Typography variant="h6" color="textSecondary" gutterBottom>
              No actions have been caried out yet
            </Typography>
          )}
          {processedItems.map((item, i) => (
            <ItemBox key={i} onClick={() => onItemClick(item)}>
              {type === 'task' && (
                <Grid container alignItems="center" wrap="nowrap">
                  <Grid container item xs={6} alignItems="center" wrap="nowrap">
                    <Grid item>
                      <Avatar style={{ background: grey[200] }}>
                        <TaskIcon />
                      </Avatar>
                    </Grid>
                    <Grid item style={{ marginLeft: 12 }}>
                      <Grid container item>
                        <Typography gutterBottom style={{ fontWeight: 500 }}>
                          {item.title}
                        </Typography>
                      </Grid>
                      <Grid container item direction="column">
                        <Typography gutterBottom color="textSecondary" style={{ fontSize: 14, marginRight: 16 }}>
                          Sent {FormatDate(item.createdAt)}
                        </Typography>

                        {item.dueAt && (
                          <Typography
                            color="textSecondary"
                            style={{
                              fontSize: 14,
                              fontWeight: item.overdue ? 500 : 400,
                              color: item.overdue ? red[600] : undefined,
                            }}
                          >
                            Due {FormatDate(item.dueAt)}
                          </Typography>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container item xs={6} justifyContent="space-between" alignItems="center" wrap="nowrap">
                    <UserGroup ids={item.assignedUsers} />
                    <StatusButton status={item.progress} isDisabled />
                  </Grid>
                </Grid>
              )}
              {type === 'form' && (
                <Grid container alignItems="center" wrap="nowrap">
                  <Grid container item xs={9} alignItems="center" wrap="nowrap">
                    <Grid item>
                      <Avatar style={{ background: grey[200] }}>
                        <FormIcon />
                      </Avatar>
                    </Grid>
                    <Grid item style={{ marginLeft: 12 }}>
                      <Grid container item>
                        <Typography gutterBottom style={{ fontWeight: 500 }}>
                          {item.title}
                        </Typography>
                      </Grid>
                      <Grid container item direction="column">
                        <Typography gutterBottom color="textSecondary" style={{ fontSize: 14, marginRight: 16 }}>
                          Sent {FormatDate(item.createdAt)}
                        </Typography>

                        {item.dueAt && !item.completedAt && (
                          <Typography
                            color="textSecondary"
                            style={{
                              fontSize: 14,
                              fontWeight: item.overdue ? 500 : 400,
                              color: item.overdue ? red[600] : undefined,
                            }}
                          >
                            Due {FormatDate(item.dueAt)}
                          </Typography>
                        )}
                        {item.completedAt && (
                          <Typography
                            color="textSecondary"
                            style={{
                              fontSize: 14,
                              fontWeight: item.overdue ? 500 : 400,
                              color: item.overdue ? red[600] : undefined,
                            }}
                          >
                            Completed {FormatDate(item.completedAt)}
                          </Typography>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container item xs={3} justifyContent="flex-end" alignItems="center">
                    {item.invitationId && (
                      <Tooltip title="Send reminder">
                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation();
                            sendReminder(item);
                          }}
                          style={{ marginRight: 8 }}
                        >
                          {sendingReminder === item._id ? (
                            <CircularProgress size={24} color="primary" />
                          ) : (
                            <NotificationsActiveRounded style={{ color: grey[500] }} />
                          )}
                        </IconButton>
                      </Tooltip>
                    )}
                    <CheckCircleRounded style={{ color: item.completedAt ? green[500] : grey[500] }} />
                  </Grid>
                </Grid>
              )}
              {type === 'process' && (
                <Grid container alignItems="center" wrap="nowrap">
                  <Grid container item xs={10} alignItems="center" wrap="nowrap">
                    <Grid item>
                      <Avatar style={{ background: grey[200] }}>
                        <ProcessIcon />
                      </Avatar>
                    </Grid>
                    <Grid item style={{ marginLeft: 12 }}>
                      <Grid container item>
                        <Typography gutterBottom style={{ fontWeight: 500 }}>
                          {item.title}
                        </Typography>
                      </Grid>
                      <Grid container item>
                        <Typography color="textSecondary" style={{ fontSize: 14, marginRight: 16 }}>
                          Sent {FormatDate(item.createdAt)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container item xs={2} justifyContent="flex-end" alignItems="center">
                    <CheckCircleRounded style={{ color: item.completedAt ? green[500] : grey[500] }} />
                  </Grid>
                </Grid>
              )}
            </ItemBox>
          ))}
        </Grid>
      </DialogContent>
      <StandardDialogActions
        additionalActions={
          editable ? (
            <Button style={{ marginRight: 'auto', color: grey[700] }} startIcon={<AddRounded />} onClick={addItem}>
              add {params?.action === 'task' ? 'task' : 'entry'}
            </Button>
          ) : null
        }
        saving={starting}
        onClose={onClose}
      />
    </RoundedDialog>
  );
}

function NaDialog({ params, open, onClose, onResult }) {
  return (
    <RoundedDialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>Do you want to start the action or make it not applicable?</DialogTitle>

      <DialogContent>
        <List style={{ width: '100%' }}>
          <ListItem
            button
            style={{ borderRadius: 5 }}
            onClick={() => {
              onResult(true);
              onClose();
            }}
          >
            <ListItemIcon>
              {params?.action === 'form' ? <FormIcon /> : params?.action === 'process' ? <ProcessIcon /> : <TaskIcon />}
            </ListItemIcon>
            <ListItemText primary={`Start ${params?.action}`} />
          </ListItem>

          <ListItem
            button
            style={{ borderRadius: 5 }}
            onClick={() => {
              onResult(false);
              onClose();
            }}
          >
            <ListItemIcon>
              <NotInterested style={{ color: grey[500] }} />
            </ListItemIcon>
            <ListItemText primary="Make action not applicable" />
          </ListItem>
        </List>
      </DialogContent>

      <StandardDialogActions onClose={onClose} />
    </RoundedDialog>
  );
}

export default GridCell;
