import React, { useState, useEffect } from 'react';
import { DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import { CircularProgress, TextField, FormControlLabel, FormControl, FormLabel } from '@mui/material';
import { Radio, RadioGroup, Checkbox, FormGroup } from '@mui/material';
import { Search } from '@mui/icons-material';
import Autocomplete from '@mui/material/Autocomplete';
import { RoundedDialog, StandardDialogActions } from '../Components';
import StateManager from '../StateManager';
import { DataIcon } from '../Icons';
import axios from 'axios';

export default function EditDataSetDialog({ open, onClose, onResult, initial, forGrid }) {
  const [options, setOptions] = useState([]);
  const [selectedSet, setSelectedSet] = useState(null);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [selectType, setSelectType] = useState('multiple');
  const [displayType, setDisplayType] = useState('dropdown');
  const loading = open && options.length === 0;

  useEffect(() => {
    if (!loading) return;
    axios
      .get('/data/getDataSetsInfo')
      .then((res) => {
        setOptions(res.data);
      })
      .catch(console.error);
  }, [loading]);

  useEffect(() => {
    if (!initial || options.length === 0) return;

    setSelectedSet(options.find((x) => x._id === initial.setId));
    setSelectType(initial.selectType || 'multiple');
    setDisplayType(initial.displayType || 'dropdown');
    setSelectedColumns(initial.columns || []);
  }, [initial, options]);

  function handleColumn(column) {
    if (selectedColumns.findIndex((x) => x.id === column.id) > -1) {
      setSelectedColumns(selectedColumns.filter((x) => x.id !== column.id));
    } else {
      setSelectedColumns([...selectedColumns, column]);
    }
  }

  function done() {
    if (!selectedColumns[0]) {
      StateManager.setErrorAlert('Select at least one column');
      return;
    }
    onResult({ setId: selectedSet._id, columns: selectedColumns, selectType, displayType });
    onClose();
  }

  return (
    <RoundedDialog open={open} onClose={onClose} maxWidth="sm" fullWidth className="non-draggable">
      <DialogTitle>Use data set</DialogTitle>
      <DialogContent>
        <Grid container>
          <Autocomplete
            sx={{ mt: 1 }}
            options={options}
            fullWidth
            loading={loading}
            value={selectedSet}
            renderOption={(props, option) => (
              <li {...props}>
                <Grid container alignItems="center">
                  <DataIcon />
                  <Typography style={{ marginLeft: '1rem' }} noWrap>
                    {option.title}
                  </Typography>
                </Grid>
              </li>
            )}
            onChange={(e, x) => {
              if (!x) return;
              setSelectedSet(x);
              setSelectedColumns([]);
            }}
            getOptionLabel={(option) => option.title}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select data set"
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {loading ? <CircularProgress size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                  startAdornment: <Search />,
                }}
              />
            )}
          />
        </Grid>
        {selectedSet && (
          <>
            <Grid container style={{ margin: '0.5rem 0' }}>
              <FormControl component="fieldset" variant="standard">
                <FormLabel component="legend">
                  Do you want to user to be able to select multiple or a single option?
                </FormLabel>
                <RadioGroup row value={selectType} onChange={(e) => setSelectType(e.target.value)}>
                  <FormControlLabel value="single" control={<Radio />} label="Single" />
                  <FormControlLabel value="multiple" control={<Radio />} label="Multiple" />
                </RadioGroup>
              </FormControl>
            </Grid>

            {!forGrid && (
              <Grid container style={{ margin: '0.5rem 0' }}>
                <FormControl component="fieldset" variant="standard">
                  <FormLabel component="legend">Display to the user as</FormLabel>
                  <RadioGroup row value={displayType} onChange={(e) => setDisplayType(e.target.value)}>
                    <FormControlLabel value="dropdown" control={<Radio />} label="Dropdown" />
                    <FormControlLabel value="searchBar" control={<Radio />} label="Search bar" />

                    {selectedColumns.length === 1 && (
                      <>
                        <FormControlLabel value="tickbox" control={<Radio />} label="Tick boxes" />
                        <FormControlLabel value="buttons" control={<Radio />} label="Buttons" />
                      </>
                    )}
                  </RadioGroup>
                </FormControl>
              </Grid>
            )}

            {['tickbox', 'buttons'].includes(displayType) && (
              <Grid container style={{ marginBottom: 16 }}>
                <Typography color={'textSecondary'} style={{ fontSize: 14 }}>
                  If the data set contains more than 20 rows the field will be displayed as search bar
                </Typography>
              </Grid>
            )}

            {selectedSet?.columns?.length > 0 && (
              <Grid container style={{ margin: '0.5rem 0' }}>
                <FormControl component="fieldset" variant="standard">
                  <FormLabel component="legend">Select which column data to show in the field</FormLabel>
                  <FormGroup>
                    {selectedSet.columns.map((column) => (
                      <FormControlLabel
                        key={column.id}
                        id={column.id}
                        label={column.title}
                        control={
                          <Checkbox
                            color="primary"
                            id={column.id}
                            checked={selectedColumns.findIndex((x) => x.id === column.id) > -1}
                            onChange={() => handleColumn(column)}
                          />
                        }
                      />
                    ))}
                  </FormGroup>
                </FormControl>
              </Grid>
            )}
          </>
        )}
      </DialogContent>
      <StandardDialogActions onClose={onClose} onDone={done} hideDone={!selectedSet} />
    </RoundedDialog>
  );
}
