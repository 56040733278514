import React, { useState, useEffect, useMemo } from 'react';
import { Button, CircularProgress, Grid, Typography, ListItemButton, Collapse, IconButton } from '@mui/material';
import { DialogTitle, DialogContent, TextField, Autocomplete, FormControlLabel, Switch } from '@mui/material';
import { useTheme, useMediaQuery } from '@mui/material';
import { BoltRounded, WorkOutlineRounded, TimelineRounded, DescriptionRounded } from '@mui/icons-material';
import { GridOnRounded, NotificationsActiveRounded, GroupAddRounded, DeleteOutlineRounded } from '@mui/icons-material';
import { isArray, isEmpty, isFunction } from 'lodash';
import { FormIcon, ProcessIcon, RegistersIcon, RequiredIcon, TaskIcon } from '../Icons';
import axios from 'axios';
import { useHistory, Link } from 'react-router-dom';
import StateManager from '../StateManager';
import { green, orange, grey, red } from '@mui/material/colors';
import { RoundedDialog, StandardDialogActions, UserChip, UserGroup } from '../Components';
import NewTask from '../../Hubs/tasks/newTask';
import { FormatDate } from '../Functions';
import Field from './Field';
import { StatusButton } from '../../Hubs/tasks/Components';
import moment from 'moment';
import { useSelector } from 'react-redux';

export default function ActionGroup({
  preSetActions,
  editable,
  activityInfo,
  itemId,
  forCell,
  forReview,
  cellInfo,
  onResult,
  onDelete,
  controlled,
  allCreatedActions,
  disabled,
  excludeRegisterAction,
}) {
  const [createdActions, setCreatedActions] = useState([]);
  const [selectedPreSetAction, setSelectedPreSetAction] = useState(null);
  const [startingActionId, setStartingActionId] = useState(null);
  const [actionDialog, setActionDialog] = useState(false);
  const [registerDialog, setRegisterDialog] = useState(false);
  const [registerAction, setRegisterAction] = useState(null);
  const [taskDialog, setTaskDialog] = useState(false);
  const [taskData, setTaskData] = useState(null);
  const [taskProjectId, setTaskProjectId] = useState();
  const [loading, setLoading] = useState(false);
  const { user } = useSelector(({ profile }) => profile);
  const history = useHistory();

  const createdNormalActions = useMemo(() => {
    const result = createdActions.filter(
      (action) =>
        !isArray(action.preSetActionIds) ||
        isEmpty(action.preSetActionIds) ||
        !isArray(preSetActions) ||
        isEmpty(preSetActions) ||
        !preSetActions.some((x) => !action.preSetActionIds.includes(x.id)),
    );

    return result;
  }, [createdActions, preSetActions]);

  const createdPreSetActions = useMemo(() => {
    const result = createdActions.filter(
      (action) =>
        isArray(action.preSetActionIds) &&
        !isEmpty(action.preSetActionIds) &&
        isArray(preSetActions) &&
        !isEmpty(preSetActions) &&
        preSetActions.some((x) => !action.preSetActionIds.includes(x.id)),
    );

    return result;
  }, [createdActions, preSetActions]);

  // action fields do not have filled value, so this id will be uniq for every field
  const actionReference = forCell
    ? `cell&${activityInfo?.entryId}&${cellInfo?.columnId}&${cellInfo?.rowId}`
    : forReview
    ? `review&${activityInfo?.entryId}`
    : `field&${activityInfo?.entryId}&${itemId}`;

  useEffect(() => {
    if ((!itemId && !forReview) || !activityInfo?.entryId || forCell || controlled) {
      return;
    }
    setLoading(true);

    const url = forReview ? '/fields/actions/getActionsForReview' : '/fields/actions/getActionsForField';
    axios
      .get(url, { params: { itemId, entryId: activityInfo?.entryId } })
      .then(({ data }) => {
        setCreatedActions(data);
        setLoading(false);
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
        setLoading(false);
      });
  }, [itemId, forCell]); // eslint-disable-line

  useEffect(() => {
    if (isEmpty(allCreatedActions) || !isArray(allCreatedActions) || (!forCell && !controlled)) return;
    // for cell - we take all ellements (they are already filtered)
    // for review - nothing - it will be fetched
    // for fields - filter the elements
    const initialActions = forCell
      ? allCreatedActions
      : forReview
      ? []
      : allCreatedActions.filter((x) =>
          x.actionReferenceInfo?.fieldId
            ? x.actionReferenceInfo?.fieldId === itemId
            : isArray(x.actionReferences) && x.actionReferences.some((reference) => String(reference).includes(itemId)),
        );
    setCreatedActions(initialActions);
  }, [allCreatedActions, forCell, forReview, controlled, itemId]);

  function startAction(action) {
    if (!editable) return;
    const actionReferenceInfo = { ...activityInfo, itemId };
    if (action.type === 'form' && action.formId) {
      setStartingActionId(action.id);
      setSelectedPreSetAction(action);
      const body = {
        formId: action.formId,
        actionReferenceInfo,
        actionReference,
        assignedUsers: action.assignedUsers,
        preSetActionId: action.id,
      };
      axios
        .post('/forms/entries/sendEntry', body)
        .then(({ data }) => {
          if (data[0]) {
            selectFormEntry(data[0]._id);
            //history.push(`/forms/entry/${data[0]._id}`);
          }
          const result = { ...data[0], type: 'form' };
          createdActions.push(result);
          setCreatedActions([...createdActions]);
          if (isFunction(onResult)) onResult({ ...result, ...cellInfo });
          setStartingActionId(null);
        })
        .catch((err) => {
          StateManager.setAxiosErrorAlert(err);
          setStartingActionId(null);
        });
    }
    if (action.type === 'process' && action.processId) {
      setStartingActionId(action.id);
      setSelectedPreSetAction(null);
      const body = {
        processId: action.processId,
        actionReferenceInfo,
        actionReference,
        assignedUsers: action.assignedUsers,
        preSetActionId: action.id,
      };
      axios
        .post('/process/startProcess', body)
        .then(({ data }) => {
          if (data.ongoingProcess._id && data?.firstStep?.owners?.includes(user._id)) {
            history.push(`/processes/ongoing/${data.ongoingProcess._id}`);
          }
          setStartingActionId(null);
          const result = data.action;
          setCreatedActions([...createdActions, result]);
          if (isFunction(onResult)) onResult({ ...result, ...cellInfo });
        })
        .catch((err) => {
          StateManager.setAxiosErrorAlert(err);
          setStartingActionId(null);
        });
    }
    if (action.type === 'register') {
      setRegisterAction(action);
      setSelectedPreSetAction(action);
      setRegisterDialog(true);
    }

    if (action.type === 'task') {
      setTaskData(action.taskData);
      setTaskProjectId(action.projectId);
      setSelectedPreSetAction(action);
      setTaskDialog(true);
    }
  }

  function saveRegisterRow(row) {
    const result = { ...row, type: 'register' };
    if (isFunction(onResult)) {
      onResult({ ...result, ...cellInfo });
    }
    const updated = [...createdActions, result];
    setCreatedActions(updated);
  }

  function saveTask(task) {
    const result = { ...task, type: 'task' };
    if (isFunction(onResult)) {
      onResult({ ...result, ...cellInfo });
    }
    setCreatedActions([...createdActions, result]);
  }

  function deleteAction(actionId) {
    setCreatedActions(createdActions.filter((x) => x._id !== actionId));
    if (isFunction(onDelete)) {
      onDelete(actionId);
    }
  }

  function selectFormEntry(entryId) {
    StateManager.openFormEntry(entryId, saveFormEntry);
  }

  function saveFormEntry(entry) {
    if (!entry?._id) return;

    const index = createdActions.findIndex((x) => x._id === entry._id);
    if (index === -1) {
      console.log('createdActions', createdActions, 'entry', entry);
      return;
    }

    const copy = [...createdActions];
    copy[index] = { ...copy[index], completedAt: entry.completedAt };
    console.log('copy[index]', copy[index]);
    setCreatedActions(copy);
  }

  // disable for external users
  if (!user) return null;

  return (
    <Grid container>
      <Grid container item>
        {loading ? (
          <CircularProgress size={24} sx={{ my: 1 }} />
        ) : (
          <>
            {isArray(preSetActions) && !isEmpty(preSetActions) && (
              <Grid container item>
                {preSetActions
                  .filter((x) => !x.hidden || x.required)
                  .map((action) => {
                    const created = createdPreSetActions.filter(
                      (x) => isArray(x.preSetActionIds) && x.preSetActionIds.includes(action.id),
                    );
                    if (!isEmpty(created)) {
                      return created.map((item) => (
                        <Action
                          key={item._id}
                          initial={item}
                          onDelete={deleteAction}
                          editable={editable}
                          onFormSelect={selectFormEntry}
                        />
                      ));
                    }
                    return (
                      <PreSetAction
                        key={action.id}
                        data={action}
                        onStart={() => startAction(action)}
                        starting={startingActionId === action.id}
                        editable={editable}
                      />
                    );
                  })}
              </Grid>
            )}
            {!isEmpty(createdNormalActions) && (
              <Grid container item>
                {createdNormalActions.map((action) => (
                  <Action
                    key={action._id}
                    initial={action}
                    onDelete={deleteAction}
                    editable={editable}
                    itemId={itemId}
                    onFormSelect={selectFormEntry}
                  />
                ))}
              </Grid>
            )}
          </>
        )}
      </Grid>
      {editable && !disabled && (
        <Grid container item sx={{ mt: 2 }}>
          <Button variant="outlined" onClick={() => setActionDialog(true)} startIcon={<BoltRounded />}>
            Create{isEmpty(preSetActions) ? '' : ' another'} action
          </Button>
          <ActionDialog
            open={actionDialog}
            onClose={() => setActionDialog(false)}
            onResult={startAction}
            preSetActions={preSetActions}
            excludeRegisterAction={excludeRegisterAction}
          />
        </Grid>
      )}

      <FillRegisterRowDialog
        open={registerDialog}
        onClose={() => setRegisterDialog(false)}
        registerId={registerAction?.registerId}
        selectedColumns={registerAction?.selectedColumns}
        title={registerAction?.title}
        actionReference={actionReference}
        onResult={saveRegisterRow}
        actionReferenceInfo={{ ...activityInfo, fieldId: itemId }}
        preSetAction={selectedPreSetAction}
        editable
      />

      <NewTask
        open={taskDialog}
        onClose={() => setTaskDialog(false)}
        initial={taskData}
        actionReference={actionReference}
        actionReferenceInfo={{ ...activityInfo, fieldId: itemId }}
        preSetAction={selectedPreSetAction}
        onResult={saveTask}
        projectId={taskProjectId}
      />
    </Grid>
  );
}

function PreSetAction({ data, onStart, starting, editable }) {
  const outerContainerSx = {
    my: 1,
    borderRadius: 1,
    background: (theme) => (theme.palette.mode === 'dark' ? grey[700] : grey[100]),
    position: 'relative',
  };

  const innerContainerSx = {
    p: 2,
    borderRadius: 1,
    border: `3px solid ${grey[400]}`,
  };

  if (!data?.type) return null;

  return (
    <Grid container item sx={outerContainerSx} id={data.id}>
      <Grid container item sx={innerContainerSx} component={ListItemButton} disabled={!editable} onClick={onStart}>
        <Grid container alignItems={'center'} wrap="nowrap">
          {starting ? (
            <CircularProgress size={24} />
          ) : (
            <>
              {data.type === 'form' && <FormIcon />}
              {data.type === 'task' && <TaskIcon />}
              {data.type === 'process' && <ProcessIcon />}
              {data.type === 'register' && <RegistersIcon />}
            </>
          )}
          <Typography color="textPrimary" sx={{ ml: 1 }}>
            {data.title}
          </Typography>
        </Grid>
      </Grid>
      {data.required && <RequiredIcon style={{ position: 'absolute', top: -8, left: -8 }} />}
    </Grid>
  );
}

export function Action({
  initial,
  editable,
  onDelete,
  itemId,
  onFormSelect,
  disabled = false,
  onSave,
  disabledAssignedUsers,
}) {
  const [sendingReminder, setSendingReminder] = useState(false);
  const [viewRowDialog, setViewRowDialog] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [data, setData] = useState(null);
  const history = useHistory();

  const theme = useTheme();
  const largeDevices = useMediaQuery(theme.breakpoints.up('sm'));

  useEffect(() => {
    setData(initial);
  }, [initial]);

  const outerContainerSx = {
    my: 1,
    borderRadius: 1,
    background: (theme) =>
      data
        ? data.completedAt
          ? theme.palette.mode === 'dark'
            ? green[800]
            : green[50]
          : theme.palette.mode === 'dark'
          ? orange[800]
          : orange[50]
        : theme.palette.mode === 'dark'
        ? grey[800]
        : grey[100],
  };

  const innerContainerSx = {
    py: 1.5,
    px: 2,
    borderRadius: 1,
    border: data
      ? data.completedAt
        ? `3px solid ${green[600]}`
        : `3px solid ${orange[600]}`
      : `3px solid ${grey[400]}`,
  };

  function sendFormReminder() {
    if (sendingReminder) return;
    setSendingReminder(true);
    axios
      .post('/forms/entries/sendFormReminder', { entryId: data?._id })
      .then((res) => {
        setSendingReminder(false);
        StateManager.setSuccessAlert('Reminder has been sent');
      })
      .catch((err) => {
        setSendingReminder(false);
        StateManager.setAxiosErrorAlert(err);
      });
  }

  function sendProcessReminder() {
    if (sendingReminder) return;
    setSendingReminder(true);

    axios
      .post('/process/entries/remindOfTheStep', { stepId: data?.currOngoingStepId })
      .then(() => {
        setSendingReminder(false);
        StateManager.setSuccessAlert('Reminder has been sent');
      })
      .catch((err) => {
        setSendingReminder(false);
        StateManager.setAxiosErrorAlert(err);
      });
  }

  function saveTask(taskData) {
    data.title = taskData.title;
    data.dueAt = taskData.dueAt;
    data.assignedUsers = taskData.assignedUsers;
    data.progress = taskData.progress;

    setData({ ...data });

    initial.title = taskData.title;
    initial.dueAt = taskData.dueAt;
    initial.assignedUsers = taskData.assignedUsers;
    initial.progress = taskData.progress;
    onSave(taskData);
  }

  function confirmActionDelete() {
    if (data?.type === 'form') {
      StateManager.setConfirm('You are about to delete this entry', deleteFormEntry);
    } else if (data?.type === 'process') {
      StateManager.setConfirm('You are about to delete this process', deleteProcessEntry);
    } else if (data?.type === 'register') {
      StateManager.setConfirm('You are about to delete this row', deleteRegisterRow);
    } else if (data?.type === 'task') {
      StateManager.setConfirm('You are about to delete this task', deleteTask);
    }
  }

  function deleteFormEntry() {
    setDeleting(true);
    axios
      .post('/forms/entries/deleteEntry', { entryId: data._id })
      .then(() => {
        StateManager.setSuccessAlert('Entry has been deleted');
        setDeleting(false);
        if (isFunction(onDelete)) {
          onDelete(data._id);
        }
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
        setDeleting(false);
      });
  }

  function deleteProcessEntry() {
    setDeleting(true);
    axios
      .post('/process/entries/deleteProcessEntry', { ongoingProcessId: data._id })
      .then(() => {
        StateManager.setSuccessAlert('Process has been deleted');
        setDeleting(false);
        if (isFunction(onDelete)) {
          onDelete(data._id);
        }
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
        setDeleting(false);
      });
  }

  function deleteRegisterRow() {
    setDeleting(true);
    axios
      .post('/registers/general/updateRegister', {
        registerId: data.registerId,
        eventType: 'rowsDelete',
        params: { rows: [data._id] },
      })
      .then(() => {
        StateManager.setSuccessAlert('Row has been deleted');
        setDeleting(false);
        if (isFunction(onDelete)) {
          onDelete(data._id);
        }
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
        setDeleting(false);
      });
  }

  function deleteTask() {
    setDeleting(true);
    axios
      .post('/tasks/management/deleteTask', { taskId: data._id })
      .then(() => {
        StateManager.setSuccessAlert('Task has been deleted');
        setDeleting(false);
        if (isFunction(onDelete)) {
          onDelete(data._id);
        }
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
        setDeleting(false);
      });
  }

  const deleteButton = isFunction(onDelete) && editable && (
    <IconButton
      sx={{ ml: 1 }}
      onClick={(e) => {
        e.stopPropagation();
        confirmActionDelete();
      }}
    >
      {deleting ? <CircularProgress size={24} /> : <DeleteOutlineRounded color="secondary" />}
    </IconButton>
  );

  if (data?.type === 'form') {
    return (
      <Grid container item sx={outerContainerSx}>
        <Grid
          container
          item
          sx={innerContainerSx}
          component={ListItemButton}
          onClick={
            isFunction(onFormSelect) ? () => onFormSelect(data._id) : () => history.push(`/forms/entry/${data._id}`)
          }
        >
          <Grid container alignItems={'center'}>
            <Grid container item lg={6} md={6} xs={12} alignItems={'center'} wrap="nowrap">
              <FormIcon />
              <Typography sx={{ ml: 1, color: (theme) => theme.palette.text.primary }}>
                {data.title} - entry #{data.number}
              </Typography>
            </Grid>

            <Grid
              container
              item
              lg={6}
              md={6}
              xs={12}
              alignItems={'center'}
              wrap="nowrap"
              justifyContent={largeDevices ? 'flex-end' : 'space-between'}
            >
              <UserGroup avatarSize={largeDevices ? 32 : 24} ids={data.assignedUsers} />

              <IconButton
                sx={{ ml: 1 }}
                onClick={(e) => {
                  e.stopPropagation();
                  sendFormReminder();
                }}
              >
                {sendingReminder ? <CircularProgress size={24} /> : <NotificationsActiveRounded />}
              </IconButton>
              {deleteButton}
            </Grid>
          </Grid>
          {!data.completedAt && (
            <Grid container alignItems={'center'}>
              <Typography sx={{ fontSize: 14 }} color={'textSecondary'}>
                Entry has not been finished
              </Typography>
              {data.dueAt && (
                <Typography sx={{ fontSize: 14, ml: 'auto' }} color={'textSecondary'}>
                  Due: {FormatDate(data.dueAt)}
                </Typography>
              )}
            </Grid>
          )}
        </Grid>
      </Grid>
    );
  }

  if (data?.type === 'process') {
    return (
      <Grid container item sx={outerContainerSx}>
        <Grid
          container
          item
          sx={innerContainerSx}
          component={ListItemButton}
          onClick={(e) => history.push(`/processes/ongoing/${data._id}`)}
        >
          <Grid container alignItems={'center'}>
            <Grid container item lg={6} md={6} xs={12} alignItems={'center'} wrap="nowrap">
              <ProcessIcon />
              <Typography sx={{ ml: 1, color: (theme) => theme.palette.text.primary }}>
                {data.title} - entry #{data.number}
              </Typography>
            </Grid>

            <Grid
              container
              item
              lg={6}
              md={6}
              xs={12}
              alignItems={'center'}
              wrap="nowrap"
              justifyContent={largeDevices ? 'flex-end' : 'space-between'}
            >
              <UserGroup ids={data.currStepOwners} avatarSize={largeDevices ? 32 : 24} />

              <IconButton
                sx={{ ml: 1 }}
                onClick={(e) => {
                  e.stopPropagation();
                  sendProcessReminder();
                }}
              >
                {sendingReminder ? <CircularProgress size={24} /> : <NotificationsActiveRounded />}
              </IconButton>
              {deleteButton}
            </Grid>
          </Grid>
          {!data.completedAt && (
            <Grid container alignItems={'center'}>
              <Typography sx={{ fontSize: 14 }} color={'textSecondary'}>
                Current step: {data.currStepTitle}
              </Typography>
              {data.currStepDue && (
                <Typography sx={{ fontSize: 14, ml: 'auto' }} color={'textSecondary'}>
                  Due: {FormatDate(data.currStepDue)}
                </Typography>
              )}
            </Grid>
          )}
        </Grid>
      </Grid>
    );
  }

  if (data?.type === 'register') {
    return (
      <Grid container item sx={{ my: 1, borderRadius: 1, border: `1px solid ${grey[500]}` }}>
        <Grid
          container
          item
          sx={{ py: 1.5, px: 2, borderRadius: 1 }}
          component={ListItemButton}
          onClick={() => setViewRowDialog(true)}
        >
          <Grid container alignItems={'center'}>
            <Grid container item lg={6} md={6} xs={12} alignItems={'center'} wrap="nowrap">
              <RegistersIcon />
              <Typography sx={{ ml: 1, color: (theme) => theme.palette.text.primary }}>
                {data.title} - row #{data.index}
              </Typography>
            </Grid>

            {!largeDevices && (
              <Grid container item sx={{ py: 0.5 }}>
                <Typography color={'textSecondary'} style={{ fontSize: 14 }}>
                  {FormatDate(data.createdAt)}
                </Typography>
              </Grid>
            )}

            <Grid
              container
              item
              lg={6}
              md={6}
              xs={12}
              alignItems={'center'}
              wrap="nowrap"
              justifyContent={largeDevices ? 'flex-end' : 'space-between'}
            >
              {largeDevices && (
                <Typography color={'textSecondary'} style={{ fontSize: 14 }}>
                  {FormatDate(data.createdAt)}
                </Typography>
              )}
              <UserGroup avatarSize={32} ids={data.createdBy} />
              {deleteButton}
            </Grid>
          </Grid>
        </Grid>

        <FillRegisterRowDialog
          open={viewRowDialog}
          onClose={() => setViewRowDialog(false)}
          registerId={data?.registerId}
          title={data?.title}
          actionReferenceInfo={{ fieldId: itemId }}
          row={data}
        />
      </Grid>
    );
  }

  if (data?.type === 'task') {
    const overdue = data.dueAt && moment(data.dueAt) < moment();
    return (
      <Grid container item sx={{ my: 1, borderRadius: 1, border: `1px solid ${grey[500]}` }}>
        <Grid
          container
          item
          sx={{ py: 1.5, px: 2, borderRadius: 1, border: overdue ? `2px solid ${red[600]}` : '' }}
          component={ListItemButton}
          onClick={() => {
            !disabled ? StateManager.selectTask(data._id, saveTask, () => onDelete(data._id)) : false;
          }}
        >
          <Grid container alignItems={'center'}>
            <Grid container item lg={7} md={7} xs={12} alignItems={'center'} wrap="nowrap">
              <TaskIcon />
              <Typography sx={{ ml: 1, color: (theme) => theme.palette.text.primary }}>{data.title}</Typography>
              {largeDevices && (
                <UserGroup
                  disable={disabledAssignedUsers}
                  avatarSize={32}
                  ids={data.assignedUsers}
                  style={{ marginLeft: 'auto' }}
                />
              )}
            </Grid>

            {!largeDevices && (
              <Grid container item alignItems={'center'} wrap="nowrap" sx={{ py: 0.5 }}>
                <UserGroup disable={disabledAssignedUsers} avatarSize={32} ids={data.assignedUsers} />
              </Grid>
            )}

            <Grid
              container
              item
              lg={5}
              md={5}
              xs={12}
              alignItems={'center'}
              wrap="nowrap"
              justifyContent={largeDevices ? 'flex-end' : 'space-between'}
            >
              {data.dueAt && (
                <Typography
                  color={'textSecondary'}
                  sx={{ fontSize: 14, fontWeight: overdue ? 600 : 400, color: overdue ? red[600] : '', ml: 1 }}
                >
                  Due: {FormatDate(data.dueAt)}
                </Typography>
              )}

              <StatusButton
                status={data.progress}
                isDisabled
                isOverdue={overdue}
                style={{ marginLeft: largeDevices ? 'auto' : '' }}
              />
              {deleteButton}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
  return null;
}

function ActionDialog({ open, onClose, onResult, preSetActions, excludeRegisterAction }) {
  const [addingAction, setAddingAction] = useState(false);
  const [addingActionType, setAddingActionType] = useState(false);

  const [processes, setProcesses] = useState([]);
  const [selectedProcess, setSelectedProcess] = useState(null);
  const [sendProcessToUsers, setSendProcessToUsers] = useState(false);
  const [processUsers, setProcessUsers] = useState([]);
  const loadProcesses = isEmpty(processes) && addingAction && addingActionType === 'process';

  const [forms, setForms] = useState([]);
  const [selectedForm, setSelectedForm] = useState(null);
  const [sendFormToUsers, setSendFormToUsers] = useState(false);
  const [formUsers, setFormUsers] = useState([]);
  const loadForms = isEmpty(forms) && addingAction && addingActionType === 'form';

  const [registers, setRegisters] = useState([]);
  const [selectedRegister, setSelectedRegister] = useState(null);
  const loadRegisters = isEmpty(registers) && addingAction && addingActionType === 'register';

  const formDone = addingActionType === 'form' && selectedForm;
  const processDone = addingActionType === 'process' && selectedProcess;
  const registerDone = addingActionType === 'register' && selectedRegister;

  const actionDone = formDone || processDone || registerDone;

  const theme = useTheme();
  const largeDevices = useMediaQuery(theme.breakpoints.up('sm'));

  useEffect(() => {
    if (!loadProcesses) return;
    axios
      .get('/process/search/getSearchProcesses')
      .then(({ data }) => setProcesses(data))
      .catch((err) => StateManager.setAxiosErrorAlert(err));
  }, [loadProcesses]);

  useEffect(() => {
    if (!loadForms) return;
    axios
      .get('/forms/general/getFormsForSearch')
      .then(({ data }) => setForms(data))
      .catch((err) => StateManager.setAxiosErrorAlert(err));
  }, [loadForms]);

  useEffect(() => {
    if (!loadRegisters) return;
    axios
      .get('/registers/general/getUserRegisters')
      .then(({ data }) => setRegisters(data))
      .catch((err) => StateManager.setAxiosErrorAlert(err));
  }, [loadRegisters]);

  function addAction(type) {
    setAddingActionType(type);
    setAddingAction(true);
  }

  function cancelAction() {
    setAddingActionType(null);
    setAddingAction(false);
    setSelectedProcess(null);
    setSelectedForm(null);
    setSendFormToUsers(false);
    setFormUsers([]);
    setSendProcessToUsers(false);
    setProcessUsers([]);
    onClose();
  }

  function done() {
    const action = { type: addingActionType };
    if (addingActionType === 'form') {
      action.formId = selectedForm._id;
      action.assignedUsers = sendFormToUsers ? formUsers : undefined;
    } else if (addingActionType === 'process') {
      action.processId = selectedProcess._id;
      action.assignedUsers = sendProcessToUsers ? processUsers : undefined;
    } else if (addingActionType === 'register') {
      action.registerId = selectedRegister._id;
    }

    onResult(action);
    cancelAction();
  }

  function createTask() {
    onResult({ type: 'task' });
    cancelAction();
  }

  function selectUsersForForm() {
    StateManager.selectMultipleUsers(saveFormUsers, { initiallySelected: formUsers });
  }

  function saveFormUsers(res) {
    if (!isArray(res.users)) return;
    setFormUsers(res.users);
  }

  function selectUsersForProcess() {
    StateManager.selectMultipleUsers(saveProcessUsers, { initiallySelected: formUsers });
  }

  function saveProcessUsers(res) {
    if (!isArray(res.users)) return;
    setProcessUsers(res.users);
  }

  return (
    <RoundedDialog
      open={open}
      onClose={onClose}
      maxWidth={'md'}
      fullWidth
      className="scroll-bar"
      fullScreen={!largeDevices}
    >
      <DialogTitle>Create action</DialogTitle>
      <DialogContent>
        <Grid container item>
          <Typography gutterBottom variant="h6" color={'textSecondary'}>
            What would you like to do?
          </Typography>
        </Grid>
        {isArray(preSetActions) && !isEmpty(preSetActions) && (
          <Grid container item>
            {preSetActions.map((action) => (
              <Grid
                container
                item
                key={action.id}
                lg={6}
                md={6}
                xs={12}
                sx={{ p: 1 }}
                wrap="nowrap"
                alignItems="center"
              >
                <Button
                  variant="outlined"
                  fullWidth
                  startIcon={
                    <>
                      {action.type === 'form' && <FormIcon />}
                      {action.type === 'task' && <TaskIcon />}
                      {action.type === 'process' && <ProcessIcon />}
                      {action.type === 'register' && <RegistersIcon />}
                    </>
                  }
                  sx={{ textTransform: 'none' }}
                  onClick={() => {
                    onClose();
                    onResult(action);
                  }}
                >
                  {action.title}
                </Button>
              </Grid>
            ))}
          </Grid>
        )}
        <Grid container item sx={{ mb: 2 }}>
          <Grid container item lg={6} md={6} xs={12} sx={{ p: 1 }}>
            <Button
              onClick={createTask}
              fullWidth
              style={{ color: addingActionType === 'task' ? '' : grey[500] }}
              startIcon={<WorkOutlineRounded />}
            >
              Create a task
            </Button>
          </Grid>
          <Grid container item lg={6} md={6} xs={12} sx={{ p: 1 }}>
            <Button
              onClick={() => addAction('process')}
              fullWidth
              style={{ color: addingActionType === 'process' ? '' : grey[500] }}
              startIcon={<TimelineRounded />}
            >
              Start a process
            </Button>
          </Grid>
          <Grid container item lg={6} md={6} xs={12} sx={{ p: 1 }}>
            <Button
              style={{ color: addingActionType === 'form' ? '' : grey[500] }}
              onClick={() => addAction('form')}
              fullWidth
              startIcon={<DescriptionRounded />}
            >
              Start a form
            </Button>
          </Grid>
          {!excludeRegisterAction && (
            <Grid container item lg={6} md={6} xs={12} sx={{ p: 1 }}>
              <Button
                onClick={() => addAction('register')}
                fullWidth
                startIcon={<GridOnRounded />}
                style={{ color: addingActionType === 'register' ? '' : grey[500] }}
              >
                Populate a register row
              </Button>
            </Grid>
          )}
        </Grid>
        <Grid container component={Collapse} item in={addingAction} id="action-area" sx={{ borderRadius: 1 }}>
          <Grid container item sx={{ mb: 2 }}>
            {addingActionType === 'form' && (
              <>
                <Autocomplete
                  options={forms}
                  value={selectedForm}
                  fullWidth
                  getOptionLabel={(option) => option?.title || ''}
                  isOptionEqualToValue={(option, value) => option?._id === value?._id}
                  openOnFocus
                  renderOption={(props, option) => (
                    <li {...props} key={option._id}>
                      <Grid container alignItems="center">
                        <FormIcon />
                        <Typography style={{ marginLeft: '1rem' }} noWrap>
                          {option.title}
                        </Typography>
                      </Grid>
                    </li>
                  )}
                  renderInput={(params) => <TextField {...params} label="Select a form" />}
                  onChange={(e, value) => setSelectedForm(value)}
                />
                <Grid container>
                  <Grid container sx={{ pt: 1 }}>
                    <FormControlLabel
                      control={<Switch checked={sendFormToUsers} />}
                      onChange={(e) => setSendFormToUsers(e.target.checked)}
                      label="Send to users"
                    />
                  </Grid>
                  <Grid container component={Collapse} in={sendFormToUsers} alignItems="center" sx={{ pt: 1 }}>
                    <Button variant="outlined" startIcon={<GroupAddRounded />} onClick={selectUsersForForm}>
                      select users
                    </Button>
                    {formUsers.map((user, i) => (
                      <UserChip
                        key={i}
                        id={user}
                        margin={8}
                        onDelete={() => setFormUsers(formUsers.filter((x) => x !== user))}
                      />
                    ))}
                  </Grid>
                </Grid>
              </>
            )}
            {addingActionType === 'process' && (
              <>
                <Autocomplete
                  options={processes}
                  value={selectedProcess}
                  getOptionLabel={(option) => option?.title || ''}
                  isOptionEqualToValue={(option, value) => option?._id === value?._id}
                  openOnFocus
                  fullWidth
                  renderOption={(props, option) => (
                    <li {...props} key={option._id}>
                      <Grid container alignItems="center">
                        <ProcessIcon />
                        <Typography style={{ marginLeft: '1rem' }} noWrap>
                          {option.title}
                        </Typography>
                      </Grid>
                    </li>
                  )}
                  renderInput={(params) => <TextField {...params} label="Select a process" />}
                  onChange={(e, value) => setSelectedProcess(value)}
                />
                <Grid container>
                  <Grid container sx={{ pt: 1 }}>
                    <FormControlLabel
                      control={<Switch checked={sendProcessToUsers} />}
                      onChange={(e) => setSendProcessToUsers(e.target.checked)}
                      label="Send to users"
                    />
                  </Grid>
                  <Grid container component={Collapse} in={sendProcessToUsers} alignItems="center" sx={{ pt: 1 }}>
                    <Button variant="outlined" startIcon={<GroupAddRounded />} onClick={selectUsersForProcess}>
                      select users
                    </Button>
                    {processUsers.map((user, i) => (
                      <UserChip
                        key={i}
                        id={user}
                        margin={8}
                        onDelete={() => setProcessUsers(processUsers.filter((x) => x !== user))}
                      />
                    ))}
                  </Grid>
                </Grid>
              </>
            )}
            {addingActionType === 'register' && (
              <Autocomplete
                options={registers}
                value={selectedRegister}
                getOptionLabel={(option) => option?.title || ''}
                isOptionEqualToValue={(option, value) => option?._id === value?._id}
                openOnFocus
                fullWidth
                renderOption={(props, option) => (
                  <li {...props} key={option._id}>
                    <Grid container alignItems="center">
                      <RegistersIcon />
                      <Typography style={{ marginLeft: '1rem' }} noWrap>
                        {option.title}
                      </Typography>
                    </Grid>
                  </li>
                )}
                renderInput={(params) => <TextField {...params} label="Select a register" />}
                onChange={(e, value) => setSelectedRegister(value)}
              />
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <StandardDialogActions onClose={cancelAction} closeButtonText={'cancel'} onDone={done} hideDone={!actionDone} />
    </RoundedDialog>
  );
}

function FillRegisterRowDialog({
  open,
  onClose,
  registerId,
  selectedColumns,
  actionReferenceInfo,
  title,
  editable,
  actionReference,
  onResult,
  row,
  preSetAction,
}) {
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [rawData, setRawData] = useState({});
  const [registerColumns, setRegisterColumns] = useState([]);

  const theme = useTheme();
  const largeDevices = useMediaQuery(theme.breakpoints.up('sm'));

  useEffect(() => {
    if (!registerId || !open) return;
    setLoading(true);
    axios
      .get('/registers/general/getFillableRegisterColumns', { params: { registerId } })
      .then(({ data }) => {
        setRegisterColumns(data);
        setLoading(false);
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
        setLoading(false);
      });
  }, [registerId, open]);

  useEffect(() => {
    // clear data when the dialog closes
    if (open) return;
    setRawData({});
  }, [open]);

  useEffect(() => {
    if (!open || isEmpty(preSetAction?.map) || isEmpty(actionReferenceInfo?.type)) return;

    const fields = Object.keys(preSetAction.map);
    axios
      .post('/fields/actions/getFieldsValues', { fields, activityInfo: actionReferenceInfo })
      .then(({ data }) => {
        const filterColumns = isArray(selectedColumns) && !isEmpty(selectedColumns);
        const mapped = data
          .filter((x) => !filterColumns || selectedColumns.includes(preSetAction.map[x.fieldId]))
          .reduce((prev, curr) => ({ ...prev, [preSetAction.map[curr.fieldId]]: curr.filledValue }), {});
        setRawData(mapped);
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
      });
  }, [preSetAction, actionReferenceInfo, open, selectedColumns]);

  function onSave(fieldId, value) {
    const result = { ...rawData, [fieldId]: value };
    setRawData(result);
  }

  function done() {
    const body = {
      registerId,
      rawData,
      actionReference,
      actionReferenceInfo,
      preSetActionId: preSetAction?.id,
      fillExisting: preSetAction.fillExistingRow,
    };
    setSaving(true);
    axios
      .post('/registers/general/addFilledRow', body)
      .then(({ data }) => {
        setSaving(false);
        onResult(data);
        onClose();
      })
      .catch((err) => {
        setSaving(false);
        StateManager.setAxiosErrorAlert(err);
      });
  }

  function cancel() {
    onClose();
  }

  const initiallyFilled =
    isArray(row?.initiallyFilled) && !isEmpty(row?.initiallyFilled)
      ? actionReferenceInfo.fieldId
        ? row.initiallyFilled.find((x) => x.fieldId === actionReferenceInfo.fieldId)?.data
        : row.initiallyFilled[0]?.data
      : null;

  const initiallyFilledColumnIds = initiallyFilled ? Object.keys(initiallyFilled) : null;

  const filteredColumns = editable
    ? isArray(selectedColumns)
      ? registerColumns.filter((x) => selectedColumns.includes(x.id))
      : registerColumns
    : isArray(initiallyFilledColumnIds)
    ? registerColumns.filter((x) => initiallyFilledColumnIds.includes(x.id))
    : registerColumns;

  //console.log({ 'row?.initiallyFilled': row?.initiallyFilled, actionReferenceInfo });

  return (
    <RoundedDialog
      open={open}
      onClose={onClose}
      maxWidth={'sm'}
      fullWidth
      className="scroll-bar"
      fullScreen={!largeDevices}
    >
      <DialogTitle>{title || 'Add row to register'}</DialogTitle>
      <DialogContent>
        {loading ? (
          <Grid container alignItems={'center'} justifyContent="center" sx={{ height: 30 }}>
            <CircularProgress size={32} />
          </Grid>
        ) : isEmpty(filteredColumns) && editable ? (
          <Typography variant="h6" color={'textSecondary'}>
            No columns available to fill
          </Typography>
        ) : (
          <Grid container>
            {filteredColumns.map((column) => (
              <Field
                key={column.id}
                item={
                  editable
                    ? { ...column, filledValue: rawData[column.id] }
                    : { ...column, filledValue: initiallyFilled ? initiallyFilled[column.id] : undefined }
                }
                editable={editable}
                onSave={onSave}
              />
            ))}
          </Grid>
        )}
      </DialogContent>
      <StandardDialogActions
        additionalActions={
          !editable &&
          row && (
            <Link
              to={`/registers/${row.registerId}?rowId=${row._id}&highlightRow=true`}
              style={{ marginRight: 'auto' }}
            >
              <Button startIcon={<RegistersIcon />}>Go to the row</Button>
            </Link>
          )
        }
        onClose={cancel}
        closeButtonText={'cancel'}
        onDone={done}
        hideDone={isEmpty(rawData)}
        saving={saving}
      />
    </RoundedDialog>
  );
}
