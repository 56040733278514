const PDF = ['pdf', 'application/pdf', 'application/x-pdf'];

const TIFF = ['tiff', 'image/tiff', 'image/x-tiff'];

const Image = [
  'jpeg',
  'jpg',
  'png',
  'webp',
  'gif',
  'image/webp',
  'image/jpeg',
  'image/png',
  'image/jpg',
  'image/gif',
  ...TIFF,
];

const Audio = [
  'mp3',
  'wav',
  'ogg',
  'flac',
  'aac',
  'm4a',
  'wma',
  'aiff',
  'aif',
  'mpeg',
  'audio/mp3',
  'audio/mpeg',
  'audio/wav',
  'audio/ogg',
  'audio/flac',
  'audio/aac',
  'audio/m4a',
  'audio/wma',
  'audio/aiff',
  'audio/aif',
];
const Video = [
  'mp4',
  'webm',
  'ogg',
  'ogv',
  'avi',
  'mov',
  'wmv',
  'flv',
  'mkv',
  'quicktime',
  'audio/mp4',
  'audio/mpeg',
  'video/mp4',
  'video/mpeg',
  'video/webm',
  'video/ogg',
  'video/ogv',
  'video/avi',
  'video/mov',
  'video/wmv',
  'video/flv',
  'video/mkv',
  'video/quicktime',
];
const QDOC = ['qhub'];

const Word = [
  'doc',
  'msword',
  'application/msword',
  'docx',
  'dotx',
  'vnd.openxmlformats-officedocument.wordprocessingml.document',
  'vnd.openxmlformats-officedocument.wordprocessingml.template',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
  'text/plain',
];

const Excel = [
  'xls',
  'xlsm',
  'vnd.ms-excel',
  'vnd.ms-excel.sheet.macroenabled.12',
  'application/vnd.ms-excel',
  'application/vnd.ms-excel.sheet.macroenabled.12',
  'xlsx',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'csv',
  'text/csv',
];

const ExcelMimeTypes = [
  'application/vnd.ms-excel',
  'application/vnd.ms-excel.sheet.macroenabled.12',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'text/csv',
];

export const acceptedExcelFormats = ExcelMimeTypes.reduce((prev, curr) => ({ ...prev, [curr]: [] }), {});

const PowerPoint = [
  'ppt',
  'application/vnd.ms-powerpoint',
  'vnd.ms-powerpoint',
  'pptx',
  'vnd.oasis.opendocument.presentation',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.oasis.opendocument.presentation',
];

const MSDOC = [...Word, ...Excel, ...PowerPoint];

const ZIP = ['application/zip', 'application/x-7z-compressed', 'application/x-zip-compressed', 'zip'];

const RTF = ['rtf', 'application/rtf'];

const NC = ['nc', 'application/octet-stream', 'octet-stream'];

export const Extensions = {
  PDF: PDF,
  Image: Image,
  QDOC: QDOC,
  MSDoc: MSDOC,
  Video: Video,
  Audio: Audio,
  Word,
  Excel,
  PowerPoint,
  RTF,
  ZIP,
  TIFF,
  NC,
};

export const AcceptedFormats = [...PDF, ...Image, ...MSDOC, ...Video, ...Audio, ...TIFF, ...ZIP, ...NC];
