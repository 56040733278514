import React, { useEffect, useState } from 'react';

import {
  Box,
  Grid,
  IconButton,
  Button,
  Typography,
  Paper,
  DialogContent,
  DialogTitle,
  CardActionArea,
} from '@mui/material';
import { ArrowForwardIosRounded, Delete } from '@mui/icons-material';
import { RoundedDialog, StandardDialogActions, Document } from '../../../../../../Global/Components';
import { grey } from '@mui/material/colors';
import { COLORS } from '../../../../constants';
import LinkIcon from '@mui/icons-material/Link';
import StateManager from '../../../../../../Global/StateManager';
import UserDLTree from '../../../../../../Global/DLTree/user';
import { FormIcon, ProcessIcon, DocIcon, RegistersIcon } from '../../../../../../Global/Icons';
import SearchItem from './search';
import { DataGridPro } from '@mui/x-data-grid-pro';

const hubs = [
  { value: 'document', icon: <DocIcon />, title: 'Link a Document' },
  { value: 'form', icon: <FormIcon />, title: 'Link a Form', url: '/forms/search/getSearchForms' },
  { value: 'process', icon: <ProcessIcon />, title: 'Link a Process', url: '/process/search/getSearchProcesses' },
  { value: 'register', icon: <RegistersIcon />, title: 'Link a Register', url: '/registers/general/search' },
];

export default function RefLinkDialog({ open, handleClose, handleCreate, initial }) {
  const [result, setResult] = useState([]);
  const [selected, setSelected] = useState([]);
  const [deletedItems, setDeletedItems] = useState([]);

  const [option, setOption] = useState(null);
  const [tab, setTab] = useState(0);

  const onCreateLinks = () => {
    if (result?.length === 0 && deletedItems?.length === 0) {
      StateManager.setErrorAlert('Please select items to link to');
      return;
    }
    const newItems = result.filter((x) => x.isNew);
    const data = {
      new: newItems,
      deleted: deletedItems,
    };
    handleCreate(data);
  };

  const handleItems = (data, type) => {
    setSelected([
      ...data.map((x) => ({
        title: type === 'document' ? '' : x.title,
        targetType: type,
        targetItem: type === 'document' ? x : x._id,
        selectionType: 'full',
        isNew: true,
      })),
    ]);
  };

  const onAdd = () => {
    setResult([...result, ...selected]);
    setSelected([]);
    setTab(0);
    setOption(null);
  };

  const columns = [
    {
      field: 'title',
      headerName: 'Title',
      renderCell: (params) => {
        if (params.row.targetType === 'document') {
          return <Document id={params.row.targetItem} fullWidth avatarSize={16} />;
        } else {
          return params.row.title;
        }
      },
      flex: 1,
    },
    {
      field: 'targetType',
      headerName: 'Link Type',
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        let icon = null;
        if (params.row.targetType === 'document') {
          icon = <DocIcon />;
        } else if (params.row.targetType === 'form') {
          icon = <FormIcon />;
        } else if (params.row.targetType === 'process') {
          icon = <ProcessIcon />;
        } else if (params.row.targetType === 'register') {
          icon = <RegistersIcon />;
        }
        return icon;
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      headerAlign: 'center',
      align: 'center',
      width: 150,
      type: 'actions',
      renderCell: (params) => (
        <>
          <IconButton
            color="error"
            onClick={() => {
              if (params.row._id && !params.row.isNew) {
                if (!deletedItems.includes(params.row._id)) {
                  setDeletedItems((prev) => [...prev, params.row._id]);
                }
                setResult((prev) => prev.filter((x) => x._id !== params.row._id));
                return;
              }
              setSelected((prev) => prev.filter((x) => x !== params.row.targetItem));
            }}
          >
            <Delete />
          </IconButton>
        </>
      ),
    },
  ];

  useEffect(() => {
    if (!open) return;
    setSelected([]);
    setDeletedItems([]);
    setOption(null);
    setTab(0);
    setResult(initial?.map((x) => ({ ...x, isNew: false })) || []);
  }, [initial, open]);

  return (
    <RoundedDialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle>
        <Grid container direction={'row'} justifyContent="space-between" alignItems={'center'}>
          <Grid item xs={6} sm={6}>
            <Typography variant={'h6'} sx={{ fontWeight: 500 }}>
              Manage Links
            </Typography>
          </Grid>
          <Grid item xs={6} sm={1} align="right">
            <LinkIcon sx={{ color: COLORS.SEARCH }} />
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container>
          {hubs.map((hub) => (
            <Grid container key={hub.value} lg={6} md={6} xs={12} item style={{ padding: 8 }}>
              <CardActionArea
                sx={{
                  borderRadius: 1,
                  width: '100%',
                  padding: 0,
                }}
                onClick={() => {
                  setOption(hub);
                  setTab(1);
                }}
              >
                <Paper
                  variant="outlined"
                  sx={{
                    borderRadius: 1,
                    padding: (theme) => theme.spacing(1, 2),
                    border: `2px solid ${grey[300]}`,
                    width: '100%',
                  }}
                >
                  <Grid container alignItems="center">
                    {hub.icon}
                    <Typography style={{ margin: '0px 16px' }}>{hub.title}</Typography>
                    <ArrowForwardIosRounded color="primary" style={{ marginLeft: 'auto' }} />
                  </Grid>
                </Paper>
              </CardActionArea>
            </Grid>
          ))}
        </Grid>
        <Grid container sx={{ my: 2 }}>
          {option && option?.value === 'document' && tab === 1 && (
            <Grid container>
              <UserDLTree handleConfirm={(x) => handleItems(x, 'document')} />
            </Grid>
          )}
          {option && option?.value !== 'document' && tab === 1 && (
            <Grid container>
              <SearchItem
                url={option?.url}
                title={`Search ${option.value}`}
                icon={option?.icon}
                onResult={(x) => handleItems(x, option.value)}
              />
            </Grid>
          )}
          {tab === 1 && selected?.length > 0 && (
            <Grid container justifyContent="flex-end" sx={{ my: 2 }}>
              <Button variant="contained" color="primary" onClick={onAdd}>
                Add
              </Button>
            </Grid>
          )}
        </Grid>
        <Grid container>
          <DataGridPro
            rows={result}
            columns={columns}
            getRowId={(row) => row.targetItem}
            pageSize={10}
            autoHeight={true}
            onCellClick={(params) => {
              if (params.field === 'title' && params.row.targetType !== 'document') {
                window.open(
                  `/${params.row.targetType}${params.row.targetType === 'process' ? 'es' : 's'}/${
                    params.row.targetItem
                  }`,
                  '_blank',
                );
              }
            }}
          />
        </Grid>
      </DialogContent>
      <StandardDialogActions
        onClose={handleClose}
        onDone={onCreateLinks}
        doneButtonText={'Confirm'}
        hideDone={(result?.length === 0 && deletedItems?.length === 0) || selected?.length > 0}
      />
    </RoundedDialog>
  );
}
