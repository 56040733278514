import React, { useEffect, useState, useRef } from 'react';
import {
  RoundedDialog,
  GeneralButton,
  UserChip,
  GroupChip,
  StandardDialogActions,
} from '../../../../Global/Components';
import { Alert, AlertTitle, TextField, useTheme } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import { Box, useMediaQuery, Grid, DialogTitle, Typography } from '@mui/material';
import { blue, grey } from '@mui/material/colors';
import { ReadUnderstoodIcon } from '../../../../Global/Icons';
import { PersonRounded } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import { Autocomplete } from '@mui/material';
import DueDate from '../DueDate';
import StateManager from '../../../../Global/StateManager';
import { RenderOption } from '../ViewDialog/components';
import ExternalUsersDialog from '../../../../Global/ExternalUsersDialog';
import axios from 'axios';
import { COLORS } from '../../constants';
import FileIconAvatar from '../FileIconAvatar';
import CloseRounded from '@mui/icons-material/CloseRounded';
import { isDev } from '../../../../../constants';

const SEARCH_TIMEOUT = 500;

const SendRUTaskDialog = ({ open, handleClose, handleConfirm, title, documentSelected = true }) => {
  const theme = useTheme();
  const largeDevices = useMediaQuery(theme.breakpoints.up('sm'));

  const timer = useRef(null);

  const [users, setUsers] = useState([]);
  const [groups, setGroups] = useState([]);
  const [dueDate, setDueDate] = useState(new Date());
  const [document, setDocument] = useState('');
  const [openExternUserDialog, setOpenExternUserDialog] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleDone = () => {
    if (users.length === 0 && groups.length === 0)
      return StateManager.setErrorAlert('Please select users or groups to send the task to.');
    if (documentSelected) {
      handleConfirm({ users, dueDate, groups });
    } else {
      handleConfirm({ users, dueDate, document, groups });
    }
    handleClose();
  };

  const handleCloseDialog = () => {
    setOpenExternUserDialog(false);
  };

  function addUsers() {
    StateManager.selectMultipleUsers(
      (res) => {
        if (res.users) {
          setUsers(res.users);
        }
        if (res.groups) {
          setGroups(res.groups);
        }
      },
      { initiallySelected: users, showGroups: true },
    );
  }

  useEffect(() => {
    if (!open) return;
    setUsers([]);
    setGroups([]);
    setDueDate(new Date());
    setDocument('');
  }, [open]);

  function activateTimer(query) {
    if (timer.current != null) {
      clearTimeout(timer.current);
    }
    timer.current = setTimeout(handleSearchDocument, SEARCH_TIMEOUT, query);
  }

  const handleSearchDocument = async (searchInput) => {
    if (searchInput.length >= 1) {
      try {
        setLoading(true);
        const response = await axios.get(`/dochub/search?query=${searchInput}`);
        setDocuments(response.data || []);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        StateManager.setAxiosErrorAlert(error);
      }
    }
  };

  return (
    <RoundedDialog
      maxWidth="sm"
      fullWidth
      open={open}
      onClose={handleClose}
      className="scroll-bar"
      fullScreen={!largeDevices}
      keepMounted
      elevation={26}
      titleId={'ru-task-dialog-title'}
    >
      <Box sx={{ padding: 1.5 }}>
        <DialogTitle>
          <Grid item sx={{ display: 'flex' }}>
            <Grid container>
              <ReadUnderstoodIcon style={{ width: 32, height: 32, color: blue[900] }} />
              <Typography sx={{ fontSize: 20, margin: (theme) => theme.spacing(0, 2) }}>
                Send Read and understood task
              </Typography>
            </Grid>
            <CloseRounded
              onClick={handleClose}
              style={{ marginTop: (theme) => theme.spacing(-1.5), color: grey[500], cursor: 'pointer' }}
            />
          </Grid>
        </DialogTitle>

        <DialogContent id="dialog-content" sx={{ padding: (theme) => theme.spacing(2) }}>
          <Grid container direction="column">
            {title && (
              <Alert
                severity="info"
                icon={<FileIconAvatar fontSize="inherit" />}
                style={{ width: '100%', borderRadius: 8 }}
              >
                <AlertTitle>{title}</AlertTitle>
              </Alert>
            )}
            {!documentSelected && (
              <Grid container item alignItems="center" spacing={1} sx={{ padding: (theme) => theme.spacing(2, 0) }}>
                <Grid container item alignItems="center" lg={2} md={2} sm={12} justifyContent="space-between">
                  <Typography sx={{ fontWeight: 600 }} noWrap>
                    Document:
                  </Typography>
                </Grid>
                <Grid container item alignItems="center" lg={10} md={10} sm={12}>
                  <Autocomplete
                    autoHighlight
                    id="document-select"
                    placeholder="Choose document"
                    loadingText={'Loading Documents'}
                    sx={{ width: 450 }}
                    options={documents}
                    getOptionLabel={(option) => (option ? option?.title : '')}
                    onInputChange={(event, newInputValue) => {
                      activateTimer(newInputValue);
                    }}
                    isOptionEqualToValue={(option, value) => option?._id === value?._id}
                    loading={loading}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <RenderOption option={option} selected={selected} field={'title'} />
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        label="Choose"
                        variant="outlined"
                        placeholder="Choose document"
                        InputProps={{
                          ...params.InputProps,
                          autoComplete: 'new-password',
                          startAdornment: <SearchIcon sx={{ color: COLORS.MAINBLUE }} fontSize="small" />,
                        }}
                      />
                    )}
                    value={document}
                    onChange={(e, value) => setDocument(value)}
                  />
                </Grid>
              </Grid>
            )}
            <Grid container item alignItems="center" spacing={1} sx={{ padding: (theme) => theme.spacing(2, 0) }}>
              <Grid container item alignItems="center" sm={12}>
                <Typography sx={{ fontWeight: 600, marginRight: '1rem' }} noWrap>
                  Send to:
                </Typography>

                <GeneralButton
                  onClick={addUsers}
                  sx={{ margin: theme.spacing(1), width: 120 }}
                  className={'selectUser'}
                  startIcon={<PersonRounded sx={{ color: grey[500] }} />}
                >
                  Add users
                </GeneralButton>
                {isDev && (
                  <GeneralButton
                    onClick={() => setOpenExternUserDialog(true)}
                    sx={{ margin: theme.spacing(1), width: 180 }}
                    className={'selectUser'}
                    startIcon={<PersonRounded sx={{ color: grey[500] }} />}
                  >
                    Add external users
                  </GeneralButton>
                )}
                {users.map((user) => (
                  <UserChip key={user} id={user} onDelete={() => setUsers(users.filter((x) => x !== user))} />
                ))}
                {groups.map((id) => (
                  <GroupChip key={id} id={id} onDelete={() => setGroups(groups.filter((x) => x !== id))} />
                ))}
              </Grid>
            </Grid>

            <Grid container item spacing={1} alignItems="flex-end">
              <Grid container item sm={12} className={'dueDate'}>
                <DueDate editable={true} value={dueDate} setValue={setDueDate} />
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <StandardDialogActions
          onClose={handleClose}
          doneButtonText={'Confirm'}
          closeButtonText={'Cancel'}
          onDone={handleDone}
        />
        <ExternalUsersDialog open={openExternUserDialog} onClose={handleCloseDialog} showGroups />
      </Box>
    </RoundedDialog>
  );
};

export default SendRUTaskDialog;
