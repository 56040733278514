import React, { useEffect, useState, useRef } from 'react';
import { Grid, Typography, TextField, CircularProgress } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { AssetIcon } from '../Icons';
import Uploader from '../Uploader';
import { DatePicker } from '../Components';
import { isFunction, isEmpty } from 'lodash';
import { FormatOnlyDate } from '../Functions';
import FileViewer from '../FileViewer';
import axios from 'axios';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { blue } from '@mui/material/colors';

export default function AssetIncident({ initial, editable, onResult }) {
  const timer = useRef(null);
  const SAVING_TIMEOUT = 1200;
  const [assets, setAssets] = useState([]);
  const [searchOpen, setSearchOpen] = useState(false);
  const [selected, setSelected] = useState(null);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [attachments, setAttachments] = useState([]);
  const [dueAt, setDueAt] = useState(null);

  const loadAssets = searchOpen && assets.length === 0 && editable;

  useEffect(() => {
    if (!initial) return;
    setSelected(initial.asset || null);
    setTitle(initial.title || '');
    setDescription(initial.description || '');
    setAttachments(initial.attachments || []);
    setDueAt(initial.dueAt || null);
  }, [initial]);

  function triggerChange(value) {
    if (!isFunction(onResult) || !editable) return;
    if (timer.current != null) {
      clearTimeout(timer.current);
    }
    timer.current = setTimeout(
      () => onResult({ asset: selected, title, description, attachments, dueAt, ...value }),
      SAVING_TIMEOUT,
    );
  }

  useEffect(() => {
    if (!loadAssets) return;

    axios
      .get('/asset/search/getSearchAssets')
      .then((res) => {
        setAssets(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [loadAssets]);

  if (!editable && (!initial?.asset?._id || !initial?.title)) {
    return (
      <Grid container item>
        <Typography color="textSecondary">No incidents created</Typography>
      </Grid>
    );
  }

  return (
    <Grid container spacing={2}>
      <Grid container item>
        {editable ? (
          <Autocomplete
            options={assets}
            style={{ width: '100%' }}
            getOptionLabel={(option) => option?.title || null}
            openOnFocus
            renderOption={(props, option) => (
              <li {...props}>
                <Grid container alignItems="center">
                  <AssetIcon />
                  <Typography style={{ marginLeft: '1rem' }} noWrap>
                    {option.title}
                  </Typography>
                </Grid>
              </li>
            )}
            renderInput={(params) => (
              <TextField
                variant="outlined"
                {...params}
                label="Select an asset"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {loadAssets ? <CircularProgress color="primary" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
            onChange={(e, value) => {
              if (!e) return;
              setSelected(value);
              triggerChange({ asset: value });
            }}
            open={searchOpen}
            onOpen={() => setSearchOpen(true)}
            onClose={() => setSearchOpen(false)}
            loading={loadAssets}
            value={selected}
          />
        ) : (
          <Link to={`/asset/${initial.asset?._id}`}>
            <Typography variant="h6" style={{ cursor: 'pointer', color: blue[700] }}>
              Asset: {initial.asset?.title}
            </Typography>
          </Link>
        )}
      </Grid>
      <Grid container item>
        {editable ? (
          <TextField
            variant="outlined"
            value={title}
            onChange={(e) => {
              setTitle(e.target.value);
              triggerChange({ title: e.target.value });
            }}
            fullWidth
            label={'Incident title'}
            required
          />
        ) : (
          <Typography variant="h6">{initial.title}</Typography>
        )}
      </Grid>
      <Grid container item>
        {editable ? (
          <TextField
            multiline
            variant="outlined"
            value={description}
            onChange={(e) => {
              setDescription(e.target.value);
              triggerChange({ description: e.target.value });
            }}
            fullWidth
            inputProps={{ maxLength: 2048 }}
            minRows={5}
            maxRows={10}
            label={'Incident description'}
          />
        ) : (
          <Typography>{initial.description}</Typography>
        )}
      </Grid>
      {(!isEmpty(initial?.attachments) || editable) && (
        <Grid container item>
          {editable && (
            <Grid container item>
              <Typography variant="h6" gutterBottom>
                Attached:
              </Typography>
            </Grid>
          )}

          <Grid container item>
            {editable ? (
              <Uploader
                uploaded={attachments}
                onChange={(files) => {
                  setAttachments(files);
                  triggerChange({ attachments: files });
                }}
              />
            ) : (
              <FileViewer files={initial.attachments} />
            )}
          </Grid>
        </Grid>
      )}
      <Grid container item>
        <Grid container item>
          <Typography variant={editable ? 'h6' : undefined} gutterBottom>
            Due date: {editable ? '' : FormatOnlyDate(initial.dueAt)}
          </Typography>
        </Grid>

        {editable && (
          <Grid container item>
            <DatePicker
              label="Incident due"
              value={dueAt}
              onChange={(date) => {
                setDueAt(moment(date).endOf('day').toDate());
                triggerChange({ dueAt: date });
              }}
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}
