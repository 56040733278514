import React, { useState, useEffect } from 'react';
import { Typography, Grid, CircularProgress, Chip, Menu, Box, useTheme } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterMoment } from '@mui/x-date-pickers-pro/AdapterMoment';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { Refresh, FilterList, FiberManualRecord, Whatshot } from '@mui/icons-material';
import { CancelOutlined, CheckCircle, List, ArchiveOutlined } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import axios from 'axios';
import { grey, blue, green, red } from '@mui/material/colors';
import TicketDialog from '../../Support/components/TicketDialog';
import SupportDialog from '../../Support/components/SupportDialog';
import { GeneralButton, HeaderButton, CheckboxWithIcon, ClearFilter } from '../Components';
import PageHeader from '../PageHeader';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { statusColors, typeColors, statuses, types, priorityColors, priorities, hubs } from '../../Support/constants';
import { useSelector } from 'react-redux';

export default function TicketManager({ ticketList, refresh, loading, setLoading }) {
  const { user } = useSelector(({ profile }) => profile);
  const theme = useTheme();
  const history = useHistory();

  // spinner
  const [ticketLoading, setTicketLoading] = useState(true);
  const [tickets, setTickets] = useState([]);

  const [selectedTicket, setSelectedTicket] = useState();
  const [openTicket, setOpenTicket] = useState(false);

  const [active, setActive] = useState(true);
  const [closed, setClosed] = useState(false);
  const [canceled, setCanceled] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [pattern, setPattern] = useState(false);
  const [typeMenu, setTypeMenu] = useState();
  const [typesFilter, setTypesFilter] = useState({});

  const [statusMenu, setStatusMenu] = useState();
  const [statusFilter, setStatusFilter] = useState({});

  const [priorityMenu, setPriorityMenu] = useState();
  const [priorityFilter, setPriorityFilter] = useState({});

  const [hubMenu, setHubMenu] = useState();
  const [hubFilter, setHubFilter] = useState({});

  const [newTicketOpen, setNewTicketOpen] = useState(false);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);

  useEffect(() => {
    setIsSuperAdmin(user?.isSuperAdmin);
    if (ticketList) {
      setTickets(ticketList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticketList]);

  function refreshPage(spinner = false) {
    if (spinner) {
      setRefreshing(true);
    }
    refresh();
    setRefreshing(false);
  }

  async function getTicket(ticket) {
    setTicketLoading(true);
    await axios
      .get(`/support/getTicket?ticketID=${ticket.id}`)
      .then((res) => {
        setSelectedTicket({ ...ticket, ...res.data[0] });
        setOpenTicket(true);
        setTicketLoading(false);
      })
      .catch((err) => {
        setTicketLoading(false);
        console.error(err);
      });
  }

  function formatDate(date) {
    const dt = moment(date);
    if (dt.isSame(moment(), 'day')) {
      // if today, only time
      return dt.format('hh:mm A');
    }
    return dt.format('DD/MM/YYYY');
  }

  function isTypeClicked() {
    return Object.values(typesFilter).indexOf(true) > -1;
  }

  function isStatusClicked() {
    return Object.values(statusFilter).indexOf(true) > -1;
  }

  function isPriorityClicked() {
    return Object.values(priorityFilter).indexOf(true) > -1;
  }

  function isHubClicked() {
    return Object.values(hubFilter).indexOf(true) > -1;
  }

  function filterTickets() {
    return tickets
      ? tickets
          .filter((ticket) => !active || (!ticket.closedAt && !ticket.canceledAt))
          .filter((ticket) => !closed || ticket.closedAt)
          .filter((ticket) => !canceled || ticket.canceledAt)
          .filter((ticket) => !pattern || Object.values(ticket).join(' ').toLowerCase().includes(pattern))
          .filter((ticket) => !isTypeClicked() || typesFilter[ticket.type])
          .filter((ticket) => !isStatusClicked() || statusFilter[ticket.status])
          .filter((ticket) => {
            const taskPriority = ticket.priority || 'None'; // Should be deprecated after all existing tickets are prioritized
            return !isPriorityClicked() || priorityFilter[taskPriority];
          })
          .filter((ticket) => !isHubClicked() || hubFilter[ticket.hub])
      : [];
  }

  function onTicketSave(ticket) {
    if (ticket?._id) {
      let index = tickets.findIndex((t) => t._id === ticket._id);
      if (index > -1) {
        tickets[index] = { ...tickets[index], ...ticket };
        setTickets([...tickets]);
      }
    }
  }
  // if (loading) {
  //   return (
  //     <Grid alignItems="center" justifyContent="center" container style={{ height: '70vh' }}>
  //       <CircularProgress style={{ color: blue[500] }} />
  //     </Grid>
  //   );
  // }
  //prettier-ignore
  const columns = [
    { field: 'title', headerName: 'Title', flex: 2 },
    {
      field: 'createdAt',
      headerName: 'Date Raised',
      type: 'date',
      valueFormatter: (params) => formatDate(params.value),
    },
    isSuperAdmin ? { field: 'companyName', headerName: 'Account Name', flex: 1 } : null,
    isSuperAdmin || user?.access === 'admin' ? { field: 'userRaised', headerName: 'User Raised', flex: 1 } : null,
    {
      field: 'type',
      headerName: 'Type',
      flex: 1,
      renderCell: (params) => renderTypeChip(params),
    },
    {
      field: 'priority',
      headerName: 'Priority',
      flex: 0.7,
      renderCell: (params) => (
        <Grid container alignItems={'center'}>
          <Whatshot style={{ color: priorityColors[params.value] || 'None' }} />
          <Box component={'div'}>{params.value || 'None'}</Box>
        </Grid>
      ),
    },
    { field: 'hub', headerName: 'Hub', flex: 0.6 },
    { field: 'status', headerName: 'Status', flex: 0.8, renderCell: (params) => renderStatusChip(params) },
    isSuperAdmin ? { field: 'QHubTaskStatus', headerName: 'Q-Hub Task', flex: 1, renderCell: (params) => renderStatusChip(params) } : null,
    canceled
      ? {
          field: 'canceledAt',
          headerName: 'Canceled At',
          type: 'date',
          valueFormatter: (params) => formatDate(params.value),
        }
      : closed
      ? {
          field: 'closedAt',
          headerName: 'Closed At',
          type: 'date',
          valueFormatter: (params) => formatDate(params.value),
        }
      : null,
  ].filter(Boolean);

  const NoticeComponent = () => {
    return (
      <Grid container item alignContent={'center'} height={'100%'} width={'50%'} marginX={'auto'}>
        <Typography variant="h5" component="h2" marginBottom={1}>
          Support Ticket Types Overview
        </Typography>
        <Typography variant="p">
          <b>Report a Bug:</b> Use this type to inform about errors, glitches, or unexpected behaviors in the system.
          This helps developers identify and fix issues to improve the product's stability.
        </Typography>
        <Typography variant="p">
          <b>Suggest Improvement:</b> Submit ideas or suggestions for enhancing the system's features, design, or
          functionality. This feedback is valuable for continuous improvement and innovation.
        </Typography>
        <Typography variant="p">
          <b>Ask for Help:</b> Use this option to seek assistance or clarification on using the system. It can include
          questions about features, troubleshooting, or guidance on best practices.
        </Typography>
      </Grid>
    );
  };

  function renderStatusChip(params) {
    const color = statusColors[params.value];
    return (
      <Chip
        label={params.value || 'Not created'}
        size="small"
        style={{
          backgroundColor: statuses.includes(params.value) ? color : grey[500],
          color: statuses.includes(params.value) ? '#000000' : '#FFFFFF',
        }}
        variant="outlined"
      />
    );
  }

  function renderTypeChip(params) {
    const color = typeColors[params.value];
    return (
      <Chip
        label={params.value}
        size="small"
        style={{
          backgroundColor: color,
          color: '#000000',
        }}
        variant="outlined"
      />
    );
  }
  const archiveAccess = isSuperAdmin ? '/superadmin/tickets/archive' : '/admin/tickets/archive';
  const newTicketButton = <HeaderButton icon={<AddIcon />} text="New Ticket" onClick={() => setNewTicketOpen(true)} />;
  const archiveButton = (
    <HeaderButton icon={<ArchiveOutlined />} text="Archive" onClick={() => history.push(archiveAccess)} />
  );
  return (
    <Grid container direction={'column'} wrap="nowrap" alignContent={'flex-start'} sx={{ height: '100%' }}>
      <Grid container item>
        <PageHeader
          title="Tickets"
          onSearch={setPattern}
          options={
            <>
              {newTicketButton}
              {isSuperAdmin || user?.access === 'admin' ? archiveButton : null}
            </>
          }
        />
      </Grid>
      <Grid container item style={{ margin: theme.spacing(1, 0) }}>
        <GeneralButton
          startIcon={<List />}
          sx={active ? { borderColor: '#517ee8', fontWeight: 700 } : ''}
          onClick={() => {
            setActive(true);
            setClosed(false);
            setCanceled(false);
          }}
        >
          Active
        </GeneralButton>
        <GeneralButton
          startIcon={<CancelOutlined style={{ color: red[500] }} />}
          sx={canceled ? { borderColor: '#517ee8', fontWeight: 700 } : ''}
          style={{ marginLeft: theme.spacing(2) }}
          onClick={() => {
            setClosed(false);
            setActive(false);
            setCanceled(true);
          }}
        >
          Cancelled
        </GeneralButton>
        <GeneralButton
          startIcon={<CheckCircle style={{ color: green[500] }} />}
          sx={closed ? { borderColor: '#517ee8', fontWeight: 700 } : ''}
          style={{ marginLeft: theme.spacing(2) }}
          onClick={() => {
            setClosed(true);
            setCanceled(false);
            setActive(false);
          }}
        >
          Closed
        </GeneralButton>
        <GeneralButton
          startIcon={<Refresh />}
          disabled={loading}
          style={{ marginLeft: theme.spacing(2) }}
          onClick={refreshPage}
        >
          Refresh
        </GeneralButton>

        <GeneralButton
          startIcon={<FilterList style={{ color: '#517ee8' }} />}
          style={{ marginLeft: theme.spacing(2) }}
          onClick={(event) => setTypeMenu(event.currentTarget)}
          sx={isTypeClicked() ? { borderColor: '#517ee8', fontWeight: 700 } : ''}
        >
          Type
        </GeneralButton>

        <Menu anchorEl={typeMenu} open={Boolean(typeMenu)} onClose={() => setTypeMenu(null)}>
          {types.map((type, i) => (
            <CheckboxWithIcon
              key={i}
              checked={Boolean(typesFilter[type.text])}
              onChange={() => {
                typesFilter[type.text] = !Boolean(typesFilter[type.text]);
                setTypesFilter({ ...typesFilter });
              }}
              text={type.text}
              icon={<FiberManualRecord style={{ color: typeColors[type.text] || blue[500] }} />}
            />
          ))}
          <ClearFilter
            onClick={() => {
              setTypesFilter({});
              setTypeMenu(null);
            }}
          />
        </Menu>
        <GeneralButton
          startIcon={<FilterList style={{ color: '#517ee8' }} />}
          style={{ marginLeft: theme.spacing(2) }}
          onClick={(event) => setPriorityMenu(event.currentTarget)}
          sx={isPriorityClicked() ? { borderColor: '#517ee8', fontWeight: 700 } : ''}
        >
          Priority
        </GeneralButton>
        <Menu anchorEl={priorityMenu} open={Boolean(priorityMenu)} onClose={() => setPriorityMenu(null)}>
          {priorities.map((priority) => (
            <CheckboxWithIcon
              key={priority}
              checked={Boolean(priorityFilter[priority])}
              onChange={() => {
                priorityFilter[priority] = !Boolean(priorityFilter[priority]);
                setPriorityFilter({ ...priorityFilter });
              }}
              text={priority}
              icon={<FiberManualRecord style={{ color: priorityColors[priority] || blue[500] }} />}
            />
          ))}
          <ClearFilter
            onClick={() => {
              setPriorityFilter({});
              setPriorityMenu(null);
            }}
          />
        </Menu>

        <GeneralButton
          startIcon={<FilterList style={{ color: '#517ee8' }} />}
          style={{ marginLeft: theme.spacing(2) }}
          onClick={(event) => setHubMenu(event.currentTarget)}
          sx={isHubClicked() ? { borderColor: '#517ee8', fontWeight: 700 } : ''}
        >
          Hub
        </GeneralButton>

        <Menu anchorEl={hubMenu} open={Boolean(hubMenu)} onClose={() => setHubMenu(null)}>
          {hubs.map((hub) => (
            <CheckboxWithIcon
              key={hub}
              checked={Boolean(hubFilter[hub])}
              onChange={() => {
                hubFilter[hub] = !Boolean(hubFilter[hub]);
                setHubFilter({ ...hubFilter });
              }}
              text={hub}
            />
          ))}
          <ClearFilter
            onClick={() => {
              setPriorityFilter({});
              setPriorityMenu(null);
            }}
          />
        </Menu>

        <GeneralButton
          startIcon={<FilterList style={{ color: '#517ee8' }} />}
          style={{ marginLeft: theme.spacing(2) }}
          onClick={(event) => setStatusMenu(event.currentTarget)}
          sx={isStatusClicked() ? { borderColor: '#517ee8', fontWeight: 700 } : ''}
        >
          Status
        </GeneralButton>
        <Menu anchorEl={statusMenu} open={Boolean(statusMenu)} onClose={() => setStatusMenu(null)}>
          {statuses.map((status, i) => (
            <CheckboxWithIcon
              key={status + i}
              checked={Boolean(statusFilter[status])}
              onChange={() => {
                statusFilter[status] = !Boolean(statusFilter[status]);
                setStatusFilter({ ...statusFilter });
              }}
              text={status}
              icon={<FiberManualRecord style={{ color: statusColors[status] || blue[500] }} />}
            />
          ))}
          <ClearFilter
            onClick={() => {
              setStatusFilter({});
              setStatusMenu(null);
            }}
          />
        </Menu>
      </Grid>
      {canceled && (
        <Grid container item>
          <Typography component={'p'}>Cancelled tickets will be archived 30 days after canceling</Typography>
        </Grid>
      )}
      {closed && (
        <Grid container item>
          <Typography>Closed tickets will be archived 30 days after closing</Typography>
        </Grid>
      )}
      {refreshing && (
        <Grid container item style={{ margin: theme.spacing(1, 0) }} justifyContent="center">
          <CircularProgress style={{ color: blue[500] }} />
        </Grid>
      )}
      <Grid container sx={{ flex: 1, position: 'relative' }}>
        <Grid container sx={{ height: '100%', position: 'absolute' }}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DataGridPro
              loading={loading}
              columns={columns}
              rows={filterTickets(tickets)}
              getRowId={(row) => row._id}
              onRowClick={(ticket) => getTicket(ticket)}
              autoPageSize={true}
              components={{
                NoRowsOverlay: NoticeComponent,
              }}
              disableColumnSelector
              disableDensitySelector
              disableRowSelectionOnClick
            ></DataGridPro>
          </LocalizationProvider>
        </Grid>
      </Grid>

      <TicketDialog
        ticket={selectedTicket}
        open={openTicket}
        loading={ticketLoading}
        onClose={() => setOpenTicket(false)}
        fromAdmin={isSuperAdmin}
        fromCompanyAdmin={user?.access === 'admin'}
        onSave={onTicketSave}
      />
      <SupportDialog
        open={newTicketOpen}
        fromAdmin={isSuperAdmin}
        onClose={(ticket) => {
          setNewTicketOpen(false);
          ticket ? setTickets((prev) => [ticket, ...prev]) : null; //Check if ticket was created or window closed
        }}
        disableLink
      />
    </Grid>
  );
}
