import React, { useState, useMemo } from 'react';
import { Typography, Button, Grid, Paper, ListItemButton, Chip, Collapse, CircularProgress } from '@mui/material';
import { isEmpty, isArray, isFunction } from 'lodash';
import { ExpandMoreRounded, DownloadRounded, DoneRounded } from '@mui/icons-material';
import FileViewer from '../../../Global/FileViewer';
import Uploader from '../../../Global/Uploader';
import StateManager from '../../../Global/StateManager';
import { BASE_URL } from '../../../../constants';
import { Action } from '../../../Global/Fields/ActionGroup';
import axios from 'axios';
import moment from 'moment';
import { blue, green, red } from '@mui/material/colors';

export default function ActionsBar({ actions, executedActions, onDelete, editable, type, entryId }) {
  const [expanded, setExpanded] = useState(false);
  const [downloading, setDownloading] = useState(false);

  const { totalActions, allActions, completedCount, overdueCount } = useMemo(() => {
    const actionsArray = isArray(actions) ? actions : [];
    const executedActionsArray = isArray(executedActions) ? executedActions : [];
    const all = [...actionsArray, ...executedActionsArray];
    const completed = all.filter(isActionCompleted);
    const overdue = all.filter(isActionOverdue);
    return {
      totalActions: actionsArray.length + executedActionsArray.length,
      allActions: all,
      completedCount: completed.length,
      overdueCount: overdue.length,
    };
  }, [actions, executedActions]);

  function downloadAll() {
    if (downloading) return;
    console.log('type');
    if (type === 'process') {
      setDownloading(true);
      axios
        .get('/process/processActionsToExcel', { params: { ongoingProcessId: entryId } })
        .then((res) => {
          window.open(`${BASE_URL}${res.data.link}`, '_blank');
          setDownloading(false);
        })
        .catch((err) => {
          StateManager.setAxiosErrorAlert(err);
          setDownloading(false);
        });
    }

    if (type === 'form') {
      setDownloading(true);
      axios
        .get('/forms/entries/formActionsToExcel', { params: { entryId } })
        .then((res) => {
          window.open(`${BASE_URL}${res.data.link}`, '_blank');
          setDownloading(false);
        })
        .catch((err) => {
          StateManager.setAxiosErrorAlert(err);
          setDownloading(false);
        });
    }
  }

  function isActionCompleted(action) {
    if (action.type === 'form' || action.type === 'process') {
      return Boolean(action.completedAt);
    }

    if (action.type === 'task') {
      return Boolean(action.completedDate);
    }

    if (action.type === 'register') {
      return true;
    }

    return false;
  }

  function isActionOverdue(action) {
    if (action.type === 'form') {
      return !action.completedAt && action.dueAt && moment(action.dueAt) < moment();
    }

    if (action.type === 'process') {
      return !action.completedAt && action.currStepDue && moment(action.currStepDue) < moment();
    }

    if (action.type === 'task') {
      return !action.completedDate && action.dueAt && moment(action.dueAt) < moment();
    }

    return false;
  }

  const expandable = totalActions > 0;

  return (
    <Grid container component={Paper} elevation={3} sx={{ borderRadius: 2, my: 1 }}>
      <Grid
        container
        component={expandable ? ListItemButton : undefined}
        onClick={() => setExpanded(!expanded)}
        sx={{ p: 2, borderRadius: 2 }}
        alignItems={'center'}
      >
        <Typography variant="h6">Actions:</Typography>
        <Chip sx={{ ml: 2 }} label={`Total: ${totalActions}`} />
        {totalActions > 0 ? (
          completedCount === totalActions ? (
            <Chip icon={<DoneRounded />} sx={{ ml: 2, background: green[500] }} label="Completed" />
          ) : (
            <>
              <Chip
                sx={{ ml: 2, background: blue[500], color: 'white' }}
                color="primary"
                label={`Completed: ${completedCount}`}
              />
              {overdueCount > 0 && (
                <Chip sx={{ ml: 2, background: red[500], color: 'white' }} label={`Overdue: ${overdueCount}`} />
              )}
            </>
          )
        ) : null}

        {expandable && (
          <Grid
            item
            container
            aria-expanded={expanded}
            alignItems={'center'}
            justifyContent={'center'}
            sx={{
              transform: 'rotate(0deg)',
              transition: (theme) =>
                theme.transitions.create('transform', {
                  duration: theme.transitions.duration.shortest,
                }),
              transform: expanded ? 'rotate(180deg)' : null,
              width: '35px',
              height: '35px',
              ml: 'auto',
            }}
          >
            <ExpandMoreRounded />
          </Grid>
        )}
      </Grid>
      {expandable && (
        <Grid container component={Collapse} in={expanded} mountOnEnter>
          <Grid container sx={{ p: 2 }}>
            {allActions.map((action, index) => (
              <Action key={index} initial={action} history={history} onDelete={onDelete} editable={editable} />
            ))}
          </Grid>
          {totalActions > 0 && (
            <Grid container sx={{ p: 2 }}>
              <Button
                onClick={downloadAll}
                startIcon={downloading ? <CircularProgress size={24} /> : <DownloadRounded />}
              >
                download all
              </Button>
            </Grid>
          )}
        </Grid>
      )}
    </Grid>
  );
}
