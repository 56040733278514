import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateTour } from './redux/actions';
import { useQuery } from '../../constants';
import Layout from '../Global/Layout';
import PasswordDialog from '../Global/PasswordDialog';
import {
  DatePicker,
  GeneralButton,
  RoundedDialog,
  StandardDialogActions,
  User,
  UserMention,
} from '../Global/Components';
import StateManager from '../Global/StateManager';
import { getUser } from './redux/actions';

import {
  Grid,
  Container,
  Paper,
  TextField,
  Typography,
  Avatar,
  Button,
  CircularProgress,
  RadioGroup,
  Radio,
  FormControlLabel,
  Chip,
} from '@mui/material';
import { DialogContent, DialogTitle } from '@mui/material';
import { AlertTitle, Alert } from '@mui/material';
import { grey, red, blue } from '@mui/material/colors';

import {
  AccountCircleRounded,
  CloseRounded,
  VisibilityRounded,
  PersonRounded,
  PersonAddAltRounded,
  CancelRounded,
  FlagRounded,
} from '@mui/icons-material';
import { VpnKeyRounded, CloudDownloadRounded, PublicRounded, SettingsRounded } from '@mui/icons-material';
import { DeleteOutlineOutlined, LocalShippingRounded } from '@mui/icons-material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CachedIcon from '@mui/icons-material/Cached';
import DeleteIcon from '@mui/icons-material/Delete';

import { Microsoft365Icon } from '../Global/Icons';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../../constants/msal';
import { InteractionRequiredAuthError, InteractionStatus } from '@azure/msal-browser';

import Tours from '../Global/Assistant/Tours';
import axios from 'axios';
import { FormatOnlyDate } from '../Global/Functions';
import { isEmpty } from 'lodash';

const options = [
  { message: 'View Only', value: 'view', icon: <VisibilityRounded style={{ color: grey[500] }} /> },
  { message: 'Standard', value: 'standard', icon: <PersonRounded style={{ color: grey[500] }} /> },
  { message: 'Admin', value: 'admin', icon: <VpnKeyRounded style={{ color: grey[500] }} /> },
  { message: 'Portal', value: 'portal', icon: <PublicRounded style={{ color: grey[500] }} /> },
];

export default function Profile() {
  let query = useQuery();
  const isTour = query.get('start_tour');
  const dispatch = useDispatch();

  const { instance, inProgress, accounts } = useMsal();

  const [ssoLoading, setSsoLoading] = useState(false);
  const [ssoErrorMessage, setSsoErrorMessage] = useState(null);
  const [microsoftId, setMicrosoftId] = useState(null);

  const [user, setUser] = useState(null);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [jobRole, setJobRole] = useState('');
  const [saving, setSaving] = useState(false);
  const [picture, setPicture] = useState('');
  const [editMode, setEditMode] = useState(isTour || false);
  const [openDialog, setOpenDialog] = useState(false);
  const [twoFaDialog, setTwoFaDialog] = useState(false);
  const [transferDialog, setTransferDialog] = useState(false);
  const [workTransfers, setWorkTransfers] = useState([]);

  const [sendingLink, setSendingLink] = useState(false);
  const [unlinking, setUnlinking] = useState(false);

  const canSetupTwoFa =
    isEmpty(user?.canSetupTwoFa) ||
    user?.canSetupTwoFa?.includes(user?.access) ||
    user?.access === 'admin' ||
    user?.access === 'superadmin';
  const canLinkSocialAccounts =
    isEmpty(user?.canLinkSocialAccounts) ||
    user?.canLinkSocialAccounts?.includes(user?.access) ||
    user?.access === 'admin' ||
    user?.access === 'superadmin';
  const canChangePassword =
    isEmpty(user?.canChangePassword) ||
    user?.canChangePassword?.includes(user?.access) ||
    user?.access === 'admin' ||
    user?.access === 'superadmin';
  const canEditProfile =
    isEmpty(user?.canEditProfile) ||
    user?.canEditProfile?.includes(user?.access) ||
    user?.access === 'admin' ||
    user?.access === 'superadmin';

  useEffect(() => {
    axios
      .get('/user/profile')
      .then((res) => {
        setUser(res.data.user);
        setMicrosoftId(res.data.user?.microsoftId);
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
      });

    axios
      .post('/workTransfer/getWorkTransfers')
      .then(({ data }) => {
        setWorkTransfers(data);
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
      });
  }, []);

  function editUser() {
    setFirstName(user.firstName);
    setLastName(user.lastName);
    setEmail(user.email);
    setMobile(user.mobile);
    setJobRole(user.jobRole);
    setPicture(user.picture);
    setEditMode(true);
  }

  function saveUser() {
    const body = {
      firstName,
      lastName,
      picture,
      jobRole,
      mobile,
      email,
    };
    setSaving(true);
    axios
      .post('/user/profile', body)
      .then((res) => {
        setSaving(false);
        setUser(res.data);
        setEditMode(false);
        StateManager.setSuccessAlert('User details have been saved');
        dispatch(getUser());
      })
      .catch((err) => {
        StateManager.setErrorAlert(err?.response?.data?.message || 'Sometning went wrong');
        setSaving(false);
      });
  }

  function changePicture(file) {
    if (!file) return;
    const data = new FormData();
    data.append('file', file);
    axios
      .post(`/uploader/upload-single`, data, { headers: { 'Content-Type': 'multipart/form-data' } })
      .then((res) => {
        setPicture(res.data.file.location);
      })
      .catch((err) => {
        StateManager.setErrorAlert(err?.response?.data?.message || 'Sometning went wrong');
      });
  }

  const saveTour = () => {
    dispatch(
      updateTour({
        setupProfile: true,
      }),
    );
  };

  function confirmDisable2FA() {
    StateManager.setConfirm(
      'You are about to disable two-factor authentication',
      disable2FA,
      'It will weaken the security of your account. Are you sure you want to continue?',
    );
  }

  function confirmUnlinkMicrosoft() {
    StateManager.setConfirm(
      'You are about to unlink your account from Microsoft',
      unlinkMicrosoft,
      'Are you sure you want to continue?',
    );
  }

  function sendPasswordReset() {
    setSendingLink(true);
    axios
      .post('/auth/sendResetLinkByEmail', { email: user?.email })
      .then(() => {
        setSendingLink(false);
        StateManager.setSuccessAlert('Password reset email has been sent');
      })
      .catch((err) => {
        setSendingLink(false);
        StateManager.setAxiosErrorAlert(err);
      });
  }

  function unlinkMicrosoft() {
    setUnlinking(true);
    axios
      .post('/user/unlinkAccountWithMicrosoft')
      .then(() => {
        setUnlinking(false);
        StateManager.setSuccessAlert('Your account has been unlinked from Microsoft');
        setUser({ ...user, microsoftId: null });
        setMicrosoftId(null);
        sessionStorage.clear();
      })
      .catch((err) => {
        setUnlinking(false);
        StateManager.setAxiosErrorAlert(err);
      });
  }

  async function initiatelinkMicrosoft() {
    try {
      setSsoLoading(true);
      await instance.loginRedirect(loginRequest);
    } catch (error) {
      setSsoLoading(false);
      console.error('Authentication error:', error);
    }
  }

  function disable2FA() {
    axios
      .post('/user/disable2FA')
      .then(() => {
        user.twoFAEnabled = false;
        user.twoFAMethod = null;
        setUser({ ...user });
        StateManager.setSuccessAlert('Two-factor authentication has been disabled');
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
      });
  }

  useEffect(() => {
    const sessionKeys = Object.keys(sessionStorage);
    if (!accounts || accounts?.length === 0 || microsoftId || sessionKeys?.length === 0) return;
    const accessTokenRequest = {
      scopes: [],
      account: accounts[0],
    };
    if (inProgress === InteractionStatus.None) {
      setSsoLoading(true);
      instance
        .acquireTokenSilent(accessTokenRequest)
        .then((accessTokenResponse) => {
          // Call your web api for oauth
          const jwt = accessTokenResponse.idToken;
          if (!jwt) return;
          axios
            .post('/user/linkAccountWithMicrosoft', { jwt })
            .then((res) => {
              setSsoLoading(false);
              if (res.data.success) {
                StateManager.setSuccessAlert('Your account has been linked with Microsoft');
                setMicrosoftId(res.data.microsoftId);
              }
            })
            .catch((err) => {
              setSsoLoading(false);
              StateManager.setAxiosErrorAlert(err);
            });
        })
        .catch((error) => {
          if (error instanceof InteractionRequiredAuthError) {
            instance.acquireTokenRedirect(accessTokenRequest);
          }
          console.log(error);
        });
    }
  }, [instance, accounts, inProgress, microsoftId]);

  if (!user) {
    return (
      <Grid container alignItems="center" justifyContent="center" style={{ height: '85vh' }}>
        <CircularProgress color="primary" />
      </Grid>
    );
  }

  return (
    <Layout title="Profile" icon={<AccountCircleIcon />}>
      <Container maxWidth="md">
        {user && (
          <Grid item container>
            {isTour && (
              <Tours
                id="setupProfile"
                run={true}
                showProgress
                continuous
                onTourEnd={saveTour}
                redirectLink={'/home'}
                locale={{
                  last: 'Finish',
                }}
              />
            )}
            <Grid container component={Paper} elevation={4} sx={{ p: 2, my: 2 }}>
              <Grid item container alignItems="center">
                <AccountCircleRounded fontSize="large" style={{ color: grey[500], marginRight: '1rem' }} />
                <Typography variant="h5">User Profile</Typography>
              </Grid>
              <Grid item container sx={{ my: 2, mx: 1 }} spacing={4}>
                <Grid item container xs={12} lg={9} md={9} spacing={2}>
                  <Grid item container alignItems="center">
                    <Grid item xs={12} sm={3}>
                      <Typography style={{ fontWeight: 600 }}>First Name</Typography>
                    </Grid>
                    <Grid item xs={12} sm>
                      {editMode ? (
                        <TextField
                          fullWidth
                          id="firstName"
                          value={firstName}
                          onChange={(e) => setFirstName(e.target.value)}
                          variant="outlined"
                        />
                      ) : (
                        <Typography>{user.firstName}</Typography>
                      )}
                    </Grid>
                  </Grid>
                  <Grid item container alignItems="center">
                    <Grid item xs={12} sm={3}>
                      <Typography style={{ fontWeight: 600 }}>Last Name</Typography>
                    </Grid>
                    <Grid item xs={12} sm>
                      {editMode ? (
                        <TextField
                          fullWidth
                          id="lastName"
                          value={lastName}
                          onChange={(e) => setLastName(e.target.value)}
                          variant="outlined"
                        />
                      ) : (
                        <Typography>{user.lastName}</Typography>
                      )}
                    </Grid>
                  </Grid>
                  <Grid item container alignItems="center">
                    <Grid item xs={12} sm={3}>
                      <Typography style={{ fontWeight: 600 }}>Email / Username</Typography>
                    </Grid>
                    <Grid item xs={12} sm>
                      {editMode ? (
                        <TextField
                          fullWidth
                          id="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          variant="outlined"
                        />
                      ) : (
                        <Typography>{user.email}</Typography>
                      )}
                    </Grid>
                  </Grid>
                  <Grid item container alignItems="center" className="phone">
                    <Grid item xs={12} sm={3}>
                      <Typography style={{ fontWeight: 600 }}>Mobile</Typography>
                    </Grid>
                    <Grid item xs={12} sm>
                      {editMode ? (
                        <TextField
                          fullWidth
                          id="mobile"
                          value={mobile}
                          onChange={(e) => setMobile(e.target.value)}
                          variant="outlined"
                        />
                      ) : (
                        <Typography>{user.mobile || '-'}</Typography>
                      )}
                    </Grid>
                  </Grid>

                  <Grid item container alignItems="center" className="jobRole">
                    <Grid item xs={12} sm={3}>
                      <Typography style={{ fontWeight: 600 }}>Job Role</Typography>
                    </Grid>
                    <Grid item xs={12} sm>
                      {editMode ? (
                        <TextField
                          fullWidth
                          id="jobRole"
                          value={jobRole}
                          onChange={(e) => setJobRole(e.target.value)}
                          variant="outlined"
                        />
                      ) : (
                        <Typography>{user.jobRole || '-'}</Typography>
                      )}
                    </Grid>
                  </Grid>
                  {!editMode && (
                    <Grid item container alignItems="center">
                      <Grid item xs={12} sm={3}>
                        <Typography style={{ fontWeight: 600 }}>Company</Typography>
                      </Grid>
                      <Grid item xs={12} sm>
                        <Typography>{user.company ? user.company.name : 'N/A'}</Typography>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
                <Grid item container xs={12} lg={3} md={3} spacing={2} justifyContent="center">
                  <Grid item container justifyContent="center">
                    <Avatar src={editMode ? picture : user.picture} style={{ height: 200, width: 200, fontSize: 100 }}>
                      {user.avatarLetter}
                    </Avatar>
                  </Grid>
                  {editMode && (
                    <>
                      <Grid item container justifyContent="center">
                        <input
                          accept="image/*"
                          style={{ display: 'none' }}
                          id="contained-button-file"
                          type="file"
                          onChange={(e) => changePicture(e.target.files[0])}
                        />
                        <label htmlFor="contained-button-file">
                          <GeneralButton
                            component="span"
                            startIcon={<CloudDownloadRounded style={{ color: grey[500] }} />}
                            className="profile-picture"
                          >
                            Change picture
                          </GeneralButton>
                        </label>
                      </Grid>

                      <Grid item container justifyContent="center">
                        <GeneralButton
                          onClick={() => setPicture('')}
                          startIcon={<DeleteIcon style={{ color: red[500] }} />}
                        >
                          Delete picture
                        </GeneralButton>
                      </Grid>
                    </>
                  )}

                  {!editMode && (
                    <Grid item container justifyContent="center" style={{ alignText: 'center' }}>
                      <Grid item container justifyContent="center" style={{ alignText: 'center' }}>
                        <Typography gutterBottom> My Access Type:</Typography>
                      </Grid>
                      <Grid item container justifyContent="center" style={{ alignText: 'center' }}>
                        <Typography style={{ fontWeight: 600, marginRight: 8 }}>
                          {options.find((d) => d.value === user.access).message}{' '}
                        </Typography>

                        {options.find((d) => d.value === user.access).icon}
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              {!microsoftId && canSetupTwoFa && (
                <Grid item container alignItems="center" spacing={2}>
                  <Grid item container>
                    <Typography variant="h5">Two-Factor Authentication (2FA)</Typography>
                  </Grid>
                  {user.twoFAEnabled ? (
                    <Grid item container alignItems="center">
                      <Typography>2FA is enabled with {user.twoFAMethod}</Typography>

                      <Button
                        style={{ marginLeft: 16 }}
                        startIcon={<DeleteOutlineOutlined />}
                        onClick={confirmDisable2FA}
                        color="error"
                      >
                        Disable 2FA
                      </Button>
                    </Grid>
                  ) : (
                    <Grid item container alignItems="center">
                      <Typography>2FA is not enabled</Typography>
                      <Button
                        onClick={() => setTwoFaDialog(true)}
                        style={{ marginLeft: 16 }}
                        startIcon={<SettingsRounded />}
                      >
                        set up 2fa
                      </Button>
                      <TwoFaDialog
                        open={twoFaDialog}
                        onClose={() => setTwoFaDialog(false)}
                        onResult={({ twoFAMethod }) => {
                          user.twoFAEnabled = true;
                          user.twoFAMethod = twoFAMethod;
                          setUser({ ...user });
                        }}
                      />
                    </Grid>
                  )}
                </Grid>
              )}

              {canLinkSocialAccounts && (
                <Grid item container alignItems="center" spacing={2} sx={{ my: 2 }}>
                  <Grid item container>
                    <Typography variant="h5">Social Accounts</Typography>
                  </Grid>
                  {microsoftId ? (
                    <Grid item container alignItems="center">
                      <Button
                        startIcon={unlinking ? <CircularProgress size={24} /> : <Microsoft365Icon />}
                        onClick={confirmUnlinkMicrosoft}
                        color="error"
                        disabled={!user?.isPasswordSet}
                      >
                        Unlink account from Microsoft
                      </Button>
                      {!user?.isPasswordSet && (
                        <Alert severity="error" sx={{ my: 1, borderRadius: 1, width: '100%' }}>
                          <AlertTitle>You can only unlink your account after you have set a password</AlertTitle>
                        </Alert>
                      )}
                    </Grid>
                  ) : (
                    <Grid item container alignItems="center">
                      <Button
                        onClick={initiatelinkMicrosoft}
                        startIcon={ssoLoading ? <CircularProgress size={24} /> : <Microsoft365Icon />}
                      >
                        link account with Microsoft
                      </Button>
                    </Grid>
                  )}
                </Grid>
              )}

              {!isEmpty(workTransfers) && (
                <Grid item container sx={{ my: 1 }}>
                  <Grid item container>
                    <Typography variant="h6" gutterBottom>
                      Work transfers:
                    </Typography>
                  </Grid>

                  <Grid item container>
                    {workTransfers.map((transfer) => (
                      <WorkTransfer
                        key={transfer._id}
                        data={transfer}
                        onDelete={() => setWorkTransfers(workTransfers.filter((x) => x._id !== transfer._id))}
                      />
                    ))}
                  </Grid>
                </Grid>
              )}

              <Grid item container sx={{ mt: 3, py: 1 }} alignItems="center">
                {!saving && (
                  <>
                    {!editMode && (
                      <>
                        {canChangePassword && (
                          <GeneralButton
                            startIcon={sendingLink ? <CircularProgress size={24} /> : <CachedIcon />}
                            onClick={() => {
                              if (user?.isPasswordSet) {
                                setOpenDialog(true);
                                return;
                              }
                              sendPasswordReset();
                            }}
                          >
                            {user?.isPasswordSet ? 'Change password' : 'Set Password'}
                          </GeneralButton>
                        )}

                        <GeneralButton
                          startIcon={<LocalShippingRounded />}
                          onClick={() => setTransferDialog(true)}
                          sx={{ ml: 2 }}
                        >
                          Transfer your work
                        </GeneralButton>

                        <TransferWorkDialog
                          open={transferDialog}
                          onClose={() => setTransferDialog(false)}
                          onResult={(res) => setWorkTransfers([...workTransfers, res])}
                          userId={user?._id}
                        />
                      </>
                    )}

                    <div style={{ marginLeft: 'auto' }} />

                    {editMode && (
                      <Button
                        onClick={() => setEditMode(false)}
                        style={{ color: red[500], borderRadius: 8, marginRight: 8 }}
                        startIcon={<CloseRounded />}
                      >
                        cancel
                      </Button>
                    )}
                    {canEditProfile && (
                      <Button
                        startIcon={editMode ? <SaveIcon /> : <EditIcon />}
                        variant="contained"
                        color="primary"
                        style={{ borderRadius: 8 }}
                        onClick={() => (editMode ? saveUser() : editUser())}
                      >
                        {editMode ? 'Save' : 'Edit'}
                      </Button>
                    )}
                  </>
                )}

                {saving && <CircularProgress size={28} style={{ marginLeft: 'auto' }} color="primary" />}
              </Grid>
            </Grid>
          </Grid>
        )}

        <PasswordDialog open={openDialog} handleClose={() => setOpenDialog(false)} />
      </Container>
    </Layout>
  );
}

function TwoFaDialog({ open, onClose, onResult }) {
  const [qrCode, setQrCode] = useState(null);
  const [loadingQrCode, setLoadingQrCode] = useState(false);
  const [checking, setChecking] = useState(false);
  const qrCodeTimer = useRef();
  const checkTimer = useRef();

  useEffect(() => {
    if (open) {
      setupQrCodeLoading();
      setupStatusCheck();
    } else {
      if (qrCodeTimer.current != null) {
        clearInterval(qrCodeTimer.current);
      }
      if (checkTimer.current != null) {
        clearInterval(checkTimer.current);
      }
    }
  }, [open]); // eslint-disable-line

  useEffect(() => {
    return () => {
      if (qrCodeTimer.current != null) {
        clearInterval(qrCodeTimer.current);
      }
      if (checkTimer.current != null) {
        clearInterval(checkTimer.current);
      }
    };
  }, []);

  function setupQrCodeLoading() {
    loadQrCode();
    qrCodeTimer.current = setInterval(loadQrCode, 9 * 60 * 1000);
  }

  function loadQrCode() {
    setLoadingQrCode(true);
    axios
      .get('/user/getAuthyQrCode')
      .then((res) => {
        setQrCode(res.data.qrCode);
        setLoadingQrCode(false);
      })
      .catch((err) => {
        setLoadingQrCode(false);
        StateManager.setAxiosErrorAlert(err);
      });
  }

  function done() {
    setChecking(true);
    axios
      .post('/user/completeAuthySetup')
      .then((res) => {
        setChecking(false);
        if (res.data.success) {
          StateManager.setSuccessAlert('Two-Factor Authentication has been enabled');
          onResult({ twoFAMethod: res.data.twoFAMethod });
          onClose();
        }
      })
      .catch((err) => {
        setChecking(false);
        StateManager.setAxiosErrorAlert(err);
      });
  }

  function checkStatus() {
    axios
      .post('/user/completeAuthySetupSave')
      .then((res) => {
        if (res.data.success) {
          StateManager.setSuccessAlert('Two-Factor Authentication has been enabled');
          onResult({ twoFAMethod: res.data.twoFAMethod });
          onClose();
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function setupStatusCheck() {
    checkTimer.current = setInterval(checkStatus, 10 * 1000);
    setTimeout(() => {
      if (checkTimer.current != null) {
        clearInterval(checkTimer.current);
      }
    }, 20 * 60 * 1000);
  }

  return (
    <RoundedDialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Set up 2FA with Authy</DialogTitle>
      <DialogContent>
        <Grid container justifyContent="center" alignItems="center">
          <Grid container>
            <Alert severity="info" style={{ width: '100%', marginTop: 8, borderRadius: 8 }}>
              <AlertTitle>How to do it:</AlertTitle>
              <Typography gutterBottom>
                Download or open{' '}
                <a href="https://authy.com/download/" target={'_blank'} style={{ color: blue[700] }}>
                  Authy app
                </a>
              </Typography>
              <Typography gutterBottom>Scan the below QR code in your 'Authy' app and click 'Done'</Typography>
            </Alert>
          </Grid>
          <Grid container justifyContent="center" alignItems="center" style={{ height: '30vh' }}>
            {loadingQrCode ? (
              <CircularProgress color="primary" />
            ) : (
              qrCode && <img alt="Authy" src={qrCode} style={{ height: '25vh', width: '25vh' }} />
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <StandardDialogActions onClose={onClose} onDone={done} saving={checking} />
    </RoundedDialog>
  );
}

export function TransferWorkDialog({ open, onClose, onResult, forAdmin, userId }) {
  const [selectedUser, setSelectedUser] = useState(null);
  const [coverStarts, setCoverStarts] = useState('now');
  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);

  function selectUser() {
    StateManager.selectUser((res) => setSelectedUser(res?._id), null, { excluded: [userId] });
  }

  function done() {
    const body = {
      coverUserId: selectedUser,
      coverStarts,
      start,
      end,
      userId,
    };

    axios
      .post(forAdmin ? '/workTransfer/createWorkTransferForUser' : '/workTransfer/createWorkTransfer', body)
      .then(({ data }) => {
        if (data.transferResult) {
          StateManager.setSuccessAlert('Work has been transferred');
        } else {
          StateManager.setSuccessAlert('Work transfer has been scheduled');
        }
        onResult(data);
        onClose();
        setSelectedUser(null);
        setCoverStarts('now');
        setStart(null);
        setEnd(null);
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
      });
  }

  const hideDone = !selectedUser || (coverStarts === 'later' && (!start || !end)) || (coverStarts === 'now' && !end);

  return (
    <RoundedDialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Transfer work</DialogTitle>
      <DialogContent>
        {!forAdmin && (
          <Grid container sx={{ my: 1 }}>
            <Typography color="text.secondary">
              You can temporary delegate your work to another user, for example if you are going on holiday
            </Typography>
          </Grid>
        )}
        <Grid container>
          <Grid container item>
            <Typography variant="h6" gutterBottom>
              Select user
            </Typography>
          </Grid>
          <Grid container item>
            {selectedUser ? (
              <User id={selectedUser} onClick={selectUser} />
            ) : (
              <Button variant="outlined" startIcon={<PersonAddAltRounded />} onClick={selectUser}>
                Pick user
              </Button>
            )}
          </Grid>
        </Grid>
        {selectedUser && (
          <Grid container sx={{ my: 1 }}>
            <Grid container item>
              <Typography variant="h6" gutterBottom>
                When would you like <UserMention id={selectedUser} /> to take over?
              </Typography>
            </Grid>
            <Grid container item>
              <RadioGroup row value={coverStarts} onChange={(e) => setCoverStarts(e.target.value)}>
                <FormControlLabel value={'now'} control={<Radio />} label="Immediately" />
                <FormControlLabel value={'later'} control={<Radio />} label="From another day" />
              </RadioGroup>
            </Grid>
            {coverStarts === 'later' && (
              <Grid container alignItems={'center'} spacing={1} sx={{ py: 1 }}>
                <Grid container item alignItems={'flex-end'} wrap="nowrap" lg={6} md={6} xs={12}>
                  <Grid item>
                    <Typography variant="h6" sx={{ mr: 2 }} gutterBottom>
                      Stars
                    </Typography>
                  </Grid>

                  <Grid item>
                    <DatePicker value={start} onChange={(date) => setStart(date)} />
                  </Grid>
                </Grid>

                <Grid container item alignItems={'flex-end'} wrap="nowrap" lg={6} md={6} xs={12}>
                  <Grid item>
                    <Typography variant="h6" sx={{ mr: 2 }} gutterBottom>
                      Ends
                    </Typography>
                  </Grid>

                  <Grid item>
                    <DatePicker value={end} onChange={(date) => setEnd(date)} />
                  </Grid>
                </Grid>
              </Grid>
            )}

            {coverStarts === 'now' && (
              <Grid container item alignItems={'flex-end'} wrap="nowrap">
                <Grid item>
                  <Typography variant="h6" sx={{ mr: 2 }} noWrap gutterBottom>
                    Cover ends
                  </Typography>
                </Grid>

                <Grid item>
                  <DatePicker value={end} onChange={(date) => setEnd(date)} />
                </Grid>
              </Grid>
            )}
          </Grid>
        )}
      </DialogContent>

      <StandardDialogActions onClose={onClose} hideDone={hideDone} onDone={done} />
    </RoundedDialog>
  );
}

export function WorkTransfer({ data, onDelete, userId, forAdmin }) {
  const [saving, setSaving] = useState(false);

  function cancelWorkTransfer() {
    setSaving(false);

    axios
      .post(forAdmin ? '/workTransfer/cancelWorkTransferForUser' : '/workTransfer/cancelWorkTransfer', {
        transferId: data._id,
        userId,
      })
      .then(() => {
        setSaving(false);
        StateManager.setSuccessAlert('Done');
        onDelete();
      })
      .catch((err) => {
        setSaving(false);
        StateManager.setAxiosErrorAlert(err);
      });
  }

  return (
    <Grid container sx={{ py: 2 }}>
      <Grid container component={Paper} variant="outlined" sx={{ p: 1.5 }}>
        <Grid container item justifyContent={'space-between'}>
          <User id={data.coverUserId} />

          <Chip
            label={data.state}
            color={data.state === 'live' ? 'primary' : 'default'}
            sx={{ textTransform: 'capitalize' }}
          />
        </Grid>
        {data.state === 'live' && (
          <Grid container item justifyContent={'space-between'} sx={{ mt: 2 }}>
            <Typography>Tasks: {data.transferResult?.tasksProcessed}</Typography>
            <Typography>Forms: {data.transferResult?.formEntriesProcessed}</Typography>
            <Typography>Processes: {data.transferResult?.stepsProcessed}</Typography>
            <Typography>Audits: {data.transferResult?.auditsProcessed}</Typography>
          </Grid>
        )}
        <Grid container item justifyContent={'space-between'} sx={{ mt: 2 }}>
          <Typography>
            {data.start && data.state === 'planned' && <>Starts {FormatOnlyDate(data.start)}; </>}Ends{' '}
            {FormatOnlyDate(data.end)}
          </Typography>

          {!saving && data.state === 'planned' && (
            <Button
              color="error"
              startIcon={<CancelRounded />}
              onClick={() => StateManager.setConfirm('You are about to cancel this transfer', cancelWorkTransfer)}
            >
              Cancel
            </Button>
          )}

          {!saving && data.state === 'live' && (
            <Button
              color="primary"
              variant="outlined"
              startIcon={<FlagRounded />}
              onClick={() => StateManager.setConfirm('You are about to complete this transfer', cancelWorkTransfer)}
            >
              Complete
            </Button>
          )}

          {saving && <CircularProgress size={24} />}
        </Grid>
      </Grid>
    </Grid>
  );
}
