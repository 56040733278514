import React, { useState, useEffect, useRef } from 'react';
import { Grid, TextField, CircularProgress, Typography, Button, FormControlLabel, Switch } from '@mui/material';
import { Collapse, RadioGroup, Radio } from '@mui/material';
import { DeleteOutlineRounded, GroupAddRounded } from '@mui/icons-material';
import axios from 'axios';
import Uploader from '../Uploader';
import StateManager from '../StateManager';
import { useSelector } from 'react-redux';
import { isEmpty, isFunction } from 'lodash';
import FileViewer from '../FileViewer';
import { FormatDate } from '../Functions';
import User from './User';
import { red } from '@mui/material/colors';
import CommentsSection from '../CommentsSection';
import ActionGroup from '../Fields/ActionGroup';
import UserGroup from './UserGroup';
import DateFormatter from './DateFormatter';

export default function ReviewComponent({ initial, onDelete, withNewVersionButton, onUpIssue }) {
  const [saving, setSaving] = useState(false);
  const [status, setStatus] = useState(false);
  const [entry, setEntry] = useState(null);
  const [reason, setReason] = useState('');
  const [outcome, setOutcome] = useState('');
  const [files, setFiles] = useState([]);
  const [comments, setComments] = useState([]);
  const [newVersionRequired, setNewVersionRequired] = useState('no');
  const [reasonForUpissue, setReasonForUpissue] = useState('');
  const [assignedForUpIssue, setAssignedForUpIssue] = useState([]);
  const debounceTimer = useRef(null);
  const currentChange = useRef(null);
  const DEBOUNCE_DELAY = 1200;
  const { user } = useSelector(({ profile }) => profile);
  const editable = user && entry && !entry.completedAt && entry.assigned.includes(user._id);
  const deletable = user && entry && (entry.createdBy === user._id || user.access === 'admin');

  useEffect(() => {
    if (!initial) {
      setEntry(null);
      setReason('');
      setOutcome('');
      setFiles([]);
      setNewVersionRequired('no');
      setReasonForUpissue('');
      setAssignedForUpIssue([]);
      return;
    }

    setEntry(initial);
    setReason(initial.reason || '');
    setOutcome(initial.outcome || '');
    setFiles(initial.files || []);
    setComments(initial.comments || []);
    setNewVersionRequired(initial.newVersionRequired ? 'yes' : 'no');
    setReasonForUpissue(initial.reasonForUpissue || '');
    setAssignedForUpIssue(initial.assignedForUpIssue || []);
    setStatus('');
  }, [initial]);

  function triggerTimer(data) {
    if (debounceTimer.current != null) {
      clearTimeout(debounceTimer.current);
    }
    currentChange.current = { ...currentChange.current, ...data };
    debounceTimer.current = setTimeout(() => save(), DEBOUNCE_DELAY);
  }

  function save() {
    setStatus('Saving...');

    axios
      .post('/reviews/entries/saveReviewEntry', { reviewEntryId: entry._id, update: currentChange.current })
      .then(() => {
        currentChange.current = null;
        setStatus('Saved');
      })
      .catch((err) => {
        setStatus('Failed to save the review');
        StateManager.setAxiosErrorAlert(err);
      });
  }

  const completable = entry && outcome && (entry.scheduled || reason) && !entry.completedAt;

  function completeEntry() {
    if (!completable) return;

    if (
      newVersionRequired === 'yes' &&
      !isEmpty(assignedForUpIssue) &&
      (assignedForUpIssue.length !== 1 || assignedForUpIssue[0] !== user?._id) &&
      !reasonForUpissue
    ) {
      StateManager.setErrorAlert('Please provide reason for up-issue');
      return;
    }

    const body = {
      reviewEntryId: entry._id,
    };

    setSaving(true);
    axios
      .post('/reviews/entries/completeReviewEntry', body)
      .then(({ data }) => {
        StateManager.setSuccessAlert('Review has been completed');
        setSaving(false);
        const result = { ...entry, ...data };
        setEntry(result);
        if (data.upIssueData && isFunction(onUpIssue)) {
          onUpIssue(data.upIssueData);
        }
        onDelete();
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
        setSaving(false);
      });
  }

  function confirmDelete() {
    StateManager.setConfirm('You are about to delete this review', deleteEntry);
  }

  function deleteEntry() {
    setSaving(true);
    axios
      .post('/reviews/entries/deleteReviewEntry', { reviewEntryId: entry._id })
      .then(() => {
        StateManager.setSuccessAlert('Review has been deleted');
        setSaving(false);
        if (isFunction(onDelete)) {
          onDelete(entry._id);
        }
        onDelete();
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
        setSaving(false);
      });
  }

  function pickAssigned() {
    StateManager.selectMultipleUsers(saveAssigned, { initiallySelected: assignedForUpIssue });
  }

  function saveAssigned(res) {
    if (!res?.users) return;
    setAssignedForUpIssue(res.users);
    triggerTimer({ assignedForUpIssue: res.users });
  }

  if (!entry) return null;

  return (
    <Grid container id={entry._id}>
      {entry.dueAt && (
        <Grid container item sx={{ mt: 1 }} alignItems="center">
          <Typography sx={{ fontWeight: 500 }}>
            Due <DateFormatter date={entry.dueAt} formatFunction={FormatDate} />
          </Typography>
        </Grid>
      )}
      <Grid container item sx={{ mt: 1 }} alignItems="center">
        <Typography color={'textSecondary'}>
          Created <DateFormatter date={entry.createdAt} formatFunction={FormatDate} />{' '}
          {entry.createdBy ? 'by' : 'automatically'}
        </Typography>
        {entry.createdBy && <User id={entry.createdBy} avatarSize={32} sx={{ ml: 2 }} />}
      </Grid>
      <Grid container item sx={{ mt: 1 }} alignItems="center">
        <Typography color={'textSecondary'}>Assigned to:</Typography>
        <UserGroup ids={entry.assigned} avatarSize={32} sx={{ ml: 2 }} />
      </Grid>
      {entry.completedAt && (
        <Grid container item sx={{ mt: 1 }} alignItems="center">
          <Typography color={'textSecondary'}>
            Completed {FormatDate(entry.completedAt)} {entry.completedBy ? 'by' : ''}
          </Typography>
          {entry.completedBy && <User id={entry.completedBy} avatarSize={32} sx={{ ml: 2 }} />}
        </Grid>
      )}
      <Grid container item sx={{ mt: 2 }}>
        {entry.scheduled ? (
          <Typography color="textSecondary">This is a routine scheduled task</Typography>
        ) : editable ? (
          <TextField
            multiline
            required
            fullWidth
            minRows={5}
            label="Reason for review"
            value={reason}
            onChange={(e) => {
              setReason(e.target.value);
              triggerTimer({ reason: e.target.value });
            }}
          />
        ) : (
          <>
            <Grid container>
              <Typography sx={{ fontWeight: 500, fontSize: '1.1rem' }}>Reason for review:</Typography>
            </Grid>
            <Grid container sx={{ mt: 1 }}>
              <Typography color={reason ? '' : 'textSecondary'} sx={{ wordBreak: 'break-word' }}>
                {reason || 'No reason provided'}
              </Typography>
            </Grid>
          </>
        )}
      </Grid>
      <Grid container item sx={{ mt: 3 }}>
        {editable ? (
          <TextField
            multiline
            required
            fullWidth
            minRows={5}
            label="Review outcome"
            value={outcome}
            onChange={(e) => {
              setOutcome(e.target.value);
              triggerTimer({ outcome: e.target.value });
            }}
          />
        ) : (
          <>
            <Grid container>
              <Typography sx={{ fontWeight: 500, fontSize: '1.1rem' }}>Review outcome:</Typography>
            </Grid>
            <Grid container sx={{ mt: 1 }}>
              <Typography color={outcome ? '' : 'textSecondary'} sx={{ wordBreak: 'break-word' }}>
                {outcome || 'No outcome provided'}
              </Typography>
            </Grid>
          </>
        )}
      </Grid>
      <Grid container item sx={{ mt: 2 }}>
        {editable ? (
          <Uploader
            uploaded={files}
            onChange={(files) => {
              setFiles(files);
              triggerTimer({ files });
            }}
          />
        ) : (
          <FileViewer files={files} />
        )}
      </Grid>

      <Grid container item sx={{ mt: 2 }}>
        <Typography variant="h6" gutterBottom>
          Actions:
        </Typography>
        <ActionGroup editable={editable} forReview activityInfo={{ type: 'review', entryId: entry._id }} />
      </Grid>

      <Grid container item sx={{ mt: 2 }}>
        <Grid container item>
          <Typography variant="h6" gutterBottom>
            Comments:
          </Typography>
        </Grid>

        <Grid container item>
          {isEmpty(comments) && (
            <Typography color="textSecondary" gutterBottom>
              No one has commented yet
            </Typography>
          )}
        </Grid>

        <CommentsSection
          title=""
          comments={comments}
          addComment={(comment) => {
            const value = [...comments, comment];
            setComments(value);
            triggerTimer({ comments: value });
          }}
          deleteComment={(id) => {
            const value = comments.filter((x) => x.id !== id);
            setComments(value);
            triggerTimer({ comments: value });
          }}
        />
      </Grid>

      {withNewVersionButton && editable && (
        <Grid container item sx={{ mt: 4 }} alignItems="center">
          <Grid container item>
            <Typography gutterBottom>Do you want to trigger a new version after this review?</Typography>
          </Grid>

          <Grid container item>
            <RadioGroup
              row
              value={newVersionRequired}
              onChange={(e) => {
                triggerTimer({ newVersionRequired: e.target.value === 'yes' });
                setNewVersionRequired(e.target.value);
              }}
            >
              <FormControlLabel value="yes" label="Yes" control={<Radio />} />
              <FormControlLabel value="no" label="No" control={<Radio />} />
            </RadioGroup>
          </Grid>

          <Grid container item component={Collapse} in={newVersionRequired === 'yes'}>
            <Grid container alignItems={'center'} sx={{ py: 2 }}>
              <Button startIcon={<GroupAddRounded />} onClick={pickAssigned} sx={{ textWrap: 'nowrap', mr: 2 }}>
                select users
              </Button>

              {isEmpty(assignedForUpIssue) ? (
                <Typography color="textSecondary" sx={{ fontSize: '0.9em' }}>
                  If you don't assign users the up-issue task will be sent to you
                </Typography>
              ) : (
                <UserGroup ids={assignedForUpIssue} />
              )}
            </Grid>
            <Grid
              container
              component={Collapse}
              in={
                !isEmpty(assignedForUpIssue) && (assignedForUpIssue.length !== 1 || assignedForUpIssue[0] !== user?._id)
              }
            >
              <TextField
                multiline
                required
                fullWidth
                minRows={5}
                label="Please indicate why an up-issue is needed"
                value={reasonForUpissue}
                onChange={(e) => {
                  setReasonForUpissue(e.target.value);
                  triggerTimer({ reasonForUpissue: e.target.value });
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
      <Grid container item sx={{ mt: 2 }} alignItems="center">
        {saving ? (
          <CircularProgress size={24} sx={{ ml: 'auto' }} />
        ) : (
          <>
            {deletable && (
              <Button startIcon={<DeleteOutlineRounded />} sx={{ color: red[500], mr: 2 }} onClick={confirmDelete}>
                delete review
              </Button>
            )}
            <Typography color={'textSecondary'} sx={{ mr: 'auto', ml: 1, fontSize: '0.8rem' }}>
              {status}
            </Typography>
            {completable && (
              <Button sx={{ ml: 'auto' }} variant="contained" onClick={completeEntry}>
                complete review
              </Button>
            )}
          </>
        )}
      </Grid>
    </Grid>
  );
}
