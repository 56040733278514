import React, { useState, useEffect } from 'react';
import { Grid, Typography, CircularProgress, Button } from '@mui/material';
import { AssetIcon } from '../Icons';
import { blue } from '@mui/material/colors';
import { Link } from 'react-router-dom';
import axios from 'axios';

export default function AssetReference({ assetId, ...props }) {
  const [loading, setLoading] = useState(false);
  const [asset, setAsset] = useState(null);

  useEffect(() => {
    if (!assetId) return;
    setLoading(true);

    axios
      .get('/asset/getAssetInfo', { params: { assetId } })
      .then(({ data }) => {
        setAsset(data);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  }, [assetId]);

  return (
    <Grid container alignItems={'center'} justifyContent={'space-between'} {...props}>
      {loading ? (
        <CircularProgress />
      ) : (
        asset && (
          <>
            <Typography>
              This entry is for asset{' '}
              {asset.prefix ? (
                <span style={{ color: blue[500], fontWeight: 500 }}>
                  {asset.prefix}-{asset.number}
                  {'   '}
                </span>
              ) : (
                <span style={{ color: blue[500], fontWeight: 500 }}>
                  {asset.number}
                  {'   '}
                </span>
              )}
              {'   '}
              <span style={{ fontWeight: 500 }}>{asset.title}</span>
            </Typography>

            <Link to={`/asset/${assetId}`}>
              <Button startIcon={<AssetIcon />}>Go to the asset</Button>
            </Link>
          </>
        )
      )}
    </Grid>
  );
}
