import { isArray, isEmpty } from 'lodash';
import { Subject } from 'rxjs';
import { WS_BASE_URL } from '../../../constants';

const NEW_TASK_EVENT = 'newTask';
const TASK_UPDATE_EVENT = 'taskUpdate';
const TASK_DELETE_EVENT = 'taskDelete';
const TASK_COMPLETED_EVENT = 'taskCompleted';

var socket = null;

// SPECIFIC events for ANY task
const taskCompletedSubscribtion = new Subject();
const taskDeletedSubscribtion = new Subject();
const taskUpdatedSubscribtion = new Subject();
const newTaskSubscribtion = new Subject();
// ANY event for SPECIFIC task
const taskSubscribtions = {};

function init() {
  const token = localStorage.getItem('token');
  if (!token) return;

  import('socket.io-client').then(({ io }) => {
    if (socket) return;

    socket = io(`${WS_BASE_URL}/tasks`, { query: { token }, transports: ['websocket'], secure: true });

    socket.on(TASK_COMPLETED_EVENT, processTaskCompletedEvent);
    socket.on(TASK_UPDATE_EVENT, processTaskUpdateEvent);
    socket.on(TASK_DELETE_EVENT, processTaskDeleteEvent);
    socket.on(NEW_TASK_EVENT, processNewTaskEvent);
  });
}

init();

function processTaskUpdateEvent(message) {
  broadcastTaskUpdate(message);
}

function processTaskCompletedEvent(taskId) {
  if (!taskId) return;
  const message = { type: TASK_COMPLETED_EVENT, taskId };
  console.log(message);
  taskCompletedSubscribtion.next(message);
  const subscribtion = taskSubscribtions[taskId];
  if (subscribtion) {
    subscribtion.next(message);
  }
}

function broadcastTaskUpdate(task) {
  const taskId = task?._id;
  const message = { type: TASK_UPDATE_EVENT, taskId, task };
  console.log(message);
  taskUpdatedSubscribtion.next(message);
  const subscribtion = taskSubscribtions[taskId];
  if (subscribtion) {
    subscribtion.next(message);
  }
}

function processTaskDeleteEvent(taskIds) {
  if (!isArray(taskIds) || isEmpty(taskIds)) return;
  const message = { type: TASK_DELETE_EVENT, taskIds };
  console.log(message);
  taskDeletedSubscribtion.next(message);
}

function processNewTaskEvent(data) {
  const message = { type: NEW_TASK_EVENT, taskId: data.taskId };
  console.log(message);
  newTaskSubscribtion.next(message);
}

function subscribeToNewTask(callback) {
  return newTaskSubscribtion.subscribe(callback);
}

function subscribeToAnyTaskUpdate(callback) {
  return taskUpdatedSubscribtion.subscribe(callback);
}

function subscribeToAnyTaskComplete(callback) {
  return taskCompletedSubscribtion.subscribe(callback);
}

function subscribeToAnyTaskDelete(callback) {
  return taskDeletedSubscribtion.subscribe(callback);
}

function subscribeToTaskEvents(taskId, callback) {
  if (!taskSubscribtions[taskId]) {
    taskSubscribtions[taskId] = new Subject();
  }
  return taskSubscribtions[taskId].subscribe(callback);
}

function reportTaskUpdateEvent(task) {
  broadcastTaskUpdate(task);
}

function reportTaskDeleteEvent(taskIds) {
  processTaskDeleteEvent(taskIds);
}

const TaskManager = {
  subscribeToAnyTaskUpdate,
  subscribeToAnyTaskComplete,
  subscribeToAnyTaskDelete,
  subscribeToTaskEvents,
  reportTaskUpdateEvent,
  reportTaskDeleteEvent,
  subscribeToNewTask,
};

export default TaskManager;
