import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  updateReview,
  cancelReview,
  updateTask,
  setTaskDialog,
  addReviewComment,
  updateReviewComment,
  deleteReviewComment,
  submitReviewApproval,
  updateReviewUsers,
  getReview,
  fetchTasksByReview,
} from '../../redux/actions/review';
import axios from 'axios';

import { orange } from '@mui/material/colors';
import {
  CircularProgress,
  Paper,
  useMediaQuery,
  FormLabel,
  FormControlLabel,
  Radio,
  FormControl,
  RadioGroup,
  Alert,
  DialogActions,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

import FeedbackIcon from '@mui/icons-material/Feedback';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Cancel from '@mui/icons-material/Cancel';

import Button from '@mui/material/Button';
import List from '@mui/material/List';

import StateManager from '../../../../Global/StateManager';
import Collapsible from '../Collapsible';
import TaskDialog from '../TaskDialog';
import ActionGroup from '../../../../Global/Fields/ActionGroup';
import { UserGroup, RoundedDialog, StandardDialogActions } from '../../../../Global/Components';

import TaskInfo from './taskInfo';
import Info from './info';
import ReviewInfoDialog from '../ReviewInfoDialog';
import CommentsSection from '../Comments';
import ReviewApprovals from './approvals';

export default function ({ setup, handleSetup, reviewId }) {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const dispatch = useDispatch();
  const history = useHistory();
  const saveRef = React.useRef();

  const SAVE_TIMEOUT = 1200;

  const { user } = useSelector(({ profile }) => profile);
  const { tasks, taskDialog, review, document, reviewLoading, reviewUpdating, commentLoading, error } = useSelector(
    ({ review }) => review,
  );
  const hubTask = useSelector(({ review }) => review?.hubTask);

  const [task, setTask] = useState(null);
  const [actions, setActions] = useState([]);
  const [allActionsCompleted, setAllActionsCompleted] = useState(false);
  const [resetReviewNow, setResetReviewNow] = useState(null);
  const [triggerUpIssue, setTriggerUpIssue] = useState(null);
  const [reason, setReason] = useState('');
  const [outcome, setOutcome] = useState('');

  const saveReview = (field, value) => {
    if (saveRef.current) clearTimeout(saveRef.current);
    saveRef.current = setTimeout(() => {
      let update = { status: 'In Progress', [field]: value };
      dispatch(updateReview(review._id, update));
    }, SAVE_TIMEOUT);
  };

  const exit = () => {
    handleSetup(false);
  };

  const handleTaskUpdate = () => {
    const update = {
      ...task,
    };
    delete update._id;
    dispatch(updateTask(task._id, update));
    StateManager.setSuccessAlert('Task updated successfully');
    dispatch(setTaskDialog(false));
  };

  const handleCancelReview = (id) => {
    dispatch(cancelReview(id));
    exit();
  };

  const openFile = (selected) => {
    window.open(`${window.location.origin}/document/${selected._id}`, '_blank');
  };

  const onAddComment = (data) => {
    const commentData = {
      text: data.text,
      createdAt: data.createdAt,
      mentionData: {
        list: data.mentionList,
        link: `/DocLibrary/hublink/review/update/${document?._id}/${review?._id}`,
        type: 'Review',
        title: document?.title,
      },
    };
    dispatch(addReviewComment(review?._id, commentData));
  };

  const onUpdateComment = ({ id, data }) => {
    const commentData = {
      text: data.text,
      createdAt: data.createdAt,
      mentionData: {
        list: data.mentionList,
        link: `/DocLibrary/hublink/review/update/${document?._id}/${review?._id}`,
        type: 'Review',
        title: document?.title,
      },
    };
    dispatch(updateReviewComment(review?._id, id, commentData));
  };

  const onDeleteComment = (id) => {
    dispatch(deleteReviewComment(review?._id, id));
  };

  const addRemoveReviewer =
    user?.access === 'admin' || user?._id === document?.owner_id
      ? () => {
          StateManager.selectMultipleUsers(
            (res) => {
              if (res?.users?.length === 0) {
                return StateManager.setErrorAlert('No users selected');
              }
              if (document?.minimumReviewerRequired && res?.users?.length < document?.minimumReviewerRequired) {
                return StateManager.setErrorAlert(
                  `You must select at least ${document?.minimumReviewerRequired} users`,
                );
              }
              dispatch(updateReviewUsers(review?._id, res?.users, (e) => history.push(e)));
            },
            { initiallySelected: review?.assigned, withoutPortal: true },
          );
        }
      : null;

  const onApprovalSubmit = (id, outcome) => {
    dispatch(submitReviewApproval(review?._id, id, outcome, (e) => history.push(e)));
  };

  useEffect(() => {
    if (!reviewId) return;
    dispatch(getReview(reviewId));
  }, [dispatch, reviewId]);

  useEffect(() => {
    if (!reviewId) return;
    axios
      .get('/fields/actions/validateActionsForReview', { params: { entryId: reviewId } })
      .then((res) => {
        const result = res?.data;
        setAllActionsCompleted(result);
      })
      .catch((err) => StateManager.setAxiosErrorAlert(err));
  }, [dispatch, reviewId, actions]);

  useEffect(() => {
    if (!hubTask || hubTask === undefined) return;
    dispatch(fetchTasksByReview(hubTask));
  }, [hubTask, dispatch]);

  useEffect(() => {
    if (!review) return;
    setResetReviewNow(
      review?.resetReviewNow === null || review?.resetReviewNow === undefined ? '' : review?.resetReviewNow,
    );
    setTriggerUpIssue(
      review?.triggerUpIssue === null || review?.triggerUpIssue === undefined ? '' : review?.triggerUpIssue,
    );
    setReason(review?.reason || '');
    setOutcome(review?.outcome || '');
  }, [review]);

  if (reviewLoading) {
    return (
      <Dialog fullWidth maxWidth="md" open={setup} scroll={'paper'} onClose={() => handleSetup(false)}>
        <DialogTitle id="document-setup-title">
          <Grid container direction="row" justifyContent="space-between" alignItems={'center'}>
            <Typography>Document Review</Typography>
          </Grid>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container direction="column" justifyContent="center" alignItems="center">
            <CircularProgress />
          </Grid>
        </DialogContent>
      </Dialog>
    );
  }

  if (!review && !reviewLoading && error) {
    return (
      <RoundedDialog open={Boolean(setup)} maxWidth="xs" fullWidth onClose={() => handleSetup(false)}>
        <DialogTitle sx={{ textAlign: 'center' }}>Not Found</DialogTitle>
        <DialogContent sx={{ textAlign: 'center' }}>
          <Grid container justifyContent="center" alignItems="center">
            <Alert severity="error" sx={{ borderRadius: 1 }}>
              {error || 'You do not have access to this review'}
            </Alert>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center' }}>
          <Button
            startIcon={<Cancel />}
            onClick={() => handleSetup(false)}
            color="error"
            sx={{ textTransform: 'none' }}
          >
            Close
          </Button>
        </DialogActions>
      </RoundedDialog>
    );
  }

  if (review?._id === reviewId && review?.status === 'Completed') {
    return <ReviewInfoDialog open={Boolean(setup)} handleClose={() => handleSetup(false)} />;
  }

  if (review?._id === reviewId) {
    return (
      <>
        <RoundedDialog
          fullWidth
          maxWidth="md"
          open={Boolean(setup)}
          scroll={'paper'}
          titleId="review-dialog"
          onClose={() => handleSetup(false)}
          fullScreen={isMobile}
        >
          <DialogTitle id="document-review-title">
            <Grid container direction="row" justifyContent={'space-between'} alignItems={'center'}>
              <Typography>Document Review</Typography>
              {review?._id && (user?._id === review?.created_by || user?._id === document?.owner_id) && (
                <Button
                  variant="contained"
                  onClick={() => handleCancelReview(review?._id)}
                  sx={{ background: orange[600] }}
                >
                  Cancel Review
                </Button>
              )}
            </Grid>
          </DialogTitle>
          <DialogContent dividers>
            <Info document={document} />
            <Grid
              container
              direction="row"
              fluid="true"
              justifyContent="center"
              alignItems="center"
              sx={{ my: 2, p: 1 }}
              gap={1}
            >
              <Typography>Review Doc</Typography>
              <FeedbackIcon />
            </Grid>
            <Grid container direction="column">
              <Grid item container fluid="true" direction="row" alignItems="center" justifyContent="space-between">
                <Grid item xs={3}>
                  <Button onClick={() => openFile(document)} startIcon={<VisibilityIcon />}>
                    Open Document
                  </Button>
                </Grid>
                <Grid item xs={3} sm container alignItems="center" justifyContent={'flex-end'}>
                  <Typography>Users assigned to Review</Typography>
                  <UserGroup ids={review?.assigned} onClick={addRemoveReviewer} />
                </Grid>
              </Grid>
              <Divider sx={{ my: 2 }} />
              <Grid item container direction="column" justifyContent="flex-start">
                <Collapsible
                  field={reason}
                  setField={(value) => {
                    setReason(value);
                    saveReview('reason', value);
                  }}
                  title={'Reason for View'}
                  defaultPlaceholder={'Type the reason for review'}
                />
                <Divider inset="true" sx={{ my: 1.25 }} />
                <Collapsible
                  field={outcome}
                  setField={(value) => {
                    setOutcome(value);
                    saveReview('outcome', value);
                  }}
                  title={'Review Outcome'}
                  defaultPlaceholder={'Type the outcome for the review'}
                />
                <Divider inset="true" sx={{ my: 1.25 }} />
                <ActionGroup
                  editable={review?.approvals?.every((x) => !x.submittedAt)}
                  forReview
                  itemId={review?._id}
                  activityInfo={{ entryId: review?._id, type: 'documentReview' }}
                  onResult={(e) => {
                    setActions([...actions, e]);
                  }}
                  onDelete={(id) => {
                    let deleted = actions?.filter((a) => a._id !== id);
                    setActions(deleted);
                  }}
                  excludeRegisterAction
                />
                <Divider sx={{ my: 2 }} />
                <Paper sx={{ p: 2 }} elevation={2}>
                  <Typography variant="h6" sx={{ mb: 2 }}>
                    Post Review Options
                  </Typography>
                  <FormControl sx={{ my: 1 }}>
                    <FormLabel id="triggerUpIssue-label">
                      Would you like to trigger an up-issue after the review is completed?
                    </FormLabel>
                    <RadioGroup
                      aria-labelledby="triggerUpIssue-label"
                      name="triggerUpIssue"
                      value={triggerUpIssue}
                      onChange={(e) => {
                        setTriggerUpIssue(e.target.value);
                        dispatch(updateReview(review._id, { triggerUpIssue: e.target.value }));
                      }}
                    >
                      <FormControlLabel value={'true'} control={<Radio />} label={'Yes'} />
                      <FormControlLabel value={'false'} control={<Radio />} label={'No'} />
                    </RadioGroup>
                  </FormControl>
                  <FormControl sx={{ my: 1 }}>
                    <FormLabel id="resetReviewNow-label">
                      Do you want to reset the next review schedule to start from when this review is complete? Or leave
                      the next review as is?
                    </FormLabel>
                    <RadioGroup
                      aria-labelledby="resetReviewNow-label"
                      name="resetReviewNow"
                      value={resetReviewNow}
                      onChange={(e) => {
                        setResetReviewNow(e.target.value);
                        dispatch(updateReview(review._id, { resetReviewNow: e.target.value }));
                      }}
                    >
                      <FormControlLabel
                        value={'false'}
                        control={<Radio />}
                        label={'Leave the next review as scheduled'}
                      />
                      <FormControlLabel value={'true'} control={<Radio />} label={'Start next review from today'} />
                    </RadioGroup>
                  </FormControl>
                </Paper>
                <Divider sx={{ my: 2 }} />
                <Grid container item direction="column" sx={{ marginTop: '15px' }}>
                  <ReviewApprovals
                    data={review?.approvals}
                    onSubmit={onApprovalSubmit}
                    disabled={
                      outcome === '' ||
                      reason === '' ||
                      resetReviewNow === null ||
                      triggerUpIssue === null ||
                      !allActionsCompleted
                    }
                  />
                </Grid>
                {tasks?.length > 0 && (
                  <Grid container item direction="column" sx={{ marginTop: '15px' }}>
                    <List component="ul" dense>
                      {tasks?.map((t) => (
                        <TaskInfo key={t._id} task={t} setTask={setTask} />
                      ))}
                    </List>
                  </Grid>
                )}
                <Divider sx={{ my: 2 }} />
                <Grid container direction={'column'} sx={{ overFlowY: 'scroll' }}>
                  <CommentsSection
                    upIssueComment={false}
                    versionSelector={false}
                    comments={review?.comments}
                    addComment={onAddComment}
                    updateComment={onUpdateComment}
                    deleteComment={onDeleteComment}
                    loading={commentLoading}
                  />
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <StandardDialogActions onClose={exit} saving={reviewUpdating} />
        </RoundedDialog>
        <TaskDialog
          edit={true}
          open={taskDialog}
          handleClose={() => dispatch(setTaskDialog(false))}
          handleConfirm={handleTaskUpdate}
          task={task}
          setTask={setTask}
        />
      </>
    );
  }
  return null;
}
