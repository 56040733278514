import { useMemo } from 'react';
import { ResponsiveChartContainer } from '@mui/x-charts/ResponsiveChartContainer';
import { BarPlot } from '@mui/x-charts/BarChart';
import { LinePlot, MarkPlot } from '@mui/x-charts/LineChart';
import { ChartsXAxis } from '@mui/x-charts/ChartsXAxis';
import { ChartsYAxis } from '@mui/x-charts/ChartsYAxis';
import { ChartsTooltip } from '@mui/x-charts/ChartsTooltip';
import { ChartsAxisHighlight } from '@mui/x-charts/ChartsAxisHighlight';
import { ChartsGrid, ChartsLegend } from '@mui/x-charts';
import { isArray, isFunction } from 'lodash';

export default function ComboChart({ data, barChartType, onBarClick, hideLegend }) {
  const series = useMemo(() => {
    if (!isArray(data?.series)) return [];

    const result = [
      ...data.series.map((x, i) => ({
        ...x,
        id: String(i),
        stack: barChartType === 'stacked' ? 'total' : undefined,
        type: 'bar',
      })),
      ...(isArray(data.targetLines) ? data.targetLines : []),
    ];

    return result;
  }, [data, barChartType]);

  if (!isArray(data.xAxis)) return null;

  const xAxisLabel = data?.xAxisLabel || '';
  const yAxisLabel = data?.yAxisLabel || '';

  return (
    <ResponsiveChartContainer series={series} xAxis={[{ data: data.xAxis, scaleType: 'band', id: 'x-axis-id' }]}>
      <BarPlot
        onItemClick={(_event, { dataIndex, seriesId }) => isFunction(onBarClick) && onBarClick(seriesId, dataIndex)}
      />
      <LinePlot />
      <MarkPlot />
      <ChartsXAxis position="bottom" axisId="x-axis-id" label={xAxisLabel} />
      <ChartsYAxis label={yAxisLabel} />
      <ChartsAxisHighlight />
      <ChartsTooltip trigger="item" />
      <ChartsGrid horizontal vertical />
      <ChartsLegend
        hidden={Boolean(hideLegend)}
        slotProps={{
          legend: {
            labelStyle: {
              fontSize: 14,
              maxWidth: 150,
            },
            itemMarkWidth: 12,
            itemMarkHeight: 12,
          },
        }}
      />
    </ResponsiveChartContainer>
  );
}
