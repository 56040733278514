import React from 'react';
import { GridColumnMenu } from '@mui/x-data-grid-pro';
import { MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import { DeleteOutlineRounded, EditRounded, FileCopyRounded, FlashOnRounded } from '@mui/icons-material';
import { AddLink, LinkOff } from '@mui/icons-material';

function EditOptionsItem({ onClick }) {
  return (
    <MenuItem onClick={onClick}>
      <ListItemIcon>
        <EditRounded fontSize="small" />
      </ListItemIcon>
      <ListItemText>Edit options</ListItemText>
    </MenuItem>
  );
}

function DeleteColumnItem({ onClick }) {
  return (
    <MenuItem onClick={onClick}>
      <ListItemIcon>
        <DeleteOutlineRounded fontSize="small" />
      </ListItemIcon>
      <ListItemText>Delete column</ListItemText>
    </MenuItem>
  );
}

function DuplicateColumnItem({ onClick }) {
  return (
    <MenuItem onClick={onClick}>
      <ListItemIcon>
        <FileCopyRounded fontSize="small" />
      </ListItemIcon>
      <ListItemText>Duplicate column</ListItemText>
    </MenuItem>
  );
}

function ActionsItem({ onClick }) {
  return (
    <MenuItem onClick={onClick}>
      <ListItemIcon>
        <FlashOnRounded fontSize="small" />
      </ListItemIcon>
      <ListItemText>Actions</ListItemText>
    </MenuItem>
  );
}

function AddReferenceItem({ onClick }) {
  return (
    <MenuItem onClick={onClick}>
      <ListItemIcon>
        <AddLink fontSize="small" />
      </ListItemIcon>
      <ListItemText>Use as reference</ListItemText>
    </MenuItem>
  );
}

function RemoveReferenceItem({ onClick }) {
  return (
    <MenuItem onClick={onClick}>
      <ListItemIcon>
        <LinkOff fontSize="small" />
      </ListItemIcon>
      <ListItemText>Remove reference</ListItemText>
    </MenuItem>
  );
}

function AutomationsItem({ onClick }) {
  return (
    <MenuItem onClick={onClick}>
      <ListItemIcon>
        <FlashOnRounded fontSize="small" />
      </ListItemIcon>
      <ListItemText>Automations</ListItemText>
    </MenuItem>
  );
}

const OPTION_TYPES = ['dropbox', 'tickbox', 'status', 'weightedList'];

export default function DataGridColumnMenu(props) {
  const { colDef } = props;
  const { columnsEditable, onMenuItemClicked, fieldType, refLimitReached, isReference } = colDef;

  const showEditOptions =
    columnsEditable &&
    [
      'dropbox',
      'weightedList',
      'tickbox',
      'status',
      'dataSet',
      'stock',
      'calculation',
      'action',
      'actionGroup',
      'readAndSign',
    ].includes(fieldType);

  const showAutomations = columnsEditable && OPTION_TYPES.includes(fieldType);

  const isStatus = fieldType === 'status';
  const showRef = !refLimitReached || isReference;

  return (
    <GridColumnMenu
      {...props}
      slots={{
        columnMenuColumnsItem: null,
        columnMenuEditOptionsItem: showEditOptions ? EditOptionsItem : null,
        columnMenuDeleteItem: columnsEditable ? DeleteColumnItem : null,
        columnMenuDuplicateItem: columnsEditable ? DuplicateColumnItem : null,
        columnMenuActionsItem: columnsEditable && isStatus ? ActionsItem : null,
        columnRefItem: showRef ? (isReference ? RemoveReferenceItem : AddReferenceItem) : null,
        columnAutomationsItem: showAutomations ? AutomationsItem : null,
      }}
      slotProps={{
        columnMenuEditOptionsItem: {
          onClick: () => {
            console.log('onMenuItemClicked');
            onMenuItemClicked('editOptions');
          },
        },
        columnMenuDeleteItem: {
          onClick: () => onMenuItemClicked('deleteColumn'),
        },
        columnMenuDuplicateItem: {
          onClick: () => onMenuItemClicked('duplicateColumn'),
        },
        columnMenuActionsItem: {
          onClick: () => onMenuItemClicked('actions'),
        },
        columnRefItem: {
          onClick: () => onMenuItemClicked(isReference ? 'removeReference' : 'addReference'),
        },
        columnAutomationsItem: {
          onClick: () => onMenuItemClicked('automations'),
        },
      }}
    />
  );
}
