import React, { useState, useEffect, useMemo } from 'react';
import { DataGridPro, GRID_CHECKBOX_SELECTION_COL_DEF } from '@mui/x-data-grid-pro';
import { isArray, difference, isEmpty, cloneDeep, isString, isDate, uniq } from 'lodash';
import GridCell from './GridCell';
import axios from 'axios';
import { Grid, Fab, IconButton, DialogTitle, DialogContent, TextField } from '@mui/material';
import StateManager from '../StateManager';
import { AddRounded, DeleteOutlineRounded } from '@mui/icons-material';
import { v4 } from 'uuid';
import { red } from '@mui/material/colors';
import { arrayMoveImmutable } from 'array-move';
import PickFieldTypeDialog from './PickFieldTypeDialog';
import FieldTypes from './FieldTypes';
import OptionsDialog from './OptionsDialog';
import EditDataSetDialog from './EditDataSetDialog';
import StockOptionsDialog from './StockOptionsDialog';
import ExpressionDialog from './ExpressionDialog';
import ActionDialog from './ActionDialog';
import ColumnSettingsDialog from '../../Hubs/registers/components/RegisterTable/ColumnSettingsDialog';
import ActionGroupDialog from './ActionGroupDialog';
import DataGridColumnMenu from './DataGridColumnMenu';
import Header from '../../Hubs/registers/components/RegisterDataGrid/Header';
import { AddHoverButton, RoundedDialog, StandardDialogActions } from '../Components';
import { areTypesCompatible } from '../../Hubs/registers/components/RegisterDataGrid/functions';
import moment from 'moment';
import UserManager from '../UserManager';
import { randomColor, validateEmail, validateUkNumber, isUuid } from '../Functions';
import ActionsDialog from './ActionsDialog';
import DocumentsDialog from './DocumentsDialog';
import AutomationDialog from './AutomationDialog';

function parseDate(str) {
  if (!str) return null;
  if (!isString(str) && !isDate(str)) return null;
  const parsed = moment(str, true);
  if (parsed.isValid()) {
    return parsed;
  }
  const strictlyParsed = moment(str);
  return strictlyParsed.isValid() ? strictlyParsed : null;
}

function parseTime(str) {
  if (!str) return null;
  if (!isString(str) && !isDate(str)) return null;
  const parsed = moment(str, ['h:m a', 'H:m']);
  if (parsed.isValid()) {
    return parsed;
  }
  return null;
}

function parseDateOnly(str) {
  if (!str) return null;
  if (!isString(str) && !isDate(str)) return null;
  const dataFormats = [moment.ISO_8601, 'DD/MM/YYYY'];
  const parsed = moment(str, dataFormats, true);
  if (parsed.isValid()) {
    return parsed;
  }
  const strictlyParsed = moment(str);
  return strictlyParsed.isValid() ? strictlyParsed : null;
}

const DEFAULT_WIDTH = 250;
const SAVING_TIMEOUT = 1200;

export default function DataGridBuilder({ initialColumns, initialRows, onTableChange, allItems }) {
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [selectedColumn, setSelectedColumn] = useState(null);
  const [fieldTypeDialog, setFieldTypeDialog] = useState(false);
  const [addingColumn, setAddingColumn] = useState(false);
  const [titleDialog, setTitleDialog] = useState(false);
  const [newColumnTitle, setNewColumnTitle] = useState(false);

  const [settingsDialog, setSettingsDialog] = useState(false);
  const [optionParams, setOptionParams] = useState({});
  const [optionsDialogOpen, setOptionsDialogOpen] = useState(false);
  const [dataSetDialog, setDataSetDialog] = useState(false);
  const [stockDialog, setStockDialog] = useState(false);
  const [expressionDialog, setExpressionDialog] = useState(false);
  const [actionGroupDialog, setActionGroupDialog] = useState(false);
  const [actionsDialog, setActionsDialog] = useState(false);
  const [documentsDialog, setDocumentsDialog] = useState(false);

  const [automationsDialog, setAutomationsDialog] = useState(false);

  const dataGridColumns = useMemo(() => {
    if (!isArray(columns)) return [];

    const currentRefCount = columns.filter((x) => x.isReference).length;
    const MAX_REF = 3;
    const refLimitReached = currentRefCount >= MAX_REF;

    const muiColumns = columns.map((column) => ({
      headerName: column.title,
      renderHeader: () => (
        <Header
          column={column}
          editable
          onTitleChange={(value) => saveColumnTitle(column.id, value)}
          openTypeDialog={() => openTypeDialog(column)}
        />
      ),
      field: column.id,
      width: column.width || DEFAULT_WIDTH,
      fieldType: column.fieldType,
      refLimitReached,
      isReference: column.isReference,
      sortable: false,
      filterable: false,
      columnsEditable: true,
      pinnable: false,
      onMenuItemClicked: (type) => onMenuItemClick(column, type),
      renderCell: ({ row }) => (
        <GridCell
          column={column}
          initial={row[column.id]}
          editable
          onChange={(value) => handleCellchange(value, row.id, column.id)}
          withTimeout
          row={row}
          textAlign={column.textAlign}
          onDataChange={() => {}}
          building
        />
      ),
      valueGetter: () => '',
    }));

    const actionColumns = [
      {
        field: 'actions',
        type: 'actions',
        resizable: false,
        width: 64,
        renderCell: ({ row }) => (
          <IconButton onClick={() => deleteRow(row.id)}>
            <DeleteOutlineRounded sx={{ color: red[500] }} />
          </IconButton>
        ),
        renderHeader: () => (
          <Grid item>
            <AddHoverButton onClick={addColumn} />
          </Grid>
        ),
      },
    ];

    muiColumns.push(...actionColumns);

    return muiColumns;
  }, [columns, rows]); // eslint-disable-line

  useEffect(() => {
    setColumns(isArray(initialColumns) ? initialColumns : []);
  }, [initialColumns]);

  useEffect(() => {
    setRows(isArray(initialRows) ? cloneDeep(initialRows) : []);
  }, [initialRows]);

  function deleteRow(rowId) {
    const filtered = rows.filter((x) => x.id !== rowId);
    setRows(filtered);
    onTableChange({ rows: filtered });
  }

  function saveOptions(res) {
    const columnIndex = getCurrColumnIndex();
    if (columnIndex === -1) return;
    const dataKey = columns[columnIndex].id;
    columns[columnIndex] = { ...columns[columnIndex], ...res };
    const newOptions = res.options.map((x) => x.id);
    rows.forEach((row) => {
      if (Array.isArray(row[dataKey]?.value)) {
        row[dataKey].value = row[dataKey].value.filter((x) => newOptions.includes(x));
      }
    });
    saveColumns([...columns]);
  }

  function saveColumns(columns) {
    setColumns(columns);
    onTableChange({ columns });
  }

  function defaultRow() {
    const id = v4();
    const row = { id, initial: true };
    columns.forEach((x) => (row[x.dataKey] = { value: null }));
    return row;
  }

  function defaultColumn() {
    const id = v4();
    return { title: '', width: DEFAULT_WIDTH, dataKey: id, id };
  }

  function addColumn() {
    setTitleDialog(true);
    setAddingColumn(true);
    setNewColumnTitle(null);
  }

  function finishAddingColumn(fieldType) {
    const column = { ...defaultColumn(), title: newColumnTitle, fieldType };
    const index = columns.length;
    saveColumns(cloneDeep([...columns, column]));
    setAddingColumn(false);
    setNewColumnTitle(null);
    setSelectedColumn(column);

    return index;
  }

  function addRow() {
    const row = defaultRow();

    rows.push(row);
    setRows([...rows]);
    onTableChange({ rows });
  }

  function getCurrColumnIndex() {
    if (!selectedColumn) return -1;
    const columnIndex = columns.findIndex((x) => x.id === selectedColumn.id);
    return columnIndex;
  }

  function handleCellchange(value, rowId, columnId) {
    const index = rows.findIndex((x) => x.id === rowId);
    if (index === -1) return;
    rows[index][columnId] = value;
    onTableChange({ rows });
  }

  function onMenuItemClick(column, eventType) {
    setSelectedColumn(column);

    if (eventType === 'editOptions') {
      const type = column.fieldType;
      if (['weightedList', 'status', 'tickbox', 'dropbox'].includes(type)) {
        setOptionParams({
          options: column.options || [],
          isTickbox: type === 'tickbox',
          selectType: column.selectType || 'single',
          withColors: type === 'status',
          withReadOnly: type === 'status',
          readOnly: column.readOnly,
          isWeighted: type === 'weightedList',
        });
        setOptionsDialogOpen(true);
      }
      if (type === 'dataSet') {
        setDataSetDialog(true);
      }
      if (type === 'stock') {
        setStockDialog(true);
      }
      if (type === 'calculation') {
        setExpressionDialog(true);
      }
      if (type === 'actionGroup') {
        setActionGroupDialog(true);
      }
      if (type === 'readAndSign') {
        setDocumentsDialog(true);
      }
    } else if (eventType === 'deleteColumn') {
      saveColumns(columns.filter((x) => x.id !== column.id));
    } else if (eventType === 'duplicateColumn') {
      const cloned = cloneDeep(column);
      const id = v4();
      cloned.id = id;
      cloned.dataKey = id;
      if (cloned.options) {
        cloned.options = cloned.options.map((option) => ({ ...option, id: v4() }));
      }
      if (cloned.preSetActions) {
        cloned.preSetActions = cloned.preSetActions.map((action) => ({ ...action, id: v4() }));
      }

      saveColumns([...columns, cloned]);
    } else if (eventType === 'settings') {
      setSettingsDialog(true);
    } else if (eventType === 'actions') {
      setActionsDialog(true);
    } else if (eventType === 'addReference') {
      saveColumnData({ isReference: true }, column);
    } else if (eventType === 'removeReference') {
      saveColumnData({ isReference: false }, column);
    } else if (eventType === 'automations') {
      setAutomationsDialog(true);
    }
  }

  function saveColumnTitle(columnId, title) {
    const columnIndex = columns.findIndex((x) => x.id === columnId);
    if (columnIndex === -1) return;

    columns[columnIndex].title = title;

    saveColumns(columns);
  }

  function openTypeDialog(column) {
    setSelectedColumn(column);
    setFieldTypeDialog(true);
  }

  function saveColumnData(data, column) {
    const columnIndex = column ? columns.findIndex((x) => x.id === column.id) : getCurrColumnIndex();
    if (columnIndex === -1) return;
    columns[columnIndex] = { ...columns[columnIndex], ...data };
    saveColumns([...columns]);
  }

  function getOptionsFromText(columnId, targetType) {
    const filled = rows.filter((row) => row[columnId]?.value);
    const stringValues = filled.map((row) => String(row[columnId]?.value).trim());
    const uniqValues = uniq(stringValues);
    const result = uniqValues.map((text) => ({
      id: v4(),
      text,
      color: targetType === 'status' ? randomColor(null, 700) : undefined,
    }));

    return result;
  }

  function isNumeric(str) {
    return !isNaN(str) && !isNaN(parseFloat(str));
  }

  function saveFieldType(type, columnEmpty, index) {
    const columnIndex = index != null ? index : getCurrColumnIndex();
    if (columnIndex === -1 || !columns[columnIndex]) return;

    const prevType = columns[columnIndex].fieldType;

    columns[columnIndex].fieldType = type;
    columns[columnIndex].observed = type === 'status' || undefined;

    const dataKey = columns[columnIndex].id;
    const updatedColumn = { id: dataKey, rows: {} };

    if (prevType && prevType !== type && !columnEmpty) {
      const typesCompatible = areTypesCompatible(prevType, type);
      if (typesCompatible === 'none') {
        // if column types are not compatible nullify the column

        rows.forEach((row) => {
          row[dataKey] = { value: null };
          updatedColumn.rows[row.id] = { value: null };
        });
      } else {
        // convert value to text
        if (['text', 'textArea'].includes(type)) {
          if (['dropbox', 'tickbox', 'status', 'weightedList'].includes(prevType)) {
            const options = columns[columnIndex].options;
            columns[columnIndex].options = [];

            rows.forEach((row) => {
              const oldValue =
                prevType === 'tickbox' && isArray(row[dataKey]?.value) ? row[dataKey]?.value[0] : row[dataKey]?.value;
              let value = '';
              if (oldValue) {
                const option = options.find((x) => x.id === oldValue);
                value = option?.text || '';
              }
              row[dataKey] = { value };
              updatedColumn.rows[row.id] = { value };
            });
          } else if (prevType === 'date') {
            rows.forEach((row) => {
              const value = row[dataKey]?.value ? moment(row[dataKey]?.value).format('DD/MM/YYYY') : '';
              row[dataKey] = { value };
              updatedColumn.rows[row.id] = { value };
            });
          } else if (prevType === 'time') {
            rows.forEach((row) => {
              const value = row[dataKey]?.value ? moment(row[dataKey]?.value).format('hh:mm A') : '';
              row[dataKey] = { value };
              updatedColumn.rows[row.id] = { value };
            });
          } else if (prevType === 'user') {
            rows.forEach((row) => {
              const old = row[dataKey]?.value;
              const users = Array.isArray(old) ? old : old ? [old] : [];
              const value =
                users && users.length > 0 ? users.map((x) => UserManager.resolveUser(x)?.fullName).join(', ') : '';
              row[dataKey] = { value };
              updatedColumn.rows[row.id] = { value };
            });
          } else if (['number', 'calculation', 'email', 'phone'].includes(prevType)) {
            rows.forEach((row) => {
              const value = row[dataKey]?.value != null ? String(row[dataKey].value) : null;
              row[dataKey] = { value };
              updatedColumn.rows[row.id] = { value };
            });
          } else if (!['text', 'textArea'].includes(prevType)) {
            rows.forEach((row) => {
              row[dataKey] = { value: null };
              updatedColumn.rows[row.id] = { value: null };
            });
          }
        }

        if (type === 'email') {
          rows.forEach((row) => {
            const prevValue = row[dataKey]?.value;
            const value = prevValue ? (validateEmail(prevValue) ? prevValue : null) : null;
            row[dataKey] = { value };
            updatedColumn.rows[row.id] = { value };
          });
        }

        if (type === 'phone') {
          rows.forEach((row) => {
            const prevValue = row[dataKey]?.value;
            const value = prevValue ? (validateUkNumber(prevValue) ? prevValue : null) : null;
            row[dataKey] = { value };
            updatedColumn.rows[row.id] = { value };
          });
        }

        if (['dropbox', 'status', 'weightedList', 'tickbox'].includes(type)) {
          // from text
          if (['text', 'textArea'].includes(prevType)) {
            const options = getOptionsFromText(dataKey, type);
            columns[columnIndex].options = options;
            rows.forEach((row) => {
              if (row[dataKey]?.value == null) return;

              const prevValue = String(row[dataKey]?.value).trim();

              const option = options.find((x) => x.text === prevValue);
              const value = option ? (type === 'tickbox' ? [option.id] : option.id) : null;
              const weight = type === 'weightedList' && option ? option.weight : undefined;
              row[dataKey] = { value, weight };
              updatedColumn.rows[row.id] = { value, weight };
            });
          }

          // tickbox => dropbox
          if (prevType === 'tickbox') {
            rows.forEach((row) => {
              if (!isArray(row[dataKey]?.value)) return;
              const value = row[dataKey].value[0] || null;
              row[dataKey] = { value };
              updatedColumn.rows[row.id] = { value };
            });
          }

          // dropbox => tickbox
          if (['dropbox', 'status', 'weightedList'].includes(prevType)) {
            rows.forEach((row) => {
              if (!isUuid(row[dataKey]?.value) && !isArray(row[dataKey]?.value)) {
                return;
              }
              const raw = isUuid(row[dataKey]?.value)
                ? row[dataKey].value
                : isArray(row[dataKey]?.value)
                ? row[dataKey].value[0]
                : null;

              const value = type === 'tickbox' && raw ? [raw] : raw;
              row[dataKey] = { value };
              updatedColumn.rows[row.id] = { value };
            });
          }
        }

        if (type === 'stock' && prevType !== 'stock') {
          rows.forEach((row) => {
            row[dataKey] = { value: null };
            updatedColumn.rows[row.id] = { value: null };
          });

          const defaultStockOptions = {
            min: 0,
            max: 100,
            initial: 100,
          };
          columns[columnIndex].stockOptions = defaultStockOptions;
        }

        if (type === 'number' && ['text', 'textArea'].includes(prevType)) {
          rows.forEach((row) => {
            const prevValue = row[dataKey]?.value;
            const value = isNumeric(prevValue) ? parseFloat(prevValue) : null;
            row[dataKey] = { value };
            updatedColumn.rows[row.id] = { value };
          });
        }

        if (['date', 'time', 'datetime'].includes(type) && ['text', 'textArea'].includes(prevType)) {
          if (type === 'date') {
            rows.forEach((row) => {
              const prevValue = row[dataKey]?.value;
              const value = prevValue ? parseDateOnly(prevValue) : null;
              row[dataKey] = { value };
              updatedColumn.rows[row.id] = { value };
            });
          }

          if (type === 'time') {
            rows.forEach((row) => {
              const prevValue = row[dataKey]?.value;
              const value = prevValue ? parseTime(prevValue) : null;
              row[dataKey] = { value };
              updatedColumn.rows[row.id] = { value };
            });
          }
          if (type === 'datetime') {
            rows.forEach((row) => {
              const prevValue = row[dataKey]?.value;
              const value = prevValue ? parseDate(prevValue) : null;
              row[dataKey] = { value };
              updatedColumn.rows[row.id] = { value };
            });
          }
        }
      }
    }

    saveColumns(columns, !isEmpty(updatedColumn.rows) ? updatedColumn : null);

    if (['weightedList', 'status', 'tickbox', 'dropbox'].includes(type)) {
      setOptionParams({
        options: columns[columnIndex].options || [],
        isTickbox: type === 'tickbox',
        selectType: columns[columnIndex].selectType || 'single',
        withColors: type === 'status',
        withReadOnly: type === 'status',
        readOnly: columns[columnIndex].readOnly,
        isWeighted: type === 'weightedList',
      });
      setOptionsDialogOpen(true);
    }

    if (type === 'dataSet') {
      setDataSetDialog(true);
      return;
    }

    if (type === 'calculation') {
      setExpressionDialog(true);
      return;
    }

    if (type === 'actionGroup') {
      setActionGroupDialog(true);
    }

    if (type === 'readAndSign') {
      setDocumentsDialog(true);
    }

    if (type === 'stock') [setStockDialog(true)];
  }

  function checkFieldType(newType) {
    let columnIndex = -1;
    if (addingColumn) {
      columnIndex = finishAddingColumn(newType);
    } else {
      columnIndex = getCurrColumnIndex();
    }

    if (columnIndex === -1 || !columns[columnIndex]) return;
    const prevType = columns[columnIndex]?.fieldType;
    const typesCompatible = areTypesCompatible(prevType, newType);

    const columnEmpty = isEmpty(rows) || rows.every((row) => row[columns[columnIndex].id]?.value == null);

    if (typesCompatible === 'fully' || columnEmpty) {
      saveFieldType(newType, columnEmpty, columnIndex);
    } else {
      StateManager.setConfirm(
        `Changing type to "${FieldTypes[newType]?.text}" ${
          typesCompatible === 'partly' ? 'may' : 'will'
        } result in data loss`,
        () => saveFieldType(newType, false),
      );
    }
  }

  function reorderRows(event) {
    const { targetIndex, oldIndex } = event;
    const newRows = arrayMoveImmutable(rows, oldIndex, targetIndex).map((row, index) => ({ ...row, index }));
    setRows(newRows);
    onTableChange({ rows: newRows });
  }

  function saveColumnsOrder(event) {
    const { oldIndex, targetIndex, column } = event;
    const { field } = column;

    const realOldIndex = columns.findIndex((x) => x.id === field);

    const diff = realOldIndex - oldIndex;

    const realTargetIndex = targetIndex + diff;

    const newColumns = arrayMoveImmutable(columns, realOldIndex, realTargetIndex);
    saveColumns(newColumns);
  }

  function saveColumnsWidth(event) {
    const field = event.colDef.field;
    const width = event.width;

    const index = columns.findIndex((x) => x.id === field);
    if (index === -1) return;
    columns[index].width = width;
    saveColumns(columns);
  }

  const calculatableColumns = useMemo(
    () => columns.filter((column) => ['number', 'date', 'weightedList', 'time'].includes(column.fieldType)),
    [columns],
  );

  const dataGridRows = useMemo(() => rows.map((row) => ({ ...row, __reorder__: 'Drag to reorder' })), [rows]);

  return (
    <Grid container style={{ position: 'relative' }}>
      <Grid container item style={{ minHeight: '20vh' }}>
        <DataGridPro
          disableRowSelectionOnClick
          rows={dataGridRows}
          columns={dataGridColumns}
          pinnedColumns={{
            right: [GRID_CHECKBOX_SELECTION_COL_DEF.field, 'actions'],
          }}
          rowReordering
          onRowOrderChange={reorderRows}
          slots={{
            columnMenu: DataGridColumnMenu,
          }}
          onColumnOrderChange={saveColumnsOrder}
          onColumnWidthChange={saveColumnsWidth}
        />
      </Grid>

      <Grid
        container
        item
        style={{ position: 'absolute', bottom: 60, width: 'fit-content', right: 120 }}
        justifyContent="flex-end"
      >
        <Fab color="primary" onClick={addRow}>
          <AddRounded fontSize="large" />
        </Fab>
      </Grid>

      <PickFieldTypeDialog
        open={fieldTypeDialog}
        onClose={() => setFieldTypeDialog(false)}
        onResult={checkFieldType}
        excludedTypes={['conditional', 'table', 'people', 'action']}
        withActionGroup
      />
      <OptionsDialog
        open={optionsDialogOpen}
        onClose={() => setOptionsDialogOpen(false)}
        initialOptions={optionParams.options}
        isTickbox={optionParams.isTickbox}
        initialSelectType={optionParams.selectType}
        onResult={saveOptions}
        withColors={optionParams.withColors}
        withReadOnly={optionParams.withReadOnly}
        initialReadOnly={optionParams.readOnly}
        isWeighted={optionParams.isWeighted}
      />

      <ColumnSettingsDialog
        open={settingsDialog}
        onClose={() => setSettingsDialog(false)}
        onResult={(res) => saveColumnData({ description: res.text, textAlign: res.textAlign })}
        initial={{
          description: selectedColumn?.description,
          textAlign: selectedColumn?.textAlign,
        }}
      />

      <EditDataSetDialog
        open={dataSetDialog}
        onClose={() => setDataSetDialog(false)}
        onResult={(dataSetParams) => saveColumnData({ dataSetParams })}
        initial={selectedColumn?.dataSetParams}
        forGrid
      />
      <StockOptionsDialog
        open={stockDialog}
        onClose={() => setStockDialog(false)}
        onResult={(stockOptions) => saveColumnData({ stockOptions })}
        initialOptions={selectedColumn?.stockOptions}
      />
      <ExpressionDialog
        open={expressionDialog}
        columns={calculatableColumns}
        onClose={() => setExpressionDialog(false)}
        onResult={(expressionParams) => saveColumnData({ expressionParams })}
        initial={selectedColumn?.expressionParams}
      />

      <ActionGroupDialog
        open={actionGroupDialog}
        onClose={() => setActionGroupDialog(false)}
        initial={selectedColumn?.preSetActions}
        onResult={(preSetActions) => saveColumnData({ preSetActions })}
        allFields={allItems}
      />

      <ActionsDialog
        open={actionsDialog}
        onClose={() => setActionsDialog(false)}
        onResult={(actionsParams) => saveColumnData(actionsParams)}
        options={selectedColumn?.options}
        initialActions={selectedColumn?.actions}
        initialConditions={selectedColumn?.statusConditions}
        allColumns={columns}
      />

      <DocumentsDialog
        open={documentsDialog}
        onClose={() => setDocumentsDialog(false)}
        onResult={(readAndSignOptions) => saveColumnData({ readAndSignOptions })}
        initial={selectedColumn?.readAndSignOptions}
      />

      <AutomationDialog
        open={automationsDialog}
        onClose={() => setAutomationsDialog(false)}
        field={selectedColumn}
        initial={selectedColumn?.automations}
        onResult={(automations) => saveColumnData({ automations })}
      />

      <TitleDialog
        open={titleDialog}
        onClose={() => setTitleDialog(false)}
        onResult={(title) => {
          setNewColumnTitle(title);
          setFieldTypeDialog(true);
        }}
      />
    </Grid>
  );
}

function TitleDialog({ open, onClose, onResult }) {
  const [title, setTitle] = useState('');

  useState(() => {
    setTitle('');
  }, [open]);

  return (
    <RoundedDialog open={open} onClose={onClose} fullWidth maxWidth="xs">
      <DialogTitle>New column title</DialogTitle>
      <DialogContent>
        <TextField
          label="Title"
          fullWidth
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          variant="standard"
          id="new column-title"
          sx={{ mt: 1 }}
        />
      </DialogContent>
      <StandardDialogActions
        onClose={onClose}
        hideDone={!title}
        onDone={() => {
          onResult(title);
          setTitle('');
          onClose();
        }}
      />
    </RoundedDialog>
  );
}
