import React from 'react';
import moment from 'moment';

import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

export default function Info({ document }) {
  return (
    <Grid container fluid="true" direction="row" spacing={2}>
      <Grid item xs={12} sx={{ textAlign: 'center' }}>
        <Typography>Current Document Details</Typography>
      </Grid>
      <Grid item xs>
        <TextField
          autoFocus
          margin="dense"
          id="filename"
          label="Filename"
          type="text"
          fullWidth
          disabled={true}
          value={document?.title}
          variant="standard"
        />
        <TextField
          margin="dense"
          id="uploadAt"
          label="Uploaded At"
          type="text"
          fullWidth
          disabled
          value={moment(document?.createdAt).format('DD/MM/YY')}
          variant="standard"
        />
      </Grid>
      <Grid item xs>
        <TextField
          margin="dense"
          id="uploadBy"
          label="Uploaded By"
          type="text"
          fullWidth
          disabled
          value={document?.ownerName}
          variant="standard"
        />
        <TextField
          margin="dense"
          id="issue"
          label="Revision"
          type="text"
          fullWidth
          disabled
          value={document?.issue}
          variant="standard"
        />
      </Grid>
    </Grid>
  );
}
