import React, { useRef } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { PanZoom } from 'react-easy-panzoom';

import { TIFFViewer } from 'react-tiff';

import ImageControls from './controls';
import { downloadFile } from '../../Hubs/dochub/constants';
import { useTheme } from '@mui/material';

export default function ImageViewer({ title, src, isTiff }) {
  const theme = useTheme();
  const panZoom = useRef(null);
  const speed = 0.5;

  function onZoomIn() {
    panZoom.current && panZoom.current.zoomIn(speed);
  }

  function onZoomOut() {
    panZoom.current && panZoom.current.zoomOut(speed);
  }

  function reset() {
    panZoom.current && panZoom.current.reset();
  }

  function rotateClockwise() {
    panZoom.current && panZoom.current.rotate((prevAngle) => prevAngle + 45);
  }

  function rotateCounterClockwise() {
    panZoom.current && panZoom.current.rotate((prevAngle) => prevAngle - 45);
  }

  if (isTiff) {
    return (
      <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center">
        <TIFFViewer
          tiff={src}
          lang="en" // en | de | fr | es | tr
          paginate="ltr" // bottom | ltr
          buttonColor={theme.palette.primary.main}
        />
      </Grid>
    );
  }

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{ minHeight: '100%', position: 'relative' }}
    >
      <PanZoom ref={panZoom} minZoom={0.5} maxZoom={3} autoCenter>
        <Box
          component="img"
          sx={{
            width: 'auto',
            height: '85vh',
            maxHeight: '80vh',
            maxWidth: '90vw',
          }}
          src={src}
          alt={title}
        />
      </PanZoom>
      <Box
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
        }}
      >
        <ImageControls
          onZoomIn={onZoomIn}
          onZoomOut={onZoomOut}
          reset={reset}
          rotateCounterClockwise={rotateCounterClockwise}
          rotateClockwise={rotateClockwise}
          download={() => downloadFile(src, title)}
        />
      </Box>
    </Grid>
  );
}
