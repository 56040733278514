import React, { useState, useEffect } from 'react';
import { Typography } from '@mui/material';
import { Description } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import axios from 'axios';

export default function FormAction({ formInfo, taskId }) {
  const [entryNo, setEntryNo] = useState('');

  useEffect(() => {
    if (!formInfo) return;
    axios
      .post(`/formhub/entries/getFillForm`, {
        _id: formInfo.previewId,
      })
      .then((res) => {
        setEntryNo(`#${res.data.fillForm.entryNo}`);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [formInfo]);
  return (
    <div style={{ display: 'flex' }}>
      <Typography style={{ margin: 'auto 0px' }}>This task was created from form </Typography>
      <Typography style={{ fontWeight: 'bold', margin: 'auto 0.4em' }}>{formInfo.formName}</Typography>

      <Link
        color="inherit"
        underline="none"
        to="/formHub/preview"
        style={{ width: 'fit-content', marginLeft: '1em' }}
        onClick={() => {
          localStorage.setItem('formID', formInfo.formId);
          localStorage.setItem('previewId', formInfo.previewId);
          localStorage.setItem('taskId', taskId);
        }}
      >
        <div
          style={{
            display: 'flex',
            padding: 5,
            borderRadius: 5,
            border: '2px solid lightgray',
          }}
        >
          <Typography style={{ margin: 'auto 0px' }}>{`Go to entry ${entryNo}`}</Typography>
          <Description style={{ color: '#64dd17', margin: '0em 0.5em' }} />
        </div>
      </Link>
    </div>
  );
}
