import React, { useState, useRef, useEffect } from 'react';
import { COLORS } from '../../../../constants';
import { RichUtils, EditorState, Modifier } from 'draft-js';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import SelectBox from '../../../SelectBox';
import OutlinedButton from '../../../../../../Global/OutlinedButton';
import { TooltipIconButton } from '../../../../../../Global/Components';
import {
  BLOCK_TYPES,
  INLINE_STYLES,
  FORMAT_STYLES,
  LIST_STYLES,
  INDENT_STYLES,
  MoreFormatMenu,
  EmojiMenu,
  MoreMenu,
  ColorMenu,
  colorStyleMap,
} from '..';

import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import AddIcon from '@mui/icons-material/Add';
import FormatColorTextIcon from '@mui/icons-material/FormatColorText';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import UndoIcon from '@mui/icons-material/Undo';
import RedoIcon from '@mui/icons-material/Redo';
import ImageIcon from '@mui/icons-material/Image';
import InsertCommentIcon from '@mui/icons-material/InsertComment';
import { Delete } from '@mui/icons-material';

export default function EditorToolbar({
  hasToc,
  editorState,
  handleMore,
  commentOnly,
  onUpdate,
  onExit,
  onComment,
  enableComment,
  onRemove,
}) {
  const emojiRef = useRef(null);
  const [blockType, setBlockType] = useState('unstyled');
  const [formats, setFormats] = useState();
  const [formatExpand, setFormatExpand] = useState(null);
  const [emoji, setEmoji] = useState(null);
  const [colorMenu, setColorMenu] = useState(null);
  const [moreMenu, setMoreMenu] = useState(null);
  const [indentControl, setIndentControl] = useState('');
  const [selectedColor, setSelectedColor] = useState('inherit');

  const toggleDepth = (e, indentDirection) => {
    e.preventDefault();
    e.stopPropagation();
    const currentBlockType = RichUtils.getCurrentBlockType(editorState);
    if (currentBlockType === 'ordered-list-item' || currentBlockType === 'unordered-list-item') {
      onUpdate(RichUtils.onTab(e, editorState, indentDirection === 'increase' ? 4 : -4));
    } else {
      // Defining number of spaces to apply after tab press
      let tabIndent = '    ';
      // Getting current state
      let currentState = editorState;
      // Getting variables to know text selection
      let selectionState = editorState.getSelection();
      let anchorKey = selectionState.getAnchorKey();
      let currentContent = editorState.getCurrentContent();
      let currentContentBlock = currentContent.getBlockForKey(anchorKey);
      let start = selectionState.getStartOffset();
      let end = selectionState.getEndOffset();
      let selectedText = currentContentBlock.getText().slice(start, end);
      // Defining next state
      let nextState = Modifier.replaceText(
        currentContent,
        selectionState,
        indentDirection === 'increase' ? tabIndent + selectedText : tabIndent - selectedText,
      );
      // Setting next state
      onUpdate(EditorState.push(currentState, nextState, 'indent'));
    }
  };

  const handleListControl = (e) => {
    const value = e.target.value;
    toggleBlock(value);
  };

  const handleIndentControl = (e) => {
    const value = e.target.value;
    setIndentControl(value);
    toggleDepth(e, value.split('depth-')[1]);
  };

  const toggleBlock = (v) => {
    const newState = RichUtils.toggleBlockType(editorState, v);
    onUpdate(EditorState.forceSelection(newState, editorState.getSelection()));
  };

  const toggleInlineStyle = (inlineStyles) => {
    setFormats(formats === inlineStyles ? null : inlineStyles);
    const newState = RichUtils.toggleInlineStyle(editorState, inlineStyles);
    onUpdate(EditorState.forceSelection(newState, newState.getSelection()));
  };

  const addEmoji = (emoji) => {
    const contentState = Modifier.replaceText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      emoji,
      editorState.getCurrentInlineStyle(),
    );
    onUpdate(EditorState.push(editorState, contentState, 'insert-characters'));
  };

  const toggleColor = (toggledColor) => {
    setSelectedColor(toggledColor);
    const selection = editorState.getSelection();
    // Let's just allow one color at a time. Turn off all active colors.
    const nextContentState = Object.keys(colorStyleMap).reduce((contentState, color) => {
      return Modifier.removeInlineStyle(contentState, selection, color);
    }, editorState.getCurrentContent());
    let nextEditorState = EditorState.push(editorState, nextContentState, 'change-inline-style');
    const currentStyle = editorState.getCurrentInlineStyle();
    // Unset style override for current color.
    if (selection.isCollapsed()) {
      nextEditorState = currentStyle.reduce((state, color) => {
        return RichUtils.toggleInlineStyle(state, color);
      }, nextEditorState);
    }
    // If the color is being toggled on, apply it.
    if (!currentStyle.has(toggledColor)) {
      nextEditorState = RichUtils.toggleInlineStyle(nextEditorState, toggledColor);
    }
    onUpdate(nextEditorState);
  };

  const undo = () => {
    onUpdate(EditorState.undo(editorState));
  };

  const redo = () => {
    onUpdate(EditorState.redo(editorState));
  };

  const getBlockValue = () => {
    if (
      [
        'unordered-list-item',
        'unstyled',
        'ordered-list-item',
        'atomic',
        'align-center',
        'align-left',
        'align-right',
      ].includes(blockType)
    ) {
      return 'unstyled';
    }
    return blockType;
  };

  const getListValue = () => {
    if (['unordered-list-item', 'ordered-list-item'].includes(blockType)) {
      return blockType;
    } else {
      return '';
    }
  };

  const getInlineStyle = (style) => {
    const styles = editorState.getCurrentInlineStyle().has(style);
    return styles;
  };

  useEffect(() => {
    if (!editorState) return;
    const s = editorState.getSelection();
    if (s.isCollapsed()) return;
    const block = editorState.getCurrentContent().getBlockForKey(s?.getStartKey());
    if (block) {
      const type = block.getType();
      setBlockType(type);
      if (type === 'unstyled') {
        setFormats(null);
      }
    }
  }, [editorState]);

  if (commentOnly && onComment) {
    return (
      <Paper
        elevation={4}
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          alignItems: 'center',
          justifyContent: 'center',
          margin: (theme) => theme.spacing(1),
          padding: (theme) => theme.spacing(0.5),
        }}
      >
        <TooltipIconButton onClick={onComment} text="Add Comment">
          <InsertCommentIcon sx={{ color: COLORS.BOOKMARK }} />
        </TooltipIconButton>
      </Paper>
    );
  }

  if (commentOnly) return null;

  return (
    <Paper
      elevation={4}
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'center',
        margin: (theme) => theme.spacing(1),
        padding: (theme) => theme.spacing(0.5),
      }}
    >
      <TooltipIconButton aria-label="undo" onClick={undo} text={'Undo'}>
        <UndoIcon />
      </TooltipIconButton>
      <TooltipIconButton aria-label="redo" onClick={redo} text={'Redo'}>
        <RedoIcon />
      </TooltipIconButton>
      <Divider flexItem orientation="vertical" sx={{ margin: (theme) => theme.spacing(0.75, 0.75) }} />
      <SelectBox
        borderRadius={7}
        small
        value={getBlockValue()}
        options={BLOCK_TYPES.map((x) => ({ label: x.label, value: x.style }))}
        handleValue={(v) => toggleBlock(v)}
        minWidth={85}
        disabled={BLOCK_TYPES.includes((a) => a.style !== getBlockValue())}
        onExit={onExit}
      />
      <Divider flexItem orientation="vertical" sx={{ margin: (theme) => theme.spacing(0.75, 0.75) }} />
      {INLINE_STYLES.map((type, index) => (
        <TooltipIconButton
          aria-label={type.style}
          key={index}
          onClick={(e) => toggleInlineStyle(type.style)}
          sx={{
            ...(getInlineStyle(type.style) && {
              fontWeight: 'bold',
              color: COLORS.SEARCH,
            }),
          }}
          text={type.label}
        >
          {type.icon}
        </TooltipIconButton>
      ))}
      <TooltipIconButton aria-label="expand" onClick={(e) => setFormatExpand(e.currentTarget)} text={'More Options'}>
        <MoreHorizIcon />
      </TooltipIconButton>
      <Divider flexItem orientation="vertical" sx={{ margin: (theme) => theme.spacing(0.75, 0.75) }} />
      <SelectBox
        borderRadius={7}
        small
        value={FORMAT_STYLES.find((x) => x.value === blockType) ? blockType : 'align-left'}
        options={FORMAT_STYLES}
        handleValue={(v) => toggleBlock(v)}
        minWidth={35}
        onExit={onExit}
      />
      <Divider flexItem orientation="vertical" sx={{ margin: (theme) => theme.spacing(0.75, 0.75) }} />
      <TooltipIconButton onClick={(e) => setColorMenu(e.currentTarget)} text={'Text Color'}>
        <FormatColorTextIcon
          sx={{
            color:
              selectedColor === 'inherit'
                ? selectedColor
                : getInlineStyle(selectedColor)
                ? `#${selectedColor.split('color-')[1]}`
                : 'inherit',
          }}
        />
      </TooltipIconButton>
      <Divider flexItem orientation="vertical" sx={{ margin: (theme) => theme.spacing(0.75, 0.75) }} />
      <SelectBox
        borderRadius={7}
        small
        sendE
        value={getListValue()}
        options={LIST_STYLES}
        handleValue={handleListControl}
        minWidth={35}
        onExit={onExit}
        noValue={true}
        activeColor={getListValue() !== '' ? COLORS.SEARCH : '#000'}
        renderValue={(v) => (v ? LIST_STYLES.find((x) => x.value === v)?.label : LIST_STYLES[0].label)}
      />
      <Divider flexItem orientation="vertical" sx={{ margin: (theme) => theme.spacing(0.75, 0.75) }} />
      <SelectBox
        borderRadius={7}
        small
        sendE
        value={indentControl}
        options={INDENT_STYLES}
        handleValue={handleIndentControl}
        minWidth={35}
        onExit={onExit}
        noValue={true}
        activeColor={indentControl !== '' ? COLORS.SEARCH : '#000'}
        renderValue={(v) => (v ? INDENT_STYLES.find((x) => x.value === v)?.label : INDENT_STYLES[0].label)}
      />
      <Divider flexItem orientation="vertical" sx={{ margin: (theme) => theme.spacing(0.75, 0.75) }} />
      <TooltipIconButton onClick={(e) => setEmoji(e.currentTarget)} text={'Emoticons'}>
        <InsertEmoticonIcon />
      </TooltipIconButton>
      <TooltipIconButton text="Add A Quote" onClick={(e) => toggleBlock('blockquote')}>
        <FormatQuoteIcon />
      </TooltipIconButton>
      <TooltipIconButton onClick={() => handleMore('image-section')} text="Upload Image">
        <ImageIcon />
      </TooltipIconButton>
      {/* {onComment && enableComment && (
        <TooltipIconButton onClick={onComment} text="Add Comment">
          <InsertCommentIcon sx={{ color: COLORS.BOOKMARK }} />
        </TooltipIconButton>
      )} */}
      <Box sx={{ margin: '0 2px' }} />
      <OutlinedButton onClick={(e) => setMoreMenu(e.currentTarget)} endIcon={<AddIcon />}>
        More
      </OutlinedButton>
      {onRemove && (
        <TooltipIconButton
          onClick={onRemove}
          text="Delete this section"
          sx={{
            color: (t) => t.palette.grey[500],
            mx: 1,
            '&:hover': {
              color: 'red',
            },
          }}
        >
          <Delete />
        </TooltipIconButton>
      )}
      <MoreFormatMenu
        open={formatExpand}
        handleClose={() => setFormatExpand(null)}
        handleClick={(v) => toggleInlineStyle(v)}
      />
      <EmojiMenu open={emoji} handleClose={() => setEmoji(null)} handleClick={addEmoji} ref={emojiRef} />
      <ColorMenu open={colorMenu} handleClose={() => setColorMenu(null)} handleClick={toggleColor} />
      <MoreMenu
        open={moreMenu}
        handleClose={() => setMoreMenu(null)}
        handleClick={(v) => handleMore(v)}
        hasToc={hasToc}
      />
    </Paper>
  );
}
