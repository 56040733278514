import React, { useState, useEffect } from 'react';
import { Grid, Typography, TextField, IconButton, CircularProgress } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { grey } from '@mui/material/colors';
import { Edit } from '@mui/icons-material';
import { DocIcon, ReadUnderstoodIcon } from '../Icons';
import axios from 'axios';

export default function DocumentAutocomplete({ documentId, documentTitle, saveRU, disabled }) {
  const [availableDocs, setAvailableDocs] = useState([]);
  const [docsOpen, setDocsOpen] = useState(false);
  const loadDocs = docsOpen && availableDocs.length === 0;

  useEffect(() => {
    if (!loadDocs) return;
    axios
      .post('dochub/documents/getAllDocuments')
      .then((res) => {
        setAvailableDocs(res.data.Documents);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [loadDocs]);

  return (
    <>
      {!documentId && (
        <Autocomplete
          options={availableDocs}
          style={{ marginLeft: '1rem', flexGrow: 1 }}
          getOptionLabel={(option) => option.title}
          openOnFocus
          renderOption={(props, option) => (
            <li {...props}>
              <Grid container alignItems="center">
                <DocIcon />
                <Typography style={{ marginLeft: '1rem', width: '90%' }} noWrap>
                  {option.title}
                </Typography>
              </Grid>
            </li>
          )}
          renderInput={(params) => (
            <TextField
              variant="outlined"
              {...params}
              label="Select a Doc"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loadDocs ? <CircularProgress color="primary" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
          onChange={(e, value) => {
            saveRU(value);
          }}
          open={docsOpen}
          onOpen={() => setDocsOpen(true)}
          onClose={() => setDocsOpen(false)}
          loading={loadDocs}
        />
      )}
      {documentId && (
        <>
          <Typography variant="h6" noWrap style={{ marginLeft: '1rem', flexGrow: 1 }}>
            {documentTitle}
          </Typography>
          {!disabled && (
            <IconButton onClick={() => saveRU({})} style={{ marginLeft: 32 }}>
              <Edit style={{ color: grey[500] }} />
            </IconButton>
          )}
        </>
      )}
    </>
  );
}
