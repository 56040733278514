import React, { useState, useEffect, useRef } from 'react';
import { DialogContent, DialogTitle, Grid, TextField, CircularProgress, Typography, Button } from '@mui/material';
import { RadioGroup, FormControlLabel, Radio, Collapse } from '@mui/material';
import { DeleteOutlineRounded, GroupAddRounded } from '@mui/icons-material';
import RoundedDialog from './RoundedDialog';
import axios from 'axios';
import Uploader from '../Uploader';
import StateManager from '../StateManager';
import StandardDialogActions from './StandardDialogActions';
import { useSelector } from 'react-redux';
import UserGroup from './UserGroup';
import { isEmpty, isFunction } from 'lodash';
import FileViewer from '../FileViewer';
import { FormatDate } from '../Functions';
import User from './User';
import { red } from '@mui/material/colors';
import { useHistory } from 'react-router-dom';
import CommentsSection from '../CommentsSection';
import { RegistersIcon, FormIcon, ProcessIcon } from '../Icons';
import ActionGroup from '../Fields/ActionGroup';
import DateFormatter from './DateFormatter';

const icons = {
  form: <FormIcon />,
  formEntry: <FormIcon />,
  process: <ProcessIcon />,
  processEntry: <ProcessIcon />,
  register: <RegistersIcon />,
};

const Types = {
  form: 'form',
  process: 'process',
  formEntry: 'form entry',
  processEntry: 'process entry',
};

export default function ReviewTaskDialog({ open, onClose, reviewEntryId, onComplete, onDelete, showTarget }) {
  const [saving, setSaving] = useState(false);
  const [status, setStatus] = useState(false);
  const [entry, setEntry] = useState(null);
  const [loading, setLoading] = useState(false);
  const [reason, setReason] = useState('');
  const [outcome, setOutcome] = useState('');
  const [files, setFiles] = useState([]);
  const [comments, setComments] = useState([]);
  const [newVersionRequired, setNewVersionRequired] = useState('no');
  const [reasonForUpissue, setReasonForUpissue] = useState('');
  const [assignedForUpIssue, setAssignedForUpIssue] = useState([]);
  const history = useHistory();
  const debounceTimer = useRef(null);
  const currentChange = useRef(null);
  const DEBOUNCE_DELAY = 1200;
  const { user } = useSelector(({ profile }) => profile);
  const editable = user && entry && !entry.completedAt && entry.assigned.includes(user._id);
  const deletable = user && entry && (entry.createdBy === user._id || user.access === 'admin');

  useEffect(() => {
    if (!reviewEntryId) {
      setEntry(null);
      setReason('');
      setOutcome('');
      setFiles([]);
      setLoading(false);
      setNewVersionRequired('no');
      setReasonForUpissue('');
      setAssignedForUpIssue([]);
      return;
    }

    setLoading(true);
    axios
      .get('/reviews/entries/getReviewEntry', { params: { reviewEntryId } })
      .then(({ data }) => {
        setEntry(data);
        setReason(data.reason || '');
        setOutcome(data.outcome || '');
        setFiles(data.files || []);
        setComments(data.comments || []);
        setNewVersionRequired(data.newVersionRequired ? 'yes' : 'no');
        setReasonForUpissue(data.reasonForUpissue || '');
        setAssignedForUpIssue(data.assignedForUpIssue || []);
        setStatus('');
        setLoading(false);
      })
      .catch((err) => {
        setStatus('');
        setLoading(false);
        StateManager.setAxiosErrorAlert(err);
      });
  }, [reviewEntryId]);

  function triggerTimer(data) {
    if (debounceTimer.current != null) {
      clearTimeout(debounceTimer.current);
    }
    currentChange.current = { ...currentChange.current, ...data };
    debounceTimer.current = setTimeout(() => save(), DEBOUNCE_DELAY);
  }

  function save() {
    setStatus('Saving...');

    axios
      .post('/reviews/entries/saveReviewEntry', { reviewEntryId: entry._id, update: currentChange.current })
      .then(() => {
        currentChange.current = null;
        setStatus('Saved');
      })
      .catch((err) => {
        setStatus('Failed to save the review');
        StateManager.setAxiosErrorAlert(err);
      });
  }

  const completable = entry && outcome && (entry.scheduled || reason) && !entry.completedAt;

  function completeEntry() {
    if (!completable) return;

    if (
      newVersionRequired === 'yes' &&
      !isEmpty(assignedForUpIssue) &&
      (assignedForUpIssue.length !== 1 || assignedForUpIssue[0] !== user?._id) &&
      !reasonForUpissue
    ) {
      StateManager.setErrorAlert('Please provide reason for up-issue');
      return;
    }

    setSaving(true);
    axios
      .post('/reviews/entries/completeReviewEntry', { reviewEntryId: entry._id })
      .then(({ data }) => {
        StateManager.setSuccessAlert('Review has been completed');
        setSaving(false);
        const result = { ...entry, ...data };
        setEntry(result);
        if (isFunction(onComplete)) {
          onComplete(result);
        }
        onClose();
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
        setSaving(false);
      });
  }

  function confirmDelete() {
    StateManager.setConfirm('You are about to delete this review', deleteEntry);
  }

  function deleteEntry() {
    setSaving(true);
    axios
      .post('/reviews/entries/deleteReviewEntry', { reviewEntryId: entry._id })
      .then(() => {
        StateManager.setSuccessAlert('Review has been deleted');
        setSaving(false);
        if (isFunction(onDelete)) {
          onDelete(entry._id);
        }
        onClose();
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
        setSaving(false);
      });
  }

  function pickAssigned() {
    StateManager.selectMultipleUsers(saveAssigned, { initiallySelected: assignedForUpIssue });
  }

  function saveAssigned(res) {
    if (!res?.users) return;
    setAssignedForUpIssue(res.users);
    triggerTimer({ assignedForUpIssue: res.users });
  }

  function goToTarget() {
    if (entry.target.type === 'form') {
      history.push(`/forms/${entry.target.targetId}`);
    }
    if (entry.target.type === 'formEntry') {
      history.push(`/forms/entry/${entry.target.targetId}`);
    }
    if (entry.target.type === 'process') {
      history.push(`/processes/${entry.target.targetId}`);
    }
    if (entry.target.type === 'register') {
      history.push(`/registers/${entry.target.targetId}`);
    }
  }

  return (
    <RoundedDialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      {loading || !entry ? (
        <DialogContent>
          <Grid container justifyContent={'center'} alignItems={'center'} sx={{ height: '50vh' }}>
            <CircularProgress />
          </Grid>
        </DialogContent>
      ) : (
        <>
          <DialogTitle>
            <Grid container justifyContent={'space-between'}>
              <Typography variant="h6">{entry?.title}</Typography>
              <UserGroup ids={entry.assigned} avatarSize={32} />
            </Grid>
          </DialogTitle>
          <DialogContent>
            <Grid container>
              {showTarget && entry.target && (
                <Grid container item sx={{ mb: 1 }} alignItems="center">
                  <Button startIcon={icons[entry.target.type]} onClick={goToTarget}>
                    Go to the {Types[entry.target.type]}
                  </Button>
                </Grid>
              )}
              {entry.dueAt && (
                <Grid container item sx={{ mt: 1 }} alignItems="center">
                  <Typography sx={{ fontWeight: 500 }}>
                    Due <DateFormatter date={entry.dueAt} formatFunction={FormatDate} />
                  </Typography>
                </Grid>
              )}
              <Grid container item sx={{ mt: 1 }} alignItems="center">
                <Typography color={'textSecondary'}>
                  Created <DateFormatter date={entry.createdAt} formatFunction={FormatDate} />{' '}
                  {entry.createdBy ? 'by' : 'automatically'}
                </Typography>
                {entry.createdBy && <User id={entry.createdBy} avatarSize={32} sx={{ ml: 2 }} />}
              </Grid>
              {entry.completedAt && (
                <>
                  <Grid container item sx={{ mt: 1 }} alignItems="center">
                    <Typography color={'textSecondary'}>
                      Completed {FormatDate(entry.completedAt)} {entry.completedBy ? 'by' : ''}
                    </Typography>
                    {entry.completedBy && <User id={entry.completedBy} avatarSize={32} sx={{ ml: 2 }} />}
                  </Grid>
                </>
              )}
              <Grid container item sx={{ mt: 2 }}>
                {entry.scheduled ? (
                  <Typography color="textSecondary">This is a routine scheduled task</Typography>
                ) : editable ? (
                  <TextField
                    multiline
                    required
                    fullWidth
                    minRows={5}
                    label="Reason for review"
                    value={reason}
                    onChange={(e) => {
                      setReason(e.target.value);
                      triggerTimer({ reason: e.target.value });
                    }}
                  />
                ) : (
                  <>
                    <Grid container>
                      <Typography sx={{ fontWeight: 500, fontSize: '1.1rem' }}>Reason for review:</Typography>
                    </Grid>
                    <Grid container sx={{ mt: 1 }}>
                      <Typography color={reason ? '' : 'textSecondary'} sx={{ wordBreak: 'break-word' }}>
                        {reason || 'No reason provided'}
                      </Typography>
                    </Grid>
                  </>
                )}
              </Grid>
              <Grid container item sx={{ mt: 3 }}>
                {editable ? (
                  <TextField
                    multiline
                    required
                    fullWidth
                    minRows={5}
                    label="Review outcome"
                    value={outcome}
                    onChange={(e) => {
                      setOutcome(e.target.value);
                      triggerTimer({ outcome: e.target.value });
                    }}
                  />
                ) : (
                  <>
                    <Grid container>
                      <Typography sx={{ fontWeight: 500, fontSize: '1.1rem' }}>Review outcome:</Typography>
                    </Grid>
                    <Grid container sx={{ mt: 1 }}>
                      <Typography color={outcome ? '' : 'textSecondary'} sx={{ wordBreak: 'break-word' }}>
                        {outcome || 'No outcome provided'}
                      </Typography>
                    </Grid>
                  </>
                )}
              </Grid>
              <Grid container item sx={{ mt: 2 }}>
                {editable ? (
                  <Uploader
                    uploaded={files}
                    onChange={(files) => {
                      setFiles(files);
                      triggerTimer({ files });
                    }}
                  />
                ) : (
                  <FileViewer files={files} />
                )}
              </Grid>

              <Grid container item sx={{ mt: 2 }}>
                <Typography variant="h6" gutterBottom>
                  Actions:
                </Typography>
                <ActionGroup editable={editable} forReview activityInfo={{ type: 'review', entryId: entry._id }} />
              </Grid>

              <Grid container item sx={{ mt: 2 }}>
                <Grid container item>
                  <Typography variant="h6" gutterBottom>
                    Comments:
                  </Typography>
                </Grid>

                <Grid container item>
                  {isEmpty(comments) && (
                    <Typography color="textSecondary" gutterBottom>
                      No one has commented yet
                    </Typography>
                  )}
                </Grid>

                <CommentsSection
                  title=""
                  comments={comments}
                  addComment={(comment) => {
                    const value = [...comments, comment];
                    setComments(value);
                    triggerTimer({ comments: value });
                  }}
                  deleteComment={(id) => {
                    const value = comments.filter((x) => x.id !== id);
                    setComments(value);
                    triggerTimer({ comments: value });
                  }}
                />
              </Grid>

              {entry.askAboutNewVersion && editable && (
                <Grid container item sx={{ mt: 4 }} alignItems="center">
                  <Grid container item>
                    <Typography gutterBottom>Do you want to trigger a new version after this review?</Typography>
                  </Grid>

                  <Grid container item>
                    <RadioGroup
                      row
                      value={newVersionRequired}
                      onChange={(e) => {
                        triggerTimer({ newVersionRequired: e.target.value === 'yes' });
                        setNewVersionRequired(e.target.value);
                      }}
                    >
                      <FormControlLabel value="yes" label="Yes" control={<Radio />} />
                      <FormControlLabel value="no" label="No" control={<Radio />} />
                    </RadioGroup>
                  </Grid>

                  <Grid container item component={Collapse} in={newVersionRequired === 'yes'}>
                    <Grid container alignItems={'center'} sx={{ py: 2 }}>
                      <Button startIcon={<GroupAddRounded />} onClick={pickAssigned} sx={{ textWrap: 'nowrap', mr: 2 }}>
                        select users
                      </Button>

                      {isEmpty(assignedForUpIssue) ? (
                        <Typography color="textSecondary" sx={{ fontSize: '0.9em' }}>
                          If you don't assign users the up-issue task will be sent to you
                        </Typography>
                      ) : (
                        <UserGroup ids={assignedForUpIssue} />
                      )}
                    </Grid>
                    <Grid
                      container
                      component={Collapse}
                      in={
                        !isEmpty(assignedForUpIssue) &&
                        (assignedForUpIssue.length !== 1 || assignedForUpIssue[0] !== user?._id)
                      }
                    >
                      <TextField
                        multiline
                        required
                        fullWidth
                        minRows={5}
                        label="Please indicate why an up-issue is needed"
                        value={reasonForUpissue}
                        onChange={(e) => {
                          setReasonForUpissue(e.target.value);
                          triggerTimer({ reasonForUpissue: e.target.value });
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              )}

              {entry.completedAt && entry.newVersionStarted && (
                <Grid container item sx={{ mt: 3 }} alignItems="center">
                  <Typography sx={{ fontSize: '1.1rem', fontWeight: 500 }}>
                    New version was started because of this review
                  </Typography>
                </Grid>
              )}
            </Grid>
          </DialogContent>
        </>
      )}
      <StandardDialogActions
        additionalActions={
          <>
            {deletable && (
              <Button startIcon={<DeleteOutlineRounded />} sx={{ color: red[500], mr: 2 }} onClick={confirmDelete}>
                delete review
              </Button>
            )}
            <Typography color={'textSecondary'} sx={{ mr: 'auto', ml: 1, fontSize: '0.8rem' }}>
              {status}
            </Typography>
          </>
        }
        onClose={onClose}
        saving={saving}
        hideDone={!completable}
        onDone={completeEntry}
        doneButtonText={'complete review'}
      />
    </RoundedDialog>
  );
}
