import React, { useState, useEffect, useRef } from 'react';
import { DialogContent, DialogTitle, ListItemButton, ListItemText, Typography, Grid, Radio } from '@mui/material';
import { FormControlLabel, Checkbox, TextField, FormControl, FormLabel, RadioGroup } from '@mui/material';
import { RoundedDialog, StandardDialogActions } from '../Components';
import { MentionsInput, Mention } from 'react-mentions';
import { grey, blue, red } from '@mui/material/colors';
import axios from 'axios';
import StateManager from '../StateManager';
import { styled } from '@mui/material/styles';

const StyledMentionsInput = styled(MentionsInput)(({ theme }) => ({
  '& div[class*="suggestions"]': {
    boxShadow: theme.shadows[12],
    borderRadius: 8,
  },
  '& ul': {
    borderRadius: 8,
    background: theme.palette.background.paper,
  },
  '& textarea': {
    border: 'none',
    borderRadius: 0.5,
    outline: 0,
    color: theme.palette.text.primary,
  },
}));

export default function ExpressionDialog({ initial, open, onClose, onResult, columns }) {
  const [text, setText] = useState('');
  const [error, setError] = useState(false);

  const [decimals, setDecimals] = useState(3);
  const [round, setRound] = useState(true);
  const [format, setFormat] = useState('number');
  const [mentions, setMentions] = useState([]);
  const timer = useRef(null);
  const TIMEOUT = 600;

  const mentionRegex =
    /(@\[.+?\])(\((\b[0-9a-f]{8})\b-([0-9a-f]{4})-([0-9a-f]{4})-([0-9a-f]{4})-(\b[0-9a-f]{12}\b)\))/g;
  const variblePattern = '$_$3$4$5$6$7';

  function getExpressionColumns(expression) {
    if (!expression || typeof expression !== 'string') return [];
    const regex = /\$_([0-9a-f]{8})([0-9a-f]{4})([0-9a-f]{4})([0-9a-f]{4})([0-9a-f]{12})/g;
    const matches = expression.match(regex);
    return matches.map((match) => match.replace(regex, '$1-$2-$3-$4-$5'));
  }

  function triggerTimer(expression, columns) {
    if (timer.current != null) {
      clearTimeout(timer.current);
    }
    timer.current = setTimeout(() => checkExpression(expression, columns), TIMEOUT);
  }

  function checkExpression(expression, columns) {
    if (!expression) {
      setError(true);
      return;
    }
    axios
      .post('registers/general/checkExpression', { expression, columns })
      .then((res) => {
        setError(!res.data.valid);
      })
      .catch((err) => {
        setError(null);
        StateManager.setAxiosErrorAlert(err);
      });
  }

  function getSuggestions(cols) {
    const result = [];
    result.push({
      text: `${cols[0].title} + ${cols[1].title}`,
      expr: `@[${cols[0].title}](${cols[0].id}) + @[${cols[1].title}](${cols[1].id})`,
      columns: [cols[0], cols[1]],
    });
    result.push({
      text: `${cols[0].title} * ${cols[1].title}`,
      expr: `@[${cols[0].title}](${cols[0].id}) * @[${cols[1].title}](${cols[1].id})`,
      columns: [cols[0], cols[1]],
    });
    result.push({
      text: `${cols[0].title} - ${cols[1].title}`,
      expr: `@[${cols[0].title}](${cols[0].id}) - @[${cols[1].title}](${cols[1].id})`,
      columns: [cols[0], cols[1]],
    });
    result.push({
      text: `${cols[0].title} / ${cols[1].title}`,
      expr: `@[${cols[0].title}](${cols[0].id}) / @[${cols[1].title}](${cols[1].id})`,
      columns: [cols[0], cols[1]],
    });
    result.push({
      text: `${cols[1].title} - ${cols[0].title}`,
      expr: `@[${cols[1].title}](${cols[1].id}) - @[${cols[0].title}](${cols[0].id})`,
      columns: [cols[1], cols[0]],
    });
    result.push({
      text: `${cols[1].title} / ${cols[0].title}`,
      expr: `@[${cols[1].title}](${cols[1].id}) / @[${cols[0].title}](${cols[0].id})`,
      columns: [cols[1], cols[0]],
    });
    return result;
  }

  const suggestions = Array.isArray(columns) && columns.length === 2 ? getSuggestions(columns) : [];

  useEffect(() => {
    if (!open) return;
    if (initial?.text) {
      setText(initial.text);
      setDecimals(initial.decimals != null ? initial.decimals : 3);
      setRound(initial.decimals != null);
      setFormat(initial.format);
    } else {
      setText('');
      setDecimals(3);
      setRound(true);
      setFormat('number');
      setError(false);
    }
  }, [open, initial, columns]);

  function onChange(string, mentions) {
    setError(null);
    setText(string);
    if (!Array.isArray(mentions)) return;
    setMentions(mentions);
    const expr = String(string).replace(mentionRegex, variblePattern);
    const ids = mentions.map((x) => x.id);
    triggerTimer(expr, ids);
  }

  function done() {
    const expression = String(text).replace(mentionRegex, variblePattern).trim().replace(/\s+/g, ' ').trim();
    const result = {
      text,
      expression,
      decimals: round ? decimals : null,
      format,
      columns: getExpressionColumns(expression),
    };
    onResult(result);
    onClose();
  }

  return (
    <RoundedDialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>Calculation</DialogTitle>
      <DialogContent>
        {columns.length === 0 && <Typography color="textSecondary">No columns available</Typography>}
        <Typography style={{ color: red[500], opacity: error ? 1 : 0 }} gutterBottom>
          Invalid expression
        </Typography>

        {columns.length > 0 && (
          <div style={{ minHeight: 300 }}>
            <Grid sx={{ border: `1px solid ${blue[500]}`, borderRadius: 1, p: 1 }}>
              <StyledMentionsInput
                value={text}
                placeholder="Type $ to pick a column"
                onChange={(event, newValue, newPlainTextValue, mentions) => onChange(event.target.value, mentions)}
              >
                <Mention
                  renderSuggestion={(entry, search, highlightedDisplay, index, focused) => (
                    <>
                      <ListItemButton
                        dense
                        sx={{
                          backgroundColor: focused
                            ? (theme) => (theme.palette.mode === 'dark' ? grey[700] : grey[100])
                            : '',
                          borderRadius: 1,
                        }}
                      >
                        <ListItemText primary={entry.display} />
                      </ListItemButton>
                    </>
                  )}
                  data={columns.map((column) => ({ id: column.id, display: column.title || '-', column }))}
                  trigger="$"
                  appendSpaceOnAdd
                />
              </StyledMentionsInput>
            </Grid>
            {format !== 'date' && (
              <Grid container item alignItems="center" style={{ marginTop: 8 }}>
                <FormControlLabel
                  onChange={(e) => setRound(e.target.checked)}
                  label="Round to"
                  control={<Checkbox color="primary" checked={round} />}
                />
                <TextField
                  value={decimals}
                  onChange={(e) => {
                    const num = Number(e.target.value);
                    if (num < 0) return;
                    setDecimals(num);
                  }}
                  variant="standard"
                  type="number"
                  inputProps={{ min: 0 }}
                  disabled={!round}
                  style={{ width: '3rem' }}
                />
                <Typography style={{ marginLeft: '0.5rem' }}>decimal places</Typography>
              </Grid>
            )}
            <Grid container item alignItems="center" style={{ marginTop: 8 }}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Format</FormLabel>
                <RadioGroup value={format} onChange={(e) => setFormat(e.target.value)} row>
                  <FormControlLabel value={'number'} label={'Number'} control={<Radio color="primary" />} />
                  <FormControlLabel value={'dollar'} label={'Dollar'} control={<Radio color="primary" />} />
                  <FormControlLabel value={'pound'} label={'Pound'} control={<Radio color="primary" />} />
                  <FormControlLabel value={'euro'} label={'Euro'} control={<Radio color="primary" />} />
                  <FormControlLabel value={'percent'} label={'Percent'} control={<Radio color="primary" />} />
                  <FormControlLabel value={'date'} label={'Date'} control={<Radio color="primary" />} />
                  <FormControlLabel value={'time'} label={'Time'} control={<Radio color="primary" />} />
                </RadioGroup>
              </FormControl>
            </Grid>
            {columns.length === 2 && (
              <Grid container item style={{ padding: 8 }}>
                <Typography style={{ fontWeight: 500 }} gutterBottom>
                  Suggested formulas
                </Typography>
                <Grid container item>
                  {suggestions.map((x, i) => (
                    <ListItemButton
                      key={i}
                      dense
                      button
                      style={{ borderRadius: 8 }}
                      onClick={() => {
                        setText(x.expr);
                        setMentions(x.columns);
                      }}
                    >
                      <ListItemText primary={x.text} />
                    </ListItemButton>
                  ))}
                </Grid>
              </Grid>
            )}
          </div>
        )}
      </DialogContent>
      <StandardDialogActions onClose={onClose} onDone={done} hideDone={error !== false || !text} />
    </RoundedDialog>
  );
}
