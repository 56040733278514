import { Component } from 'react';
import ViewSDKClient from './ViewSDKClient';
import { v4 } from 'uuid';

const configurations = (enablePrint = false, enableDownload = false) => ({
  dockPageControls: true,
  showDownloadPDF: enableDownload,
  showPrintPDF: enablePrint,
  showLeftHandPanel: false,
  enableAnnotationAPIs: false,
  includePDFAnnotations: false,
  showAnnotationTools: false,
});

class PDFViewer extends Component {
  constructor(props) {
    super();
    this.state = {
      id: v4(),
    };
  }

  componentDidMount() {
    const viewSDKClient = new ViewSDKClient();
    viewSDKClient.ready().then(() => {
      /* Invoke file preview */
      if (this.props.file) {
        viewSDKClient.previewFile(
          `pdf-div-${this.state.id}`,
          configurations(this.props.enablePrint, this.props.enableDownload),
          this.props.file,
          true,
        );
      }
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.file?.location !== prevProps.file?.location) {
      const viewSDKClient = new ViewSDKClient();
      viewSDKClient.ready().then(() => {
        viewSDKClient.previewFile(
          `pdf-div-${this.state.id}`,
          configurations(this.props.enablePrint, this.props.enableDownload),
          this.props.file,
          true,
        );
      });
    }
  }

  render() {
    return <div id={`pdf-div-${this.state.id}`} className="full-window-div" />;
  }
}

export default PDFViewer;
