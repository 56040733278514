import React, { useEffect, useState } from 'react';
import { Grid, CircularProgress, Typography } from '@mui/material';
import { LibraryBooksRounded } from '@mui/icons-material';
import { Button, Box } from '@mui/material';
import { FormIcon, ProcessIcon, RegistersIcon, DocIcon } from '../Icons';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { isArray, isEmpty, first } from 'lodash';
import StateManager from '../StateManager';
import { grey, blue } from '@mui/material/colors';
import { VFORMAT, formatMiniRevisionIteration } from '../../Hubs/dochub/constants';

export default function FieldReference({ actionReferenceInfo, actionReferences }) {
  const [info, setInfo] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!actionReferenceInfo || !isArray(actionReferences) || isEmpty(actionReferences)) {
      return;
    }
    setLoading(true);
    axios
      .get('/fields/actions/getReferenceInfo', {
        params: { ...actionReferenceInfo, actionReference: first(actionReferences) },
      })
      .then(({ data }) => {
        setInfo(data);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  }, [actionReferenceInfo, actionReferences]);

  if (!loading && !info) return null;

  return (
    <Grid container alignItems="center" sx={{ py: 1 }}>
      {loading ? (
        <CircularProgress size={24} />
      ) : (
        <>
          {info?.type === 'form' && (
            <>
              <Typography>
                Created from{' '}
                {info.itemType === 'field' ? (
                  <>
                    field <span style={{ fontWeight: 500 }}>{info?.field?.title}</span>
                  </>
                ) : (
                  <>
                    column <span style={{ fontWeight: 500 }}>{info?.columnTitle}</span> row #{info?.rowNumber}
                  </>
                )}{' '}
                in form
              </Typography>
              <Link to={`/forms/entry/${info?._id}`}>
                <Button
                  startIcon={<FormIcon />}
                  sx={{ ml: 1, textTransform: 'none', color: (theme) => theme.palette.text.primary }}
                >
                  {info?.title} - #{info?.number}
                </Button>
              </Link>
            </>
          )}
          {info?.type === 'process' && (
            <>
              <Typography>
                Created from step <span style={{ fontWeight: 500 }}>{info?.stepTitle}</span> from
              </Typography>
              <Link to={`/processes/step/${info._id}`}>
                <Button
                  startIcon={<ProcessIcon />}
                  sx={{ ml: 1, textTransform: 'none', color: (theme) => theme.palette.text.primary }}
                >
                  {info?.title} - #{info?.number}
                </Button>
              </Link>
            </>
          )}
          {info?.type === 'registerRow' && (
            <>
              <Typography>
                Created from register <span style={{ fontWeight: 500 }}>{info.title}</span> from
              </Typography>
              <Link to={`/registers/${info.registerId}?rowId=${info._id}&highlightRow=true`}>
                <Button
                  startIcon={<RegistersIcon />}
                  sx={{ ml: 1, textTransform: 'none', color: (theme) => theme.palette.text.primary }}
                >
                  row #{info?.index + 1}
                </Button>
              </Link>
            </>
          )}
          {info?.type === 'review' && (
            <>
              <Typography>
                Created from review <span style={{ fontWeight: 500 }}>{info.title}</span> from
              </Typography>
              <Button
                startIcon={<LibraryBooksRounded sx={{ color: grey[500] }} />}
                sx={{ ml: 1, textTransform: 'none', color: (theme) => theme.palette.text.primary }}
                onClick={() => StateManager.selectTask(info.taskId)}
              >
                Go to the review
              </Button>
            </>
          )}
          {info?.type === 'documentReview' && (
            <>
              <Typography>
                Created from review <span style={{ fontWeight: 500 }}>{info.title}</span> from
              </Typography>
              <Button
                startIcon={<LibraryBooksRounded sx={{ color: grey[500] }} />}
                sx={{ ml: 1, textTransform: 'none', color: (theme) => theme.palette.text.primary }}
                onClick={() => StateManager.openReviewDialog(info._id)}
              >
                Go to the review
              </Button>
            </>
          )}
          {info?.type === 'documentIssue' && (
            <>
              <Typography>
                Created from up-issue document{' '}
                <Box component="span" sx={{ fontWeight: 500 }}>
                  {info.title}{' '}
                  {formatMiniRevisionIteration(
                    info?.baseVersion,
                    info?.revisionFormat || VFORMAT,
                    info?.currentIteration,
                    info?.manualIssueControl,
                    info?.manualIssue,
                  )}
                </Box>{' '}
              </Typography>
              <Link to={`/editor/${info.document}/upissue/${info._id}`}>
                <Button
                  startIcon={<DocIcon />}
                  sx={{ ml: 1, textTransform: 'none', color: (theme) => theme.palette.text.primary }}
                >
                  Go to the up-issue
                </Button>
              </Link>
            </>
          )}
        </>
      )}
    </Grid>
  );
}
