import React, { useState, useEffect } from 'react';
import { Grid, CircularProgress, Typography, Button } from '@mui/material';
import axios from 'axios';
import StateManager from '../StateManager';
import { AuditIcon } from '../Icons';
import { Link } from 'react-router-dom';
import { HighlightOffRounded } from '@mui/icons-material';
import HtmlContainer from './HtmlContainer';

export default function NcrInfo({ ncrId, onClick, ...props }) {
  const [loading, setLoading] = useState(false);
  const [ncrInfo, setNcrInfo] = useState(null);

  useEffect(() => {
    if (!ncrId) {
      setNcrInfo(null);
      return;
    }
    setLoading(true);

    axios
      .get('/audits/ncrs/getNcrInfo', { params: { ncrId } })
      .then(({ data }) => {
        setNcrInfo(data);
        setLoading(false);
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
        setLoading(false);
      });
  }, [ncrId]);

  return (
    <Grid container {...props}>
      {loading ? (
        <CircularProgress />
      ) : ncrInfo ? (
        <>
          <Grid container item alignItems={'center'}>
            <HighlightOffRounded color="secondary" />
            <Typography variant="h6" sx={{ ml: 1 }}>
              {ncrInfo.title}
            </Typography>
          </Grid>
          <Grid container item sx={{ mt: 1 }}>
            <Typography>
              This is a non-conformance report for requirement{' '}
              <span style={{ fontWeight: 500, fontSize: '1.15em' }}>{ncrInfo.requirementTitle}</span> in audit{' '}
              <span style={{ fontWeight: 500, fontSize: '1.15em' }}>{ncrInfo.auditTitle}</span>
            </Typography>
          </Grid>
          {ncrInfo.description && (
            <Grid container item sx={{ mt: 2 }}>
              <HtmlContainer html={ncrInfo.description} />
            </Grid>
          )}
          {ncrInfo.state === 'cancelled' && (
            <Grid container item sx={{ mt: 1 }} direction={'column'}>
              <Typography variant="h6" color="secondary" gutterBottom>
                This NCR has been cancelled
              </Typography>
              <Typography style={{ whiteSpace: 'break-spaces' }}>Reason: {ncrInfo.cancelReason}</Typography>
            </Grid>
          )}
          <Grid container item sx={{ mt: 1 }}>
            <Link to={`/audits/${ncrInfo.auditId}?requirementId=${ncrInfo.requirementId}`}>
              <Button onClick={onClick} startIcon={<AuditIcon />}>
                Go to the audit
              </Button>
            </Link>
          </Grid>
        </>
      ) : null}
    </Grid>
  );
}
