import React, { useState } from 'react';
import { Grid, Typography, Paper, TextField } from '@mui/material';
import { User } from '../../../../Global/Components';
import { Send, CheckCircle } from '@mui/icons-material';
import { grey, green } from '@mui/material/colors';
import { formatDate } from '../../constants';
import { FormatDateCustom } from '../../../../Global/Functions';
import { useSelector } from 'react-redux';
import { NormalButton } from '../IssueComponents';

const ReviewApprovals = ({ data, disabled, onSubmit, title = 'Complete Review' }) => {
  const { user } = useSelector(({ profile }) => profile);
  const userId = user?._id;

  const [comment, setComment] = useState('');
  const [errorAlert, setErrorAlert] = useState(false);

  if (!data || data?.length === 0) return null;

  return (
    <Grid container>
      <Paper elevation={2} sx={{ width: '100%', borderRadius: '8px' }}>
        {title && (
          <Typography variant="h6" sx={{ padding: 2 }}>
            {title}
          </Typography>
        )}
        <Grid container sx={{ padding: 1 }}>
          {data?.map((item) => (
            <Grid
              item
              container
              direction="column"
              justifyContent="space-between"
              key={item._id}
              sx={{ margin: (theme) => theme.spacing(1, 0) }}
            >
              <Grid item container direction="column" justifyContent="space-between" alignItems="center">
                <Grid container alignItems="center">
                  <Grid item container lg={3} md={3} xs={3} alignItems="center" justifyContent="flex-start">
                    <User id={item.user} clickable dense />
                  </Grid>
                  <Grid
                    item
                    lg={9}
                    md={9}
                    xs={9}
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                  >
                    {item.submittedAt && (
                      <Typography
                        variant="h6"
                        sx={{
                          textTransform: 'capitalize',
                          color: green[500],
                          marginRight: '8px',
                        }}
                      >
                        {formatDate(item.submittedAt, 'DD/MM/YYYY')}
                      </Typography>
                    )}
                    {!item.submittedAt && userId !== item.user && (
                      <Typography sx={{ marginRight: '5px' }} color="textSecondary">
                        Pending
                      </Typography>
                    )}
                    {userId === item.user && !item.submittedAt && (
                      <Grid
                        item
                        lg={6}
                        md={6}
                        xs={9}
                        container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                      >
                        <NormalButton
                          endIcon={<Send />}
                          onClick={() => onSubmit(item._id, comment)}
                          disabled={comment.length === 0 || disabled}
                          label={'Complete Review'}
                          borderColor={green[300]}
                          activeBorderColor={green[500]}
                          labelColor={'white'}
                          activeLabelColor={'white'}
                          backgroundColor={green[500]}
                          borderStyle="none"
                        />
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                {userId === item.user && !item.submittedAt && (
                  <Grid item container sx={{ margin: (theme) => theme.spacing(1, 0) }}>
                    <Typography>
                      When you complete this review you are confirming you have fully read the document being reviewed
                    </Typography>
                  </Grid>
                )}
                {!item.submittedAt && userId === item.user && (
                  <Grid item container sx={{ margin: (theme) => theme.spacing(1, 0) }}>
                    <TextField
                      fullWidth
                      label="Leave a review"
                      variant="outlined"
                      multiline
                      value={comment}
                      onClick={() => setErrorAlert(true)}
                      onChange={(e) => setComment(e.target.value)}
                      minRows={2}
                      maxRows={4}
                      id="review-comments"
                      name="review-comments"
                      autoComplete="review-comments"
                      autoFocus
                      required
                      error={errorAlert && comment.length === 0}
                      helperText={errorAlert && comment.length === 0 ? 'Please enter some review comments' : ''}
                      disabled={disabled}
                    />
                  </Grid>
                )}
                {item.submittedAt && (
                  <Grid
                    item
                    container
                    key={data._id}
                    sx={{
                      border: `1px solid ${grey[300]}`,
                      borderRadius: '8px',
                      marginTop: (theme) => theme.spacing(1),
                    }}
                  >
                    <Grid
                      item
                      container
                      justifyContent="space-between"
                      alignItems="center"
                      sx={{ padding: (theme) => theme.spacing(1) }}
                    >
                      <Typography color="textSecondary" sx={{ fontSize: 14 }}>
                        {FormatDateCustom(item.submittedAt)}{' '}
                      </Typography>
                      {!item.submittedAt && <CheckCircle sx={{ color: green[500] }} />}
                    </Grid>
                    <Grid
                      item
                      container
                      sx={{ padding: (theme) => theme.spacing(1), borderTop: `1px solid ${grey[300]}` }}
                    >
                      <Typography>{item.outcome}</Typography>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Paper>
    </Grid>
  );
};

export default ReviewApprovals;
