import React, { useState, useRef, useEffect } from 'react';
import { Typography, DialogContent, DialogTitle, Grid, Tooltip, Paper } from '@mui/material';
import { Button, IconButton, Checkbox, useTheme, useMediaQuery, TextField } from '@mui/material';
import { FormControl, RadioGroup, FormControlLabel, Radio, ListItemButton } from '@mui/material';
import { Done, AddBoxOutlined, DeleteOutlineRounded } from '@mui/icons-material';
import { Whatshot, Add, Clear, PublishRounded } from '@mui/icons-material';
import { RoundedDialog, CrossButton, User, FromNow } from '../../../Global/Components';
import { HtmlContent, GeneralButton, DnDList, StandardDialogActions } from '../../../Global/Components';
import { blue, green, red, grey, amber, deepOrange, deepPurple } from '@mui/material/colors';
import { TaskIcon, RequiredIcon } from '../../../Global/Icons';
import Uploader from '../../../Global/Uploader';
import FileViewer from '../../../Global/FileViewer';
import PriorityButton from './PriorityButton';
import StatusButton from './StatusButton';
import CommentsSection from '../../../Global/CommentsSection';
import StateManager from '../../../Global/StateManager';
import { useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';
import { v4 } from 'uuid';
import axios from 'axios';

export default function SubTasks({
  subTasks,
  setSubTasks,
  mainTaskId,
  mainTaskCreator,
  isTemplate,
  selectedSubTaskId,
  disabled,
  isNewTask,
}) {
  const theme = useTheme();
  const { user } = useSelector(({ profile }) => profile);
  const userId = user?._id;
  const largeDevices = useMediaQuery(theme.breakpoints.up('sm'));
  const [selectedSubTask, setSelectedSubTask] = useState(false);
  const [shortTask, setShortTask] = useState(false);
  const [shortTaskTitle, setShortTaskTitle] = useState('');
  const [taskOpen, setTaskOpen] = useState(false);

  useEffect(() => {
    if (selectedSubTaskId && subTasks && subTasks[0]) {
      let index = subTasks.findIndex((x) => x.id === selectedSubTaskId);
      if (index > -1) {
        openSubTask(subTasks[index]);
      }
    }
  }, [selectedSubTaskId]); // eslint-disable-line react-hooks/exhaustive-deps

  const priorityColors = {
    None: grey[400],
    Low: green[500],
    Medium: amber[500],
    High: deepOrange[500],
    Critical: deepPurple[500],
  };

  function saveStatus(id, status) {
    let index = subTasks.findIndex((x) => x.id === id);
    if (index === -1) return;
    let task = subTasks[index];
    if (status !== task.status) {
      subTasks[index].status = status;
      setSubTasks([...subTasks]);
      axios
        .post('/tasks/saveSubTask', {
          mainTaskId,
          subTaskId: task.id,
          update: {
            field: 'status',
            value: status,
          },
        })
        .catch((err) => console.log(err));
    }
  }

  function deleteTask(id) {
    setSubTasks([...subTasks.filter((t) => t.id !== id)]);
  }

  function createSubTask() {
    if (!shortTaskTitle) return;
    const subTask = {
      id: v4(),
      title: shortTaskTitle,
      description: '',
      attachments: [],
      required: false,
      priority: 'Medium',
      createdBy: userId,
    };
    if (!isNewTask && !isTemplate) {
      axios.post('/tasks/createSubTask', { taskId: mainTaskId, subTask }).catch((err) => {
        console.log(err);
        setSubTasks([...subTasks.filter((x) => x.id !== subTask.id)]);
      });
    }
    setSubTasks([...subTasks, subTask]);
  }

  function openSubTask(task) {
    setSelectedSubTask(task);
    setTaskOpen(true);
  }

  function reorderSubTasks(tasks) {
    setSubTasks(tasks);
    if (isNewTask || isTemplate) return;
    const order = tasks.map((task, index) => ({ index, id: task.id }));
    axios.post('/tasks/reorderSubTasks', { mainTaskId, order }).catch((err) => StateManager.setAxiosErrorAlert(err));
  }

  function saveSubTask(task) {
    setTaskOpen(false);

    if (!task?.id) return;

    const index = subTasks.findIndex((x) => x.id === task.id);
    if (index > -1) {
      subTasks[index] = task;
    } else {
      subTasks.push(task);
    }
    setSubTasks([...subTasks]);
  }

  function renderTask(task, isDragging) {
    return (
      <Grid container item sx={{ py: 2, px: 1 }}>
        <Paper
          elevation={isDragging ? 8 : 3}
          sx={{ width: '100%', position: 'relative', background: isDragging ? '#9cff8d' : '' }}
        >
          {task?.required === true && (
            <RequiredIcon
              sx={{
                position: 'absolute',
                top: -10,
                left: -10,
              }}
            />
          )}
          {task?.type !== 'hub' && (
            <ListItemButton sx={{ p: 2, borderRadius: 1, width: '100%' }} onClick={() => openSubTask(task)}>
              <Grid item container alignItems="center" wrap="nowrap">
                <Whatshot fontSize="small" style={{ color: priorityColors[task.priority || 'Low'] }} />
                <TaskIcon style={{ margin: '0 8px', display: 'flex' }} />
                <Typography style={{ fontWeight: 600 }} noWrap={largeDevices}>
                  {task.title}
                </Typography>
                {!isTemplate && (
                  <StatusButton
                    style={{ marginLeft: 'auto' }}
                    status={task.status ? task.status : 'Not Started'}
                    setStatus={(status) => saveStatus(task.id, status)}
                    isDisabled={isNewTask || disabled || isTemplate}
                  />
                )}
              </Grid>
            </ListItemButton>
          )}
        </Paper>
      </Grid>
    );
  }

  return (
    <Grid container>
      <Grid container item>
        <Typography variant="h6">Sub tasks:</Typography>
      </Grid>
      <Grid container item>
        <DnDList items={subTasks} setItems={reorderSubTasks} renderItem={renderTask} disabled={disabled} spacing={0} />
      </Grid>

      {!shortTask && !disabled && (
        <Grid container item sx={{ pt: 1 }}>
          <GeneralButton startIcon={<AddBoxOutlined style={{ color: grey[500] }} />} onClick={() => setShortTask(true)}>
            Add sub task
          </GeneralButton>
        </Grid>
      )}

      {shortTask && (
        <Grid container item alignItems="center" style={{ marginTop: theme.spacing(2) }} lg={10} md={10} sm={12}>
          <TextField
            style={{ flexGrow: 1, margin: '0 1rem' }}
            placeholder="Write a title"
            autoFocus
            value={shortTaskTitle}
            autoComplete="off"
            onChange={(e) => setShortTaskTitle(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                createSubTask();
                setShortTask(false);
                setShortTaskTitle('');
              }
            }}
            variant="standard"
          />
          <Tooltip title="Done" placement="top">
            <IconButton
              onClick={() => {
                createSubTask();
                setShortTask(false);
                setShortTaskTitle('');
              }}
            >
              <Done style={{ color: green[500] }} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Add more" placement="top">
            <IconButton
              onClick={() => {
                setSelectedSubTask({ id: v4(), title: shortTaskTitle, createdBy: userId });
                setTaskOpen(true);
                setShortTask(false);
                setShortTaskTitle('');
              }}
            >
              <Add style={{ color: blue[500] }} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Cancel" placement="top">
            <IconButton
              onClick={() => {
                setShortTask(false);
                setShortTaskTitle('');
              }}
            >
              <Clear style={{ color: red[500] }} />
            </IconButton>
          </Tooltip>
        </Grid>
      )}

      <SubTaskDialog
        open={taskOpen}
        disabled={disabled}
        isTemplate={isTemplate}
        task={selectedSubTask}
        mainTaskId={mainTaskId}
        mainTaskCreator={mainTaskCreator}
        isNewTask={isNewTask}
        onDelete={deleteTask}
        onClose={saveSubTask}
        userId={userId}
      />
    </Grid>
  );
}

function SubTaskDialog({
  open,
  onClose,
  task,
  mainTaskId,
  mainTaskCreator,
  onDelete,
  disabled,
  isTemplate,
  isNewTask,
  userId,
}) {
  const SAVING_TIMEOUT = 1200;
  const theme = useTheme();
  const largeDevices = useMediaQuery(theme.breakpoints.up('sm'));
  const [title, setTitle] = useState('');
  const [required, setRequired] = useState(false);
  const [description, setDescripton] = useState('');
  const [attachments, setAttachments] = useState([]);
  const [status, setStatus] = useState('Not Started');
  const filesTimer = useRef(null);
  const [comments, setComments] = useState([]);
  const [priority, setPriority] = useState('Low');
  const [saving, setSaving] = useState(null);
  const [savedAt, setSavedAt] = useState(null);
  const titleTimer = useRef();
  const [upgrade, setUpgrade] = useState(false);
  const isTaskOwner = userId === mainTaskCreator || userId === task?.createdBy;
  const editable = isNewTask || isTemplate || isTaskOwner;

  useEffect(() => {
    setTitle(task?.title || '');
    setRequired(Boolean(task?.required));
    setDescripton(task?.description || '');
    setAttachments(task?.attachments || []);
    setStatus(task?.status || 'Not Started');
    setComments(task?.comments || []);
    setPriority(task?.priority || 'Low');
    setSaving('');
    setSavedAt(null);
  }, [task]);

  function getResult() {
    return {
      id: task?.id || v4(),
      status,
      title,
      description,
      attachments,
      comments,
      required,
      priority,
      number: task?.number,
      newMainTaskId: task?.newMainTaskId,
    };
  }

  function handleClose() {
    const result = getResult();
    if ((isTemplate || isNewTask) && !isEqual(result, task)) {
      StateManager.setConfirm('Task has unsaved changes', () => onClose(), 'Are you sure you want to discard them?');
      return;
    }
    onClose(isTemplate || isNewTask ? null : result);
  }

  function done() {
    const result = getResult();
    if (!result.title) {
      StateManager.setErrorAlert('Provide task title');
      return;
    }
    onClose(result);
  }

  function save(update) {
    if (isNewTask || isTemplate) return;
    setSavedAt(null);
    setSaving('Saving...');
    axios
      .post('/tasks/saveSubTask', {
        mainTaskId,
        subTaskId: task.id,
        update,
      })
      .then(() => {
        setSaving('Saved');
        setSavedAt(new Date());
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
        setSaving('Oops... failed to save');
      });
  }

  function scrollToBottom() {
    setTimeout(() => {
      const element = document.getElementById('sub-task-dialog-content');
      if (element) {
        element.scrollTop = element.scrollHeight;
      }
    }, 100);
  }

  function saveTitle(value) {
    setTitle(value);
    if (titleTimer.current) clearTimeout(titleTimer.current);
    titleTimer.current = setTimeout(save, SAVING_TIMEOUT, { field: 'title', value });
  }

  function saveDescription() {
    save({
      field: 'description',
      value: description,
    });
  }

  function savePriority(value) {
    setPriority(value);
    save({
      field: 'priority',
      value,
    });
  }

  function saveAttachments(value) {
    setAttachments(value);
    if (filesTimer.current !== null) clearTimeout(filesTimer.current);
    filesTimer.current = setTimeout(save, SAVING_TIMEOUT, { field: 'attachments', value });
  }

  function saveStatus(status) {
    setStatus(status);
    save({
      field: 'status',
      value: status,
    });
  }

  function saveRequired(value) {
    setRequired(value);
    save({
      field: 'required',
      value,
    });
  }

  function deleteComment(id) {
    setComments([...comments.filter((x) => x.id !== id)]);
    save({
      field: 'comments',
      action: 'remove',
      commentId: id,
    });
  }

  function saveComment(comment) {
    comments.push(comment);
    setComments([...comments]);
    save({
      field: 'comments',
      action: 'add',
      comment,
    });
  }

  function confirmDelete() {
    StateManager.setConfirm('You are about to delete this sub task', deleteTask);
  }

  function deleteTask() {
    onDelete(task.id);
    onClose(null);
    if (!isNewTask && !isTemplate) {
      axios
        .post('/tasks/deleteSubTask', {
          mainTaskId,
          subTaskId: task.id,
        })
        .catch((err) => console.log(err));
    }
  }

  function makeMainTask(action, newMainTaskId) {
    if (action === 'delete') {
      onDelete(task.id);
      onClose(null);
    } else {
      const result = getResult();
      if (!result.title) {
        StateManager.setErrorAlert('Provide task title');
        return;
      }
      result.newMainTaskId = newMainTaskId;
      onClose(result);
    }
  }

  return (
    <RoundedDialog
      maxWidth="md"
      fullWidth
      open={open}
      onClose={handleClose}
      className="scroll-bar"
      fullScreen={!largeDevices}
      titleId={'sub-task-dialog-title'}
    >
      <DialogTitle
        id={'sub-task-dialog-title'}
        style={{ padding: isTemplate || isNewTask ? undefined : 8, cursor: 'move' }}
      >
        <Grid container alignItems="center">
          <Grid item container xs={4}>
            {!isNewTask && !isTemplate && <User id={task?.createdBy || mainTaskCreator} avatarSize={30} />}
          </Grid>
          <Grid item container xs={4} justifyContent="center">
            <Typography variant="h6" color="textSecondary" noWrap>
              {task?.number || 'Sub Task'}
            </Typography>
          </Grid>
          <Grid item container xs={4} justifyContent="flex-end">
            <CrossButton onClick={handleClose} />
          </Grid>
        </Grid>
      </DialogTitle>

      <DialogContent id="sub-task-dialog-content" dividers>
        <Grid container alignItems="center" sx={{ py: 1 }}>
          <Grid container item xs={isTemplate || isNewTask ? 12 : 9} style={{ position: 'relative' }}>
            {(isTaskOwner || isTemplate || isNewTask) && !disabled ? (
              <TextField
                variant="outlined"
                placeholder="Sub task title"
                value={title}
                fullWidth
                inputProps={{ style: { fontSize: 24 } }}
                onChange={(e) => saveTitle(e.target.value)}
              />
            ) : (
              <Typography variant="h5" noWrap>
                {title}
              </Typography>
            )}
            {!isTaskOwner && required && !isNewTask && !isTemplate && (
              <RequiredIcon
                sx={{
                  position: 'absolute',
                  top: -10,
                  left: -10,
                }}
              />
            )}
          </Grid>
          {!isTemplate && !isNewTask && (
            <Grid
              container
              item
              xs={3}
              alignItems="center"
              justifyContent="flex-end"
              alignContent="flex-start"
              style={{ paddingLeft: 12 }}
            >
              <Typography style={{ fontWeight: 500, marginRight: 16 }}> Status</Typography>
              <StatusButton status={status} setStatus={saveStatus} isDisabled={disabled} />
            </Grid>
          )}
        </Grid>
        {(isTaskOwner || isTemplate || isNewTask) && !disabled && (
          <Grid container alignItems="center" sx={{ py: 1 }}>
            <FormControlLabel
              control={<Checkbox checked={required} color="primary" onChange={(e) => saveRequired(e.target.checked)} />}
              label="Required"
            />
          </Grid>
        )}

        {task?.newMainTaskId && (
          <Grid container item alignItems="center">
            <Typography
              style={{ fontWeight: 500, fontSize: 20 }}
              onClick={() => StateManager.selectTask(task.newMainTaskId)}
              sx={{
                color: blue[700],
                cursor: 'pointer',
                '&:hover': {
                  textDecoration: 'underline',
                },
              }}
            >
              This sub task was made a main task
            </Typography>
          </Grid>
        )}
        <Grid container item alignItems="center" sx={{ py: 1 }}>
          <Typography style={{ fontWeight: 600, marginRight: '1em' }}>Priority:</Typography>
          <PriorityButton priority={priority} setPriority={savePriority} disabled={!editable || disabled} />
        </Grid>

        <Grid container item sx={{ py: 1 }}>
          <HtmlContent
            title="Sub task description"
            placeholder="No description provided"
            content={description}
            editable={!disabled}
            onEdit={setDescripton}
            onSave={saveDescription}
            editIfEmpty={!!isTemplate || !!isNewTask}
            outlined
          />
        </Grid>

        {attachments.length > 0 && (
          <FileViewer files={attachments} onDelete={(id) => saveAttachments(attachments.filter((x) => x.id !== id))} />
        )}

        {!disabled && (
          <Grid container item sx={{ py: 1 }}>
            <Uploader clearOnDone uploaded={attachments} onChange={saveAttachments} />
          </Grid>
        )}

        {!isTemplate && !isNewTask && (
          <CommentsSection
            disabled={disabled}
            comments={comments}
            deleteComment={deleteComment}
            addComment={saveComment}
            onAddingCommentChange={(val) => {
              if (!val) return;
              scrollToBottom();
            }}
          />
        )}
      </DialogContent>

      <StandardDialogActions
        additionalActions={
          !disabled ? (
            <>
              {editable && (
                <Button style={{ color: red[500] }} onClick={confirmDelete} startIcon={<DeleteOutlineRounded />}>
                  Delete task
                </Button>
              )}
              {!task?.newMainTaskId && isTaskOwner && !isNewTask && !isTemplate && (
                <Button style={{ color: grey[600] }} onClick={() => setUpgrade(true)} startIcon={<PublishRounded />}>
                  Make main task
                </Button>
              )}
              <div style={{ marginRight: 'auto' }} />

              {!isNewTask && !isTemplate && (
                <FromNow
                  value={savedAt}
                  prefix={saving}
                  style={{ color: '#616161', marginRight: '1em', fontSize: 14, marginLeft: 'auto' }}
                />
              )}
            </>
          ) : null
        }
        onClose={handleClose}
        onDone={done}
      />

      <UpgradeOptions
        open={upgrade}
        onClose={() => setUpgrade(false)}
        onResult={makeMainTask}
        taskId={mainTaskId}
        subTaskId={task?.id}
      />
    </RoundedDialog>
  );
}

function UpgradeOptions({ open, onClose, onResult, taskId, subTaskId }) {
  const [action, setAction] = useState('delete');
  const [saving, setSaving] = useState(false);

  function makeMainTask() {
    setSaving(true);
    let body = {
      taskId,
      subTaskId,
      action,
    };
    axios
      .post('/tasks/makeMainTask', body)
      .then((res) => {
        StateManager.setSuccessAlert('Task created');
        setSaving(false);
        onResult(body.action, res.data._id);
        onClose();
        StateManager.selectTask(res.data._id);
      })
      .catch(() => {
        StateManager.setErrorAlert('Failed to create the task');
        setSaving(false);
      });
  }

  return (
    <RoundedDialog open={open} maxWidth="sm" onClose={onClose}>
      <DialogTitle>You are about to make this task a main task</DialogTitle>

      <DialogContent>
        <Grid container>
          <Typography gutterBottom variant="h6">
            What would you like to do with the sub task?
          </Typography>
        </Grid>
        <Grid container>
          <FormControl component="fieldset" variant="standard">
            <RadioGroup value={action} onChange={(event) => setAction(event.target.value)}>
              <FormControlLabel value="delete" control={<Radio color="primary" />} label="Delete sub task" />
              <FormControlLabel
                value="complete"
                control={<Radio color="primary" />}
                label="Mark sub task as complete"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
      </DialogContent>

      <StandardDialogActions saving={saving} onClose={onClose} onDone={makeMainTask} />
    </RoundedDialog>
  );
}
