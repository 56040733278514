import { useState, useEffect } from 'react';
import { Grid, CircularProgress, Typography, TextField, Button } from '@mui/material';
import { DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { DoneRounded, CloseRounded } from '@mui/icons-material';
import { RoundedDialog } from '../../../Global/Components';
import StateManager from '../../../Global/StateManager';
import Autocomplete from '@mui/material/Autocomplete';
import { red } from '@mui/material/colors';
import axios from 'axios';
import { FormIcon, ProcessIcon, RoutineIcon, AssetIcon, FieldIcon, RegistersIcon } from '../../../Global/Icons';
import Chart from '../../../Global/Charts/Chart';

export default function AddKpiDialog({ open, onClose, onResult }) {
  const [kpis, setKpis] = useState([]);
  const [selectedKpi, setSelectedKpi] = useState(null);
  const [kpi, setKpi] = useState(null);
  const [loadingKpis, setLoadingKpis] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    axios
      .get('/kpi/getAvailableKpis')
      .then((res) => {
        setKpis(res.data);
        setLoadingKpis(false);
      })
      .catch((err) => {
        console.error(err);
        setLoadingKpis(false);
      });
  }, []);

  useEffect(() => {
    setKpi(null);
    if (!selectedKpi) return;
    setLoading(true);

    axios
      .get('/kpi/general/getKpi', { params: { kpiId: selectedKpi._id, type: selectedKpi.type } })
      .then((res) => {
        setKpi(res.data);
        setLoading(false);
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
        setLoading(false);
      });
  }, [selectedKpi]);

  function save() {
    if (!kpi) return;
    onResult({ ...kpi, type: selectedKpi.type });
    setSelectedKpi(null);
    setKpi(null);
    onClose();
  }

  return (
    <RoundedDialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Select KPI</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid container item>
            <Autocomplete
              sx={{ mt: 1 }}
              fullWidth
              getOptionLabel={(option) => (option ? option.title : '')}
              options={kpis}
              loading={loading}
              value={selectedKpi}
              renderOption={(props, option) => (
                <li {...props}>
                  <Grid container item alignItems="center" wrap="nowrap">
                    {option.type === 'field' && <FieldIcon />}
                    {(option.dataSource?.type || option.type) === 'form' && <FormIcon />}
                    {(option.dataSource?.type || option.type) === 'process' && <ProcessIcon />}
                    {option.type === 'asset' && <AssetIcon />}
                    {option.type === 'routineField' && <RoutineIcon />}
                    {option.type === 'routineAdherence' && <RoutineIcon />}
                    {option.dataSource?.type === 'register' && <RegistersIcon />}
                    <Typography style={{ marginLeft: '1rem' }}>{option.title}</Typography>
                  </Grid>
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Search for a kpi"
                  variant="outlined"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {loadingKpis ? <CircularProgress color="primary" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                    startAdornment: (
                      <>
                        {selectedKpi?.type === 'field' && <FieldIcon />}
                        {selectedKpi?.type === 'form' && <FormIcon />}
                        {selectedKpi?.type === 'process' && <ProcessIcon />}
                        {selectedKpi?.type === 'asset' && <AssetIcon />}
                        {selectedKpi?.type === 'routineField' && <RoutineIcon />}
                        {selectedKpi?.type === 'routineAdherence' && <RoutineIcon />}
                      </>
                    ),
                  }}
                />
              )}
              onChange={(e, kpi) => {
                setSelectedKpi(kpi);
              }}
            />
          </Grid>

          <Grid container item justifyContent="center" alignItems="center" style={{ height: 320 }}>
            {loading && <CircularProgress color="primary" />}

            {kpi && (
              <Chart
                data={kpi.data}
                chartType={kpi.chartParams?.chartType || kpi.chartType}
                barChartType={kpi.chartParams?.barChartType || kpi.barChartType}
              />
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} style={{ borderRadius: 8, color: red[500] }} startIcon={<CloseRounded />}>
          close
        </Button>
        {kpi && (
          <Button
            onClick={save}
            variant="contained"
            color="primary"
            style={{ borderRadius: 8 }}
            startIcon={<DoneRounded />}
          >
            Add
          </Button>
        )}
      </DialogActions>
    </RoundedDialog>
  );
}
