import React, { useState, useEffect } from 'react';
import { Grid, Typography, FormControlLabel, Collapse, DialogContent, Switch, Tabs, Tab } from '@mui/material';
import { Checkbox } from '@mui/material';
import { CheckCircleRounded, CheckCircleOutlineRounded, InfoOutlined } from '@mui/icons-material';
import { User, GeneralButton, RoundedDialog, StandardDialogActions, UserChip } from '../../../Global/Components';
import { DatePicker, TimePicker } from '../../../Global/Components';
import { grey, green } from '@mui/material/colors';
import ViewStepDialog from '../components/ViewStepDialog';
import StateManager from '../../../Global/StateManager';
import { ProcessIcon, FormIcon, TaskIcon } from '../../../Global/Icons';

import { isArray, isEmpty, range } from 'lodash';
import moment from 'moment';
import axios from 'axios';

export default function NextStepDialog({ open, onClose, onResult, nextStepInfo }) {
  const defaultDueDate = moment().add(1, 'days').set({ hours: 17, minutes: 0, seconds: 0, milliseconds: 0 });
  const userId = localStorage.getItem('_id');
  const [section, setSection] = useState('users');
  const [completeActionIndex, setCompleteActionIndex] = useState(0);
  const [completeActionsParams, setCompleteActionsParams] = useState(null);
  const [startActionIndex, setStartActionIndex] = useState(0);
  const [startActionsParams, setStartActionsParams] = useState(null);
  const [nextStepInfoDialog, setNextStepInfoDialog] = useState(false);
  const [dueAt, setDueAt] = useState(null);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [users, setUsers] = useState([]);
  const [portalUsers, setPortalUsers] = useState([]);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [userTab, setUserTab] = useState(0);

  const completeActions = isArray(nextStepInfo?.completeActions) ? nextStepInfo.completeActions : [];
  const currCompleteAction = completeActions[completeActionIndex];
  const startActions = isArray(nextStepInfo?.startActions) ? nextStepInfo.startActions : [];
  const currStartAction = startActions[startActionIndex];

  const currCompleteActionFilled =
    currCompleteAction &&
    (!currCompleteAction.provideUserChoice ||
      (completeActionsParams &&
        completeActionsParams[currCompleteAction.id] &&
        !isEmpty(completeActionsParams[currCompleteAction.id].users)));

  const currStartActionFilled =
    currStartAction &&
    (!currStartAction.provideUserChoice ||
      (startActionsParams &&
        startActionsParams[currStartAction.id] &&
        !isEmpty(startActionsParams[currStartAction.id].users)));

  const loadUsers = nextStepInfo && nextStepInfo.nextStepUsers == null && allUsers.length === 0 && !nextStepInfo.final;

  useEffect(() => {
    if (!loadUsers) return;
    setLoadingUsers(true);
    axios
      .get('/user/getUsers', { params: { onlyId: true, separatePortal: true } })
      .then((res) => {
        setAllUsers(res.data.normal);
        if (!isEmpty(res.data.portal)) {
          setPortalUsers(res.data.portal);
        }
        setUserTab(0);
        setLoadingUsers(false);
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
        setLoadingUsers(false);
      });
  }, [loadUsers]);

  useEffect(() => {
    if (!nextStepInfo) return;
    setUserTab(0);
    setDueAt(nextStepInfo.presetTime);
    const usersChosen = isArray(nextStepInfo.nextStepUsers) && !isEmpty(nextStepInfo.nextStepUsers);
    const nextStepUsers = usersChosen ? nextStepInfo.nextStepUsers : allUsers;
    setUsers(nextStepUsers);
    if (usersChosen) {
      setPortalUsers([]);
    }
  }, [nextStepInfo, allUsers]);

  useEffect(() => {
    if (!nextStepInfo) return;
    const hasCompleteActions = isArray(nextStepInfo.completeActions) && !isEmpty(nextStepInfo.completeActions);
    if (hasCompleteActions) {
      setSection('completeActions');
    } else {
      setSection('users');
    }
  }, [nextStepInfo]);

  const hideDone =
    (section === 'completeActions' && !currCompleteActionFilled) ||
    (section === 'users' &&
      (!nextStepInfo || loadingUsers || (nextStepInfo?.usersPickable && isEmpty(selectedUsers)))) ||
    (section === 'startActions' && !currStartActionFilled);
  const portalTab = userTab === 1 && !isEmpty(portalUsers);

  function done() {
    if (section === 'completeActions') {
      if (completeActionIndex < completeActions.length - 1) {
        setCompleteActionIndex(completeActionIndex + 1);
      } else {
        if (!nextStepInfo.usersPickable && !nextStepInfo.timePickable) {
          if (isEmpty(startActions)) {
            onResult({ users: selectedUsers, dueDate: dueAt, completeActionsParams });
            onClose();
          } else {
            setSection('startActions');
          }
        } else {
          setSection('users');
        }
      }
    } else if (section === 'users') {
      if (isEmpty(startActions)) {
        onResult({ users: selectedUsers, dueDate: dueAt, completeActionsParams });
        onClose();
      } else {
        setSection('startActions');
      }
    } else if (section === 'startActions') {
      if (startActionIndex < startActions.length - 1) {
        setStartActionIndex(startActionIndex + 1);
      } else {
        onResult({ users: selectedUsers, dueDate: dueAt, completeActionsParams, startActionsParams });
        onClose();
      }
    }
  }

  return (
    <RoundedDialog open={open} onClose={onClose} maxWidth={'xs'} fullWidth className="scroll-bar">
      {section === 'completeActions' && currCompleteAction && (
        <DialogContent>
          <Grid container item style={{ margin: '1rem 0' }} wrap="nowrap" alignItems="center">
            {currCompleteAction.actionType === 'startProcess' && <ProcessIcon />}
            {currCompleteAction.actionType === 'startForm' && <FormIcon />}
            {currCompleteAction.actionType === 'sendTask' && <TaskIcon />}
            <Typography style={{ marginLeft: 8, whiteSpace: 'break-spaces' }}>
              Completing this step will{' '}
              {currCompleteAction.actionType === 'startProcess' && (
                <>
                  start process: {'\n'}
                  <span style={{ fontWeight: 500 }}>{currCompleteAction.processTitle}</span>
                </>
              )}
              {currCompleteAction.actionType === 'startForm' && (
                <>
                  start form entry: {'\n'}
                  <span style={{ fontWeight: 500 }}>{currCompleteAction.formTitle}</span>
                </>
              )}
              {currCompleteAction.actionType === 'sendTask' && (
                <>
                  send task: {'\n'}
                  <span style={{ fontWeight: 500 }}>{currCompleteAction.task?.title}</span>
                </>
              )}
            </Typography>
          </Grid>
          {currCompleteAction.provideUserChoice &&
            currCompleteAction.userType === 'selectedUsers' &&
            !isEmpty(currCompleteAction.users) &&
            isArray(currCompleteAction.users) && (
              <Grid container item style={{ margin: '1rem 0' }}>
                <Typography style={{ fontWeight: 500 }} gutterBottom>
                  Pick users:
                </Typography>
                <Grid container item>
                  {currCompleteAction.users.map((userId, i) => (
                    <User
                      id={userId}
                      key={i}
                      fullWidth
                      markPortal
                      onClick={() => {
                        const update =
                          completeActionsParams && completeActionsParams[currCompleteAction.id]
                            ? { ...completeActionsParams[currCompleteAction.id] }
                            : {};
                        const users = isArray(update.users) ? update.users : [];

                        if (users.includes(userId)) {
                          update.users = users.filter((x) => x !== userId);
                        } else {
                          update.users = [...users, userId];
                        }
                        setCompleteActionsParams({ ...completeActionsParams, [currCompleteAction.id]: update });
                      }}
                      action={
                        completeActionsParams &&
                        isArray(completeActionsParams[currCompleteAction.id]?.users) &&
                        completeActionsParams[currCompleteAction.id].users.includes(userId) ? (
                          <CheckCircleRounded fontSize="large" style={{ color: green[500] }} />
                        ) : (
                          <CheckCircleOutlineRounded fontSize="large" style={{ color: grey[300] }} />
                        )
                      }
                    />
                  ))}
                </Grid>
              </Grid>
            )}
          {currCompleteAction.startAnytime && (
            <Grid container item style={{ margin: '0.5rem 0' }}>
              {currCompleteAction.actionType !== 'sendTask' && (
                <Grid container item style={{ paddingBottom: 12 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={Boolean(
                          completeActionsParams && completeActionsParams[currCompleteAction.id]?.startLater,
                        )}
                        onChange={(e) => {
                          const update =
                            completeActionsParams && completeActionsParams[currCompleteAction.id]
                              ? { ...completeActionsParams[currCompleteAction.id], startLater: e.target.checked }
                              : { startLater: e.target.checked };

                          setCompleteActionsParams({ ...completeActionsParams, [currCompleteAction.id]: update });
                        }}
                      />
                    }
                    label={
                      currCompleteAction.actionType === 'startProcess'
                        ? 'Start process later'
                        : currCompleteAction.actionType === 'startForm'
                        ? 'Start form later'
                        : 'Send task later'
                    }
                  />
                </Grid>
              )}

              <Grid container>
                <FormControlLabel
                  control={
                    <Switch
                      color="primary"
                      checked={Boolean(completeActionsParams && completeActionsParams[currCompleteAction.id]?.dueAt)}
                      onChange={() => {
                        const dueAt = completeActionsParams
                          ? completeActionsParams[currCompleteAction.id]?.dueAt
                          : null;

                        const update =
                          completeActionsParams && completeActionsParams[currCompleteAction.id]
                            ? { ...completeActionsParams[currCompleteAction.id] }
                            : {};

                        update.dueAt = dueAt ? null : currCompleteAction.presetTime || defaultDueDate;

                        setCompleteActionsParams({ ...completeActionsParams, [currCompleteAction.id]: update });
                      }}
                    />
                  }
                  label="Include due date"
                />
              </Grid>
              <Collapse
                unmountOnExit
                style={{ width: '100%' }}
                in={Boolean(completeActionsParams && completeActionsParams[currCompleteAction.id]?.dueAt)}
              >
                <Grid container>
                  <Grid item container xs={6} style={{ paddingRight: 4 }}>
                    <DatePicker
                      value={completeActionsParams ? completeActionsParams[currCompleteAction.id]?.dueAt : null}
                      onChange={(dueAt) => {
                        const update =
                          completeActionsParams && completeActionsParams[currCompleteAction.id]
                            ? { ...completeActionsParams[currCompleteAction.id], dueAt }
                            : { dueAt };

                        setCompleteActionsParams({ ...completeActionsParams, [currCompleteAction.id]: update });
                      }}
                    />
                  </Grid>

                  <Grid item container xs={6} style={{ paddingLeft: 4 }}>
                    <TimePicker
                      value={completeActionsParams ? completeActionsParams[currCompleteAction.id]?.dueAt : null}
                      onChange={(dueAt) => {
                        const update =
                          completeActionsParams && completeActionsParams[currCompleteAction.id]
                            ? { ...completeActionsParams[currCompleteAction.id], dueAt }
                            : { dueAt };

                        setCompleteActionsParams({ ...completeActionsParams, [currCompleteAction.id]: update });
                      }}
                    />
                  </Grid>
                </Grid>
              </Collapse>
            </Grid>
          )}
        </DialogContent>
      )}
      {section === 'users' && !nextStepInfo?.final && (
        <DialogContent>
          {nextStepInfo?.nextStep?.title && (
            <Grid container item style={{ margin: '1rem 0' }} justifyContent="space-between" alignItems="center">
              <Typography variant="h6">{`Next step: ${nextStepInfo?.nextStep.title}`}</Typography>

              <GeneralButton
                onClick={() => setNextStepInfoDialog(true)}
                startIcon={<InfoOutlined style={{ color: grey[700] }} />}
              >
                Info
              </GeneralButton>
              <ViewStepDialog
                step={nextStepInfo?.nextStep}
                open={nextStepInfoDialog}
                onClose={() => setNextStepInfoDialog(false)}
              />
            </Grid>
          )}

          {nextStepInfo?.timePickable && (
            <Grid container>
              <Grid container>
                <FormControlLabel
                  control={
                    <Switch
                      color="primary"
                      checked={Boolean(dueAt)}
                      onChange={() => setDueAt(dueAt ? null : nextStepInfo.presetTime || defaultDueDate)}
                    />
                  }
                  label="Include due date"
                />
              </Grid>
              <Collapse style={{ width: '100%' }} in={Boolean(dueAt)}>
                <Grid container>
                  <Grid item container xs={6} style={{ paddingRight: 4 }}>
                    <DatePicker value={dueAt} onChange={setDueAt} />
                  </Grid>

                  <Grid item container xs={6} style={{ paddingLeft: 4 }}>
                    <TimePicker value={dueAt} onChange={setDueAt} />
                  </Grid>
                </Grid>
              </Collapse>
            </Grid>
          )}

          {loadingUsers ? (
            <Grid container>
              {range(3).map((i) => (
                <User key={i} fullWidth />
              ))}
            </Grid>
          ) : (
            <>
              <Grid container style={{ paddingTop: '1rem', paddingBottom: '0.5rem' }}>
                <Typography variant="h6">Next step users:</Typography>
              </Grid>
              {!isEmpty(selectedUsers) && nextStepInfo?.usersPickable && !isEmpty(portalUsers) && (
                <Grid item container style={{ margin: '0.5rem 0' }}>
                  {selectedUsers.map((id, i) => (
                    <UserChip
                      margin={4}
                      size="small"
                      key={i}
                      id={id}
                      onDelete={() => selectedUsers.filter((x) => x !== id)}
                    />
                  ))}
                </Grid>
              )}
              {!isEmpty(portalUsers) && (
                <Tabs
                  indicatorColor="primary"
                  textColor="primary"
                  value={userTab}
                  onChange={(e, i) => setUserTab(i)}
                  style={{ marginBottom: 8, width: '100%' }}
                >
                  <Tab label="Users" />
                  <Tab label="Portal" />
                </Tabs>
              )}

              <Grid container style={{ margin: '1rem 0', maxHeight: '40vh', overflow: 'auto' }}>
                {users.includes(userId) && !portalTab && (
                  <User
                    id={userId}
                    fullWidth
                    markPortal
                    onClick={() => {
                      if (!nextStepInfo?.usersPickable) return;
                      if (selectedUsers.includes(userId)) {
                        setSelectedUsers(selectedUsers.filter((x) => x !== userId));
                      } else {
                        setSelectedUsers([...selectedUsers, userId]);
                      }
                    }}
                    action={
                      nextStepInfo?.usersPickable ? (
                        selectedUsers.includes(userId) ? (
                          <CheckCircleRounded fontSize="large" style={{ color: green[500] }} />
                        ) : (
                          <CheckCircleOutlineRounded fontSize="large" style={{ color: grey[300] }} />
                        )
                      ) : null
                    }
                  />
                )}
                {(portalTab ? portalUsers : users)
                  .filter((x) => x !== userId)
                  .map((user) => (
                    <User
                      key={user}
                      id={user}
                      fullWidth
                      markPortal
                      onClick={() => {
                        if (!nextStepInfo?.usersPickable) return;
                        if (selectedUsers.includes(user)) {
                          setSelectedUsers(selectedUsers.filter((x) => x !== user));
                        } else {
                          setSelectedUsers([...selectedUsers, user]);
                        }
                      }}
                      action={
                        nextStepInfo?.usersPickable ? (
                          selectedUsers.includes(user) ? (
                            <CheckCircleRounded fontSize="large" style={{ color: green[500] }} />
                          ) : (
                            <CheckCircleOutlineRounded fontSize="large" style={{ color: grey[300] }} />
                          )
                        ) : null
                      }
                    />
                  ))}
              </Grid>
            </>
          )}
        </DialogContent>
      )}
      {section === 'startActions' && (
        <DialogContent>
          <Grid container item style={{ margin: '1rem 0' }} wrap="nowrap" alignItems="center">
            {currStartAction.actionType === 'startProcess' && <ProcessIcon />}
            {currStartAction.actionType === 'startForm' && <FormIcon />}
            {currStartAction.actionType === 'sendTask' && <TaskIcon />}
            <Typography style={{ marginLeft: 8, whiteSpace: 'break-spaces' }}>
              Starting the next step will{' '}
              {currStartAction.actionType === 'startProcess' && (
                <>
                  start process: {'\n'}
                  <span style={{ fontWeight: 500 }}>{currStartAction.processTitle}</span>
                </>
              )}
              {currStartAction.actionType === 'startForm' && (
                <>
                  start form entry: {'\n'}
                  <span style={{ fontWeight: 500 }}>{currStartAction.formTitle}</span>
                </>
              )}
              {currStartAction.actionType === 'sendTask' && (
                <>
                  send task: {'\n'}
                  <span style={{ fontWeight: 500 }}>{currStartAction.task?.title}</span>
                </>
              )}
            </Typography>
          </Grid>
          {currStartAction.provideUserChoice &&
            currStartAction.userType === 'selectedUsers' &&
            !isEmpty(currStartAction.users) &&
            isArray(currStartAction.users) && (
              <Grid container item style={{ margin: '1rem 0' }}>
                <Typography style={{ fontWeight: 500 }} gutterBottom>
                  Pick users:
                </Typography>
                <Grid container item>
                  {currStartAction.users.map((userId, i) => (
                    <User
                      id={userId}
                      key={i}
                      fullWidth
                      markPortal
                      onClick={() => {
                        const update =
                          startActionsParams && startActionsParams[currStartAction.id]
                            ? { ...startActionsParams[currStartAction.id] }
                            : {};
                        const users = isArray(update.users) ? update.users : [];

                        if (users.includes(userId)) {
                          update.users = users.filter((x) => x !== userId);
                        } else {
                          update.users = [...users, userId];
                        }
                        setStartActionsParams({ ...startActionsParams, [currStartAction.id]: update });
                      }}
                      action={
                        startActionsParams &&
                        isArray(startActionsParams[currStartAction.id]?.users) &&
                        startActionsParams[currStartAction.id].users.includes(userId) ? (
                          <CheckCircleRounded fontSize="large" style={{ color: green[500] }} />
                        ) : (
                          <CheckCircleOutlineRounded fontSize="large" style={{ color: grey[300] }} />
                        )
                      }
                    />
                  ))}
                </Grid>
              </Grid>
            )}
          {currStartAction.startAnytime && (
            <Grid container item style={{ margin: '0.5rem 0' }}>
              {currStartAction.actionType !== 'sendTask' && (
                <Grid container item style={{ paddingBottom: 12 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={Boolean(startActionsParams && startActionsParams[currStartAction.id]?.startLater)}
                        onChange={(e) => {
                          const update =
                            startActionsParams && startActionsParams[currStartAction.id]
                              ? { ...startActionsParams[currStartAction.id], startLater: e.target.checked }
                              : { startLater: e.target.checked };

                          setStartActionsParams({ ...startActionsParams, [currStartAction.id]: update });
                        }}
                      />
                    }
                    label={
                      currStartAction.actionType === 'startProcess'
                        ? 'Start process later'
                        : currStartAction.actionType === 'startForm'
                        ? 'Start form later'
                        : 'Send task later'
                    }
                  />
                </Grid>
              )}

              <Grid container>
                <FormControlLabel
                  control={
                    <Switch
                      color="primary"
                      checked={Boolean(startActionsParams && startActionsParams[currStartAction.id]?.dueAt)}
                      onChange={() => {
                        const dueAt = startActionsParams ? startActionsParams[currStartAction.id]?.dueAt : null;

                        const update =
                          startActionsParams && startActionsParams[currStartAction.id]
                            ? { ...startActionsParams[currStartAction.id] }
                            : {};

                        update.dueAt = dueAt ? null : currStartAction.presetTime || defaultDueDate;

                        setStartActionsParams({ ...startActionsParams, [currStartAction.id]: update });
                      }}
                    />
                  }
                  label="Include due date"
                />
              </Grid>
              <Collapse
                unmountOnExit
                style={{ width: '100%' }}
                in={Boolean(startActionsParams && startActionsParams[currStartAction.id]?.dueAt)}
              >
                <Grid container>
                  <Grid item container xs={6} style={{ paddingRight: 4 }}>
                    <DatePicker
                      value={startActionsParams ? startActionsParams[currStartAction.id]?.dueAt : null}
                      onChange={(dueAt) => {
                        const update =
                          startActionsParams && startActionsParams[currStartAction.id]
                            ? { ...startActionsParams[currStartAction.id], dueAt }
                            : { dueAt };

                        setStartActionsParams({ ...startActionsParams, [currStartAction.id]: update });
                      }}
                    />
                  </Grid>

                  <Grid item container xs={6} style={{ paddingLeft: 4 }}>
                    <TimePicker
                      value={startActionsParams ? startActionsParams[currStartAction.id]?.dueAt : null}
                      onChange={(dueAt) => {
                        const update =
                          startActionsParams && startActionsParams[currStartAction.id]
                            ? { ...startActionsParams[currStartAction.id], dueAt }
                            : { dueAt };

                        setStartActionsParams({ ...startActionsParams, [currStartAction.id]: update });
                      }}
                    />
                  </Grid>
                </Grid>
              </Collapse>
            </Grid>
          )}
        </DialogContent>
      )}

      <StandardDialogActions onClose={onClose} onDone={done} hideDone={hideDone} />
    </RoundedDialog>
  );
}
